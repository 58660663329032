import React, {useEffect, useState, useRef} from "react";
import {getListModels, getListModelsMS, deleteModel} from "../../hooks/AppProvider";
import ParserTemplComponentsModels from "../../utils/ParserTemplComponentsModels";
import TagsInput from 'react-tagsinput'

import 'react-tagsinput/react-tagsinput.css'
import {
    WrapperChooseModel,
    BoxListChooseModel,
    ChooseModelTarget,
    ButtonDeleteModel, ItemResponseModal, WrapButtonsModal,
    BoxModelTagMessage,
    BoxModelInfoTagMsg,
    ButtonModelAddTag,
    PanelOverlayModal,
    BoxItemTextPlatform,
    BoxItemOverlayPlatform,
    BoxErrorInlineOverPlatform,
    BoxResponseOverPlatform,
    MainContentModel

} from "../../styles/Modals";
import TextModel from "./models/messenger/TextModel";
import Send01 from "../../source/svg/Send01";
import {ButtonSendModel, WrapLoadMessageBar} from "../../styles/businessConversation";
import {saveReplyConsoleMS} from "../../hooks/AppProvider";
import SvgAdd01 from "../../source/svg/Add01";
import {Button011, Button01, Button013, Button02} from "../../styles/Commons";
import Loader from "../../utils/Loader";
import Main_settings from "../../styles/main_settings/Main_settings";
import Delete01 from "../../source/svg/Delete01";
import TextButtonsModel from "./models/messenger/TextButtonsModel";
import SimpleBar from "simplebar-react";
import TextQuickReplies from "./models/messenger/TextQuickReplies";
import SliderModel from "./models/messenger/SliderModel";


function ModalChooseModelMS(props) {
    const properties = props
    const app = properties.dataModal.data.app
    const [models, setModels] = useState(false)
    const [modelTarget, setModelTarget] = useState(false)

    const [showResponse, setShowResponse] = useState(false)
    const [responseMessage, setResponseMessage] = useState(false);
    const [eventTarget, setEventTarget] = useState(false)
    const [showSavedReply, setShowSavedReply] = useState(false)
    const [showButtonSavedReply, setShowButtonSavedReply] = useState(false)
    const [tags, setTags] = useState( [])
    const [nameSavedReply, setNameSavedREply] = useState(false)
    const [showButtonSend, setShowButtonSend] = useState(false)
    const [showMessageReplaceReplie, setShowMessageReplaceReplie] = useState(false)

    const [showLoadingSavedReplie, setShowLoadingSavedReplie] = useState(false)

    const [messagingError, setMessagingError] = useState(false)
    const [messageResponse, setMessageResponce] = useState(false)

    const taginput = useRef(null)

    //console.log(properties)

    let nameUser

    if (properties.dataModal.data.user) {
        if (properties.dataModal.data.user.name) nameUser = properties.dataModal.data.user.name
        else nameUser = properties.dataModal.data.user.sender
    }
    useEffect(() => {
        getModels()
    }, []);

    useEffect(() => {
        if (showResponse) {
            setTimeout(function(){
                setShowResponse(false)
                if (responseMessage) setResponseMessage(false)

            }, 2000);
        }
    }, [showResponse]);

    useEffect(() => {
        if (responseMessage) {
            setShowButtonSend(true)
        }
        else {
            setShowButtonSend(false)
        }
    }, [responseMessage]);


    const getModels = async () =>  {
        let listmodels = await getListModelsMS(app._id)
        //console.log(listmodels)
        if (!listmodels.error) {
            if (listmodels.models.length) {
                setModels(listmodels.models)
                setModelTarget(listmodels.models[0].name)
            }
            else {
                setModels([])
            }

        }

    }


    const createModel = async (e) => {

        properties.setModalSystem({show:true, source:"modal-choose-modal", action:"create-model-conversation", data:{app:app}})
    }

    const handleChange = (tags) => {
        //console.log(tags)
        setTags(tags)
    }
    const changeInputSavedReply = (e) => {
        e.preventDefault()
        if (e.target.value != "") {
            setNameSavedREply(e.target.value)
            setShowButtonSavedReply(true)
        }
        else {
            setNameSavedREply(false)
            setShowButtonSavedReply(false)
        }
    }
    const actionSend = async (e) => {
        if (tags.length) {
            responseMessage.code.tags = tags
        }
        properties.setResultModalSystem({target:"business-message-bar-MS", model:{name:responseMessage.name, code:responseMessage.code}})
        properties.setModalSystem({show:false, action:null, source:null, data:null})

    }
    const actionCloseResponse = async (e) => {
        if (nameSavedReply) setNameSavedREply(false)
        if (messageResponse) setMessageResponce(false)
        if (showMessageReplaceReplie) setShowLoadingSavedReplie(false)
        actionShowSavedReply()
    }


    const actionShowSavedReply = async (e) => {
        //console.log(responseMessage)
        if (showSavedReply) {
            setShowSavedReply(false)
        }
        else  setShowSavedReply(true)
    }

    const actionSavedReply = async (replace) => {
        if (nameSavedReply) {
            if (/\s/.test(nameSavedReply)) {
                setMessagingError("La risposta non deve contenere spazi!")
            }
            else {
                if (messagingError) setMessagingError(false)
                if (tags.length) {
                    responseMessage.code.tags = tags
                }
                let findmodel = models.find(element => element.name === modelTarget)
                if (findmodel) {
                    setShowLoadingSavedReplie(true)
                    let callSavedReply = await saveReplyConsoleMS({
                        app_id:app._id,
                        name_model:nameSavedReply,
                        user_id:properties.user._id,
                        body: responseMessage,
                        model:findmodel,
                        replace:replace
                    })
                    setShowLoadingSavedReplie(false)
                    if (!callSavedReply.error) {
                        if (callSavedReply.exist) {
                            setShowMessageReplaceReplie(true)
                        }
                        else {
                            setMessageResponce("La risposta è stata salvata con successo! Ora la puoi trovare nella voce '⬅ Risposte salvate' nel menu (▪▪▪) della barra messaggio.")
                            properties.setResultModalSystem({target:"business-message-bar-MS", action:"update-savedreplies"})
                        }

                    }
                    else {
                        setMessageResponce(true)
                        setMessagingError("Ops, mi spiace ma qualcosa è andato storto. Riprova più tardi.")
                    }
                }

            }
        }
    }




    const onChangeChooseModel = async (e) => {
        setModelTarget(e.target.value)
    }

    const actionCancelReplaceSavedReplie = async (e) => {
        setShowMessageReplaceReplie(false)
        if (nameSavedReply) setNameSavedREply(false)
        setShowButtonSavedReply(false)
    }

    return (
       <div>
           {(() => {
                   if (modelTarget && models.length) {
                       return (
                           <>
                               <WrapperChooseModel>

                                       <h1>Scegli il modello da inviare a {nameUser}</h1>
                                       <BoxListChooseModel>
                                           <select defaultValue={modelTarget} onChange={onChangeChooseModel}>
                                               {(() => {
                                                   return models.map((child, i) => {
                                                       let namemodel
                                                       switch(child.name) {
                                                           case"textModel":
                                                               namemodel = "Modello solo testo"
                                                               break;
                                                           case"textButtonsModel":
                                                               namemodel = "Modello testo e bottoni permanenti"
                                                               break;
                                                           case"textQuickReplies":
                                                               namemodel = "Modello testo e bottoni rapidi"
                                                               break;
                                                           case"slider":
                                                               namemodel = "Modello slider"
                                                               break;
                                                       }
                                                       return (
                                                           <option
                                                               key={i}
                                                               value={child.name} >
                                                               {namemodel}
                                                           </option>
                                                       )
                                                   })
                                               })()}
                                           </select>
                                       </BoxListChooseModel>
                                       <MainContentModel>
                                           <SimpleBar autoHide={false} style={{maxHeight: "400px", width:"100%"}}>
                                               {(() => {
                                                   if (modelTarget) {
                                                       if (modelTarget === "textModel") {
                                                           return (
                                                               <TextModel
                                                                   setResponseMessage={setResponseMessage}
                                                               />
                                                           )

                                                       }
                                                       if (modelTarget === "textButtonsModel") {
                                                           return (
                                                               <TextButtonsModel
                                                                   setResponseMessage={setResponseMessage}
                                                               />
                                                           )

                                                       }
                                                       if (modelTarget === "textQuickReplies") {
                                                           return (
                                                               <TextQuickReplies
                                                                   setResponseMessage={setResponseMessage}
                                                               />
                                                           )

                                                       }
                                                       if (modelTarget === "slider") {
                                                           return (
                                                               <SliderModel
                                                                   setResponseMessage={setResponseMessage}
                                                               />
                                                           )

                                                       }
                                                   }
                                               })()}
                                           </SimpleBar>
                                       </MainContentModel>
                                       <BoxModelTagMessage>
                                           <BoxModelInfoTagMsg>
                                               <h3>Tags</h3>
                                               <p>(I tags sono informazioni di tipo semantico molto utili in fase di ricerca ed invio di messaggi mirati)</p>
                                           </BoxModelInfoTagMsg>
                                               <TagsInput inputProps={{
                                               placeholder: 'Aggiungi tag'
                                           }} ref={taginput} value={tags} onChange={handleChange} />
                                           <ButtonModelAddTag title="Aggiungi" onClick={() => taginput.current.accept()}><SvgAdd01 /></ButtonModelAddTag>
                                       </BoxModelTagMessage>
                                       <WrapButtonsModal>
                                           {(showButtonSend) ? <Button01 title="invia" onClick={actionSend} >Invia </Button01> : <Button011>Invia</Button011> }
                                           {(showButtonSend) ? <Button01 title="invia" onClick={actionShowSavedReply} >Salva  </Button01> : <Button011>Salva</Button011>}
                                       </WrapButtonsModal>

                                   {(() => {
                                       if (showSavedReply) {
                                           return (
                                                   <PanelOverlayModal>
                                                       <h2>Salva la risposta</h2>
                                                       {(() => {
                                                           if (showLoadingSavedReplie) {
                                                               return (
                                                                   <>
                                                                       <WrapLoadMessageBar>
                                                                           <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                                                       </WrapLoadMessageBar>
                                                                   </>
                                                               )
                                                           }
                                                           else {
                                                               if (messageResponse) {

                                                                   return (
                                                                       <>
                                                                           <BoxResponseOverPlatform>{(messagingError) ? <BoxErrorInlineOverPlatform><p>{(messagingError)}</p></BoxErrorInlineOverPlatform> : <p>{(messageResponse)}</p>}</BoxResponseOverPlatform>
                                                                           <WrapButtonsModal>
                                                                               <Button01 title="Ok chiudi" onClick={actionCloseResponse} >Ok</Button01>
                                                                           </WrapButtonsModal>
                                                                       </>
                                                                   )
                                                               }
                                                               if (showMessageReplaceReplie) {
                                                                   return (
                                                                       <>
                                                                           <BoxItemOverlayPlatform>
                                                                               <p>Esiste già una risposta salvata con questo nome. Vuoi sovrascriverla?</p>
                                                                           </BoxItemOverlayPlatform>
                                                                           <WrapButtonsModal>
                                                                               <Button01 title="Sovrascrivi" onClick={() => actionSavedReply("true")} >Sovrascrivi </Button01>
                                                                               <Button02 title="Sovrascrivi" onClick={actionCancelReplaceSavedReplie} >Annulla </Button02>
                                                                           </WrapButtonsModal>
                                                                       </>
                                                                   )

                                                               }
                                                               else {
                                                                   return (
                                                                       <>
                                                                           {(messagingError) ? <BoxErrorInlineOverPlatform><p>{(messagingError)}</p></BoxErrorInlineOverPlatform> : null}
                                                                           <BoxItemOverlayPlatform>
                                                                               <input type="text" onChange={changeInputSavedReply} placeholder="Inserisci il nome della risposta (senza spazi)..." />
                                                                           </BoxItemOverlayPlatform>
                                                                           <WrapButtonsModal>

                                                                               {(showButtonSavedReply) ?<Button01 title="invia" onClick={() => actionSavedReply("false")} >Salva </Button01> : <Button011>Salva</Button011>}
                                                                               <Button02 onClick={(e) => actionShowSavedReply()}>Annulla</Button02>
                                                                           </WrapButtonsModal>
                                                                       </>
                                                                   )
                                                               }
                                                           }

                                                       })()}
                                                   </PanelOverlayModal>
                                               )


                                       }
                                   })()}

                               </WrapperChooseModel>

                           </>
                       )
                   }
                   else {
                       return (
                           <>

                           </>
                       )
                   }


           })()}
       </div>

    )
}

export default ModalChooseModelMS