import React, {useState, useEffect, useRef} from "react";
import {
    BusinessMessageBarWrapper,
    BusinessConvWrapper,
    BusinessConvContainer,
    BusinessFilterContainer,
    BusinessAllConvContainer,
    BusinessConvListWrapper,
    BusinessConvChatWrap,
    BusinessConvDetailsChat,
    BusinessUserConversationWrapper,
    BusinessNoConvContainer,
    BoxLoader
} from "../../../../styles/businessConversation"
import SimpleBar from "simplebar-react";
import {disableBodyScroll} from "body-scroll-lock";
import {getListConversation, getListConversationWA, getListConversationMS} from "../../../../hooks/ConversationProvider";
import BusOptionFilter from "./BusOptionFIlter";
import BusListConversations from "./BusListConversations";
import BusTargetConversationChat from "./BusTargetConversationChat";
import {reconnectingSocket} from "../../../../socket/clientsocket";
import setting from "../../../../settings/setting";
import branch from "../../../../settings/branch";
import BusTargetConversationUserChat from "./BusTargetConversationUser";
import BusMessageBar from "./Messagebar/BusMessageBar";
import {BoxMainConversation} from "../../../../styles/businessConversation";
import BusListConversationsWA from "./WhatsApp/BusListConversationsWA";
import BusTargetConversationChatWA from "./WhatsApp/BusTargetConversationChatWA";
import BusTargetConversationUserWA from "./WhatsApp/BusTargetConversationUserWA"
import BusMessageBarWA from "./WhatsApp/MessageBar/BusMessageBarWA"
import Loader from "../../../../utils/Loader";
import Main_settings from "../../../../styles/main_settings/Main_settings";
import {getApp} from "../../../../hooks/AppProvider";
import BusListConversationsMS from "./Messenger/BusListConversationsMS";
import BusTargetConversationChatMS from "./Messenger/BusTargetConversationChatMS";
import BusMessageBarMS from "./Messenger/MessageBar/BusMessageBarMS";
import BusTargetConversationUserMS from "./Messenger/BusTargetConversationUserMS";
const heightheader= 64


function BusinessConversationsPage(props) {
    const properties = props

    const [platformTarget, setPlatformTarget] = useState(false)
    const [optLangConversation, setOptLangConversation] = useState(props.app.languages.default_language)
    const [listConversations, setListConversations] = useState(false)
    const [totoalConversations, setTotalConversations] = useState(false)
    const [updatelistConversations, setUpdateListConversations] = useState(false)

    const [listLang, setListLang] = useState([])

    const [listActors, setListActors] = useState([])

    const [loadConversations, setLoadConversations] = useState(false)
    const [currentConversation, setCurrentConversation] = useState(false)
    const [currentConversationWA, setCurrentConversationWA] = useState(false)
    const [currentConversationMS, setCurrentConversationMS] = useState(false)
    const [currentConversationDetails, setCurrentConversationDetails] = useState(false)
    const [currentConversationDetailsWA, setCurrentConversationDetailsWA] = useState(false)
    const [currentConversationDetailsMS, setCurrentConversationDetailsMS] = useState(false)

    const [currentUserConvDetails, setCurrentUserConvDetails] = useState(false)
    const [currentUserConvDetailsWA, setCurrentUserConvDetailsWA] = useState(false)
    const [currentUserConvDetailsMS, setCurrentUserConvDetailsMS] = useState(false)

    const [updateConversationBmama, setUpdateConversationBmama] = useState(false)
    const [updateConversationWhatsApp, setUpdateConversationWhatsApp] = useState(false)
    const [updateConversationMessenger, setUpdateConversationMessenger] = useState(false)


    const [updateUserWhatsApp, setUpdateUserWhatsApp] = useState(false)
    const [updateUserMessenger, setUpdateUserMessenger] = useState(false)

    const [optStandbyApp, setOptStandbyApp] = useState(false)
    const [optStandbyAppWA, setOptStandbyAppWA] = useState(false)
    const [optStandbyAppMS, setOptStandbyAppMS] = useState(false)

    const [client, setClient] = useState(false)
    const [isConnected, setIsConnected] = useState(false);
    const consolesocketurl = setting[branch.name].clientsocket_url;
    //for size element dashboard
    const [resizeWindow, setResizeWindow] = useState({
        height: window.innerHeight - heightheader,
        width: window.innerWidth
    })
    const [widthBodyDettConversation, setWidthBodyDettConversation] = useState(false)
    const [heightBodyDettConversation, setHeightBodyDettConversation] = useState(false)
    const [heightConsoleNoFilter, setHeightConsoleNoFilter] = useState(false)
    const [reactivateSimplebar , setReactivateSimplebar] = useState(false)

    const bodymainconversation = useRef(null)
    const filterconversation = useRef(null)
    const messagebarel = useRef(null)


    useEffect(() => {
        function handleResize() {
            setResizeWindow({
                height: window.innerHeight - heightheader,
                width: window.innerWidth
            })
            if (bodymainconversation.current && bodymainconversation.current.clientWidth) setWidthBodyDettConversation(bodymainconversation.current.clientWidth)

        }
        let urlclient = consolesocketurl + "?appid=" + props.app._id

        if (props.app.state && props.app.state.standby && props.app.state.standby.app && props.app.state.standby.app === "true") {
            setOptStandbyApp(true)
        }
        if (props.app.external && props.app.external.platforms && props.app.external.platforms.whatsApp) {

            let whatsappApp = props.app.external.platforms.whatsApp
            if (whatsappApp.state && whatsappApp.state.standby && whatsappApp.state.standby.app === "true") {
                setOptStandbyAppWA(true)
            }
        }
        if (props.app.external && props.app.external.platforms && props.app.external.platforms.messenger) {

            let messengerApp = props.app.external.platforms.messenger
            if (messengerApp.state && messengerApp.state.standby && messengerApp.state.standby.app === "true") {
                setOptStandbyAppMS(true)
            }
        }
        if (!platformTarget) {
            if (props.locationsearch) {
                if (props.locationsearch === "?whatsapp") {
                    setPlatformTarget(

                        "WhatsApp")
                }
                else if (props.locationsearch === "?messenger") {
                    setPlatformTarget("Messenger")
                }
                else {
                    setPlatformTarget("Botmama")
                }
            }
            else setPlatformTarget("Botmama")
        }
        setLoadConversations(true)
        disableBodyScroll(document)
        setClient(reconnectingSocket(urlclient))
        window.addEventListener('resize', handleResize)
    }, []);

    useEffect(() => {
        if (client) {
            setIsConnected(client.isConnected())
        }
    }, [client]);


    setInterval(function () {
        if (client) {
            client.getClient().onmessage = (message) => {
                let objinfo = JSON.parse(message.data)
                let updateinfo = new Date().toISOString().replace(/\s/g, '') + "$" + message.data
                if (objinfo.platform === "Botmama") {
                    setUpdateConversationBmama(updateinfo)
                }
                if (objinfo.platform === "WhatsApp") {
                    if (objinfo.action === "standby") {
                        setUpdateUserWhatsApp(new Date())
                    }
                    else {
                        setUpdateConversationWhatsApp(updateinfo)
                    }
                }
                if (objinfo.platform === "Messenger") {
                    if (objinfo.action === "standby") {
                        setUpdateUserMessenger(new Date())
                    }
                    else {
                        setUpdateConversationMessenger(updateinfo)
                    }
                }
            };
        }
    }, 60000);







    useEffect(() => {
        if (listConversations.length && !currentConversation) {
            if (platformTarget === "Botmama") setCurrentConversation(listConversations[0])
            if (platformTarget === "WhatsApp") setCurrentConversationWA(listConversations[0])
            if (platformTarget === "Messenger") setCurrentConversationMS(listConversations[0])
        }
    }, [listConversations]);

    //for size element dashboard
    useEffect(() => {
        if (filterconversation.current && filterconversation.current.clientHeight) setHeightConsoleNoFilter(resizeWindow.height - filterconversation.current.clientHeight)
        if (heightConsoleNoFilter && messagebarel.current && messagebarel.current.clientHeight) {
            setHeightBodyDettConversation(heightConsoleNoFilter - messagebarel.current.clientHeight )
        }
    }, [resizeWindow]);

    useEffect(() => {
       if (bodymainconversation.current && bodymainconversation.current.clientWidth) setWidthBodyDettConversation(bodymainconversation.current.clientWidth)

    }, [bodymainconversation.current]);

    useEffect(() => {
        if (filterconversation.current && filterconversation.current.clientHeight) {
            setHeightConsoleNoFilter(resizeWindow.height - filterconversation.current.clientHeight)
        }
    }, [filterconversation.current]);


    useEffect(() => {
        if (heightConsoleNoFilter && messagebarel.current && messagebarel.current.clientHeight) {
            setHeightBodyDettConversation(heightConsoleNoFilter - messagebarel.current.clientHeight)
        }
    }, [heightConsoleNoFilter]);


    useEffect(() => {
        if (heightConsoleNoFilter && messagebarel.current && messagebarel.current.clientHeight) {
            setHeightBodyDettConversation(heightConsoleNoFilter - messagebarel.current.clientHeight)
        }
    }, [loadConversations]);



    //


    useEffect(() => {
        if (platformTarget) {
            setLoadConversations(true)
            setCurrentConversation(false)
            setListConversations([])
            if (platformTarget === "Botmama") {
                callConversationsBmama()
            }
            else if (platformTarget === "WhatsApp") {
                callConversationsWhatsApp()
            }
            else if (platformTarget === "Messenger") {
                callConversationsMessenger()
            }
        }

    }, [platformTarget]);


    useEffect(() => {
        if (optLangConversation) {
            setLoadConversations(true)
            setCurrentConversation(false)
            if (platformTarget === "Botmama") {
                callConversationsBmama()
            }
            else if (platformTarget === "WhatsApp") {
                callConversationsWhatsApp()
            }
            else if (platformTarget === "Messenger") {
                callConversationsMessenger()
            }
        }

    }, [optLangConversation]);





    if (client) {
        client.getClient().onmessage = (message) => {
            let objinfo = JSON.parse(message.data)
            let updateinfo = new Date().toISOString().replace(/\s/g, '') + "$" + message.data
            if (objinfo.platform === "Botmama") {
                setUpdateConversationBmama(updateinfo)
            }
            if (objinfo.platform === "WhatsApp") {
                if (objinfo.action === "standby") {
                    setUpdateUserWhatsApp(new Date())
                }
                else {
                    setUpdateConversationWhatsApp(updateinfo)
                }
            }
            if (objinfo.platform === "Messenger") {
                if (objinfo.action === "standby") {
                    setUpdateUserMessenger(new Date())
                }
                else {
                    setUpdateConversationMessenger(updateinfo)
                }
            }
        };
    }




    async function callConversationsBmama() {
        let currentLang= optLangConversation
        let calllistconversations = await getListConversation({app_id:properties.app._id, lang:currentLang});

        if (!calllistconversations.error) {

            setListConversations(calllistconversations.conversations)
            setTotalConversations(calllistconversations.total_conversations)

            if (calllistconversations.actors) {
                //console.log(calllistconversations.actors)
                setListActors(calllistconversations.actors)
            }
        }
        setLoadConversations(false)
        setListLang(calllistconversations.languages)
    }

    async function callConversationsWhatsApp() {
        let calllistconversations = await getListConversationWA({app_id:props.app._id});

        if (!calllistconversations.error) {
            setListConversations(calllistconversations.conversations)
            setTotalConversations(calllistconversations.total_conversations)
            setLoadConversations(false)
        }
    }

    async function callConversationsMessenger() {
        let calllistconversations = await getListConversationMS({app_id:props.app._id});

        if (!calllistconversations.error) {
            setListConversations(calllistconversations.conversations)
            setTotalConversations(calllistconversations.total_conversations)
            setLoadConversations(false)
        }
    }


    return (
        <>
            <BusinessConvWrapper heightwrapper={resizeWindow.height}>
                {(() => {
                    if (properties.permissions) {
                        return (
                            <>
                                <BusinessConvContainer>
                                    <BusinessFilterContainer ref={filterconversation}>
                                        <BusOptionFilter
                                            app={props.app}
                                            setApp={props.setApp}
                                            user={props.user}
                                            setCurrentConversation={setCurrentConversation}
                                            updateConversation={updateConversationBmama}
                                            optLangConversation={optLangConversation}
                                            setOptLangConversation={setOptLangConversation}
                                            listLang={listLang}
                                            setOptStandbyApp={setOptStandbyApp}
                                            setOptStandbyAppWA={setOptStandbyAppWA}
                                            setOptStandbyAppMS={setOptStandbyAppMS}
                                            optStandbyApp={optStandbyApp}
                                            optStandbyAppWA={optStandbyAppWA}
                                            optStandbyAppMS={optStandbyAppMS}
                                            platformTarget={platformTarget}
                                            setPlatformTarget={setPlatformTarget}
                                            listConversations={listConversations}
                                            setModalSystem={props.setModalSystem}
                                            setResultModalSystem={props.setResultModalSystem}
                                            currentConversationWA={currentConversationWA}
                                            currentConversation={currentConversation}
                                        />
                                    </BusinessFilterContainer>
                                    <BusinessAllConvContainer heightconvcontainer={heightConsoleNoFilter}>
                                        {(() => {
                                            if (loadConversations) {
                                                return (
                                                    <>
                                                        <BoxLoader heightloader={heightConsoleNoFilter}>
                                                            <Loader width="60" height="60" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                                        </BoxLoader>
                                                    </>
                                                )
                                            }
                                            else {
                                                if (listConversations.length) {
                                                    return (
                                                        <>
                                                            <BusinessConvListWrapper heightlistwrapper={heightConsoleNoFilter}>
                                                                <SimpleBar style={{maxHeight: heightConsoleNoFilter}}>
                                                                    {(() => {
                                                                        if (platformTarget && platformTarget === "Botmama") {
                                                                            return(
                                                                                <>
                                                                                    <BusListConversations
                                                                                        app={props.app}
                                                                                        user={props.user}
                                                                                        setClearToken={props.setClearToken}
                                                                                        setApp={props.setApp}
                                                                                        setToken={props.setToken}
                                                                                        setCurrentConversation={setCurrentConversation}
                                                                                        updateConversation={updateConversationBmama}
                                                                                        optLangConversation={optLangConversation}
                                                                                        listLang={listLang}
                                                                                        setListLang={setListLang}
                                                                                        listConversations={listConversations}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        currentConversation={currentConversation}
                                                                                        updatelistConversations={updatelistConversations}
                                                                                        heightList={heightConsoleNoFilter}
                                                                                        totalConversations={totoalConversations}
                                                                                        setTotalConversations={setTotalConversations}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (platformTarget && platformTarget === "WhatsApp") {
                                                                            return(
                                                                                <>
                                                                                    <BusListConversationsWA
                                                                                        app={props.app}
                                                                                        user={props.user}
                                                                                        setClearToken={props.setClearToken}
                                                                                        setApp={props.setApp}
                                                                                        setToken={props.setToken}
                                                                                        setCurrentConversation={setCurrentConversationWA}
                                                                                        updateConversation={updateConversationWhatsApp}
                                                                                        listLang={listLang}
                                                                                        setListLang={setListLang}
                                                                                        listConversations={listConversations}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        currentConversation={currentConversationWA}
                                                                                        updatelistConversations={updatelistConversations}
                                                                                        heightList={heightConsoleNoFilter}
                                                                                        totalConversations={totoalConversations}
                                                                                        setTotalConversations={setTotalConversations}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (platformTarget && platformTarget === "Messenger") {
                                                                            return (
                                                                                <>
                                                                                    <BusListConversationsMS
                                                                                        app={props.app}
                                                                                        user={props.user}
                                                                                        setClearToken={props.setClearToken}
                                                                                        setApp={props.setApp}
                                                                                        setToken={props.setToken}
                                                                                        setCurrentConversation={setCurrentConversationMS}
                                                                                        updateConversation={updateConversationMessenger}
                                                                                        listLang={listLang}
                                                                                        setListLang={setListLang}
                                                                                        listConversations={listConversations}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        currentConversation={currentConversationMS}
                                                                                        updatelistConversations={updatelistConversations}
                                                                                        heightList={heightConsoleNoFilter}
                                                                                        totalConversations={totoalConversations}
                                                                                        setTotalConversations={setTotalConversations}


                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}
                                                                </SimpleBar>
                                                            </BusinessConvListWrapper>
                                                            <BusinessConvChatWrap ref={bodymainconversation} heightconvchatwrap={heightConsoleNoFilter}>
                                                                <BusinessConvDetailsChat heightconvdetailschat={heightBodyDettConversation} >
                                                                    <SimpleBar style={{maxHeight: heightBodyDettConversation}}>
                                                                        {(() => {
                                                                            if (platformTarget && platformTarget === "Botmama") {
                                                                                return (
                                                                                    <>
                                                                                        <BusTargetConversationChat
                                                                                            app={props.app}
                                                                                            user={props.user}
                                                                                            setClearToken={props.setClearToken}
                                                                                            setApp={props.setApp}
                                                                                            setToken={props.setToken}
                                                                                            updateConversation={updateConversationBmama}
                                                                                            conversation={currentConversation}
                                                                                            setCurrentConversationDetails={setCurrentConversationDetails}
                                                                                            setCurrentUserConvDetails={setCurrentUserConvDetails}
                                                                                            optLangConversation={optLangConversation}
                                                                                            setModalSystem={props.setModalSystem}
                                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                                            resultModalSystem={props.resultModalSystem}
                                                                                            optLangConversation={optLangConversation}
                                                                                            setUpdateListConversations={setUpdateListConversations}
                                                                                            actors={listActors}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }

                                                                            if (platformTarget && platformTarget === "WhatsApp") {
                                                                                return (
                                                                                    <>
                                                                                        <BusTargetConversationChatWA
                                                                                            app={props.app}
                                                                                            user={props.user}
                                                                                            setClearToken={props.setClearToken}
                                                                                            setApp={props.setApp}
                                                                                            setToken={props.setToken}
                                                                                            updateConversation={updateConversationWhatsApp}
                                                                                            conversation={currentConversationWA}
                                                                                            setCurrentConversationDetails={setCurrentConversationDetailsWA}
                                                                                            setCurrentUserConvDetails={setCurrentUserConvDetailsWA}
                                                                                            setModalSystem={props.setModalSystem}
                                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                                            resultModalSystem={props.resultModalSystem}
                                                                                            setUpdateListConversations={setUpdateListConversations}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }
                                                                            if (platformTarget && platformTarget === "Messenger") {
                                                                                return (
                                                                                    <>
                                                                                        <BusTargetConversationChatMS
                                                                                            app={props.app}
                                                                                            user={props.user}
                                                                                            setClearToken={props.setClearToken}
                                                                                            setApp={props.setApp}
                                                                                            setToken={props.setToken}
                                                                                            updateConversation={updateConversationMessenger}
                                                                                            conversation={currentConversationMS}
                                                                                            setCurrentConversationDetails={setCurrentConversationDetailsMS}
                                                                                            setCurrentUserConvDetails={setCurrentUserConvDetailsMS}
                                                                                            setModalSystem={props.setModalSystem}
                                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                                            resultModalSystem={props.resultModalSystem}
                                                                                            setUpdateListConversations={setUpdateListConversations}

                                                                                        />
                                                                                    </>
                                                                                )

                                                                            }
                                                                        })()}
                                                                    </SimpleBar>
                                                                </BusinessConvDetailsChat>
                                                                <BusinessMessageBarWrapper ref={messagebarel} widthmessagebarwrapper={widthBodyDettConversation}>

                                                                    {(() => {

                                                                        if (platformTarget && platformTarget === "Botmama") {
                                                                            return(
                                                                                <>
                                                                                    <BusMessageBar
                                                                                        app={properties.app}
                                                                                        userchat={currentUserConvDetails}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        resultModalSystem={props.resultModalSystem}
                                                                                        conversation={currentUserConvDetails}
                                                                                        updateConversation={updateConversationBmama}
                                                                                        optLangConversation={optLangConversation}
                                                                                        currentConversation={currentConversation}
                                                                                        actors={listActors}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (platformTarget && platformTarget === "WhatsApp") {
                                                                            return(
                                                                                <>
                                                                                    <BusMessageBarWA
                                                                                        app={properties.app}
                                                                                        userchat={currentUserConvDetailsWA}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        resultModalSystem={props.resultModalSystem}
                                                                                        conversation={currentUserConvDetailsWA}
                                                                                        updateConversation={updateConversationWhatsApp}
                                                                                        currentConversation={currentConversationWA}
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                        if (platformTarget && platformTarget === "Messenger") {
                                                                            return (
                                                                                <>
                                                                                    <BusMessageBarMS
                                                                                        app={properties.app}
                                                                                        userchat={currentUserConvDetailsMS}
                                                                                        setModalSystem={props.setModalSystem}
                                                                                        setResultModalSystem={props.setResultModalSystem}
                                                                                        resultModalSystem={props.resultModalSystem}
                                                                                        conversation={currentUserConvDetailsMS}
                                                                                        updateConversation={updateConversationMessenger}
                                                                                        currentConversation={currentConversationMS}

                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                    })()}


                                                                </BusinessMessageBarWrapper>
                                                            </BusinessConvChatWrap>
                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <>
                                                            <BusinessNoConvContainer>
                                                                <p>Non sono presenti conversazioni</p>
                                                            </BusinessNoConvContainer>
                                                        </>
                                                    )
                                                }
                                            }

                                        })()}

                                    </BusinessAllConvContainer>
                                </BusinessConvContainer>
                                {(() => {
                                    if (!loadConversations) {
                                        if (listConversations.length) {

                                            return (

                                                <BusinessUserConversationWrapper heightbar={resizeWindow.height}>
                                                    <SimpleBar style={{maxHeight: heightConsoleNoFilter, width:"100%"}}>
                                                        {(() => {
                                                            if (platformTarget && platformTarget === "Botmama") {
                                                                return (
                                                                    <>
                                                                        <BusTargetConversationUserChat
                                                                            app={props.app}
                                                                            user={props.user}
                                                                            setClearToken={props.setClearToken}
                                                                            setApp={props.setApp}
                                                                            setToken={props.setToken}
                                                                            conversation={currentConversation}
                                                                            optStandbyApp={optStandbyApp}
                                                                            setModalSystem={props.setModalSystem}
                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                            resultModalSystem={props.resultModalSystem}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            if (platformTarget && platformTarget === "WhatsApp") {
                                                                return (
                                                                    <>
                                                                        <BusTargetConversationUserWA
                                                                            app={props.app}
                                                                            user={props.user}
                                                                            setClearToken={props.setClearToken}
                                                                            setApp={props.setApp}
                                                                            setToken={props.setToken}
                                                                            conversation={currentConversationWA}
                                                                            optStandbyApp={optStandbyAppWA}
                                                                            updateUserWhatsApp={updateUserWhatsApp}
                                                                            setModalSystem={props.setModalSystem}
                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                            resultModalSystem={props.resultModalSystem}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                            if (platformTarget && platformTarget === "Messenger") {

                                                                return (
                                                                    <>
                                                                        <BusTargetConversationUserMS
                                                                            app={props.app}
                                                                            user={props.user}
                                                                            setClearToken={props.setClearToken}
                                                                            setApp={props.setApp}
                                                                            setToken={props.setToken}
                                                                            conversation={currentConversationMS}
                                                                            optStandbyApp={optStandbyAppMS}
                                                                            updateUserWhatsApp={updateUserMessenger}
                                                                            setModalSystem={props.setModalSystem}
                                                                            setResultModalSystem={props.setResultModalSystem}
                                                                            resultModalSystem={props.resultModalSystem}

                                                                        />
                                                                    </>

                                                                    )

                                                            }
                                                        })()}
                                                    </SimpleBar>
                                                </BusinessUserConversationWrapper>
                                            )
                                        }
                                    }
                                })()}
                            </>
                        )
                    }
                    else {
                        <>
                            <p>Non hai i permessi per visualizzare questa pagina</p>
                        </>
                    }

                })()}
            </BusinessConvWrapper>
        </>
    )
}



export default BusinessConversationsPage;
