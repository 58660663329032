import React from 'react';

const Emojii01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path id="bAreaRounded60" display="none" d="M342.4,512.5H169.6C76,512.5-0.5,436-0.5,342.4V169.6C-0.5,76,76-0.5,169.6-0.5h172.8
	C436-0.5,512.5,76,512.5,169.6v172.8C512.5,436,436,512.5,342.4,512.5z"/>
        <path id="bAreaRounded40" display="none" d="M399.1,512.5H112.9
	c-62.4,0-113.4-51-113.4-113.4V112.9c0-62.4,51-113.4,113.4-113.4h286.2c62.4,0,113.4,51,113.4,113.4v286.2
	C512.5,461.5,461.5,512.5,399.1,512.5z"/>
        <path id="bAreaRounded20" display="none" d="M455.8,512.5H56.2C25,512.5-0.5,487-0.5,455.8V56.2C-0.5,25,25-0.5,56.2-0.5h399.6
	c31.2,0,56.7,25.5,56.7,56.7v399.6C512.5,487,487,512.5,455.8,512.5z"/>
        <path id="bAreaRounded10" display="none" d="M484.7,513H28.3C12.8,513,0,500.2,0,484.7V28.3C0,12.8,12.8,0,28.3,0h456.3
	C500.2,0,513,12.8,513,28.3v456.3C513,500.2,500.2,513,484.7,513z"/>
        <rect id="bAreaSquare" display="none" width="513" height="513"/>
        <circle id="bAreaCircle" display="none" cx="255" cy="257" r="253"/>
        <path id="bArea01" fill="#212121" d="M248.5,97c82,0,148.5,66.5,148.5,148.5S330.5,394,248.5,394S100,327.5,100,245.5
	S166.5,97,248.5,97z M248.5,119.3c-69.7,0-126.2,56.5-126.2,126.2s56.5,126.2,126.2,126.2s126.2-56.5,126.2-126.2
	S318.2,119.3,248.5,119.3z M196,286.8c12.6,16,31.8,25.5,52.5,25.5c20.7,0,39.9-9.5,52.5-25.5c3.8-4.8,10.8-5.6,15.6-1.8
	c4.8,3.8,5.6,10.8,1.8,15.6c-16.8,21.3-42.3,33.9-70,33.9c-27.7,0-53.2-12.7-70-34c-3.8-4.8-3-11.8,1.9-15.6
	C185.2,281.2,192.2,282,196,286.8z M204,197.3c10.2,0,18.5,8.3,18.5,18.5s-8.3,18.5-18.5,18.5c-10.2,0-18.5-8.3-18.5-18.5
	S193.7,197.3,204,197.3z M293,197.3c10.2,0,18.5,8.3,18.5,18.5s-8.3,18.5-18.5,18.5s-18.5-8.3-18.5-18.5S282.8,197.3,293,197.3z"/>
    </svg>
);

export default Emojii01;