import React, {useState, useEffect, useRef} from "react";
import Header from "../Header/Header";
import {getApp} from "../../hooks/AppProvider";
import DevSettingsPage from "./Development/DevSettingsPage";
import Menubar from "./Menubar";


import BusinessConversationPage from "./Business/Conversations/BusinessConversationPage";

import BusStatsPage from "./Business/Stats/BusStatsPage";
import {
    MainWrapper,
    AppConsoleWrapper,
    MenubarAppConsole,
    BodyAppConsole
} from "../../styles/AppConsole"
import setting from "../../settings/setting";
import branch from "../../settings/branch";

const sectiondev = "development"
const sectionbusiness = "business"
const sectiondevsettings= "settings"
const sectiondevroles = "roles"
const sectionbsconv= "conversations"
const sectionbsstats = "stats"
const sectionbsroles = "roles"
const sectionbsbilling = "billing"

function AppConsole(props) {

    const [app, setApp] = useState()
    const [userAllowDev, setUserAllowDev] = useState({
        settings:false,
        roles: false,
        doc:true
    })
    const [userAllowBusiness, setUserAllowBusiness] = useState({
        conversations:false,
        stats: false,
        roles:false,
        billing:false
    })
    const [changeObj, setChangeObj] = useState("")

    const nameapp = props.match.params.nameapp
    const areaconsole= props.match.params.area
    const pageconsole = props.match.params.page
    const locationsearch = props.location.search
    const [resizeWindow, setResizeWindow] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })
    const [heightHeader, setHeightHeader] = useState(false)
    const [heightConsole, setHeightConsole] = useState(false)

    useEffect(() => {
        function handleResize() {
            setResizeWindow({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize)
        checkUrl()
    }, []);

    useEffect(() => {
        let heightc = resizeWindow.height - heightHeader
        setHeightConsole(heightc)
    }, [heightHeader]);


    useEffect(() => {
        let heightc = resizeWindow.height - heightHeader
        setHeightConsole(heightc)
    }, [resizeWindow]);

    useEffect(() => {

        if (app) {

            setPermissions()
            setResizeWindow({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }
    }, [app, props.user]);




    async function checkUrl() {
        let righturl = false

        if (areaconsole === sectiondev || areaconsole === sectionbusiness) {
            switch (pageconsole) {
                case sectiondevsettings:
                case sectiondevroles:
                case sectionbsconv:
                case sectionbsstats:
                case sectionbsbilling:
                    righturl = true
            }
        }
        if (righturl) {
            goApp()
        }
        else {
            //window.location.href = "/"
        }
    }


    async function goApp() {
        let callapp = await getApp(nameapp);
        if (callapp && !callapp.error) {
            let apptarget = callapp.app
            let permissionlist = apptarget.manage.permissions
            setApp(callapp.app)
            setChangeObj(new Date())
        }
        else {
            window.location.href = "/"
        }
    }


    async function setPermissions() {
        let objallowDev = userAllowDev
        let objAllowBusiness = userAllowBusiness

        let permissionlist = app.manage.permissions
        for (let z = 0; z < permissionlist.length; z++) {
            if (permissionlist[z].user_id === props.user._id) {
                let thisroles = permissionlist[z].roles_id
                if (thisroles.length) {
                    for (let i = 0; i < thisroles.length; i++) {
                        //development admin
                        if (thisroles[i] === "d1") {
                            objallowDev.settings = true
                            objallowDev.roles = true
                        }
                        //business admin
                        if (thisroles[i] === "b1") {
                            objAllowBusiness.conversations = true
                            objAllowBusiness.stats = true
                            objAllowBusiness.roles = true
                            objAllowBusiness.billing = true
                        }
                    }
                }
            }
        }
        setUserAllowDev(objallowDev)
        setUserAllowBusiness(objAllowBusiness)
        setChangeObj(new Date())
    }

    return (
        <React.Fragment>
            {(() => {
                if (app && props.user) {
                    return (
                        <>
                            <MainWrapper>
                                <Header showApp={{show:true, name:nameapp}} setHeightHeader={setHeightHeader} setListApp={props.setListApp} app={app} listApp={props.listApp} user={props.user} setClearToken={props.setClearToken} setToken={props.setToken} />
                                <AppConsoleWrapper>
                                    <MenubarAppConsole>
                                        <Menubar
                                            app={app}
                                            area={areaconsole}
                                            page={pageconsole}
                                            user={props.user}
                                            setClearToken={props.setClearToken}
                                            setApp={setApp}
                                            setToken={props.setToken}
                                            devpermission={userAllowDev}
                                            buspermission = {userAllowBusiness}
                                        />
                                    </MenubarAppConsole>
                                        <BodyAppConsole>
                                            {(() => {
                                                if (areaconsole === sectiondev) {
                                                    if (pageconsole === sectiondevsettings) {
                                                        return (
                                                            <DevSettingsPage
                                                                permissions={userAllowDev.settings}
                                                                app={app}
                                                                user={props.user}
                                                                setClearToken={props.setClearToken}
                                                                setApp={setApp}
                                                                setToken={props.setToken}
                                                            />
                                                        )
                                                    }
                                                }
                                                if (areaconsole === sectionbusiness) {
                                                    if (pageconsole === sectionbsconv) {
                                                        return (
                                                            <BusinessConversationPage
                                                                permissions={userAllowBusiness.conversations}
                                                                app={app}
                                                                user={props.user}
                                                                setClearToken={props.setClearToken}
                                                                setApp={setApp}
                                                                setToken={props.setToken}
                                                                heightConsole={heightConsole}
                                                                elDisableScroll={props.elDisableScroll}
                                                                setModalSystem={props.setModalSystem}
                                                                setResultModalSystem={props.setResultModalSystem}
                                                                resultModalSystem={props.resultModalSystem}
                                                                heightHeader={heightHeader}
                                                                locationsearch={locationsearch}
                                                            />
                                                        )
                                                    }
                                                    if (pageconsole === sectionbsstats) {
                                                        return (
                                                            <BusStatsPage
                                                                permissions={userAllowBusiness.stats}
                                                                app={app}
                                                                user={props.user}
                                                                setClearToken={props.setClearToken}
                                                                setApp={setApp}
                                                                setToken={props.setToken}
                                                            />
                                                        )
                                                    }
                                                }
                                            })()}
                                        </BodyAppConsole>
                                </AppConsoleWrapper>
                            </MainWrapper>
                        </>
                    )
                }
            })()}
        </React.Fragment>


    )
}

export default AppConsole;
