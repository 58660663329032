import React, {useState, useEffect, useRef} from "react"
import {setMessengerTemplate} from "../../../../utils/platformTemplates";
import {
    ChooseModelTarget,
    ChooseModelPlatform,
    InfoModelPLatform,
    BoxElementModelPlatform,
    BoxItemTextPlatform

} from "../../../../styles/Modals";

function TextModel(props) {
    const textareatext= useRef(null)

    useEffect(() => {
        if (props.replie) {
            changeContentModel()
        }
    }, []);

    useEffect(() => {
        if (props.replie) {
            changeContentModel()
        }
    }, [props.replie]);

    const onChangeElementTextarea = async (e) => {
        e.stopPropagation()
        let value = e.target.value
        if (value != "") {
            let template = setMessengerTemplate("text", e.target.value)
            props.setResponseMessage({name:"modello solo testo", code:template})
        }
        else {
            props.setResponseMessage(false)
        }
    }
    const changeContentModel = () => {
        let objdata = JSON.parse(props.replie.message_data)
        textareatext.current.value = objdata.text
        let template = setMessengerTemplate("text",  objdata.text)
        props.setResponseMessage({name:"modello solo testo", code:template})
    }
    return (
        <>
            <ChooseModelPlatform>
                <h2>Modello solo testo</h2>
                <InfoModelPLatform>
                    <p>Con questo modello è possibile inviare un messaggio di solo testo.</p>
                </InfoModelPLatform>
                <BoxElementModelPlatform>
                    <BoxItemTextPlatform>
                        <label>Testo del messaggio (obbligatorio)</label>
                        <textarea
                            onChange={onChangeElementTextarea}
                            ref={textareatext}
                            placeholder="Scrivi il testo del messaggio..."
                        />
                    </BoxItemTextPlatform>
                </BoxElementModelPlatform>
            </ChooseModelPlatform>
        </>
    )

}


export default TextModel