import React, {useState, useEffect, useRef} from "react";
import nouserimage from "../source/svg/nouserimage.svg"
import SvgNoUserImage01 from "../source/svg/SvgNoUserImage01";
import {
    IconImageUser,
    WrapSvgNoUserImage
} from "../styles/Commons";
function ImageUserApp(props) {
    const properties = props

  return (
        <>
          {(() => {
              if (properties.user) {
                  if (properties.user.guest) {
                      return (
                          <WrapSvgNoUserImage widthimg={properties.widthimage} heightimg={properties.heightimage} ><SvgNoUserImage01 /></WrapSvgNoUserImage>
                      )
                  }
                  else {
                      if (properties.user.picture) {
                          return (
                              <IconImageUser alt="" widthimg={properties.widthimage} heightimg={properties.heightimage} src={properties.user.picture} />
                          )
                      }
                      else  {
                          return (
                              <WrapSvgNoUserImage widthimg={properties.widthimage} heightimg={properties.heightimage} ><SvgNoUserImage01 /></WrapSvgNoUserImage>
                          )
                      }
                  }
              }
          })()}

        </>
  );
}

export default ImageUserApp;
