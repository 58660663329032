import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


function ParserTemplComponentsUserListMS(props) {
    const properties = props
    const focusmessage = properties.code
    //console.log(focusmessage)
    return (
        <>
             {(() => {
                    if (focusmessage && focusmessage.type) {
                        let lengthetxt = 10;
                        let isdottetext
                        let dottedtext= "..."
                        if (focusmessage.type === "text") {

                            if (focusmessage.body.text.length > lengthetxt) isdottetext = 1
                            let text = focusmessage.body.text.substring(0, lengthetxt);
                            if (isdottetext) text = text + dottedtext
                            return (text)
                        }
                        if (focusmessage.type === "postback") {
                            if (focusmessage.body.title.length > lengthetxt) isdottetext = 1
                            let title = focusmessage.body.title.substring(0, lengthetxt);
                            if (isdottetext) title = title + dottedtext
                            return (title)
                        }
                        if (focusmessage.type === "attachments") {

                            if (focusmessage.body.attachment.length) {
                                let attach = focusmessage.body.attachment[focusmessage.body.attachment.length -1]
                                if (attach.type === "image") {
                                    return (
                                        <span>Ha inviato un'immagine</span>
                                    )
                                }
                                if (attach.type === "audio") {
                                    return (
                                        <span>Ha inviato un audio</span>
                                    )
                                }
                            }
                            return (
                                <span>Ha inviato un file</span>
                            )
                        }
                    }
                    else {
                        return (
                            <span>Ha risposto</span>
                        )
                    }
            })()}
       </>
    )
}

export default ParserTemplComponentsUserListMS