import React, {useEffect, useState} from "react";
import {userLogin} from "../../hooks/UserProvider";
import {validateEmail} from "../../utils/utils";
import {checkErrorSubCode, checkErrorCode} from "../../utils/error_codes";
import {
    Button01,
    Button011, RoundIcon, ButtonRoundIcon,
} from "../../styles/Commons"
import {
    BodyModalAccessLogin,
    TitleModalAccess,
    PanelDataModalAccess,
    ItemDataModalAccess,
    WrapButtonsModalAccess,
    ItemExtraLinkModalAccessFPwd,
    ItemExtraLinkModalAccessSign,
    ExtraLinkModalAccess,
    WrapValidatorDataModalAccess,
    ValidatorDataModalAccess
} from "../../styles/User";
import setting from "../../settings/setting";
import branch from "../../settings/branch";
function Login(props) {
    const [InputEmail, setInputEmail] = useState("")
    const [InputPassword, setInputPassword] = useState("")
    const [InputCaptcha, setInputCaptcha] = useState("")
    const [showError, setShowError] = useState(false)
    const [showButtonLogin, setShowButtonLogin] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [captchaValue, setCaptchaValue] = useState('');
    const [generatedValue, setGeneratedValue] = useState(Math.floor(Math.random() * 10));
    useEffect(() => {
        if (showError) setShowError(false)
        if (InputEmail != "" && InputPassword != "" && InputCaptcha != "" && (parseInt(InputCaptcha) === (generatedValue + 1))) {
            const cheemail = validateEmail(InputEmail)
            if (cheemail) setShowButtonLogin(true)
            else {
                if (showButtonLogin) setShowButtonLogin(false)
            }
        }
        else {
            setShowButtonLogin(false)
        }
    }, [InputEmail, InputPassword, InputCaptcha]);

    async function sendDataForm(e) {
        e.preventDefault();
        const dataUser = {
            email: InputEmail,
            password: InputPassword
        }
        try {
            let responsereg = await userLogin(dataUser);
            if (responsereg.error) {
                setShowError(true)
                if (responsereg.error_code === 401) {
                    if (responsereg.error_subcode === 401.2) {
                        props.setModalContent({component:"securCode", source:"login", app:props.extracontent.app, show:true, console:props.extracontent.console})
                    }
                    let error = checkErrorSubCode(responsereg.error_subcode)
                    setErrorMessage(error)
                }
                else {
                    let error = checkErrorSubCode(10000)
                    setErrorMessage(error)
                }
            }
            else {
                if (responsereg.token) {
                    props.setToken(responsereg.token)
                }
                props.setModalContent({show:false})


                /*if (props.api) {
                    window.location.reload();
                }
                else {
                    let path = setting[branch.name].client_url + "/wonnie"
                    window.open(path, "_self");
                }*/

                if (props.extracontent.console) {
                    let path = setting[branch.name].console_url
                    window.open(path, "_self");
                }
                else {
                    window.location.reload();
                }

            }
        }catch (e) {
            console.log(e)
        }
    }
    function callForgotPassword(e) {
        e.preventDefault()
        if (window.location.pathname === "/login") {
            let path = setting[branch.name].console_url + "/forgotpassword"
            window.open(path, "_self");
        }
        else {
            props.setModalContent({component:"forgotpassword", source:"login", show:true, condition: "step1"})
        }


    }


    return (
        <>
            <BodyModalAccessLogin>

                <TitleModalAccess>Accedi alla Console</TitleModalAccess>
                {props.extracontent.message && (<p>{props.extracontent.message.toString()}</p>)}
                {showError && (<WrapValidatorDataModalAccess><ValidatorDataModalAccess>{errorMessage}</ValidatorDataModalAccess></WrapValidatorDataModalAccess>)}
                <PanelDataModalAccess>
                    <ItemDataModalAccess><input placeholder="Email" className="itI6874ac" type="text" name="email" value={InputEmail} onChange={e => setInputEmail(e.target.value.replace(/\s/g, ""))}/></ItemDataModalAccess>
                    <ItemDataModalAccess><input placeholder="password" className="itI6874ac" type="password" name="password" value={InputPassword} onChange={e => setInputPassword(e.target.value)}/></ItemDataModalAccess>
                    <ItemDataModalAccess><input placeholder={"Controllo SPAM: Quanto fa" + " " + generatedValue + " + 1?"} className="itI6874ac"  name="captcha" value={InputCaptcha} onChange={e => setInputCaptcha(e.target.value)}/></ItemDataModalAccess>
                    <WrapButtonsModalAccess>
                        {(showButtonLogin) ? <Button01 onClick={sendDataForm}>Invia</Button01> : <Button011>Invia</Button011>}
                    </WrapButtonsModalAccess>
                    <ItemExtraLinkModalAccessFPwd><ExtraLinkModalAccess onClick={callForgotPassword}>Password dimenticata?</ExtraLinkModalAccess></ItemExtraLinkModalAccessFPwd>
                </PanelDataModalAccess>
            </BodyModalAccessLogin>
        </>

    )
}
export default Login
