import React from 'react';

const Reply01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path id="bAreaRounded60" display="none" d="M342.4,512.5H169.6C76,512.5-0.5,436-0.5,342.4V169.6C-0.5,76,76-0.5,169.6-0.5h172.8
	C436-0.5,512.5,76,512.5,169.6v172.8C512.5,436,436,512.5,342.4,512.5z"/>
        <path id="bAreaRounded40" display="none" d="M399.1,512.5H112.9
	c-62.4,0-113.4-51-113.4-113.4V112.9c0-62.4,51-113.4,113.4-113.4h286.2c62.4,0,113.4,51,113.4,113.4v286.2
	C512.5,461.5,461.5,512.5,399.1,512.5z"/>
        <path id="bAreaRounded20" display="none" d="M455.8,512.5H56.2C25,512.5-0.5,487-0.5,455.8V56.2C-0.5,25,25-0.5,56.2-0.5h399.6
	c31.2,0,56.7,25.5,56.7,56.7v399.6C512.5,487,487,512.5,455.8,512.5z"/>
        <path id="bAreaRounded10" display="none" d="M484.7,513H28.3C12.8,513,0,500.2,0,484.7V28.3C0,12.8,12.8,0,28.3,0h456.3
	C500.2,0,513,12.8,513,28.3v456.3C513,500.2,500.2,513,484.7,513z"/>
        <rect id="bAreaSquare" display="none" width="513" height="513"/>
        <circle id="bAreaCircle" display="none" cx="255" cy="257" r="253"/>
        <path id="bArea01" d="M348.5,222.9c-29.5-29.5-71.6-45.7-120.1-48.5v-43.3c0-6.5-4.9-12.5-10.9-15c-6.1-2.4-13.3-1.2-18.2,3.6
	L99.7,220.9c-6.1,6.5-6.5,16.6,0,22.7l99.5,101.1c4.4,4.9,12.1,6.1,18.2,3.6c6.1-2.4,10.9-8.5,10.9-15v-43.7
	c32.4-0.8,108.4,2.4,136.3,51.8c2.8,5.3,8.1,8.1,13.8,8.1c1.2,0,2.4,0,4-0.4c7.3-2,12.5-8.1,12.5-15.8
	C394.6,331,394.6,269.1,348.5,222.9z M209.7,258.1c-8.1,0.8-13.8,7.7-13.8,16.2v19.4l-60.7-61.5l60.7-61.5v19.4
	c0,8.9,6.5,16.2,15.4,16.2c87,0,123.8,41.7,139.6,77.3C293.9,249.6,213.8,257.7,209.7,258.1z"/>
    </svg>
);

export default Reply01;