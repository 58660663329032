import React, {useState, useEffect, useRef} from "react";
import noappimage from "../source/svg/noapp.svg"
function ImageApp(props) {
    const properties = props

  return (
        <>
          {(() => {
              if (properties.actor) {
                  return (
                      <img title={properties.actor.name} style={{width:"40px", height:"40px"}} alt="" src={properties.actor.image} />

                  )
              }
              else {
                  if (properties.app) {
                      if (properties.app.image) {
                          return (
                              <img title={properties.app.public_name} style={{width:"40px", height:"40px"}} alt="" src={properties.app.image} />

                          )
                      }
                      else {
                          return (
                              <img alt="" src={noappimage} />
                          )

                      }
                  }
              }

          })()}

        </>
  );
}

export default ImageApp;
