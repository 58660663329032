import React, {useEffect, useState} from "react";
import SimpleBar from "simplebar-react";
import {setLegalConditions} from "../../hooks/AppProvider";
import {
    WrapperCreateTermsAddInfoUser,
    BoxDetailsTermsAddInfoUser

} from "../../styles/Modals";
import {getLegalConditions} from "../../hooks/AppProvider";
function ModalTermsAddInfoUser(props) {
    console.log(props)
    const setTerms = async (e) => {
        let callsetterm = await setLegalConditions({app_id:props.dataModal.data.app._id, conditions:"add_info_users"})
        if (!callsetterm.error) {
            props.setModalSystem({show:true, source:"modaltermsaddinfouser", action:"data-info-add-user", data:{userChat:props.dataModal.data.userChat}})
        }
    }

    const closeModal = () => {
        props.setModalSystem({show:false, action:null, source:null, data:null})
    }

    return(
        <>
            <WrapperCreateTermsAddInfoUser>
                <h1>Condizioni d'uso delle informazioni sull'utente</h1>
                <BoxDetailsTermsAddInfoUser>
                        <SimpleBar style={{maxHeight:"200px"}}>
                                            <p>Le funzioni dei Contatti per le conversazioni su Botmama consentono di importare i dati del proprio elenco di clienti, ad esempio nome, cognome, e-mail, indirizzo, numero di telefono, immagini e data di nascita ("Dati dei contatti"), le informazioni associate, come gli appuntamenti e le note dei clienti ("Dati delle prenotazioni"), i dati delle inserzioni per acquisizione contatti raccolti tramite il relativo prodotto e/o altri dati raccolti al di fuori dei Prodotti Botmama dall'amministratore della Pagina ("Dati delle inserzioni per acquisizione contatti) e di utilizzare tali dati per interagire con i clienti (le "Funzioni dei contatti delle Pagine"). Dati dei contatti, Dati delle prenotazioni e Dati delle inserzioni per acquisizione contatti vengono collettivamente individuati come "Dati dei contatti delle Pagine". Senza pregiudicare precedenti contratti fra l'utente e Botmama, fornendo a Botmama i Dati dei contatti delle Pagine o usando la funzione in questione nelle interazioni con i clienti, si accettano le condizioni seguenti:
                            L'utente dichiara e garantisce, senza pregiudicare nessun elemento delle presenti condizioni, di disporre di tutti i diritti e le autorizzazioni necessari, nonché di una base legale, per divulgare e usare i Dati dei contatti delle Pagine in conformità con tutte le leggi, le normative e le linee guida di settore applicabili, fra cui, a titolo esemplificativo e non esaustivo, tutti i diritti e le autorizzazioni necessari, compresi i casi un cui il consenso esplicito è obbligatorio per: usare e trasferire i Dati dei contatti delle Pagine a Botmama, inviare, direttamente o indirettamente, tramite le Funzioni dei contatti delle Pagine, messaggi commerciali (ad es., messaggi relativi a transazioni, di marketing e/o promozionali) tramite e-mail, SMS e altri meccanismi di comunicazione online (ad es., Botmama Messenger), nonché per effettuare chiamate di telemarketing alle persone le cui informazioni sono riportate nei Dati dei contatti delle Pagine.
                                                                                                                                                                                        Se fornisce Dati dei contatti delle Pagine per conto di un'altra entità, l'utente dichiara e garantisce di disporre, in qualità di agente di tale entità, dell'autorità per divulgare e usare i Dati dei contatti delle Pagine per conto dell'entità, che sarà vincolata alle presenti condizioni. L'utente inoltre dichiara e garantisce, in qualità di agente di detta entità, che tale entità dispone di tutti i diritti e le autorizzazioni necessari, nonché di una base legale, per divulgare e usare i Dati dei contatti delle Pagine in conformità con tutte le leggi, le normative e le linee guida di settore applicabili, come indicato in precedenza.
                            L'utente dichiara e garantisce che i Dati dei contatti delle Pagine non si riferiscono ad alcuna persona che abbia scelto l'opzione, direttamente o indirettamente, di non acconsentire alla divulgazione e all'utilizzo dei dati nelle modalità previste dal presente contratto. Se una persona si avvale di tale opzione dopo l'inserimento dei propri dati, l'utente è tenuto a rimuovere la persona interessata dai Dati dei contatti delle Pagine. Se l'utente fornisce Dati dei contatti delle Pagine per conto di un'altra entità, gli obblighi della presente disposizione si applicano anche se la persona interessata decide di avvalersi di tale opzione, direttamente o indirettamente, nei confronti dell'entità in questione.
                            L'utente indica a Botmama di caricare e memorizzare i Dati dei contatti delle Pagine. Botmama non condividerà i Dati dei contatti delle Pagine con terzi. Botmama garantisce la riservatezza e la sicurezza dei Dati dei contatti delle Pagine provvedendo, tra l'altro, ad assicurare l'applicazione di misure di protezione tecniche e fisiche volte a (a) tutelare la sicurezza e l'integrità dei dati mentre si trovano all'interno dei sistemi di Botmama e a (b) proteggerli in caso di accesso, uso, alterazione o divulgazione accidentale o non autorizzata all'interno dei sistemi di Botmama.
                            Salvo in presenza del consenso dell'utente o di un obbligo di legge, Botmama non userà i Dati dei contatti delle Pagine per scopi diversi dall'erogazione del servizio e non fornirà informazioni o l'accesso in relazione ai Dati dei contatti delle Pagine a terzi (a eccezione dei fornitori di servizi che agiscono solo per conto di Botmama nell'erogazione del servizio). Quando le persone interagiscono con l'utente attraverso prodotti o funzioni di Botmama(ad es., fissando un appuntamento mediante le funzioni delle Pagine di Botmama), le informazioni potrebbero essere usate nelle modalità descritte dalla Normativa sui dati di Botmama, indipendentemente dal fatto che le informazioni delle persone rientrino nei Dati dei contatti delle Pagine.
                                                                                                                                                                                        Botmama può apportare modifiche, sospendere, interrompere l'accesso o annullare la disponibilità delle Funzioni dei contatti delle Pagine in qualsiasi momento. L'utente può interrompere l'uso delle Funzioni dei contatti delle Pagine in qualsiasi momento. L'utente può eliminare i Dati dei contatti delle Pagine dal sistema di Botmama in qualsiasi momento, tramite gli strumenti dell'account. In determinate circostanze, Botmama potrebbe modificare il nome utente degli account.
                            Nella misura in cui trova applicazione il Regolamento (UE) 2016/679 (il Regolamento generale sulla protezione dei dati), le parti riconoscono e accettano quanto segue:
                            L'utente agisce in qualità di titolare del trattamento dei dati e fornisce istruzioni scritte a Botmama Ireland Limited e Botmama Inc., in base all'entità Botmama con cui l'utente ha aderito alle Condizioni dei contatti delle Pagine e alle Condizioni d'uso di Botmama, per trattare i Dati dei contatti delle Pagine in qualità di responsabile del trattamento dei dati. Il trattamento dei dati avverrà secondo le istruzioni fornite dall'utente, come indicato nelle Condizioni dei contatti delle Pagine e nelle Condizioni sul trattamento dei dati di Botmama, qui inserite per riferimento.
                                                                                                                                                                                        L'utente riconosce e accetta che Botmama, Inc. può agire come subincaricato del trattamento dei dati per e per conto di Botmama Ireland Limited al fine di adempiere agli obblighi di Botmama Ireland Limited definiti dalle presenti Condizioni dei contatti delle Pagine e dalle Condizioni sul trattamento dei dati.
                            Per i trasferimenti dei Dati dei contatti delle Pagine che potrebbero verificarsi nelle interazioni con Botmama Inc., l'utente accetta di sottoscrive le Clausole contrattuali standard approvate dalla Commissione europea ("Clausole contrattuali standard"), qui inserite per riferimento, con Botmama Inc.
                            In base alle istruzioni dell'utente, i Dati dei contatti delle Pagine dei clienti verranno trattati per consentire all'utente di gestire i clienti nello stesso strumento, finché l'utente non deciderà di eliminarli. I Dati dei contatti delle Pagine che l'utente invia a Botmama potrebbero contenere categorie speciali di dati personali, come illustrato nell'Allegato 1 delle Clausole contrattuali standard.
                            I dipendenti di Botmama autorizzati a trattare i Dati dei contatti delle Pagine ai sensi delle presenti condizioni, delle Condizioni sul trattamento dei dati e delle Clausole contrattuali standard, sono tenuti a mantenere la riservatezza o sono soggetti a opportuni obblighi legali di riservatezza in relazione ai Dati dei contatti delle Pagine.
                            I termini "dati personali", "titolare del trattamento dei dati", "responsabile del trattamento dei dati" e "persona interessata" hanno i significati indicati nel Regolamento generale sulla protezione dei dati.
                            Le presenti Condizioni dei dati delle Pagine e, nella misura applicabile, le Condizioni sul trattamento dei dati e le Clausole contrattuali standard, disciplinano l'uso dei Dati dei contatti delle Pagine e delle Funzioni dei contatti delle Pagine. I termini in questione non sostituiscono le condizioni applicabili all'uso di Botmama e tali condizioni continueranno a essere applicate. Le Funzioni dei contatti delle Pagine sono un Prodotto Botmama ai sensi delle Condizioni d'uso di Botmama (https://www.Botmama.com/terms, le "Condizioni"). In caso di conflitto esplicito fra le Condizioni dei contatti delle Pagine e le Condizioni, le presenti Condizioni dei contatti delle Pagine e, nella misura applicabile, le Condizioni sul trattamento dei dati e le Clausole contrattuali standard, disciplineranno esclusivamente, solo nella misura del conflitto, l'uso dei Dati dei contatti delle Pagine e delle Funzioni dei contatti delle Pagine. Botmama si riserva il diritto di monitorare o controllare il rispetto delle presenti condizioni commerciali da parte dell'utente e di aggiornarle periodicamente.></p>
                        </SimpleBar>
                    </BoxDetailsTermsAddInfoUser>
                    <button onClick={closeModal}>Annulla</button>
                    <button onClick={setTerms}>Accetto le conzioni</button>
            </WrapperCreateTermsAddInfoUser>
        </>
    )
}


export default ModalTermsAddInfoUser