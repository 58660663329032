import React from 'react';

const SvgNoUserImage01 = props => (
    <svg
        viewBox="0 0 219.8 219.8"
    >
        <ellipse fill="#FFE1EF" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -45.5313 109.9224)" className="st0" cx="109.9"
                 cy="109.9" rx="109.9" ry="109.9"/>


        <path fill="#FFF" className="st1" d="M104,209c28.3,1.8,54.7-8,74.4-25.3c-2.6-9.8-5.7-19.4-9.4-28.4c-6.2-15.2-17.6-27.3-31.6-34.3
		c0.1-0.2,0.2-0.3,0.4-0.5c6.1-9.8,7.9-21.1,7.8-32.2c-0.1-10.5-2-21.4-8.4-30.2c-6.4-8.9-18.1-15.4-30.2-14.3
		C94.1,45,83.7,54.6,79.3,65.2c-4.4,10.7-4,22.3-3.1,33.6c0.6,8.1,2.8,16.3,6.7,23.1c-8.5,5.1-16,12.7-23.2,22
		c-7.4,9.5-7.1,9.9-11.7,21.1c-2.3,5.5-4.5,11.2-6.5,17.5C58.1,197.6,79.8,207.5,104,209z"/>
    </svg>
);

export default SvgNoUserImage01;