import React, {useEffect, useState, Fragment } from "react";
import SimpleBar from "simplebar-react";
import {
    WrapperCreateTermsAddInfoUser,
    BoxDetailsTermsAddInfoUser, WrapperCreateModel, BoxButtonFormModel

} from "../../styles/Modals";
import {getConversation, getConversationWA, getExportConversationWA, getExportConversationMS, getExportConversation, getConversationFile} from "../../hooks/ConversationProvider";
import Loader from "../../utils/Loader";
import {
    ChatMessageWrapButtons, ChatMessageWrapperAppMS, ChatMessageWrapperAppMSImage, ChatMessageWrapperAppMSQuickReply,
    ChatMessageWrapperAppMSSLider,
    ChatMessageWrapperAppMSTButton, ChatMessageWrapQuickReply,
    WrapLoadMoreMessage
} from "../../styles/businessConversation";
import Main_settings from "../../styles/main_settings/Main_settings";
import {Button01, Button011, Button012, Button013, Button02, ButtonRoundIcon} from "../../styles/Commons";
import SvgAdd01 from "../../source/svg/Add01";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import Button from "react-multi-date-picker/components/button"
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import moment from "moment";
import ParserTemplComponentsUser from "../../utils/ParserTemplComponentsUser";
import ParserTemplComponentsApp from "../../utils/ParserTemplComponentsApp";

import {
    WrapperExportConversation,
    PanelFilterExportConversation,
    PanelResulExportConversation,
    PanelExportSearch,
    FormExportSearch,
    ItemLabelFormExportSearch,
    InputForm01,
    BlockFormExportChat,
    TableResultExpConversation,
    PanelInfoResultExpConversation,
    InfoBubbleResultSearch,
    PanelButtonActionExpConversation,
    PreviewResultExpConversation,
    PanelMessageErrorBtnActionExpConv

} from "../../styles/Modals";
import SvgDownload01 from "../../source/svg/SvgDownload01";
import SvgShare01 from "../../source/svg/SvgShare01";
import SvgPreview01 from "../../source/svg/SvgPreview01";
import MultipleItemsMS from "../../tempcomponents/sliders/slick/MultiplesliderMS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ModalExportConversation(props) {
    const [loadingConversation, setLoadingConversation] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [listMessages, setListMessages] = useState([])

    const [lastMessageNumber, setLastMessageNumber] = useState("")
    const [showPanelLastMessageNumber, setShowPanelLastMessageNumber] = useState(false)
    const [enableBtnSearchLastMessages, setEnableBtnSearchLastMessages] = useState(false)
    const [titleResultSearch, setTitleResultSearch] = useState(false)
    const [infoButtonResultLastMessage, setInfoButtonResultLastMessage] = useState(false)

    const [dateConversation, setDateConversation] = useState(false)
    const [hourConversation, setHourConversation] = useState(false)
    const [enableBtnSearchDateConversation, setEnableBtnSearchDateConversation] = useState(false)

    const [dateConversationRangeTo, setDateConversationRangeTo] = useState(false)
    const [hourConversationRangeTo, setHourConversationRangeTo] = useState(false)
    const [dateConversationRangeFrom, setDateConversationRangeFrom] = useState(false)
    const [hourConversationRangeFrom, setHourConversationRangeFrom] = useState(false)
    const [enableBtnSearchDateConversationRange, setEnableBtnSearchDateConversationRange] = useState(false)
    const [infoButtonResultDate, setInfoButtonResultDate] = useState(false)


    const [showPanelDateConversation, setShowPanelDateConversation] = useState(false)
    const [showPanelDateConversationRange, setShowPanelDateConversationRange] = useState(false)
    const [enableLastMessages, setEnableLastMessages] = useState(true)
    const [enableDateMessages, setEnableDateMessages] = useState(true)
    const [enableDateRangeMessages, setEnableDateRangeMessages] = useState(true)
    const [infoButtonResultRange, setInfoButtonResultRange] = useState(false)

    const [enableBtnDownloadFile, setEnableBtnDownloadFile] = useState(false)
    const [enableBtnCallService, setEnableBtnCallService] = useState(false)

    const [messageError, setMessageError] = useState(false)

    const [urlCacheFileDownload, setUrlCacheFileDownload] = useState(false)

    let currentuser = props.dataModal.data.user.name

    if (!currentuser) currentuser = props.dataModal.data.user.sender

    let platformtarget = props.dataModal.data.platform




    const callLastMessages = async (e) => {
        setLoadingConversation(true)
        let actCallMessages
        if (platformtarget === "WhatsApp") {
            actCallMessages = await getExportConversationWA({conversation_id:props.dataModal.data.conversation_target._id, condition:"exportLast", data:{messages_lenght:parseInt(lastMessageNumber)}});
        }
        if (platformtarget === "Botmama") {
            actCallMessages = await getExportConversation({conversation_id:props.dataModal.data.conversation_target._id, lang:props.dataModal.data.conversation_target.url_lang, condition:"exportLast", data:{messages_lenght:parseInt(lastMessageNumber)}});
        }
        if (platformtarget === "Messenger") {
            actCallMessages = await getExportConversationMS({conversation_id:props.dataModal.data.conversation_target._id, lang:props.dataModal.data.conversation_target.url_lang, condition:"exportLast", data:{messages_lenght:parseInt(lastMessageNumber)}});
        }
        setLoadingConversation(false)
        if (!actCallMessages.error) {

            setUrlCacheFileDownload(false)
            setEnableBtnDownloadFile(true)
            //setEnableBtnCallService(true)
            setListMessages(actCallMessages.listmessages)
            setInfoButtonResultLastMessage(lastMessageNumber)
            if (infoButtonResultRange) setInfoButtonResultRange(false)
            if (infoButtonResultDate) setInfoButtonResultDate(false)
            clearLastMessages()
        }

    }

    const callDateMessages = async (e) => {
        setLoadingConversation(true)
        let timedata = convertDate(dateConversation)
        let actCallMessagesData
        if (platformtarget === "WhatsApp") {
            actCallMessagesData = await getExportConversationWA({conversation_id:props.dataModal.data.conversation_target._id, condition:"exportFromDate", data:{date:timedata}});
        }
        if (platformtarget === "Messenger") {
            actCallMessagesData = await getExportConversationMS({conversation_id:props.dataModal.data.conversation_target._id, condition:"exportFromDate", data:{date:timedata}});
        }
        if (platformtarget === "Botmama") {
            actCallMessagesData = await getExportConversation({conversation_id:props.dataModal.data.conversation_target._id, lang:props.dataModal.data.conversation_target.url_lang, condition:"exportFromDate", data:{date:timedata}});
        }
        setLoadingConversation(false)
        if (!actCallMessagesData.error) {
            let dateinfobutton =  moment(timedata.to).format("DD/MM/YY");
            setUrlCacheFileDownload(false)
            setEnableBtnDownloadFile(true)
            //setEnableBtnCallService(true)
            setListMessages(actCallMessagesData.listmessages)
            setInfoButtonResultDate(dateinfobutton)
            if (infoButtonResultRange) setInfoButtonResultRange(false)
            if (infoButtonResultLastMessage) setInfoButtonResultLastMessage(false)
            clearDateConversation()
        }
    }

    const callRangeMessages = async (e) => {
        setLoadingConversation(true)
        let timedata = convertDate(dateConversationRangeFrom, dateConversationRangeTo)
        let actCallRangeData
        if (platformtarget === "WhatsApp") {
            actCallRangeData = await getExportConversationWA({conversation_id:props.dataModal.data.conversation_target._id, condition:"exportFromDate", data:{date:timedata}});
        }
        if (platformtarget === "Messenger") {
            actCallRangeData = await getExportConversationMS({conversation_id:props.dataModal.data.conversation_target._id, condition:"exportFromDate", data:{date:timedata}});
        }
        if (platformtarget === "Botmama") {
            actCallRangeData = await getExportConversation({conversation_id:props.dataModal.data.conversation_target._id, lang:props.dataModal.data.conversation_target.url_lang, condition:"exportFromDate", data:{date:timedata}});
        }
        setLoadingConversation(false)
        if (!actCallRangeData.error) {
            let dateinfobuttonFrom =  moment(timedata.from).format("DD/MM/YY");
            let dateinfobuttonTo =  moment(timedata.to).format("DD/MM/YY");
            setUrlCacheFileDownload(false)
            setEnableBtnDownloadFile(true)
            //setEnableBtnCallService(true)
            setListMessages(actCallRangeData.listmessages)
            setInfoButtonResultRange(dateinfobuttonFrom + " - " + dateinfobuttonTo)
            if (infoButtonResultLastMessage) setInfoButtonResultLastMessage(false)
            if (infoButtonResultDate) setInfoButtonResultDate(false)
            clearDateConversationRange()
        }
    }

    const callDownloadFile = async (e) => {
            let urlfile

            if (urlCacheFileDownload) {
                urlfile = urlCacheFileDownload
            }
            else {
                setLoadingDownload(true)
                const callFileToServer = await getConversationFile({conversation:listMessages, user: props.dataModal.data.user, app:props.dataModal.data.app, platform:platformtarget })
                setLoadingDownload(false)
                if (callFileToServer.error) {
                    setMessageError("Si è verificato un errore nel download. Riprova più tardi.")
                    return true
                }
                else {
                    if (messageError) setMessageError(false)
                    setUrlCacheFileDownload(callFileToServer.url_file)
                    urlfile = callFileToServer.url_file
                }

            }
            let a = document.createElement('a');
            a.href = urlfile;
            console.log(urlfile)
            a.setAttribute(
                'download',
                ""
            );
            a.click();

    }

    const callExternalService = async (e) => {

    }

    useEffect(() => {
        if (dateConversationRangeFrom && dateConversationRangeTo) {
            setEnableBtnSearchDateConversationRange(true)
        }
        else {
            setEnableBtnSearchDateConversationRange(false)
        }
    }, [dateConversationRangeFrom, dateConversationRangeTo]);

    useEffect(() => {
        if (dateConversation) {
            setEnableBtnSearchDateConversation(true)
        }
        else {
            setEnableBtnSearchDateConversation(false)
        }
    }, [dateConversation]);

    useEffect(() => {
        if (lastMessageNumber) {
            setEnableBtnSearchLastMessages(true)
        }
        else {
            setEnableBtnSearchLastMessages(false)
        }
    }, [lastMessageNumber]);

    const convertDate= (dateFrom, dateTo, hour) => {
        let timeFromtarget = "00:00"
        let timeToTarget = "23:59"
        /*if (hour) {
            console.log(hour)
            let hourtarget = "00"
            let minutetarget = "00"
            if (hour.hour != 0) hourtarget = hour.hour.toString()
            if (hour.minute != 0) {
                minutetarget = hour.minute.toString()
                if (hour.minute < 10)  minutetarget = "0" + minutetarget
            }
            timetarget = hourtarget + ":" + minutetarget
        }*/
        var dateFromString = moment.unix(dateFrom.unix).format("MM/DD/YYYY " + timeFromtarget);
        let utctimeFrom= moment(dateFromString).utc().format()

        let newdateTo

        if (dateTo) newdateTo = dateTo
        else newdateTo = dateFrom

        var dateToString = moment.unix(newdateTo.unix).format("MM/DD/YYYY " + timeToTarget);
        let utctimeTo= moment(dateToString).utc().format()

        return {
            from:utctimeFrom,
            to:utctimeTo
        }
    }

    const actionPanelLastMessages= () => {
        setShowPanelLastMessageNumber(true)
        setEnableLastMessages(false)
        if (showPanelDateConversationRange) setShowPanelDateConversationRange(false)
        clearDateConversation()
        clearDateConversationRange()
    }

    const actionPanelDate = () => {
        setShowPanelDateConversation(true)
        setEnableDateMessages(false)
        clearLastMessages()
        clearDateConversationRange()
    }
    const actionPanelDateRange = () => {
        setShowPanelDateConversationRange(true)
        setEnableDateRangeMessages(false)
        clearLastMessages()
        clearDateConversation()
    }

    const clearLastMessages = () => {
        if (showPanelLastMessageNumber) setShowPanelLastMessageNumber(false)
        if (!enableLastMessages) setEnableLastMessages(true)


        /*if (enableBtnSearchLastMessages) setEnableBtnSearchLastMessages(false)*/
    }

    const clearDateConversation= () => {
        if (showPanelDateConversation) setShowPanelDateConversation(false)
        if (!enableDateMessages) setEnableDateMessages(true)
        /*if (dateConversation) setDateConversation(false)
        if (hourConversation) setHourConversation(false)
        if (enableBtnSearchDateConversation) setEnableBtnSearchDateConversation(false)*/
    }
    const clearDateConversationRange= () => {
        if (showPanelDateConversationRange) setShowPanelDateConversationRange(false)
        if (!enableDateRangeMessages) setEnableDateRangeMessages(true)
        /*if (dateConversationRangeFrom) setDateConversationRangeFrom(false)
        if (dateConversationRangeTo) setDateConversationRangeTo(false)
        if (hourConversationRangeFrom) setHourConversationRangeFrom(false)
        if (hourConversationRangeTo) setHourConversationRangeTo(false)
        if (enableBtnSearchDateConversationRange) setEnableBtnSearchDateConversationRange(false)*/
    }
    return(
        <>
            <WrapperExportConversation>
                <h2>Esporta la conversazione di {currentuser}</h2>
                <PanelFilterExportConversation>
                    {(() => {
                        if (enableLastMessages) {
                            return (
                                <Button01 onClick={actionPanelLastMessages}>Ultimi messaggi {(infoButtonResultLastMessage) ? <InfoBubbleResultSearch>{infoButtonResultLastMessage}</InfoBubbleResultSearch>  : null}</Button01>
                            )
                        }
                        else {
                            return (
                                <Button013>Ultimi messaggi {(infoButtonResultLastMessage) ? <InfoBubbleResultSearch>{infoButtonResultLastMessage}</InfoBubbleResultSearch>  : null}</Button013>
                            )
                        }

                    })()}

                    {(() => {
                        if (enableDateMessages) {
                            return (
                                <Button01 onClick={actionPanelDate}>Data {(infoButtonResultDate) ?  <InfoBubbleResultSearch>{infoButtonResultDate}</InfoBubbleResultSearch>  : null}</Button01>
                            )
                        }
                        else {
                            return (
                                <Button013>Data {(infoButtonResultDate) ? <InfoBubbleResultSearch>{infoButtonResultDate}</InfoBubbleResultSearch>  : null}</Button013>
                            )
                        }

                    })()}

                    {(() => {
                        if (enableDateRangeMessages) {
                            return (
                                <Button01 onClick={actionPanelDateRange}>Range {(infoButtonResultRange) ?  <InfoBubbleResultSearch>{infoButtonResultRange}</InfoBubbleResultSearch>  : null}</Button01>
                            )
                        }
                        else {
                            return (
                                <Button013>Range {(infoButtonResultRange) ?  <InfoBubbleResultSearch>{infoButtonResultRange}</InfoBubbleResultSearch>  : null}</Button013>
                            )
                        }

                    })()}
                    {(() => {
                        if (showPanelLastMessageNumber) {
                            return (
                                <>
                                    <PanelExportSearch>
                                        <FormExportSearch>
                                            <ItemLabelFormExportSearch>
                                                <label>Indica il numero di messaggi da esportare</label>
                                                <InputForm01 placeholder="scrivi il numero..." value={lastMessageNumber} onChange={e => setLastMessageNumber(e.target.value)}/>
                                            </ItemLabelFormExportSearch>
                                            <BoxButtonFormModel>
                                                {(() => {
                                                    if (enableBtnSearchLastMessages) {
                                                        return (
                                                            <Button01 onClick={callLastMessages}>Cerca</Button01>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <Button011>Cerca</Button011>
                                                        )
                                                    }

                                                })()}
                                                <Button02 onClick={clearLastMessages}>Annulla</Button02>
                                            </BoxButtonFormModel>
                                        </FormExportSearch>
                                    </PanelExportSearch>
                                </>
                            )

                        }
                    })()}
                    {(() => {
                        if (showPanelDateConversation) {
                            return (
                                <PanelExportSearch>
                                    <FormExportSearch>
                                        <ItemLabelFormExportSearch>
                                            <label>Indica la data dei messaggi da esportare</label>
                                            <DatePicker
                                                placeholder="seleziona la data..."
                                                value={dateConversation}
                                                onChange={setDateConversation}
                                                format={"DD/MM/YYYY"}
                                                animations={[
                                                    opacity(),
                                                    transition({duration: 500 })
                                                ]}
                                            />
                                        </ItemLabelFormExportSearch>
                                        <BoxButtonFormModel>
                                            {(() => {
                                                if (enableBtnSearchDateConversation) {
                                                    return (
                                                        <>
                                                            <Button01 onClick={callDateMessages}>Cerca</Button01>

                                                        </>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Button011>Cerca</Button011>
                                                    )
                                                }

                                            })()}
                                            <Button02 onClick={clearDateConversation}>Annulla</Button02>
                                        </BoxButtonFormModel>
                                    </FormExportSearch>
                                </PanelExportSearch>
                            )
                        }

                    })()}

                    {(() => {
                        if (showPanelDateConversationRange) {
                            return (
                                <>
                                <PanelExportSearch>
                                    <FormExportSearch>
                                        <ItemLabelFormExportSearch>
                                            <label>Indica il range di tempo dei messaggi da esportare</label>
                                            <BlockFormExportChat >
                                                <DatePicker
                                                    value={dateConversationRangeFrom}
                                                    placeholder="selezione la data DA..."
                                                    onChange={setDateConversationRangeFrom}
                                                    format={"DD/MM/YYYY"}
                                                    animations={[
                                                        opacity(),
                                                        transition({duration: 500 })
                                                    ]}
                                                />
                                            <DatePicker
                                                value={dateConversationRangeTo}
                                                placeholder="selezione la data A..."
                                                onChange={setDateConversationRangeTo}
                                                format={"DD/MM/YYYY"}
                                                animations={[
                                                    opacity(),
                                                    transition({duration: 500 })
                                                ]}
                                            />
                                        </BlockFormExportChat>
                                        </ItemLabelFormExportSearch>
                                        <BoxButtonFormModel>
                                            {(() => {
                                                if (enableBtnSearchDateConversationRange) {
                                                    return (
                                                        <Button01 onClick={callRangeMessages}>Cerca</Button01>
                                                    )
                                                }
                                                else {
                                                    return (
                                                            <Button011>Cerca</Button011>
                                                        )

                                                }

                                            })()}
                                            <Button02 onClick={clearDateConversationRange}>Annulla</Button02>
                                        </BoxButtonFormModel>
                                    </FormExportSearch>
                                </PanelExportSearch>
                                </>
                            )
                        }
                    })()}
                </PanelFilterExportConversation>
                <PanelInfoResultExpConversation>
                    {(() => {
                        if (listMessages.length) {
                            let textresult
                            if (listMessages.length > 1) {
                                textresult = "Sono stati trovati " +  listMessages.length + " messaggi da mostrare in anteprima"
                            }
                            else {
                                textresult = "È stato trovato " + listMessages.length + " messaggio da mostrare in anteprima"
                            }
                            return (
                                <p> {textresult}</p>
                            )
                        }
                        else {
                            return (
                                    <p>Non ci sono messaggi da mostrare in anteprima</p>
                                )

                        }
                    })()}

                </PanelInfoResultExpConversation>
                <PanelResulExportConversation>
                {(() => {
                    if (loadingConversation) {
                        return (
                            <WrapLoadMoreMessage>
                                <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                            </WrapLoadMoreMessage>
                        )

                    }
                    else {

                        if (listMessages.length) {
                            return (
                                <>
                                    <h3>{titleResultSearch}</h3>
                                    <SimpleBar autoHide={false} style={{width:"100%", maxHeight:"180px"}}>
                                        <TableResultExpConversation cellpadding="0" cellspacing="0">
                                            <colgroup>
                                                <col />
                                                <col />
                                                <col />
                                                <col />
                                                <col />
                                                <col className={"colDateExport"} />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <td>User</td>
                                                    <td>Name</td>
                                                    <td>Firstname</td>
                                                    <td>Lastname</td>
                                                    <td>Message</td>
                                                    <td>Date</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                        {(() => {
                                            return listMessages.map((child, i) => {
                                                //console.log(listMessages)
                                                let usermessage
                                                let namemessage
                                                let firstnamessage
                                                let lastnamemessage
                                                let messabody
                                                let datemessage = moment(child.date.date).format('dddd, ll HH:mm');

                                                if (platformtarget === "WhatsApp") {
                                                    if (child.message_data && child.message_data.body) messabody = child.message_data.body
                                                }
                                                if (platformtarget === "Messenger") {
                                                    let focusmessage = child.message_data

                                                    if (child.from === "app") {
                                                        if (focusmessage && focusmessage.body) {

                                                            if (focusmessage.body.attachment) {
                                                                if (focusmessage.body.attachment.payload) {
                                                                    let attachtype= focusmessage.body.attachment.type
                                                                    if (attachtype === "template") {
                                                                        let temptype = focusmessage.body.attachment.payload.template_type
                                                                        if (temptype === "generic") {
                                                                            let elements = focusmessage.body.attachment.payload.elements


                                                                            messabody =
                                                                                (
                                                                                    <div style={{display:"flex", flexWrap:"wrap"}}>
                                                                                        <ul style={{display:"flex", flexWrap:"wrap"}}>
                                                                                            {(() => {
                                                                                                return elements.map((el, a) => {
                                                                                                    return (
                                                                                                        <li style={{listStyle:"none", width:"100px", marginLeft:"3px", padding:"2px", border:"solid 1px #bcbcbc", borderRadius:"10px"}} key={a}>
                                                                                                            {(() => {
                                                                                                                if (el.image_url) {
                                                                                                                    return (
                                                                                                                        <img style={{width:"50px"}} alt="" src={el.image_url} />
                                                                                                                    )
                                                                                                                }
                                                                                                            })()}
                                                                                                            {(() => {
                                                                                                                if (el.title) {
                                                                                                                    return (
                                                                                                                        <p>{el.title}</p>
                                                                                                                    )
                                                                                                                }
                                                                                                            })()}
                                                                                                            {(() => {
                                                                                                                if (el.subtitle) {
                                                                                                                    return (
                                                                                                                        <p>{el.subtitle}</p>
                                                                                                                    )
                                                                                                                }
                                                                                                            })()}
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            })()}
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                        if (temptype === "button") {
                                                                            let buttons = focusmessage.body.attachment.payload.buttons
                                                                            messabody =
                                                                                (
                                                                                    <div style={{display:"flex", flexWrap:"wrap"}}>
                                                                                        <p>{focusmessage.body.attachment.payload.text}</p>
                                                                                        <ul style={{display:"flex", flexWrap:"wrap"}}>
                                                                                            {(() => {
                                                                                                return buttons.map((button, a) => {
                                                                                                    return (
                                                                                                        <li style={{listStyle:"none", marginLeft:"3px", padding:"2px", border:"solid 1px #bcbcbc", borderRadius:"40px"}} key={a}>
                                                                                                            {button.title}
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            })()}
                                                                                        </ul>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    }
                                                                    if (attachtype === "image") {

                                                                        messabody =
                                                                            (
                                                                                <Fragment>
                                                                                    <img style={{width:"50px"}} alt="" src={focusmessage.body.attachment.payload.url} />
                                                                                </Fragment>
                                                                            )

                                                                    }

                                                                }
                                                            }
                                                            else if (focusmessage.body.text && focusmessage.body.quick_replies) {
                                                                let quickreplies = focusmessage.body.quick_replies


                                                                messabody =
                                                                    (
                                                                        <div style={{display:"flex", flexWrap:"wrap"}}>
                                                                            <p>{focusmessage.body.text}</p>
                                                                            <ul style={{display:"flex", flexWrap:"wrap"}}>
                                                                                {(() => {
                                                                                    return quickreplies.map((button, a) => {
                                                                                        return (
                                                                                            <li style={{listStyle:"none", marginLeft:"3px", padding:"2px", border:"solid 1px #bcbcbc", borderRadius:"40px"}} key={a}>
                                                                                                {button.title}
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                })()}
                                                                            </ul>
                                                                        </div>
                                                                    )
                                                            }
                                                            else if (focusmessage.body.text) {
                                                                messabody = focusmessage.body.text
                                                            }
                                                        }
                                                    }
                                                    else {
                                                        console.log(focusmessage)

                                                        if (focusmessage && focusmessage.type === "attachments") {

                                                            let attachments = focusmessage.body.attachment
                                                            if (attachments.length) {
                                                                attachments.map((child, i) => {
                                                                    if (child.type === "image") {
                                                                        return (
                                                                            messabody =
                                                                                (
                                                                                    <Fragment>
                                                                                        <img style={{width:"50px"}} alt="" src={child.payload.url} />
                                                                                    </Fragment>
                                                                                )
                                                                        )
                                                                    }
                                                                    if (child.type === "audio") {
                                                                        return (
                                                                            messabody =
                                                                                (
                                                                                    <Fragment>
                                                                                        <audio controls>
                                                                                            <source src={child.payload.url} />
                                                                                        </audio>
                                                                                        <p><a href={child.payload.url}> Audio url</a></p>
                                                                                    </Fragment>
                                                                                )



                                                                        )
                                                                    }
                                                                    if (child.type === "file") {
                                                                        let namefile
                                                                        let url = child.payload.url
                                                                        namefile = url.split(':').pop().split(';')[0];
                                                                        return (
                                                                            messabody =
                                                                                (
                                                                                    <Fragment>
                                                                                        <FontAwesomeIcon icon={["fas", "file-download"]}/>
                                                                                        <p><a href={child.payload.url}>File url</a></p>
                                                                                    </Fragment>
                                                                                )
                                                                        )
                                                                    }
                                                                })
                                                            }


                                                        }
                                                        if (focusmessage && focusmessage.type === "text") {
                                                            messabody = focusmessage.body.text
                                                        }
                                                        if (focusmessage && focusmessage.type === "postback") {
                                                            messabody = focusmessage.body.title
                                                        }
                                                        if (focusmessage && focusmessage.type === "quick_reply") {
                                                            messabody = focusmessage.body.text
                                                        }
                                                    }



                                                }
                                                if (platformtarget === "Botmama") {
                                                    let htmltext = child.message_data.replace(/<[^>]+>/g, '').trim()
                                                    if (child.message_data) messabody = htmltext
                                                }

                                                if (child.from === "user") {
                                                    usermessage = props.dataModal.data.user.sender
                                                    namemessage = props.dataModal.data.user.name
                                                    firstnamessage = props.dataModal.data.user.firstname
                                                    lastnamemessage = props.dataModal.data.user.lastname
                                                }
                                                else{
                                                    usermessage = props.dataModal.data.app.name
                                                    namemessage = props.dataModal.data.app.name
                                                }
                                                return (
                                                    <tr>
                                                        <td>{usermessage}</td>
                                                        <td>{namemessage}</td>
                                                        <td>{firstnamessage}</td>
                                                        <td>{lastnamemessage}</td>
                                                        <td>{messabody}</td>
                                                        <td>{datemessage}</td>
                                                    </tr>
                                                )
                                            })
                                        })()}
                                        </tbody>
                                        </TableResultExpConversation>
                                    </SimpleBar>



                                </>
                            )
                        }
                        else {
                            return (
                                <>
                                    <PreviewResultExpConversation><SvgPreview01 /></PreviewResultExpConversation>
                                </>
                            )
                        }




                    }

                })()}
                </PanelResulExportConversation>
                {(() => {
                    if (listMessages.length) {
                        return (
                            <PanelButtonActionExpConversation>
                                <BoxButtonFormModel>
                                    {(() => {
                                        if (enableBtnDownloadFile)  {
                                            return (
                                                <Button01 onClick={callDownloadFile}>
                                                    <SvgDownload01 />
                                                    Download
                                                    {(loadingDownload) ? <Loader width="15" height="15" color={Main_settings.color01} secondaryColor={Main_settings.color01} type="03" /> : null}
                                                </Button01>
                                            )
                                        }

                                    })()}
                                    {(() => {

                                        if (enableBtnCallService) {
                                            return (
                                                <>
                                                    <Button01 onClick={callExternalService}>
                                                        <SvgShare01 />
                                                        Condividi con ERP

                                                    </Button01>

                                                </>
                                            )
                                        }

                                    })()}
                                </BoxButtonFormModel>
                                {(() => {

                                    if (messageError) {
                                        return (
                                            <>
                                                <PanelMessageErrorBtnActionExpConv>
                                                    <p>{messageError}</p>
                                                </PanelMessageErrorBtnActionExpConv>

                                            </>
                                        )
                                    }

                                })()}
                            </PanelButtonActionExpConversation>

                        )


                    }

                })()}
            </WrapperExportConversation>

        </>
    )
}


export default ModalExportConversation