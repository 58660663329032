import React from 'react';

const SvgChat01 = props => (
    <svg
        viewBox="0 0 460 460"
    >
        <path fill={props.color} d="M440.047,396.143c-13.192-5.207-24.726-14.157-33.044-25.81C439.582,348.319,460,316.762,460,281.701
				c0-38.367-24.445-72.543-62.527-94.602c-4.023,92.95-102.669,159.14-213.667,159.14c-9.342,0-18.6-0.481-27.755-1.387
				c28.905,34.387,80.701,57.309,139.8,57.309c21.556,0,42.138-3.055,60.996-8.597c16.873,15.79,40.876,23.447,65.237,18.454
				c6.542-1.341,12.702-3.51,18.395-6.376c1.834-0.923,2.955-2.838,2.862-4.889C443.247,398.7,441.957,396.897,440.047,396.143z"/>
        <path fill={props.color} d="M367.612,181.353c0-74.495-82.293-134.885-183.806-134.885S0,106.858,0,181.353c0,39.257,22.86,74.592,59.337,99.241
				C49.767,294,36.4,304.212,21.124,309.968c-1.919,0.723-3.236,2.503-3.364,4.55s0.958,3.978,2.773,4.934
				c11.88,6.254,25.231,9.519,38.572,9.519c21.307,0,41.377-8.301,56.401-22.36c21.116,6.206,44.163,9.627,68.301,9.627
				C285.32,316.238,367.612,255.848,367.612,181.353z"/>
    </svg>
);

export default SvgChat01;