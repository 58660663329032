import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import {

    BusinessUserConversationWrapper,
    BusinessUserConversationImage,
    BusinessUserConversationInfo,
    BusinessUserConversationSection,
    BusinessUserConversationAutoReply,
    BusinessUserConversationCrmPreview,
    BusinessUserConvDescriptionService,
    BusinessUserConversationOtherActions
} from "../../../../styles/businessConversation";
import {setStandbyUser} from "../../../../hooks/AppProvider";
import {getStandbyUser} from "../../../../hooks/AppProvider";
import ImageUserChat from "../../../../utils/ImageUserChat";
import Main_Settings from "../../../../styles/main_settings/Main_settings";
import BusInfoUser from "./BusInfoUser";
import {Button01} from "../../../../styles/Commons";

function BusTargetConversationUserChat(props) {
    const properties = props
    const [userChat, setUserChat] = useState(false)
    const [switchControlUser, setSwitchControlUser] = useState(false)
    const standbyapp = props.optStandbyApp
    const heightbar= props.heightBar

    useEffect(() => {
        if (props.optStandbyApp && switchControlUser) {
            setSwitchControlUser(false)
        }

    }, []);

    //console.log(props)



    useEffect(() => {
        if (props.optStandbyApp) {
            setSwitchControlUser(false)
        }
        else {
            setSwitchControlUser(true)
        }
    }, [standbyapp]);

    const changestandby = async  () => {
        if (!standbyapp) {
            actionSwitch()
        }
    }
    //console.log(props.conversation)
    const exportConversation = () => {
        let usertosend
        if (props.conversation.user) usertosend = props.conversation.user
        else  usertosend = props.conversation.guest

        props.setModalSystem({show:true, source:"business-option-filter", action:"export-conversation", data:{app:props.app, platform:"Botmama", conversation_target:props.conversation.conversations,  user:usertosend}})
    }



    const actionSwitch = async() => {
        let standby
        if (switchControlUser) standby="true"
        else standby= "false"
        let actionstandbydb= await setStandbyUser({
            app_name:properties.app.name,
            standby_app:standbyapp,
            standby_user:standby,
            user_conversation: userChat._id
        })
        if (!actionstandbydb.error) {
            if (switchControlUser) {
                setSwitchControlUser(false)
            }
            else {
                setSwitchControlUser(true)
            }
            properties.setApp(actionstandbydb.app)
        }
        else {
            properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }

    }



    useEffect(() => {
        if (properties.conversation) {
            if (properties.conversation.user) {
                setUserChat(properties.conversation.user)
            }
            else {
                setUserChat(properties.conversation.guest)
            }
        }

    }, [properties.conversation]);


    useEffect(() => {
        if (userChat && !standbyapp) getstateuser()

    }, [userChat]);


    const getstateuser = async  () => {
        const usersdbstandby = properties.app.state.standby.users
        let userindex = usersdbstandby.indexOf(userChat._id)
        if (userindex != -1) {
            if (switchControlUser) setSwitchControlUser(false)
        }
        else {
            if (!switchControlUser) setSwitchControlUser(true)
        }
    }



  return (
      <>
          {(() => {
              if (userChat) {
                  let info

                  if (userChat.name) {
                      info = userChat.name
                  }
                  else {
                      info = "User " + userChat.sender

                  }
                  return (
                      <>
                      <BusinessUserConversationImage>
                        <ImageUserChat user={userChat} />
                      </BusinessUserConversationImage>
                      <BusinessUserConversationInfo>
                            <p> {info}</p>
                      </BusinessUserConversationInfo>
                      <BusinessUserConversationSection>
                          <h3>Risposta bot</h3>
                          <BusinessUserConvDescriptionService> {(switchControlUser) ? "Disattiva " : "Attiva "} la risposta del bot su questo utente.</BusinessUserConvDescriptionService>
                          <BusinessUserConversationAutoReply>
                              <p>
                                  {(switchControlUser) ? " Disattiva " : " Attiva "}
                              </p>
                              <Switch
                                  checked={switchControlUser}
                                  onChange={changestandby}
                                  onColor={Main_Settings.color03}
                                  onHandleColor={Main_Settings.color02}
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  id="material-switch"
                              />
                          </BusinessUserConversationAutoReply>
                      </BusinessUserConversationSection>
                      <BusinessUserConversationCrmPreview>
                          <h3>Dettagli aggiunti</h3>
                          <BusInfoUser
                              app={props.app}
                              userChat={userChat}
                              platform={"Botmama"}
                              setModalSystem={props.setModalSystem}
                              setResultModalSystem={props.setResultModalSystem}
                              resultModalSystem={props.resultModalSystem}
                          />

                      </BusinessUserConversationCrmPreview>
                          <BusinessUserConversationOtherActions>
                              <h3>Conversazione</h3>
                              <h4>Esportazione</h4>
                              <BusinessUserConvDescriptionService>Esporta la conversazione in formato file o condividila con un servizio esterno.</BusinessUserConvDescriptionService>
                              <Button01 onClick={exportConversation}>Esporta</Button01>
                          </BusinessUserConversationOtherActions>
                  </>
                  )
              }
              else {
                  return(

                      <p>Non ci sono informazioni per questo utente</p>
                   )

              }
          })()}

      </>
  );
}

export default BusTargetConversationUserChat;
