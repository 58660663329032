import React, {useEffect, useState, useRef} from "react";
import Registration from "./Registration";
import Login from "./Login";
import Forgotpwd from "./Forgotpwd";
import SecureCode from "./SecurCode";
import {
    PanelModalAccess,
    WrapperModalAccess,
    WrapCloseModalAccess
} from "../../styles/User";
function ModalAccess(props) {
    const [modalContent, setModalContent] = useState(props.content)
    const [display, setDisplay] = useState(props.content.show)
    const [heightpanel, setHeightPanel] = useState(false)
    const [windowwidth, setWindowWidth] = useState(window.innerWidth || (document.documentElement || document.body).clientWidth)
    const [windowheight, setWindowHeight] = useState(false)
    const panelaccess = useRef(null)
    const [showOverflow, setShowOverflow] = useState(false)
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
    console.log(modalContent)
    const handleResize = () => {
        setWindowWidth(window.innerWidth || (document.documentElement || document.body).clientWidth)
        setWindowHeight(Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight))
        setHeightPanel(panelaccess.current.clientHeight)
    }
    useEffect(() => {
        setWindowHeight(Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight))

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (modalContent && !modalContent.show) {
            props.setShowModal(false)
        }
    }, [modalContent]);
    useEffect(() => {
        if (windowheight) {
            if (window.innerHeight < heightpanel) {
                if (!showOverflow) setShowOverflow(new Date())
            }
            else {
                if (showOverflow) setShowOverflow(false)
            }
        }
    }, [windowheight]);
    useEffect(() => {
        if (panelaccess.current.clientHeight) {
            setHeightPanel(panelaccess.current.clientHeight)
        }
    }, [panelaccess]);
    function closeModal(e) {
        e.preventDefault();
        props.setShowModal(false)
    }


    return (
        (display && modalContent) ? (
            <WrapperModalAccess windowh={windowheight} windoww={windowwidth}>
                <PanelModalAccess ref={panelaccess} windoww={windowwidth}>
                    {(() => {
                        switch (modalContent.component) {
                            case "registration":return  <Registration extracontent={modalContent} setModalContent={setModalContent} setToken={props.setToken} />
                            case "securCode": return <SecureCode extracontent={modalContent} setModalContent={setModalContent} setToken={props.setToken} />
                            case "login":return  <Login extracontent={modalContent} setModalContent={setModalContent} setToken={props.setToken} />
                            case "forgotpassword":
                                if (modalContent.condition != "step2") {
                                    return <Forgotpwd extracontent={modalContent} setModalContent={setModalContent} setToken={props.setToken} />
                                }
                                else {
                                    return <SecureCode extracontent={modalContent} setModalContent={setModalContent} setToken={props.setToken} />
                                }
                        }
                    })()}

                </PanelModalAccess>
            </WrapperModalAccess>
        ) : (
            null
        )
    )
}

export default ModalAccess