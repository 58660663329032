import React, {useEffect, useRef} from "react";
import UserAreaLink from "./UserAreaLink";
import ListApp from "../App/ListApp";
import logo from "../../source/svg/bconsole.svg"
import {
    Headerbar, BoxLogo, BoxListAppHeader, BoxUserHeader
} from "../../styles/Commons"
import setting from "../../settings/setting";
import branch from "../../settings/branch";
function Header(props) {
    const headerref = useRef(null)
    const gotopage = () => {
        let path = setting[branch.name].console_url + "/"
        window.open(path, "_self");
    }
    useEffect(() => {
        if (props.setHeightHeader) {
            props.setHeightHeader(headerref.current.clientHeight)
        }
    }, []);

    return (
        <Headerbar ref={headerref}>
            <BoxLogo onClick={gotopage}><img alt="botmama console" title="botmama console" src={logo} /></BoxLogo>
            {
                (props.user && props.user.registration.active) && (
                    <BoxListAppHeader>
                        <ListApp
                            setListApp={props.setListApp}
                            showApp={props.showApp}
                            app={props.app}
                            listApp={props.listApp} user={props.user}
                            setClearToken={props.setClearToken}
                            setToken={props.setToken}
                        />
                    </BoxListAppHeader>
                )
            }
            <BoxUserHeader>
                <UserAreaLink user={props.user} setClearToken={props.setClearToken} setToken={props.setToken} />

            </BoxUserHeader>
        </Headerbar>
    )
}

export default Header