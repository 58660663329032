export const checkErrorSubCode=(subcode)=> {
    let error_text

    switch (subcode)  {
        case 401.3:
            error_text ="Non sembra esserci nessun account collegato a questa Email. Puoi riprovare?"
            break;
        case 401.5:
            error_text ="Il codice inserito non è corretto"
            break;
        case 401.7:
            error_text ="Questa password non è corretta, mi spiace. Puoi riprovare?"
            break;
        case 401.1:
            error_text ="Esiste già un account collegato a questa mail. Effettua il login"
            break;
        case 401.10:
            error_text ="Il formato della mail non è valido"
            break;
        case 401.11:
            error_text ="La password dev'essere lunga 12 o più caratteri e deve contenere almeno: una lettera minuscola, una lettera maiuscola, un numero, un carattere speciale"
            break;

        default:
            error_text ="Qualcosa è andato storto. Aggiorna la pagina per favore o prova più tardi."

    }
    return error_text;

}


export const checkErrorCode=(code)=> {
    let error_text

    return error_text;

}