import React, {useState, useEffect} from "react";
import {userConfirmRegistration, sendUserRegistrationSecureCode, userConfirmForgotPwd, sendUserForgotPwdSecureCode} from "../../hooks/UserProvider";
import {
    BodyModalAccessSecureCode,
    TitleModalAccess,
    PanelDataModalAccess,
    WrapButtonsModalAccess,
    ItemDataModalAccess,
    TextInfoDataModalAccess,
    ItemExtraLinkModalAccessLogin,
    ExtraLinkModalAccess, WrapValidatorDataModalAccess, ValidatorDataModalAccess
} from "../../styles/User";
import {Button01, Button011, RoundIcon} from "../../styles/Commons";
import setting from "../../settings/setting";
import branch from "../../settings/branch";
import {checkErrorSubCode} from "../../utils/error_codes";



function SecurCode(props) {
    const [InputCode, setInputCode] = useState("")
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [extraMessage, setExtraMessage] = useState()
    const [showButtonSend, setShowButtonSend] = useState(false)
    const [conversationGuest, setConversationGuest] = useState(false)
    const pathchatplugin = "?system=true&chatplugin=true"


    useEffect(() => {
        if (props.extracontent && props.extracontent.conversation_guest) {
            setConversationGuest(props.extracontent.conversation_guest)
        }
        if (props.extracontent && props.extracontent.message) {
            setExtraMessage(props.extracontent.message.toString())
        }
        else {
            if (props.extracontent.source === "registration") {
                setExtraMessage("Ti è stato inviato un codice all'indirizzo e-mail che hai indicato in fase di iscrizione. I codici di sicurezza scadono dopo 2 ore.")
            }
            if (props.extracontent.source === "login") {
                setExtraMessage("Sembra che il tuo account non sia ancora attivo. Per attivarlo inserisci il codice di sicurezza che è stato inviato al tuo indirizzo e-mail in fase di registrazione.")
            }
            if (props.extracontent.source === "forgotpassword") {
                setExtraMessage("Ti è stato inviato un codice all'indirizzo e-mail che hai indicato in fase di cambio password. I codici di sicurezza scadono dopo 2 ore.")
            }
        }
    }, []);


    useEffect(() => {
        console.log(InputCode)
        if (showError) setShowError(false)
        if (InputCode != "") {
           setShowButtonSend(true)
        }
        else {
            setShowButtonSend(false)
        }
    }, [InputCode]);

    async function sendNewCode(e) {
        try {
            e.preventDefault();
            let sendnewcode
            if (showError) setShowError(false)
            if (InputCode) setInputCode("")
            if (props.extracontent && props.extracontent.source) {
                if (props.extracontent.source === "registration" || props.extracontent.source === "login") {
                    sendnewcode = await sendUserRegistrationSecureCode()
                }
                else if (props.extracontent.source === "forgotpassword") {
                    //sendnewcode = await sendUserForgotPwdSecureCode()
                    props.setModalContent({component:"forgotpassword", source:"SecurCode", show:true, condition: "step1"})
                }
            }
            if (sendnewcode && sendnewcode.error) {
                setShowError(true)
                if (sendnewcode.error_code === 401) {
                    setErrorMessage(sendnewcode.errorreply.message.toString())
                }
                else {
                    setErrorMessage("Something was wrong")
                }
            }
            else {
                (sendnewcode.token) && props.setToken(sendnewcode.token)
                setExtraMessage("")

                setTimeout(function(){ setExtraMessage("Ti è stato inviato un nuovo codice") }, 1000);

            }
        }catch (e) {

        }
    }


    async function sendDataForm(e) {
        e.preventDefault();
        const dataUser = {
            code: InputCode
        }
        if (conversationGuest) {
            dataUser.conversation_guest = conversationGuest
        }
        try {
            let responsereg
            if (props.extracontent && props.extracontent.source) {
                if (props.extracontent.source === "registration" || props.extracontent.source === "login") {
                    responsereg = await userConfirmRegistration(dataUser)
                }
                else if (props.extracontent.source === "forgotpassword") {
                    responsereg = await userConfirmForgotPwd(dataUser)
                }
            }
            if (responsereg.error) {
                setShowError(true)
                if (responsereg.error_code === 401) {
                    let error = checkErrorSubCode(responsereg.error_subcode)
                    setErrorMessage(error)
                }
                else {
                    setErrorMessage("Something was wrong")
                }
            }
            else {
                //(responsereg.token) && props.setToken(responsereg.token)

                if (props.extracontent.source === "registration" || props.extracontent.source === "login") {
                    if (props.extracontent.console) {
                        let path = setting[branch.name].console_url
                        window.open(path, "_self");
                    }
                    else {
                        if (props.extracontent.app.name) {
                            let path = setting[branch.name].client_url + "/" + props.extracontent.app.name
                            if (props.extracontent.ip && props.extracontent.ip === "chatplugin") {
                                path = path + pathchatplugin
                            }
                            window.open(path, "_self");
                        }
                        else {
                            console.log("QUI ZIO")
                            props.setModalContent({component:"welcomeuser", source:"registration", show:true, user:responsereg.user, app:props.extracontent.app})
                        }
                    }

                    //props.setModalContent({component:"welcomeuser", source:"registration", show:true, user:responsereg.user, api:props.extracontent.api})
                }
                else if (props.extracontent.source === "forgotpassword") {
                    props.setModalContent({component:"forgotpassword", source:"SecurCode", user:responsereg.user, show:true, condition: "step3"})
                }

            }
        }catch (e) {

        }
    }
    return (
        <BodyModalAccessSecureCode>
            <TitleModalAccess>Codice di sicurezza</TitleModalAccess>
            {extraMessage && (<TextInfoDataModalAccess><p>{extraMessage}</p></TextInfoDataModalAccess>)}
            {showError && (
                (<WrapValidatorDataModalAccess><ValidatorDataModalAccess>{errorMessage}</ValidatorDataModalAccess></WrapValidatorDataModalAccess>)
            )
            }
            <PanelDataModalAccess>
                <ItemDataModalAccess><input className="itI6874ac"  placeholder={"Codice..."} type="text" name="code" value={InputCode} onChange={e => setInputCode(e.target.value)}/></ItemDataModalAccess>
                <WrapButtonsModalAccess>
                    {(showButtonSend) ? <Button01 onClick={sendDataForm}>Invia</Button01> : <Button011>Invia</Button011>}
                </WrapButtonsModalAccess>
            </PanelDataModalAccess>
            <ItemExtraLinkModalAccessLogin>Non ti è arrivata l'email con il codice?</ItemExtraLinkModalAccessLogin> <ExtraLinkModalAccess onClick={sendNewCode}>Invia nuovo codice</ExtraLinkModalAccess>

        </BodyModalAccessSecureCode>
    )
}
export default SecurCode
