
import React, {useState, useEffect, useRef} from "react";

import { UseInterval } from "../../../../hooks/UseInterval";
import {getConversation, getMoreMessages, setMessagesAsRead} from "../../../../hooks/ConversationProvider";
import moment from "moment"
import SimpleBar from "simplebar-react";
import 'moment/locale/it';
import ParserTemplComponentsUser from "../../../../utils/ParserTemplComponentsUser";
import ParserTemplComponentsApp from "../../../../utils/ParserTemplComponentsApp";
import BusMessageBar from "./Messagebar/BusMessageBar";



import {
    BoxMainConversation, WrapLoadMoreMessage
} from "../../../../styles/businessConversation"
import Loader from "../../../../utils/Loader";
import Main_settings from "../../../../styles/main_settings/Main_settings";
import {ButtonRoundIcon} from "../../../../styles/Commons";
import SvgAdd01 from "../../../../source/svg/Add01";
const dJSON = require("dirty-json")

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function BusTargetConversationChat(props) {
    const properties = props
    const [listMessages, setListMessages] = useState([])
    const [listActors, setListActors] = useState(properties.actors)
    const [widthMainConv, setWidthMainConv ]  = useState(false)
    const [heightWrapChat, setHeightWrapChat ]  = useState(false)
    const [heightMessagebar, setHeightMessagebar ]  = useState(false)
    const [userchat, setUserChat] = useState(false)
    const [loadMoreMessages, setLoadMoreMessages] = useState(false)
    const oldListMessageCount = usePrevious(listMessages.length)

    const mainconversation = useRef(null)

    const heightchat= properties.heightConversation - 60

    const scrollbar= useRef()
    const scrollfocus= useRef()



    useEffect(() => {
       if (oldListMessageCount != listMessages.length) {
           //nuovo messaggio arrivato!!
           //scrollfocus.current.scrollIntoView({block: "end", inline: "nearest"})
       }

    }, [listMessages]);


    useEffect(() => {

        if (properties.conversation && properties.conversation.conversations) {
            callDettConversation("load")

            //console.log(properties.conversation)

            if (properties.conversation.user) {
                setUserChat(properties.conversation.user)
                properties.setCurrentUserConvDetails(properties.conversation.user)
            }
            else {
                setUserChat(properties.conversation.guest)
                properties.setCurrentUserConvDetails(properties.conversation.guest)
            }
        }

    }, [properties.conversation]);


    useEffect(() => {

        scrollfocus.current.scrollIntoView({block: "end", inline: "nearest"})
    }, [heightWrapChat]);

    //console.log(scrollbar.current)


    useEffect(() => {
        if (heightMessagebar) {
            resetHeight()
        }

    }, [heightMessagebar]);

    useEffect(() => {
        if (properties.updateConversation) {
            let parameters = properties.updateConversation.toString()
            let paramconv =  parameters.substring(parameters.indexOf('$') + 1);
            let objparameters = dJSON.parse(paramconv)
            /*if (properties.conversation && properties.conversation.conversations) {
                if (objparameters.conversation_id === props.conversation.conversations._id) callDettConversation()
            }*/
            if (properties.conversation && (properties.user || properties.guest)) {
                if (properties.conversation.user && (objparameters.id === properties.conversation.user._id)) {
                    callDettConversation()
                }
                if (properties.conversation.guest && (objparameters.id === properties.conversation.guest._id)) {
                    callDettConversation()
                }
            }
        }


    }, [properties.updateConversation]);

    useEffect(() => {
        if (properties.optLangConversation) {
            if (properties.conversation && properties.conversation.conversations) {
                callDettConversation()
            }
        }
        if (heightMessagebar) {
            resetHeight()
        }
    }, [properties.optLangConversation]);

    /*UseInterval(async() => {
        callDettConversation()

    }, 600 )*/

    function resetHeight() {
        const heighttarget = props.heightConversation - (heightMessagebar + 60) + "px"
        setHeightWrapChat(heighttarget)
    }


    async function callDettConversation(load) {
        const idconv= properties.conversation.conversations._id
        let currentLang= properties.optLangConversation
        let objtopass = {
            conversation_id:idconv,
            lang:currentLang
        }
        if (properties.conversation.user) {
            objtopass.user_id = properties.conversation.user._id
        }
        else {
            objtopass.guest_id = properties.conversation.guest._id
        }
        let callconversation = await getConversation(objtopass);

        if (!callconversation.error && callconversation.conversation.length) {
            setListMessages(callconversation.conversation)

            /*if (callconversation.actors.length) {
                setListActors(callconversation.actors)
            }*/

            properties.setCurrentConversationDetails(callconversation.conversation)
            callMessagesAsRead(callconversation.conversation)
            if (scrollfocus.current) scrollfocus.current.scrollIntoView({block: "end", inline: "nearest"})
        }
    }

    async function callMessagesAsRead(conversation) {

        const found = conversation.find(element => element.updates &&  element.updates.console &&  element.updates.console.read && element.updates.console.read === "false");

        if (found) {
            const idconv= properties.conversation.conversations._id
            let currentLang= properties.optLangConversation
            const callmessageread = await setMessagesAsRead({conversation_id:idconv, lang:currentLang})
            if (!callmessageread.error) {
                props.setUpdateListConversations(new Date())
            }
        }
    }

    async function callMoreMessages() {
        setLoadMoreMessages(true)
        let currentnumbermsg = listMessages.length
        let currentLang= properties.optLangConversation
        const idconv= properties.conversation.conversations._id
        let callconversationMore = await getMoreMessages({conversation_id:idconv, lang:currentLang, current_number: currentnumbermsg});
        setLoadMoreMessages(false)
        if (!callconversationMore.error) {
            setListMessages(callconversationMore.conversation)
            properties.setCurrentConversationDetails(callconversationMore.conversation)
        }
    }

  return (
      <>
          <BoxMainConversation  ref={mainconversation} >
              {(() => {
                  if (listMessages.length) {
                      return (
                          <>
                              {(() => {
                                  if (loadMoreMessages) {
                                      return (
                                          <WrapLoadMoreMessage>
                                              <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                          </WrapLoadMoreMessage>
                                      )

                                  }
                                  else {
                                          return (
                                              <WrapLoadMoreMessage onClick={callMoreMessages}>
                                                  <ButtonRoundIcon title="Visualizza messaggi precedenti"><SvgAdd01 /></ButtonRoundIcon>
                                              </WrapLoadMoreMessage>
                                          )
                                  }

                              })()}
                              {(() => {
                                  moment.locale('IT');
                                  let ddate = moment(listMessages[0].date.date).format('dddd, ll');
                                  let hourdate = moment(listMessages[0].date.date).format('HH: mm');
                                  return listMessages.map((child, i) => {
                                       let newdate = moment(child.date.date).format('dddd, ll');
                                       let newhour = moment(child.date.date).format('HH: mm');
                                        if (i === 0 )
                                           return (
                                               <div key={i}>
                                                   <div className="date-chat">{ddate + ", " + hourdate}</div>
                                                   {(() => {
                                                        if (child.from === "user") {
                                                            return (
                                                                <ParserTemplComponentsUser
                                                                     code={child.message_data}
                                                                     app={properties.app}
                                                                     userchat={userchat}
                                                                     message={child}
                                                                />
                                                            )
                                                        }
                                                        else {

                                                        }
                                                   })()}
                                               </div>

                                          )
                                          else {
                                              if (ddate != newdate) {
                                                  ddate = newdate
                                                  hourdate = newhour
                                                  return (
                                                      <div key={i}>
                                                          <div className="date-chat">{ddate + ", " + hourdate}</div>
                                                          {(() => {
                                                              if (child.from === "user") {
                                                                  return (
                                                                      <ParserTemplComponentsUser
                                                                          code={child.message_data}
                                                                          app={properties.app}
                                                                          userchat={userchat}
                                                                          message={child}
                                                                      />
                                                                  )
                                                              }
                                                              else {
                                                                  return (
                                                                      <ParserTemplComponentsApp
                                                                          code={child.message_data}
                                                                          app={properties.app}
                                                                          message={child}
                                                                          actors={listActors}
                                                                      />
                                                                  )
                                                              }
                                                          })()}
                                                      </div>
                                                  )
                                              }
                                              else {
                                                  return (
                                                      <div key={i}>
                                                          {(() => {
                                                              if (child.from === "user") {
                                                                  return (
                                                                      <ParserTemplComponentsUser
                                                                          code={child.message_data}
                                                                          app={properties.app}
                                                                          userchat={userchat}
                                                                          message={child}
                                                                      />
                                                                  )

                                                              }
                                                              else {
                                                                  return (
                                                                      <ParserTemplComponentsApp
                                                                          code={child.message_data}
                                                                          app={properties.app}
                                                                          message={child}
                                                                          actors={listActors}
                                                                      />
                                                                  )
                                                              }
                                                          })()}
                                                      </div>
                                                  )
                                              }
                                          }

                                  })
                              })()}
                          </>
                      )
                  }
                  else {
                      return (
                          <p>Non ci sono messaggi</p>
                      )
                  }
              })()}
              <div ref={scrollfocus}></div>
          </BoxMainConversation>
       </>
  );
}

export default BusTargetConversationChat;
