import React, { useState, useEffect } from 'react';

import {MutatingDots, TailSpin, Oval} from "react-loader-spinner"
import Main_settings from "../styles/main_settings/Main_settings"
import {
    WrapLoader
} from "../styles/Commons"
function Loader(props) {
    const properties = props

    return (
        <WrapLoader>
        {(() => {
            switch (properties.type) {
                case "01":
                    return (
                        <MutatingDots width={properties.width} height={properties.height} color={properties.color} secondaryColor={Main_settings.color02}/>
                    )
                    break;
                case "02":
                    return (
                        <TailSpin width={properties.width} height={properties.height} color={properties.color} />
                    )
                break;
                case "03":
                    return (
                        <Oval width={properties.width} height={properties.height} color={properties.color} secondaryColor={properties.secondaryColor} />
                    )
                    break;
            }

        })()}
        </WrapLoader>
    )
}

export default Loader