import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


function ParserTemplComponentsAppListMS(props) {
    const properties = props
    const focusmessage = properties.code
    return (
        <>
             {(() => {
                    if (focusmessage && focusmessage.body) {
                        if (focusmessage.body.attachment) {
                            let objms = focusmessage.body.attachment
                            if (objms.type === "template") {
                                if (objms.payload) {
                                    let payload = objms.payload
                                    if (payload.template_type) {
                                        if (payload.template_type === "button") {
                                            let length = 5;
                                            let trimmedText = payload.text.substring(0, length) + "...";
                                            return (
                                                <span>{trimmedText}</span>
                                            )
                                        }
                                        if (payload.template_type === "generic") {
                                            return (
                                                <span>Ha..</span>
                                            )
                                        }
                                    }

                                }
                            }
                        }
                        if (focusmessage.body.quick_replies) {
                            let textquick = focusmessage.body.text
                            let length = 5;
                            let trimmedText = textquick.substring(0, length) + "...";
                            return (
                                <span>{trimmedText}</span>
                            )
                        }
                        if (focusmessage.body.text) {
                            let textquick = focusmessage.body.text
                            let length = 5;
                            let trimmedText = textquick.substring(0, length) + "...";
                            return (
                                <span>{trimmedText}</span>
                            )
                        }
                        else {
                            return (
                                <span>ha risposto</span>
                            )
                        }

                    }
                    else {
                        return (
                            <span>ha risposto</span>
                        )

                    }
            })()}
       </>
    )
}

export default ParserTemplComponentsAppListMS