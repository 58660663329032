import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import ImageUserChat from "./ImageUserChat";
import ImageApp from "./ImageApp";
import moment from "moment";
import Slider from "../tempcomponents/sliders/Slider";


function ParserTemplComponentsApp(props) {
    const properties = props
    const html = properties.code
    const message=  properties.message
    let hourdate = moment(message.date.date).format('HH: mm');
    return (
        <div className="viewdefault_row_chat viewdefault_row_chat_app">
            <div title={properties.app.name}  className="viewdefault_opt_msg_picture">
                {(() => {
                    if (message.actor_id && properties.actors.length) {
                        let listactors = properties.actors
                        let actortarget = listactors.find(actor => actor._id === message.actor_id)
                        return (
                            <ImageApp
                                app={properties.app}
                                actor={actortarget}
                            />
                        )
                    }
                    else {
                        return (
                            <ImageApp
                                app={properties.app}
                            />
                        )
                    }

                })()}
            </div>
            <div title={hourdate} className="viewdefault_message_wrapper viewdefault_message_wrapper_app">
                {
                    ReactHtmlParser(html, {
                        transform(node, index) {
                            let attribs = node.attribs
                            if (attribs && attribs["data-mama"]) {
                                if (attribs["data-mama"] === "slider") {
                                    return (
                                        <Slider
                                            key={index}
                                            node={node}
                                            code={html}
                                        />
                                     )
                                }
                                if (attribs["data-mama"] === "audiosource") {
                                    return (
                                        <audio controls>
                                            <source src={attribs.src} />
                                        </audio>
                                    )
                                }
                            }
                        }
                    })
                }
            </div>
        </div>
    )
}

export default ParserTemplComponentsApp