import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ImageUserChat from "./ImageUserChat";
import moment from "moment";


function ParserTemplComponentsUserWA(props) {
    const properties = props
    const focusmessage = properties.code
    const message=  properties.message


    let nameuser

    if (properties.userchat.guest) {
        nameuser = "User " + properties.userchat.sender
    }
    else {
        nameuser = properties.userchat.name
    }
    let hourdate = moment(message.date.date).format('HH: mm');

    async function clickFile(fileUrl)  {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileUrl;
            link.click();
        }catch
        (e)
        {
            console.log(e)
        }
    };
    return (
        <div className="viewdefault_row_chat">
            <div title={nameuser} className="viewdefault_opt_msg_picture">
                <ImageUserChat
                    user={properties.userchat}
                />
            </div>
            <div title={hourdate} className="viewdefault_message_wrapper viewdefault_message_wrapper_user">
                {(() => {

                if (focusmessage) {

                    if (focusmessage.type != "form") {
                        if (focusmessage.type === "document") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_text user_message user_file">
                                        <p onClick={() =>clickFile(focusmessage.body)}> <FontAwesomeIcon icon={["fas", "file-download"]}/> {focusmessage.caption  } </p>
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "image") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_image user_message">
                                        <img width="100px" alt="" src={focusmessage.body}/>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "video") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_video user_message">
                                        <video controls >
                                            <source type="video/mp4" src={focusmessage.body} />
                                        </video>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "audio" || focusmessage.type === "ptt") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_video user_message">
                                        <audio controls >
                                            <source src={focusmessage.body} />
                                        </audio>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "location") {
                            let coordinates = focusmessage.body.split(",")
                            let lat = coordinates[0]
                            let lng = coordinates[1]
                            let linkframe = "https://maps.google.com/maps?q=" + lat + "," + lng + "&hl=en&z=14&output=embed"
                            let linkref= "https://www.google.com/maps/dir//" + lat + "," + lng
                            return (
                                <div className="container_message">
                                    <div className="user_message_position user_message">
                                        <div className="user_message_position_map">
                                            <div className="user_message_position_icon">
                                                <FontAwesomeIcon icon={["fas", "map-marker-alt"]}/>
                                                <p className="user_message_type">{focusmessage.type}: {focusmessage.body}  </p>
                                            </div>
                                        </div>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_position_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "contacts") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_text user_message">
                                        <p><FontAwesomeIcon icon={["fas", "address-card"]}/> Contatto</p>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="container_message">
                                    <div className="user_message_text user_message">
                                        <p> {focusmessage.body  }</p>
                                    </div>
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                            <div className="container_message">
                                <div className="user_message_text user_message">
                                    <p>Contenuto non disponibile al momento</p>
                                </div>
                            </div>
                        )
                    }
                }
                else {
                    if (message.platforms.chatapi && message.platforms.chatapi.message_data) {
                        let focusmessage =  JSON.parse(message.platforms.chatapi.message_data)
                        if (focusmessage.type === "document") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_text user_message">
                                        <p onClick={() =>clickFile(focusmessage.body)}> <FontAwesomeIcon icon={["fas", "file-download"]}/> {focusmessage.caption  } </p>
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "image") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_image user_message">
                                        <img width="100px" alt="" src={focusmessage.body}/>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "video") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_video user_message">
                                        <video controls >
                                            <source type="video/mp4" src={focusmessage.body} />
                                        </video>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "audio" || focusmessage.type === "ptt") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_video user_message">
                                        <audio controls >
                                            <source src={focusmessage.body} />
                                        </audio>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_video_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "location") {
                            let coordinates = focusmessage.body.split(",")
                            let lat = coordinates[0]
                            let lng = coordinates[1]
                            let linkframe = "https://maps.google.com/maps?q=" + lat + "," + lng + "&hl=en&z=14&output=embed"
                            let linkref= "https://www.google.com/maps/dir//" + lat + "," + lng
                            return (
                                <div className="container_message">
                                    <div className="user_message_position user_message">
                                        <div className="user_message_position_map">
                                            <div className="user_message_position_icon">
                                                <FontAwesomeIcon icon={["fas", "map-marker-alt"]}/>
                                                <p className="user_message_type">{focusmessage.type}: {focusmessage.body}  </p>
                                            </div>
                                        </div>
                                        {(() => {
                                            if (focusmessage.caption) {
                                                return (
                                                    <p className="user_message_position_message">{focusmessage.caption}</p>
                                                )
                                            }

                                        })()}
                                    </div>
                                </div>
                            )
                        }
                        if (focusmessage.type === "contacts") {
                            return (
                                <div className="container_message">
                                    <div className="user_message_text user_message">
                                        <p><FontAwesomeIcon icon={["fas", "address-card"]}/> Contatto</p>
                                    </div>
                                </div>
                            )
                        }
                    }
                    else {
                        return (
                                <div className="container_message">
                                    <div className="user_message_text user_message">
                                        <p>Contenuto non disponibile al momento</p>
                                    </div>
                                </div>
                            )

                    }

                }

                })()}
            </div>
         </div>
    )
}

export default ParserTemplComponentsUserWA