import styled from 'styled-components';
import Main_Settings from "../styles/main_settings/Main_settings"
import {ButtonRoundIcon, Button01} from "./Commons";
import {ModalSettings, LinkSettings} from "./main_settings/Elements_settings";
export const BusinessConvWrapper = styled.div`

    display:flex;
    width:100%;
    height:${props => props.heightwrapper + "px"};
`;

export const BusinessConvContainer = styled.div`
    flex:1;
`;
export const BusinessFilterContainer = styled.div`
    width:100%, 
    height:60px
`;
export const BusinessAllConvContainer = styled.div`
    display:flex;
     height:${props => props.heightconvcontainer + "px"};
`;


export const BusinessConvListWrapper = styled.div`
    width:350px;
    height:${props => props.heightlistwrapper + "px"};
`;


export const BusinessOptionFilterWrapper = styled.div`
    width:100%;
    padding:10px;
    border-bottom:solid 1px #d0d0d0;
    display:flex;
    align-items: center;
    justify-content: space-between;
}
    
`;

export const BusinessFilterLanguages = styled.div`
    display:flex;
     align-items: center;
    
`;
export const BusinessTxtLanguages = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:5px;
    svg {
        width:20px;
        margin-right:5px;
    }
`;


export const BusinessListLanguages = styled.ul`
    display:flex;
    align-items: center;
    list-style:none;
`;
export const BusinessItemLanguages = styled.li`
    margin-right:20px;
    button {
        background:none;
        border:0;
        color:${Main_Settings.color02};
        cursor:pointer;
    }
`;
export const BusinessItemLanguagesCurrent = styled(BusinessItemLanguages)`
    background:${Main_Settings.color02};
    color:${Main_Settings.color04};
    border-radius:40px;
    padding:5px 20px;
`;

export const BusinessFilterPlatform = styled.div`
    display:flex;
     align-items: center;
    
`;
export const BusinessTxtPlatform = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:5px;
    svg {
        width:20px;
        margin-right:5px;
    }
`;

export const BusinessListPlatforms = styled.ul`
    display:flex;
    align-items: center;
    list-style:none;
`;
export const BusinessItemPlatform = styled.li`
    margin-right:20px;
    button {
        background:none;
        border:0;
        color:${Main_Settings.color02};
        cursor:pointer;
    }
`;
export const BusinessFilterAutomaticReply = styled.ul`
    display:flex;
     align-items: center;
`;

export const BusinessExportConversation = styled.div`

`;


export const BusinessMessageBarWrapper = styled.div`
    position:fixed;
    width:100%;
    min-height:50px;
    bottom:0;
    box-sizing: border-box;
    background:${Main_Settings.color02};
    width:${props => props.widthmessagebarwrapper + "px"};

`;
export const BusinessMessageBar = styled.div`
    background:${Main_Settings.color04};
    border-radius:40px; 
    padding:5px 20px;
    position:relative;
`;

export const WrapLoadMoreMessage = styled.div`
        display:flex;
        align-items:center;
        justify-content:center;
    ${ButtonRoundIcon} {
        min-height:40px;
        width:40px;
        display:flex;
        align-items:center;
        justify-content:center;
        background:none;
        
       :hover {
            background:${Main_Settings.color02};
        }
    }
`;



export const BusinessMessageBarContent = styled.div`
    display:flex;
    align-items: center;
`;
export const BoxCurrentActor = styled.div`
    cursor:pointer;
    margin-right:5px;
`;
export const CurrentModelMessagePack = styled.div`
    background:${Main_Settings.color02};
    display:flex;
    padding:10px;
    padding-right:5px;
    margin-left:10px;
    border-radius:40px;
    color:${Main_Settings.color04};
`;

export const NameModelMessagePack = styled.span`
    color:${Main_Settings.color04};
`;
export const ButtonDeleteMessagePack = styled(ButtonRoundIcon)`
    width:20px;
    background:none;
    justify-self:flex-end;
    svg {
        fill:${Main_Settings.color04};
    }
`;
export const ButtonSendModel = styled.button`

    justify-self:flex-end;
    width:40px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    background:${Main_Settings.color03};
    border:0;
    border-radius:50%;
    margin-left:5px;
    
    svg {
        width:70%;
    }

`;
export const ButtonOptionMessage = styled.button`

    justify-self:flex-end;
    width:40px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    background:${Main_Settings.color03};
    border:0;
    border-radius:100%;
    svg {
        width:100%;
    }
   svg #icoArea1, svg #icoArea2 {
        fill:transparent;
    }

`;
export const BusinessMessageBarMenuMessage = styled.div`
    position:absolute;
    top:-65px;
    right:20px;
    padding:20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    background:${Main_Settings.color04};
    border-radius:20px;
    box-shadow:${ModalSettings.box_shadow_panel_modal_access};
    

`;

export const ItemOptMessageBarMenuMessage = styled.div`
    display:flex;
    cursor:pointer;
    width:30px;
    margin-right:10px;
    svg {
        width:30px;
    }
    svg #bAreaCircle {
        display:block;
        fill:${Main_Settings.color03}
    }
    :last-child {
        margin-right:0;
    }
`;

export const ItemOptMessageBarMenuMessageBg = styled(ItemOptMessageBarMenuMessage)`
    width:35px;
    svg {
        width:35px;
    }

`;
export const ContentWrapperCurrentModelMsgPack = styled.div`
    display:flex;
    flex:1;
`;
export const PanelPickerEmo = styled.div`
   z-index:1000;
   position:absolute;
   left:-310px;
   top:-450px;
`;


export const ContMessageAppFile = styled.div`
    display: flex;
    align-items:center;
  cursor: pointer;
    svg {
      margin-right: 10px;
      font-size: 1.5em;
    }
`;
export const WrapperCurrentModelMessagePack = styled.div`
`;
export const WrapperCurrentModelMessagePackWa = styled.div`
    width: 100%;
  display: flex;
`;
export const TitleMenuActor = styled.div`
    font-family:"Roboto-Bold";
    border-bottom:solid 1px #d0d0d0;
    padding-bottom:8px;
    margin-bottom:8px;
`;


export const BusinessConvChatWrap = styled.div`
    flex:1;
    border:solid 1px #d0d0d0;
    border-width:0 0 0 1px;
    position:relative,
    height:${props => props.heightconvchatwrap + "px"};
    
`;
export const BusinessConvDetailsChat = styled.div`
    height:${props => props.heightconvdetailschat + "px"};
    padding-left:10px;
`;
export const BoxLoader = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:${props => props.heightloader + "px"};
`;

export const BusinessNoConvContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    p {
        font-size:1.5em;
    }
`;


export const BoxMainConversation = styled.div`
    height:${props => props.heightbox + "px"};
`;

export const BusinessMenuActor = styled.div`
    position:absolute;
    bottom:60px;
    width:250px;
    padding:10px 20px;
    min-height:200px;
    background:${Main_Settings.color04};
    border-radius:20px;
    box-shadow:${ModalSettings.box_shadow_panel_modal_access};
`;
export const WrapperListMenuActor = styled.div`

`;
export const CurrentActorItem = styled.p`
    display:flex;
    align-items:center;
    opacity:.5;
    margin-bottom:10px;
    img {
        margin-right:10px;
    }
`;
export const ListMenuActor = styled.ul`
    li {
        display:flex;
        align-items:center;
        list-style:none;
        cursor:pointer;
        border-radius:40px;
        margin-bottom:10px;
        img {
        margin-right:10px;
        }
        :hover {
            background:${Main_Settings.color10};
        }
    }
`;
export const BusinessListConversations = styled.div`
    height:${props => props.heightbar + "px"};
    position:relative;
    padding-bottom:50px;
`;

export const BusinessUserConversationWrapper = styled.div`
    width:280px;
    padding:20px 0;
    margin-top:10px;
    position:relative;
    z-index:2;
    height:${props => props.heightbar -10 + "px"};
    display:flex;
    flex-flow:column;
    align-items:center;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    border-left:0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;
export const BusinessUserConversationImage = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    img {
        width:100px;
        border-radius:100%;
        
    },
    svg {
        width:100px;
        margin:0 auto;
    }
`;
export const BusinessUserConversationInfo = styled.div`
    padding:10px 0 10px 0;
    width:100%;
    text-align:center;
    border-bottom:solid 1px #d0d0d0;
    p {
        margin-bottom:5px;
    }
    a {
        color:${LinkSettings.color_main}
    }
`;
export const BusinessUserConversationPanelAction = styled.div`
    width:100%; 
    padding:10px 20px;
    border-top:solid 1px #d0d0d0;
    h3 {
        margin-bottom:10px;
        font-size:1.1em;
    }
    h4 {
        margin-bottom:5px;
        font-size:1em;
    }
     ${Button01} {
        font-size:.9em;
        padding:10px 20px;
    }
`;



export const BusinessUserConvDescriptionService = styled.p`
    font-size:.9em;
    margin-bottom:5px;
`;
export const BusinessUserConversationSection = styled(BusinessUserConversationPanelAction)`
    margin-top:0;
    border-top:0;
`;

export const BusinessUserConversationAutoReply = styled.div`
    display:flex;
    align-items:center;
    .react-switch-handle {
        width:25px !important;
        height:25px !important;
        top:2px !important;
    }
`;
export const MessageBarWA = styled.div`
  
      word-wrap: break-word;
      word-break: break-all;
      width:100%;
      min-height:30px;
      padding:0 5px;
      padding-top:5px;
     &:read-write:focus {
     outline: none;
     }
     &[placeholder]:empty:before {
        content: attr(placeholder);
     } 
     &[placeholder]:empty:focus:before {
        content: "";
    }
`;
export const MessageBarTemplate = styled.div`
      width:100%;
      min-height:30px;
      
      display:flex;
      flex-wrap:wrap;
      justify-content:flex-start;
`;

export const PaginationListConv = styled.div`
    
    position:fixed;
    z-index:1000000;
    bottom:0;
    width:350px;
    height:54px;
    display:flex;
    background:#fff;
    align-items:center;
    justify-content:center;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    .pagination {
        display:flex;
        width:250px;
    }
    .pagination li {
        list-style:none;
        font-size:.9em;
        cursor:pointer;
        flex:1;
        display:flex;
        align-items:center;
        justify-content:center;
         color:${LinkSettings.color_main};

    }
     .pagination li a {
         cursor:pointer;
         color:${LinkSettings.color_main};
         line-height: 18px;
         display:flex;
         border-radius: 50%;
         width:30px;
         height:30px;
         align-items:center;
         justify-content:center;
    }
    .pagination li.disabled  {
        opacity:.5;
    }
    .pagination li.active a  {
       background:${Main_Settings.color02};
      color:${Main_Settings.color03};
       
    }
    .pagination li.disabled a,  .pagination li.active a {
        cursor:default;
    }
    .pagination li.disabled a {
        color:${Main_Settings.color05};
    }
`;


export const BusinessUserConversationCrmPreview = styled(BusinessUserConversationPanelAction)`

`;


export const BusinessUserConversationOtherActions = styled(BusinessUserConversationPanelAction)`

`;

export const BoxMainInfoUser = styled.div`
  
 
`;
export const PanelInfoUserCrmPreview = styled.div`
 
`;
export const ListInfoUserCrmPreview = styled.ul`
  padding:0;
  margin:0;
 
`;
export const ItemInfoUserCrmPreview = styled.li`
  list-style:none;
  display:flex;
  align-items:center;
  font-size:.95em;
  margin-bottom:10px;
  svg {
    width:16px;
    margin-right:10px;
  }
  a {
    color:${LinkSettings.color_main};
    font-weight:normal;
  }
  
 
`;
export const PanelAddInfoUserCrmPreview = styled.div`
  p {
    margin-bottom:5px;
  }

 
`;



export const RowChatMessage = styled.div`
    display:flex;
    align-items:center;
    margin-top:20px;
 
`;

export const RowChatMessageApp = styled(RowChatMessage)`
    justify-content:flex-end;
margin-left:20px;
 
`;
export const ChatMessagePictureApp = styled.div`
    order:2;
    margin-left:20px;
    width: 35px;
    margin-right: 20px;
`;
export const ChatMessageWrapper = styled.div`
    display:flex; 
    flex-flow:column; 
    padding:10px; 
    border-radius:10px;
`;

export const ChatMessageWrapperApp = styled(ChatMessageWrapper)`
    background:#fafafa;
    padding:20px;
    max-width:100%;
`;

export const ChatMessageWrapperAppWA = styled(ChatMessageWrapperApp)`
    max-width:60%;
`;
export const ChatMessageWrapperAppMS = styled(ChatMessageWrapperApp)`
    max-width:60%;
`;
export const ChatMessageWrapperAppMSSLider = styled(ChatMessageWrapperApp)`
    max-width:70%;
`;
export const ChatMessageWrapperAppMSQuickReply = styled(ChatMessageWrapperApp)`
    max-width:70%;
    p {
        margin-bottom:10px;
    }
`;

export const ChatMessageWrapQuickReply = styled.ul`
   list-style:none;
   display:flex;
   flex-wrap:wrap;
   
   li div img {
    height:20px;
    margin-right:5px;
    border-radius:40px;
   }
`;
export const ChatMessageWrapperAppMSTButton = styled(ChatMessageWrapperApp)`
    max-width:35%;
    p {
        margin-bottom:10px;
    }
`;
export const ChatMessageWrapButtons = styled.ul`
   list-style:none;
   
   li {
    margin-bottom:10px;
   }
`;

export const ChatMessageWrapperAppMSImage = styled(ChatMessageWrapperApp)`
    max-width:35%;
   img {
        width:150px;
    }
`;

export const ChatMessageWrapperAppMSFile = styled(ChatMessageWrapperApp)`
    max-width:35%;
   img {
        width:150px;
    }
`;
export const ChatMessageWrapperAppMSFileInfo = styled.div`
`;
export const ChatMessageWrapperAppMSFileIcon = styled.div`
    a {
        font-size:3.3em;
        color:${Main_Settings.color02}
    }

`;

export const ChatMessageWrapperAppMSFileText = styled.p`
    color:${Main_Settings.color12};
    font-style:italic;
    margin-top:8px;
    font-size:.9em;
`;

export const WrapLoadMessageBar = styled.p`
    display:flex;
    width:100%;
    background:#fff;
    align-item:center;
    justify-content:center;
`;

































