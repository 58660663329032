export const componentscss = `
 .aromaslider_wrapper  { max-width:600px; position:relative}
 .aromaslider_inner {white-space:nowrap;}
 .aromaslider_slide { display:inline-flex; flex-flow:column; white-space: normal; align-items:center;}
 .aromaslider_slide > div { max-width:250px; box-sizing:border-box; padding:10px; border:solid 5px #fff;  }
 .aromaslider_slide img, .aromaslider_slide video { max-width:100% !important; max-height:200px !important; margin:auto;}
  .aromaslider_slide iframe { height:150px !important; width:100% !important;}
  .aromaslider_button { background: #fae5ed;padding: 5px;text-align: center;border-radius: 10px; margin-bottom: 5px; font-weight: 700;}
  .aromaslider_btnslide_dir {position:absolute; display:flex; align-items:center; justify-content:center; z-index:2; border-radius:10px; top:50%; margin-top:-20px; width:40px; height:40px; opacity:.1; cursor:default; font-size:0; background:#FAE5ED;}
 .aromaslider_btnslide_dir svg {width:25px !important; height:25px !important;}
 .aromaslider_btnslide_dir_active { opacity:1; cursor:pointer;}
 .aromaslider_btnslide_dir_active:hover { background:#FF398A}
 .aromaslider_btnslide_dirprev {left:-10px;}
 .aromaslider_btnslide_dirnext {right:-10px;}
`;
