import React, {useState, useEffect} from "react";
import Header from "../Header/Header";
import {getListApp} from "../../hooks/AppProvider";
import settings from "../../settings/setting"
import branch from "../../settings/branch"
import ImageApp from "../../utils/ImageApp";

function ListApp(props) {
    const [listApps, setListApps] = useState([]);
    const [userPermissions, setUserPermissions] = useState(false);
    useEffect(() => {
        callListApp()
    }, []);

    useEffect(() => {
    }, [listApps]);

    const changeListApp = async (e) => {
        let url_settings = settings[branch.name].console_url +  "/app/" + e.target.value + "/business/conversations"


        window.location.href = url_settings

    };


    const callListApp = async (e) => {
        let varlistapp = await getListApp()
        let alllistapp = varlistapp.apps
        let listappallowed = []
        if (alllistapp.length) {
            for (let i = 0; i < alllistapp.length; i++) {

                let permissionlist = alllistapp[i].manage.permissions

                for (let z = 0; z < permissionlist.length; z++) {
                    if (permissionlist[z].user_id === props.user._id) {
                        listappallowed.push(alllistapp[i])
                    }
                }
            }
        }
        setListApps(listappallowed)
        props.setListApp(listappallowed)

    };
    return (
        <>
            {(() => {
                if (listApps.length) {
                    if (props.showApp.show) {
                        return (
                            <>
                                <div id="appimage_listapp_header">
                                    <ImageApp
                                        app={props.app}
                                    />
                                </div>
                                {(() => {
                                    if (listApps.length > 0) {
                                        return (
                                            <select defaultValue={props.showApp.name} onChange={changeListApp}>
                                                {(() => {
                                                    return listApps.map((child, i) => {

                                                        return (
                                                            <option key={i} value={child.name} key={i}>{child.name}</option>
                                                        )
                                                    })
                                                })()}
                                            </select>
                                        )
                                    }
                                    else {
                                        return (
                                            <span>{props.app.name}</span>
                                        )
                                    }

                                })()}

                            </>
                        )
                    }

                }
            })()}
        </>
    )
}

export default ListApp
