import React, {useState, useEffect, useRef} from "react";
import {sendMessageConsoleWA} from "../../../../../../hooks/ConversationProvider";

import {

    BusinessMessageBar,
    BusinessMessageBarWrapper,
    BusinessMessageBarContent,
    MessageBarWA,
    BoxCurrentActor,
    ButtonSendModel,
    ItemOptMessageBarMenuMessage,
    WrapperCurrentModelMessagePack,
    WrapperCurrentModelMessagePackWa,
    CurrentModelMessagePack, NameModelMessagePack, ButtonDeleteMessagePack, WrapLoadMessageBar, ItemOptMessageBarMenuMessageBg
} from "../../../../../../styles/businessConversation"

import Send01 from "../../../../../../source/svg/Send01";

import {ButtonRoundIcon} from "../../../../../../styles/Commons";

import SvgCLose01 from "../../../../../../source/svg/SvgClose01";
import File01 from "../../../../../../source/svg/File01";
import Loader from "../../../../../../utils/Loader";
import Main_settings from "../../../../../../styles/main_settings/Main_settings";

function BusMessageBarWA(props) {
    const properties = props
    const app = properties.app
    const messagebar= useRef(null)
    const [currentModel, setCurrentModel] = useState(false)
    const [contentBarMessage, setContentBarMessage] = useState(false)
    const [ableToSendMessage, setAbleToSendMessage] = useState("true")
    const [showLoadingMessage, setShowLoadingMessage] = useState(false)
    const [selectedFile, setSelectedFile] = useState(false);
    const [base64File, setBase64File] = useState(false)
    const refmessagetext = useRef(null)
    const inputFileFile = useRef(null);
    useEffect(() => {
        if (messagebar.current && messagebar.current.offsetHeight && messagebar.current.clientHeight) {
            setTimeout(() => {properties.setHeightMessagebar(messagebar.current.clientHeight)}, 2000);
        }
    }, []);

    useEffect(() => {
        setContentBarMessage(false)
        refmessagetext.current.textContent = ""
    }, [props.currentConversation]);


    useEffect(() => {
        if (contentBarMessage.length) {
            /*refmessagetext.current.innerText = contentBarMessage
            var range = document.createRange();
            var sel = window.getSelection();
            range.setStart(refmessagetext.current["childNodes"][0], contentBarMessage.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            refmessagetext.current.focus()*/
        }
    }, [contentBarMessage]);

    const handleFileChange = async (e) => {
        try {
            e.stopPropagation()
            const file = e.target.files[0];

            if (file.size > 7000000) {
                properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:2}})
            }
            else {
                const base64 = await getBase64(file)

                setBase64File(base64)
                setSelectedFile(file);
            }

        }
        catch (e) {

        }

    };

    useEffect(() => {
        if (selectedFile) {
            setContentBarMessage(selectedFile)
        }
    }, [selectedFile]);

    const onChangeContentBarMessage = (e) =>  {

        setContentBarMessage(e.currentTarget.textContent)
    }

    const getBase64 = async (file) => {
        try {
            return await new Promise((resolve, reject) => {
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();

                // Convert the file to base64 text
                reader.readAsDataURL(file);

                // on reader load something...
                reader.onload = () => {
                    // Make a fileInfo Object
                    console.log("Called", reader);
                    baseURL = reader.result;
                    console.log(baseURL);
                    resolve(baseURL);
                };

                reader.onerror = (error) => {
                    reject(error);
                };
            });
        } catch (error) {
            console.error('Errore durante la conversione in base64:', error);
            throw error; // Puoi gestire l'errore o propagarlo ulteriormente secondo le tue esigenze
        }
    };

    const onClickMediaFile = () => {
        inputFileFile.current.click();
    };

    const removeCurrentFile = () => {
        setBase64File(false)
        setSelectedFile(false)
        setContentBarMessage("")
    }
    const actionSend = async (e) => {
        if (contentBarMessage != "") {


            let user_conversation


            if (properties.conversation) {
                user_conversation = properties.conversation._id
            }
            let objMessage
            let type
            if (selectedFile) {
                type = "file"
                objMessage = {
                    app_id:properties.app._id,
                    message: {
                        sender:user_conversation,
                        type:"file",
                        name:selectedFile.name,
                        body: {
                            message:base64File
                        }
                    }
                }
            }
            else {
                type = "text"
                objMessage = {
                    app_id:properties.app._id,
                    message: {
                        sender:user_conversation,
                        type:"text",
                        body: {
                            message:contentBarMessage
                        }
                    }
                }
            }

            setAbleToSendMessage(false)
            setShowLoadingMessage(true)
            let runmessage = await sendMessageConsoleWA(type, objMessage)
            setShowLoadingMessage(false)
            setAbleToSendMessage("true")
            if (runmessage.error) {
                //console.log(runmessage)
                properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
            }
            if (selectedFile) setSelectedFile(false)
            setContentBarMessage('')
            refmessagetext.current.innerText = ""
        }

    }
  return (
      <>
          {(() => {
                return (
                   <BusinessMessageBar  >
                            <BusinessMessageBarContent >
                                <BoxCurrentActor>
                                    <img style={{width:"40px"}} alt="" src={app.image} />
                                </BoxCurrentActor>
                                {(() => {
                                    if (showLoadingMessage) {
                                        return (
                                            <WrapLoadMessageBar>
                                                <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                            </WrapLoadMessageBar>
                                        )
                                    }
                                    else {
                                        if (selectedFile) {
                                            return (
                                                <WrapperCurrentModelMessagePackWa>
                                                    <CurrentModelMessagePack>
                                                        <NameModelMessagePack>{selectedFile.name}</NameModelMessagePack>
                                                        <ButtonDeleteMessagePack onClick={removeCurrentFile}><SvgCLose01></SvgCLose01></ButtonDeleteMessagePack>
                                                    </CurrentModelMessagePack>
                                                </WrapperCurrentModelMessagePackWa>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <MessageBarWA
                                                        contentEditable="true"
                                                        suppressContentEditableWarning={true}
                                                        placeholder="Rispondi su WhatsApp..."
                                                        onInput={onChangeContentBarMessage}
                                                        ref={refmessagetext}
                                                    >
                                                    </MessageBarWA>
                                                </>
                                            )

                                        }
                                    }

                                })()}


                                {(() => {
                                    if (!selectedFile && !contentBarMessage) {
                                        return (

                                                <ItemOptMessageBarMenuMessageBg title="File"  onClick={onClickMediaFile}>
                                                    <File01 />
                                                    <input ref={inputFileFile} style={{ display: "none" }} accept="*" type="file" multiple  onChange={handleFileChange} />
                                                </ItemOptMessageBarMenuMessageBg>
                                        )
                                    }
                                    else {
                                        if (ableToSendMessage === "true") {
                                            return (
                                                <ButtonSendModel title="invia" onClick={actionSend} ><Send01/> </ButtonSendModel>
                                            )
                                        }

                                    }
                                })()}
                            </BusinessMessageBarContent>
                        </BusinessMessageBar>
                )

          })()}

       </>
  );
}

export default BusMessageBarWA;
