import React, {useEffect} from "react";
import Header from "../Header/Header";
import {
    WrapperHome, WrapperNoRegHome, BoxCreateAppHome, WrapperListAppHome, ListAppHome, ItemAppHome, ImgItemAppHome, DataItemAppHome, TextListAppHome
} from "../../styles/Home";

import {
    Button01
} from "../../styles/Commons";
import ListApp from "../App/ListApp";
import setting from "../../settings/setting";
import branch from "../../settings/branch";


function Home(props) {




    const gotoapp = (name) => {
        let path = setting[branch.name].console_url + "/app/" + name + "/business/conversations"
        window.open(path, "_self");
    }
    const actioncreateapp = () => {
        props.setModalSystem({show:true, source:"home", action:"create-api"})
    }
    const _goToLogin = () => {
        let path = setting[branch.name].client_url + "/login/console=true"
        window.open(path, "_self");
    }
    const goToLogin = () => {
        let path = setting[branch.name].console_url + "/login"
        window.open(path, "_self");
    }



    return (
        <>
            <Header showApp={{show:false}} setListApp={props.setListApp} listApp={props.listApp} user={props.user} setClearToken={props.setClearToken} setToken={props.setToken}/>
            <WrapperHome>
                {(() => {
                    if (props.user && props.user?.registration?.active ) {
                        return (
                            <>
                                <WrapperListAppHome>
                                    {(() => {
                                        if (props.listApp.length) {
                                            const elapps= props.listApp
                                            return (
                                                <>
                                                    <TextListAppHome>Le tue App</TextListAppHome>
                                                    <ListAppHome>
                                                        {(() => {
                                                            return elapps.map((child, i) => {
                                                                let url_settings = "api/" + child.name + "/development/settings"
                                                                return (
                                                                    <ItemAppHome onClick={e => gotoapp(child.name)} key={i}>
                                                                        <ImgItemAppHome>
                                                                            <img alt="" src={child.image}  />
                                                                        </ImgItemAppHome>
                                                                        <DataItemAppHome>
                                                                            <h2>{child.name}</h2>
                                                                            <p>ID app: {child.id_app}</p>
                                                                        </DataItemAppHome>
                                                                    </ItemAppHome>
                                                                )
                                                            })
                                                        })()}
                                                    </ListAppHome>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <p>Non ci sono ancora applicazioni attive</p>
                                                </>
                                            )
                                        }
                                    })()}
                                </WrapperListAppHome>
                            </>
                        )
                    }
                    else {
                        return (
                            <WrapperNoRegHome>
                                <p>Devi essere autenticato per visualizzare il contenuto</p>
                                <Button01 onClick={goToLogin} >Accedi</Button01>
                            </WrapperNoRegHome>
                        )
                    }

                })()}
            </WrapperHome>
        </>
    )
}

export default Home
