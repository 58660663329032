import React, {useEffect, useState} from "react";
import {userForgotPassword} from "../../hooks/UserProvider";

import {validateEmail, validatePassword} from "../../utils/utils";
import {checkErrorCode, checkErrorSubCode} from "../../utils/error_codes";
import {
    BodyModalAccessForgotPwd,
    TitleModalAccess,
    PanelDataModalAccess,
    WrapButtonsModalAccess,
    ItemDataModalAccess,
    ItemDataSelectModalAccess,
    TextInfoDataModalAccess,
    MainWrapDateofbirhtday, WrapDateofbirthday,
    ItemExtraLinkModalAccessLogin,
    ItemExtraLinkModalAccessInfoTerms,
    ExtraLinkModalAccess, WrapValidatorDataModalAccess, ValidatorDataModalAccess
} from "../../styles/User";
import {Button01, Button011, RoundIcon} from "../../styles/Commons";;
function Forgotpwd(props) {
    const [InputEmail, setInputEmail] = useState("")
    const [InputNewPassword, setInputNewPassword] = useState("")
    const [step, setStep] = useState(props.extracontent.condition)
    const [errorMessage, setErrorMessage] = useState()
    const [showError, setShowError] = useState(false)
    const [showButtonSend, setShowButtonSend] = useState(false)

    useEffect(() => {
        if (showError) setShowError(false)
        if (InputEmail != "") {
            const cheemail = validateEmail(InputEmail)
            if (cheemail) setShowButtonSend(true)
            else {
                if (showButtonSend) setShowButtonSend(false)
            }
        }
        else {
            setShowButtonSend(false)
        }
    }, [InputEmail]);

    useEffect(() => {
        if (showError) setShowError(false)
        if (InputNewPassword != "") {
            const checkpasswrd = validatePassword(InputNewPassword)
            if (checkpasswrd) {
                setShowButtonSend(true)
            }
            else {
                let error = checkErrorCode(15000)
                setErrorMessage(error)
                setShowError(true)
                setShowButtonSend(false)
            }
        }
        else {
            setShowButtonSend(false)
        }
    }, [InputNewPassword]);

    async function sendDataForm(e) {
        e.preventDefault();
        let dataUser = {}
        try {
            if (step.toString() == "step1") {
                dataUser = {
                    email: InputEmail
                }
            }
            if (step.toString() == "step3") {
                dataUser = {
                    user:props.extracontent.user,
                    newpassword: InputNewPassword
                }
            }
            let responsechange = await userForgotPassword(dataUser, step);
            if (responsechange.error) {
                let error
                error = checkErrorSubCode(responsechange.error_subcode)
                setShowError(true)
                if (responsechange.error_code === 401) {
                    setErrorMessage(error)
                }
                else {
                    setErrorMessage(error)
                }
            }
            else {
                if (step.toString() == "step1") {
                    props.setModalContent({component:"securCode", source:"forgotpassword", show:true})
                }
                if (step.toString() == "step3") {
                    props.setToken(responsechange.token)
                    props.setModalContent({show:false})
                    window.location.reload();
                }
            }
        }catch (e) {

        }
    }
    return (
        <BodyModalAccessForgotPwd>
            <TitleModalAccess>Recupera password</TitleModalAccess>
            {showError && (
                (<WrapValidatorDataModalAccess><ValidatorDataModalAccess>{errorMessage}</ValidatorDataModalAccess></WrapValidatorDataModalAccess>)
            )
            }
            <PanelDataModalAccess>
                {(() => {
                    if (step.toString() == "step1") {
                        return (
                              <>
                                  <TextInfoDataModalAccess><p>Inserisci l'e-mail con cui ti sei registrato</p></TextInfoDataModalAccess>
                                  <ItemDataModalAccess>
                                      <input className="itI6874ac itI6874bc" placeholder="Email attuale" type="text" name="email" value={InputEmail} onChange={e => setInputEmail(e.target.value.replace(/\s/g, ""))}/>
                                  </ItemDataModalAccess>
                              </>

                            )
                    }
                    else if (step.toString() == "step3") {
                        return (
                            <>
                                <TextInfoDataModalAccess><p>Inserisci la nuova password</p></TextInfoDataModalAccess>
                                <ItemDataModalAccess>
                                    <input className="itI6874ac itI6874bc" placeholder="Nuova password" type="password" name="password" value={InputNewPassword} onChange={e => setInputNewPassword(e.target.value)}/>
                                </ItemDataModalAccess>
                            </>
                      )
                    }
                })()}
                <WrapButtonsModalAccess>
                    {(showButtonSend) ? <Button01 onClick={sendDataForm}>Invia</Button01> : <Button011>Invia</Button011>}
                </WrapButtonsModalAccess>
            </PanelDataModalAccess>
        </BodyModalAccessForgotPwd>
    )
}
export default Forgotpwd