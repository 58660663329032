import React, {useEffect, useState} from "react";
import ModalChooseModel from "./ModalChooseModel";
import ModalCreateModel from "./ModalCreateModel";
import ModalAddDataInfoUser from "./ModalAddDataInfoUser"
import AppConsole from "../App/AppConsole";
import {
    ButtonCloseModal,
    ModalErrorContent,
    PanelModalModel,
    WrapperModalSystem,
    PanelModalModelLarge,
    PanelModalModelExport
} from "../../styles/Modals";
import {
    Button01

} from "../../styles/Commons";
import SvgCLose01 from "../../source/svg/SvgClose01";
import ModalCreateApp from "./ModalCreateApp";
import ModalTermsAddInfoUser from "./ModalTermsAddInfoUser";
import setting from "../../settings/setting";
import branch from "../../settings/branch";
import ModalExportConversation from "./ModalExportConversation";
import ModalChooseModelMS from "./ModalChooseModelMS";
import ModalSavedRepliesMS from "./ModalSavedRepliesMS";

function ModalSystem(props) {
    const properties = props
    const nameDisableScroll= "modalSystem"
    const arrScroll= properties.elDisableScroll
    const [windowwidth, setWindowWidth] = useState(window.innerWidth || (document.documentElement || document.body).clientWidth)
    const handleResize = () => {
        setWindowWidth(window.innerWidth || (document.documentElement || document.body).clientWidth)
    }
    useEffect(() => {
        let indexreq = arrScroll.indexOf(nameDisableScroll)
        if (arrScroll.length) {
            if (indexreq === -1) properties.setElDisableScroll(prevArray => [...prevArray, nameDisableScroll])
        }
        else {
            properties.setElDisableScroll(prevArray => [...prevArray, nameDisableScroll])
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
        properties.setEventScroll(new Date())
    }, []);

    const closeModal = async (e) => {

        properties.setModalSystem({show:false, action:null, source:null, data:null})
    }

    const reloadpage = async (e) => {
        const path = window.location.pathname + window.location.search
        window.open(path, "_self");

    }

    const elCloseModal =   (
        <ButtonCloseModal title="chiudi" onClick={closeModal}><SvgCLose01 /></ButtonCloseModal>
    )


    return (
        <WrapperModalSystem windoww={windowwidth}>

                {(() => {
                    switch (properties.dataModal.action) {
                        case "choose-model-conversation":
                            return (
                                <PanelModalModel windoww={windowwidth}>

                                    <ModalChooseModel
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}
                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                        break;
                        case "choose-saved-replies-MS":
                            return (
                                <PanelModalModel windoww={windowwidth}>

                                    <ModalSavedRepliesMS
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}
                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "choose-model-conversation-MS":
                            return (
                                <PanelModalModel windoww={windowwidth}>

                                    <ModalChooseModelMS
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}
                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "create-model-conversation":
                            return (
                                <PanelModalModel windoww={windowwidth}>

                                    <ModalCreateModel
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}
                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "create-api":
                            return (
                                <PanelModalModel windoww={windowwidth}>
                                    <ModalCreateApp
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}

                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "edit-model-conversation":
                            return (
                                <PanelModalModel windoww={windowwidth}>

                                    <ModalChooseModel
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}
                                        edit="true"
                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "show-terms-info-user":
                            return (
                                <PanelModalModel windoww={windowwidth}>
                                    <ModalTermsAddInfoUser
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        app={properties.app}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}

                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "data-info-add-user":
                            return (
                                <PanelModalModel windoww={windowwidth}>
                                    <ModalAddDataInfoUser
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        app={properties.app}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}

                                    />
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                        case "export-conversation":
                            return (
                                <PanelModalModelExport windoww={windowwidth}>
                                    <ModalExportConversation
                                        dataModal={properties.dataModal}
                                        user={properties.user}
                                        app={properties.app}
                                        setModalSystem={properties.setModalSystem}
                                        setResultModalSystem={properties.setResultModalSystem}
                                        resultModalSystem={properties.resultModalSystem}

                                    />
                                    {elCloseModal}
                                </PanelModalModelExport>
                            )
                            break;
                        case "error":
                            let messageerror
                            let showreloadpage
                            switch (properties.dataModal.data.message_code) {
                                case 1:
                                    messageerror = "Qualcosa è andato storto e si è verificato un errore nell'invio. Ricarica la pagina e riprova."
                                    showreloadpage = 1
                                    break;
                                case 2:
                                    messageerror = "Il file non può superare i 7mb di peso."
                                    break;
                            }
                            return (
                                <PanelModalModel windoww={windowwidth}>
                                    <ModalErrorContent>
                                        <h1>Attenzione</h1>
                                        <p>{messageerror}</p>
                                        {(showreloadpage) && <p><Button01 onClick={reloadpage}>Ricarica la pagina</Button01></p>}
                                    </ModalErrorContent>
                                    {elCloseModal}
                                </PanelModalModel>
                            )
                            break;
                    }
                })()}

        </WrapperModalSystem>
    )

}

export default ModalSystem
