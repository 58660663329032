
import Main_settings from "./Main_settings"

export const buttonSettings= {
    bg:Main_settings.color03,
    bglow:Main_settings.color10,
    bg_selected:Main_settings.color02,
    color_active: Main_settings.color01,
    color_inactive: Main_settings.color05,
    color_selected: Main_settings.color04,
    border_radius: Main_settings.border_radius01,
    font_normal: Main_settings.font02,
    font_inactive: Main_settings.font01
}
export const ButtonRoundIconSettings= {
    bg_inactive: Main_settings.color03,
    bg_active: Main_settings.color02,
    fill_inactive: Main_settings.color05,
    fill_active: Main_settings.color04,
    border_radius: Main_settings.border_radius02
}
export const RoundIconSettings= {
    border_radius: Main_settings.border_radius02
}

export const FormSettings= {
    border_radius_input: Main_settings.border_radius01,
    border_color_input: Main_settings.color09,
    height_input_text: Main_settings.height01,
    height_input_textarea: Main_settings.height02,
    height_input_select: Main_settings.height01,
    padding_input_text: "0 20px",
    padding_input_select: "5px 10px 5px 0",
    font_size_input_text: Main_settings.font_size02,
    font_size_option_select: Main_settings.font_size02,
    font_size_label_select: Main_settings.font_size01,
    color_label_select:Main_settings.color02
}

export const LinkSettings= {
    color_main: Main_settings.color02,
    color_danger:Main_settings.color07,
    text_decoration_main: "none",
    text_decoration_hover_main: "underline"
}

export const ModalSettings= {
    bg_wrapper_modal_access:Main_settings.colorRgba01,
    bg_panel_modal_access:Main_settings.color02,
    bg_panel_modal_model:Main_settings.color04,
    border_radius_panel_modal_access: Main_settings.border_radius01,
    box_shadow_panel_modal_access: Main_settings.boxshadow02,
    modal_access_main_title_color: Main_settings.color04,
    modal_access_main_title_fontfamily: Main_settings.font01,
    modal_access_link_color: Main_settings.color04,
    modal_access_link_fontfamily: Main_settings.font02,
    modal_access_text_color: Main_settings.color04,
}

export const TextSettings= {
}


export const SvgLogoSettings= {
    bg:  Main_settings.color02,
    fill:  Main_settings.color01
}