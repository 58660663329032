import styled from 'styled-components';
import Main_Settings from "../styles/main_settings/Main_settings"
import Main_settings from "./main_settings/Main_settings";
export const MainWrapper = styled.div`

    display:flex;
    flex-flow:column;
    min-height:100vh;
    align-items:stretch;
    

`;

export const AppConsoleWrapper = styled.div`

    display:flex;
    width:100%;
    flex:1;

`;

export const MenubarAppConsole = styled.div`

    width:220px;
    padding:10px;
    background:${Main_settings.color04};
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-left:0;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

`;
export const BodyAppConsole = styled.div`

flex:1;

`;
export const SectMenuBar = styled.div`
    padding:20px 0;
    text-align:center;   
    > p {
        font-size:1.5em;
        font-weight:500;
        margin-bottom:15px;
    }

`;
export const SectDevMenuBar = styled(SectMenuBar)`
   

`;

export const SectBusMenuBar = styled(SectMenuBar)`


`;

export const ListSectionMenuBar = styled.ul`


`;













