import React, { Component, useState, useRef, useEffect } from "react";
import ReactHtmlParser, {convertNodeToElement, processNodes, transform} from "react-html-parser";
import cio from "cheerio-without-node-native";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function AromaSlider(props) {
    const properties = props
    const node = properties.node
    const code = properties.code
    const $ = cio.load(code);

    $('[data-mama=slide]').each(function( index ) {
        const slidestructure = "<div data-mama='slide'><div>" + $( this ).html() + "</div></div>"
        $( this ).replaceWith(slidestructure);
    });

    let html = $('[data-mama=slider]').html()
    let numberslide = $('[data-mama=slide]').length

    const [activeIndex, setActiveIndex] = useState(0)
    const [moveTranslate, setMoveTranslate] = useState(0)
    const [slideToShow, setSlideToShow] = useState(2)
    const [slideIndex, setSlideIndex] = useState(null)
    const [widthSlide, setWidthSlide] = useState(null)

    const [originTranslate, setOriginTranslate] = useState(false)





    useEffect(() => {


        setFitSlider()


    },[])

    useEffect(() => {
        setSizeTranslate()
    },[widthSlide])

    useEffect(() => {
        setFitSlider()
    },[slideToShow])

    useEffect(() => {
        setSizeTranslate()
    },[activeIndex])

    function setFitSlider() {
        let widthitemslide= slider.current.offsetWidth/ slideToShow
        let sizeslideindex

        if (slideToShow === numberslide) {
            sizeslideindex = 0
        }
        else {
            let sectionslide = numberslide/slideToShow
            sizeslideindex= Math.ceil(sectionslide)
        }
        setSlideIndex(sizeslideindex)
        setWidthSlide(widthitemslide)
    }

    function setSizeTranslate() {
        let sizeTranslate = widthSlide * slideToShow
        let totalsize = (widthSlide * numberslide) - sizeTranslate
        let move
        if (originTranslate) {
            move = totalsize + sizeTranslate
            setMoveTranslate(move)
        }
        else {
            move = activeIndex * sizeTranslate
            if (activeIndex + 1 === slideIndex) {
                move = totalsize
            }
            setMoveTranslate(move)
        }
    }

    const eventDirSlider = (e, dir) => {
        if (e) e.preventDefault()
        let sizeTranslate = widthSlide * slideToShow
        if (dir === "next") {
            updateIndex(activeIndex + 1)
        }
        else {
            updateIndex(activeIndex - 1)
        }
    };

    const updateIndex = (newindex) => {
        if (newindex < 0) {
            newindex= 0
        }
        else if (newindex >= slideIndex){
            newindex = newindex -1
        }
        setActiveIndex(newindex)
    }

    const slider = useRef(null)
    return (

        <React.Fragment>
            {(() => {
                return(
                    <>
                        <div ref={slider} className="aromaslider_wrapper">
                            <div style={{overflow:"hidden"}} className="aromaslider_mask">
                                <div className="aromaslider_inner" style={{transition:"800ms", transform:`translateX(-${moveTranslate}px)`}}>
                                    {
                                        ReactHtmlParser(html, {
                                            transform(node, index) {
                                                let attribs = node.attribs
                                                if (attribs && attribs["data-mama"]) {
                                                    if (attribs["data-mama"] === "slide") {
                                                        let widthsl= "width:" + widthSlide + "px"
                                                        node.attribs.style = widthsl;
                                                        node.attribs.class = "aromaslider_slide"

                                                    }
                                                    if (attribs["data-mama"] === "button") {
                                                        node.attribs.class = "aromaslider_button"

                                                    }
                                                }
                                            }
                                        })
                                    }
                                </div>
                                <div className="aromaslider_controls">
                                    <div className={`${activeIndex === 0 ? "aromaslider_btnslide_dir aromaslider_btnslide_dirprev" : "aromaslider_btnslide_dir aromaslider_btnslide_dir_active aromaslider_btnslide_dirprev "}`}
                                         onClick={activeIndex === 0 ? null : (event) => {eventDirSlider(event, "prev")} }>
                                        Prev
                                        <FontAwesomeIcon icon={["fas", "chevron-left"]}/>
                                    </div>
                                    <div className={`${activeIndex + 1 === slideIndex  ? "aromaslider_btnslide_dir aromaslider_btnslide_dirnext" : "aromaslider_btnslide_dir aromaslider_btnslide_dir_active aromaslider_btnslide_dirnext "}`}
                                         onClick={activeIndex + 1 === slideIndex ? null : (event) => {eventDirSlider(event, "next")}}>
                                        Next
                                        <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })()}
        </React.Fragment>

    )

}