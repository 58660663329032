import React, {useEffect, useState} from "react";
import {setUserLogOut} from "../../hooks/UserProvider";
import ModalAccess from "../User/ModalAccess";
import ReactDOM from 'react-dom';
import {
    PanelUserLogged, PanelUserProfile, PanelUserProfileOpt, UserHeadProfImage, ItemPanelUserProfileOpt
} from "../../styles/Commons";

import ImageUserApp from "../../utils/ImageUserApp";


function UserAreaLink(props) {
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [showUserProfileOpt, setShowUserProfileOpt] = useState(false)
    async function callLogOut(e) {
        e.preventDefault();
        try {
            let setlogout = await setUserLogOut()
            if(setlogout.success) {
                props.setClearToken(true)
                window.location.href = '/';
            }

        }catch (e) {
            console.log(e)
        }
    }

    const setUserProfileOpt = async (e) => {
        //console.log("setUserProfileOpt")
        if (showUserProfileOpt) {
            setShowUserProfileOpt(false)
        }
        else {
            setShowUserProfileOpt(true)
        }
    }

    function setModal(e, content) {
        e.preventDefault();
        setModalContent(content);
        (!showModal) ? setShowModal(true) : setShowModal(false)
    }
    return (
        <>

            {(() => {
                if (props.user && props.user.registration.active) {
                    return (
                        <PanelUserLogged>
                            <PanelUserProfile>
                                <UserHeadProfImage onClick={setUserProfileOpt}>
                                    <ImageUserApp user={props.user}/>
                                    <span>{props.user.firstname}</span>
                                </UserHeadProfImage>
                                {(showUserProfileOpt) &&
                                <PanelUserProfileOpt>
                                    <ItemPanelUserProfileOpt onClick={callLogOut}>Esci</ItemPanelUserProfileOpt>
                                </PanelUserProfileOpt>
                                }
                            </PanelUserProfile>
                        </PanelUserLogged>
                    )
                }
            })()}
            {(showModal) &&
                <ModalAccess setShowModal={setShowModal} content={modalContent} setToken={props.setToken}  />
            }

        </>
    )
}

export default UserAreaLink