import React from 'react';

const SvgMail01= props => (
    <svg
        viewBox="0 0 400 400"
    >
        <polygon fill={props.color} id="XMLID_869_" points="0,127.5 0,274.219 104.8,206.1 	"/>
        <polygon fill={props.color} id="XMLID_870_" points="400,274.219 400,127.5 295.2,206.1 	"/>
        <polygon fill={props.color} id="XMLID_871_" points="200,277.5 130.357,225.268 0,310 0,340 400,340 400,310 269.643,225.268 	"/>
        <polygon fill={props.color} id="XMLID_872_" points="0,90 200,240 400,90 400,60 0,60 	"/>
    </svg>
);

export default SvgMail01;