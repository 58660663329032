import React, {useState, useEffect, useRef} from "react";
import {callStandbyApp, callStandbyAppWA, callStandbyAppMS} from "../../../../hooks/AppProvider";
import {
    BusinessOptionFilterWrapper,
    BusinessFilterLanguages,
    BusinessListLanguages,
    BusinessTxtLanguages,
    BusinessFilterAutomaticReply,
    BusinessItemLanguages,
    BusinessItemLanguagesCurrent,
    BusinessFilterPlatform,
    BusinessTxtPlatform,
    BusinessListPlatforms,
    BusinessItemPlatform,
    BusinessExportConversation
} from "../../../../styles/businessConversation"
import Main_Settings from "../../../../styles/main_settings/Main_settings"
import {
    Button01,
} from "../../../../styles/Commons"

import Switch from "react-switch";
import index from "react-html-parser/lib/elementTypes";
import Language01 from "../../../../source/svg/Language01";
import SvgChat01 from "../../../../source/svg/SvgChat01"
import setting from "../../../../settings/setting";
import branch from "../../../../settings/branch";



function BusOptionFilter(props) {
    const [switchControl, setSwitchControl] = useState(false)

    const [listPlatform, setListPlatform] = useState([])
    const languages = props.listLang
    const standbyapp = props.optStandbyApp
    const standbyappWA = props.optStandbyAppWA
    const standbyappMS = props.optStandbyAppMS
    const filterwrapper= useRef(null)

    const changelanguages = (lang) => {
        props.setOptLangConversation(lang)
    }

    const changePlatform = (platform) => {
        let path
        //props.setPlatformTarget(platform)
        if (platform === "Botmama") {
            path = setting[branch.name].console_url + "/app/" + props.app.name + "/business/conversations"
        }
        if (platform === "WhatsApp") {
            path = setting[branch.name].console_url + "/app/" + props.app.name + "/business/conversations?whatsapp"
        }
        if (platform === "Messenger") {
            path = setting[branch.name].console_url + "/app/" + props.app.name + "/business/conversations?messenger"
        }
        window.open(path, "_self");
    }


    const changestandby = async  () => {

        let setstate
        if (standbyapp) setstate="false"
        else setstate= "true"

        let appsetstate= await callStandbyApp({app_name:props.app.name, standby:setstate})

        if (!appsetstate.error) {
            let statechanged = appsetstate.app.state.standby.app
            if (statechanged === "true") props.setOptStandbyApp(true)
            else props.setOptStandbyApp(false)

            props.setApp(appsetstate.app)
        }
        else {
            props.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }
    }


    const changestandbyWA = async  () => {
        let setstateWA
        if (standbyappWA) {
            setstateWA="false"
        }
        else setstateWA= "true"

        let appsetstateWA= await callStandbyAppWA({app_name:props.app.name, standby:setstateWA})

        if (!appsetstateWA.error) {
            let statechangedWA = appsetstateWA.app.external.platforms.whatsApp.state.standby.app

            if (statechangedWA === "true") {
                props.setOptStandbyAppWA(true)
            }
            else {
                props.setOptStandbyAppWA(false)
            }
            props.setApp(appsetstateWA.app)
        }
        else {
            props.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }
        //if (switchControl) props.setOptStandbyApp(true)
        //else  props.setOptStandbyApp(false)
    }


    const changestandbyMS = async  () => {
        let setstateMS
        if (standbyappMS) {
            setstateMS="false"
        }
        else setstateMS= "true"

        let appsetstateMS= await callStandbyAppMS({app_name:props.app.name, standby:setstateMS})

        if (!appsetstateMS.error) {
            let statechangedMS = appsetstateMS.app.external.platforms.messenger.state.standby.app

            if (statechangedMS === "true") {
                props.setOptStandbyAppMS(true)
            }
            else {
                props.setOptStandbyAppMS(false)
            }
            props.setApp(appsetstateMS.app)
        }
        else {
            props.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }
        //if (switchControl) props.setOptStandbyApp(true)
        //else  props.setOptStandbyApp(false)
    }

    useEffect(() => {
        actionSwitch()
    }, [standbyapp]);

    useEffect(() => {
        actionSwitch()
    }, [standbyappWA]);

    useEffect(() => {
        actionSwitch()
    }, [standbyappMS]);


    useEffect(() => {
        let arrPlatform = ["Botmama"]
        if (props.app.external && props.app.external.platforms) {

            if (props.app.external.platforms.whatsApp && props.app.external.platforms.whatsApp.active === "true") {
                arrPlatform.push("WhatsApp")
            }
            if (props.app.external.platforms.telegram && props.app.external.platforms.telegram.active === "true") {
                arrPlatform.push("Telegram")
            }
            if (props.app.external.platforms.messenger && props.app.external.platforms.messenger.active === "true") {
                arrPlatform.push("Messenger")
            }
        }
        setListPlatform(prevArray => arrPlatform)
        actionSwitch()
    }, [props.platformTarget]);

    const actionSwitch = () => {
        if (props.platformTarget === "Botmama") {
            if (standbyapp) {
                setSwitchControl(false)
            }
            else {
                setSwitchControl(true)
            }
        }
        if (props.platformTarget === "WhatsApp") {

            if (standbyappWA) {
                setSwitchControl(false)
            }
            else {
                setSwitchControl(true)
            }
        }

        if (props.platformTarget === "Messenger") {

            if (standbyappMS) {
                setSwitchControl(false)
            }
            else {
                setSwitchControl(true)
            }
        }

    }


    return (

        <BusinessOptionFilterWrapper ref={filterwrapper}>

            {(() => {
                if (listPlatform.length) {
                    return (
                        <>
                            <BusinessFilterPlatform>
                                <BusinessTxtPlatform>
                                    <SvgChat01 color="#000" />
                                </BusinessTxtPlatform>
                                <BusinessListPlatforms>
                                    {(() => {
                                        return listPlatform.map((child, i) => {
                                            if (props.platformTarget === child) {
                                                return (
                                                    <BusinessItemLanguagesCurrent key={i}>{child}</BusinessItemLanguagesCurrent>
                                                )
                                            }
                                            else {
                                                return (
                                                    <BusinessItemLanguages key={i}><button onClick={e => changePlatform(child)}>{child}</button></BusinessItemLanguages>
                                                )
                                            }
                                        })
                                    })()}
                                </BusinessListPlatforms>
                            </BusinessFilterPlatform>
                        </>
                    )
                }
            })()}

            {(() => {
                if (props.listConversations.length) {
                    return(
                        <>
                            {(() => {
                                if (languages.length && languages[0]) {
                                    return (
                                        <>
                                            <BusinessFilterLanguages>
                                                <BusinessTxtLanguages>
                                                    <Language01 color="#000" />

                                                </BusinessTxtLanguages>
                                                <BusinessListLanguages>
                                                    {(() => {
                                                        return languages.map((child, i) => {
                                                            let language
                                                            switch (child) {
                                                                case "en":
                                                                    language= "english"
                                                                    break;
                                                                case "it":
                                                                    language= "italiano"
                                                                    break;
                                                                default:
                                                                    language = child
                                                            }
                                                            if (props.optLangConversation === child) {
                                                                return (
                                                                    <BusinessItemLanguagesCurrent key={i}>{language}</BusinessItemLanguagesCurrent>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <BusinessItemLanguages key={i}><button onClick={e => changelanguages(child)}>{language}</button></BusinessItemLanguages>
                                                                )
                                                            }

                                                        })
                                                    })()}
                                                </BusinessListLanguages>
                                            </BusinessFilterLanguages>
                                        </>
                                    )
                                }
                            })()}
                            <BusinessFilterAutomaticReply>
                                <p>
                                    Risposta bot
                                </p>
                                {(() => {
                                    if (props.platformTarget === "Botmama") {
                                        return (
                                            <Switch
                                                checked={switchControl}
                                                onChange={changestandby}
                                                onColor={Main_Settings.color03}
                                                onHandleColor={Main_Settings.color02}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                height={20}
                                                width={48}
                                                id="material-switch"
                                            />
                                        )
                                    }
                                    else if (props.platformTarget === "Messenger") {
                                        return (
                                            <Switch
                                                checked={switchControl}
                                                onChange={changestandbyMS}
                                                onColor={Main_Settings.color03}
                                                onHandleColor={Main_Settings.color02}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                height={20}
                                                width={48}
                                                id="material-switch"
                                            />
                                        )
                                    }
                                    else {
                                        return (
                                            <Switch
                                                checked={switchControl}
                                                onChange={changestandbyWA}
                                                onColor={Main_Settings.color03}
                                                onHandleColor={Main_Settings.color02}
                                                handleDiameter={30}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                height={20}
                                                width={48}
                                                id="material-switch"
                                            />
                                        )
                                    }

                                })()}
                            </BusinessFilterAutomaticReply>
                        </>
                    )
                }
            })()}


        </BusinessOptionFilterWrapper>
    )
}


export default BusOptionFilter;
