import styled from 'styled-components';

import {buttonSettings, ButtonRoundIconSettings, RoundIconSettings} from "./main_settings/Elements_settings";
import Main_settings from "../styles/main_settings/Main_settings";


export const WrapperHome = styled.div`

    padding:100px;
    max-width:1200px;
    margin:auto;
`;

export const BoxCreateAppHome = styled.div`

`;
export const WrapperNoRegHome = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-flow:column;
    background:${Main_settings.color02};
    padding:30px;
    max-width:600px;
    margin:auto;
    border-radius:40px;
    p {
        font-size:1.2em;
        color:#fff;
        text-align:center;
        margin-bottom:20px;
    }
`;

export const WrapperListAppHome = styled.div`

    margin-top:20px;
    border-radius:40px;
    padding:40px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;
export const ListAppHome = styled.ul`

   list-style:none;
   display:flex;
   width:100%;
   justify-content:center;
   flex-wrap:wrap;
`;
export const ItemAppHome = styled.li`

   border:solid 1px #d4d4d4;
   border-radius:40px;
   padding:30px;
   display:flex;
   align-items:center;
   margin-right:15px;
   max-width:300px;
   margin-bottom:20px;
   cursor:pointer;
   :hover {
        opacity:.5;
   }
   
`;
export const ImgItemAppHome = styled.div`
    margin-right:20px;

    img {
        width:60px;
    }
   
`;
export const DataItemAppHome = styled.div`
    h2 {
        font-size:1.2em;
    }
   
`;
export const TextListAppHome = styled.p`
   text-align:center;
   padding-bottom:20px;
   font-family:"Roboto-Bold";
`;









