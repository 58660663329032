import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import ImageUserChat from "./ImageUserChat";
import ImageApp from "./ImageApp";
import moment from "moment";
import Slider from "../tempcomponents/sliders/Slider";
import MultipleItemsMS from "../tempcomponents/sliders/slick/MultiplesliderMS";
import {
    RowChatMessageApp,
    ChatMessagePictureApp,
    ChatMessageWrapperAppMS,
    ChatMessageWrapperAppMSSLider,
    ChatMessageWrapperAppMSQuickReply,
    ChatMessageWrapQuickReply,
    ChatMessageWrapperAppMSTButton,
    ChatMessageWrapButtons,
    ChatMessageWrapperAppMSImage,
    ChatMessageWrapperAppMSFile,
    ChatMessageWrapperAppMSFileInfo,
    ChatMessageWrapperAppMSFileIcon,
    ChatMessageWrapperAppMSFileText
} from "../styles/businessConversation";
import {Button012} from "../styles/Commons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ParserTemplComponentsAppMS(props) {
    const properties = props
    const message=  properties.message

    const focusmessage = properties.code
    let hourdate = moment(message.date.date).format('HH: mm');

    return (
        <RowChatMessageApp>
            <ChatMessagePictureApp title={properties.app.name}>
                {(() => {
                    if (message.actor) {
                        return (
                            <ImageApp
                                app={properties.app}
                                actor={message.actor}
                            />
                        )
                    }
                    else {
                        return (
                            <ImageApp
                                app={properties.app}
                            />
                        )
                    }

                })()}
            </ChatMessagePictureApp>

            {(() => {
                //console.log(focusmessage)
                if (focusmessage.body) {


                    if (focusmessage.body.attachment) {
                        if (focusmessage.body.attachment.payload) {
                            let attachtype= focusmessage.body.attachment.type
                            if (attachtype === "template") {
                                let temptype = focusmessage.body.attachment.payload.template_type
                                if (temptype === "generic") {
                                    let elements = focusmessage.body.attachment.payload.elements
                                    let slidetoshow = 2
                                    if (elements.length === 1) slidetoshow = 1
                                    return (
                                        <ChatMessageWrapperAppMSSLider title={hourdate}>
                                            <MultipleItemsMS
                                                slidetoshow={slidetoshow}
                                                elements={elements}
                                            />
                                        </ChatMessageWrapperAppMSSLider>
                                    )
                                }
                                if (temptype === "button") {
                                    let buttons = focusmessage.body.attachment.payload.buttons
                                    return (
                                        <ChatMessageWrapperAppMSTButton title={hourdate}>
                                            <p>{focusmessage.body.attachment.payload.text}</p>
                                            <ChatMessageWrapButtons>
                                                {(() => {
                                                    return buttons.map((button, a) => {
                                                        return (
                                                            <li key={a}><Button012 key={a}>{button.title}</Button012></li>
                                                        )
                                                    })
                                                })()}
                                            </ChatMessageWrapButtons>
                                        </ChatMessageWrapperAppMSTButton>

                                    )

                                }
                            }
                            if (attachtype === "image") {
                                return (
                                    <ChatMessageWrapperAppMSImage title={hourdate}>
                                        <img alt="" src={focusmessage.body.attachment.payload.url} />
                                    </ChatMessageWrapperAppMSImage>
                                )
                            }
                            if (attachtype === "file") {
                                return (
                                    <ChatMessageWrapperAppMSFile title={focusmessage.body.attachment.payload.url} className="user_message_file app_message">
                                        <ChatMessageWrapperAppMSFileInfo>
                                            <ChatMessageWrapperAppMSFileIcon>
                                                <a href={focusmessage.body.attachment.payload.url} ><FontAwesomeIcon icon={["fas", "file-download"]}/></a>
                                            </ChatMessageWrapperAppMSFileIcon>
                                            <ChatMessageWrapperAppMSFileText>File</ChatMessageWrapperAppMSFileText>
                                        </ChatMessageWrapperAppMSFileInfo>
                                    </ChatMessageWrapperAppMSFile>
                                )
                            }
                        }
                    }
                    else if (focusmessage.body.text && focusmessage.body.quick_replies) {
                        let quickreplies = focusmessage.body.quick_replies
                        return (
                            <ChatMessageWrapperAppMSQuickReply title={hourdate}>
                                <p>{focusmessage.body.text}</p>
                                <ChatMessageWrapQuickReply>
                                    {(() => {
                                        return quickreplies.map((button, a) => {
                                            return (
                                                <li key={a}><Button012 key={a}>{(button.image_url) ? <img alt="" src={button.image_url} /> : null }{button.title}</Button012></li>
                                            )
                                        })
                                    })()}
                                </ChatMessageWrapQuickReply>
                            </ChatMessageWrapperAppMSQuickReply>
                        )
                    }
                    else if (focusmessage.body.text) {
                        return (
                            <ChatMessageWrapperAppMS title={hourdate}>
                                <p>{focusmessage.body.text}</p>
                            </ChatMessageWrapperAppMS>
                        )
                    }

                }
            })()}
        </RowChatMessageApp>
    )
}

export default ParserTemplComponentsAppMS