import React, {useEffect, useState} from "react";
import {getListModels, deleteModel} from "../../hooks/AppProvider";
import ParserTemplComponentsModels from "../../utils/ParserTemplComponentsModels";
import {
    WrapperChooseModel,
    BoxListChooseModel,
    ChooseModelTarget,
    ButtonDeleteModel, ItemResponseModal, WrapButtonsModal

} from "../../styles/Modals";
import Add01 from "../../source/svg/Add01";
import {ButtonRoundIcon, Button01} from "../../styles/Commons";
import Delete01 from "../../source/svg/Delete01";

function ModalChooseModel(props) {
    const properties = props
    const app = properties.dataModal.data.app
    const [models, setModels] = useState(false)
    const [modelTarget, setModelTarget] = useState(false)
    const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false)
    const [showResponse, setShowResponse] = useState(false)
    const [responseMessage, setResponseMessage] = useState(false);
    const [eventTarget, setEventTarget] = useState(false)
    useEffect(() => {
        getModels()
    }, []);



    useEffect(() => {
        if (showResponse) {
            setTimeout(function(){
                setShowResponse(false)
                if (responseMessage) setResponseMessage(false)

            }, 2000);
        }
    }, [showResponse]);


    const createModel = async (e) => {

        properties.setModalSystem({show:true, source:"modal-choose-modal", action:"create-model-conversation", data:{app:app}})
    }


    const confirmDeleteModel = async (e) => {
        let message
        let datasend = {
            id_app: app._id,
            name:modelTarget,
            user:properties.user
        }
        const calldeletemodel = await deleteModel(datasend)

        if (calldeletemodel.error) {
            message = "Si è verificato un errore. Riprova più tardi"
            setShowResponse(true)
            setResponseMessage(message)
        }
        else {
            message = "Il modello " + modelTarget + " è stato eliminato"
            setShowResponse(true)
            setResponseMessage(message)
            setShowConfirmDeleteModel(false)
        }
        getModels("delete")

    }
    const switchDeleteModel = async (e) => {
        if (showConfirmDeleteModel) {
            setShowConfirmDeleteModel(false)
        }
        else {
            setShowConfirmDeleteModel(true)
        }
    }
    const onChangeChooseModel = async (e) => {
        setModelTarget(e.target.value)
    }


    const getModels = async (isdelete) =>  {
        let listmodels = await getListModels(app._id)

        if (!listmodels.error) {
            if (listmodels.models.length) {
                setModels(listmodels.models)
                if (properties.dataModal.data.model && !isdelete) {
                    setModelTarget(properties.dataModal.data.model)
                }
                else {
                    setModelTarget(listmodels.models[0].name)
                }
            }
            else {
                setModels([])
            }

        }

    }
    return (
       <div>
           {(() => {
               if (models) {
                   if (modelTarget && models.length) {
                       return (
                           <>
                               <WrapperChooseModel>
                                   <h1>Scegli il modello da inviare</h1>
                                   <BoxListChooseModel>
                                       <select defaultValue={modelTarget} onChange={onChangeChooseModel}>
                                           {(() => {
                                               return models.map((child, i) => {
                                                   return (
                                                       <option
                                                           key={i}
                                                           value={child.name} >
                                                           {child.name}
                                                       </option>
                                                   )
                                               })
                                           })()}
                                       </select>
                                       <ButtonRoundIcon title="Crea nuovo" onClick={createModel}><Add01 /></ButtonRoundIcon>
                                   </BoxListChooseModel>

                                   {(() => {
                                        if (modelTarget) {
                                            return models.map((child, i) => {
                                                if (child.name === modelTarget) {
                                                    if (showResponse) {
                                                        return (
                                                            <div key={i}>
                                                                <ChooseModelTarget>
                                                                    <ItemResponseModal>{responseMessage}</ItemResponseModal>
                                                                </ChooseModelTarget>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        if (showConfirmDeleteModel) {
                                                            return (
                                                                <div key={i}>
                                                                    <ChooseModelTarget>
                                                                        <ItemResponseModal>Sei sicuro di voler eliminare il modello "{child.name}" ?</ItemResponseModal>
                                                                        <WrapButtonsModal>
                                                                            <Button01 onClick={switchDeleteModel}>Annulla</Button01>
                                                                            <Button01 onClick={confirmDeleteModel}>Elimina</Button01>
                                                                        </WrapButtonsModal>
                                                                    </ChooseModelTarget>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div key={i}>
                                                                    <ChooseModelTarget>
                                                                        <ButtonDeleteModel title="Elimina questo modello" onClick={switchDeleteModel}><Delete01 /></ButtonDeleteModel>
                                                                        <h2>{child.name}</h2>
                                                                        <ParserTemplComponentsModels
                                                                            nameModel={child.name}
                                                                            code={child.model_data}
                                                                            setResultModalSystem={properties.setResultModalSystem}
                                                                            setModalSystem={properties.setModalSystem}
                                                                        />
                                                                    </ChooseModelTarget>
                                                                </div>
                                                            )
                                                        }
                                                    }


                                                }
                                            })

                                        }
                                   })()}
                               </WrapperChooseModel>

                           </>
                       )
                   }
                   else {
                       return (
                           <>
                               <ChooseModelTarget>
                                    <ItemResponseModal>Non ci sono modelli creati</ItemResponseModal>
                                       <WrapButtonsModal>
                                            <Button01 onClick={createModel}>Crea un modello</Button01>
                                       </WrapButtonsModal>
                               </ChooseModelTarget>
                           </>
                       )
                   }

               }

           })()}
       </div>

    )
}

export default ModalChooseModel