import styled from 'styled-components';
import Main_Settings from "../styles/main_settings/Main_settings"
import {buttonSettings, FormSettings, ModalSettings, ButtonRoundIconSettings, RoundIconSettings, LinkSettings} from "./main_settings/Elements_settings";
import {ButtonRoundIcon, Button01, Button013} from "./Commons";
import {WrapLoadMessageBar} from "./businessConversation";

export const WrapperModalSystem = styled.div`
  width:100%;
  height:100%;
  position:absolute;
  z-index:9999999;
  left:0;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${ModalSettings.bg_wrapper_modal_access};
`;

export const PanelModalModel = styled.div`
  background:${ModalSettings.bg_panel_modal_model};
  padding:40px;
  width:800px;
  min-height:400px;
  border-radius:  ${ModalSettings.border_radius_panel_modal_access};
  position:relative;
  box-shadow:${ModalSettings.box_shadow_panel_modal_access};
    ${ButtonRoundIcon} {
        width:30px;
        height:30px;
        background:none;
        margin-right:-20px;
        :hover {
            background:rgba(0,0,0, .2)
        }
        svg {
            fill:#fff;
        }
  }
  @media (min-width: 0) and (max-width: 600px) {
        width:450px;
        min-width:100%;
        min-height:100%;
        border-radius:0;
        display:flex;
        flex-flow:column;
        padding:40px 20px;
  }

`;

export const PanelModalModelLarge = styled(PanelModalModel)`
    width:1200px;
`;
export const PanelModalModelExport = styled(PanelModalModelLarge)`
    padding:20px 40px;
`;
export const WrapCloseModalAccess = styled.div`
  display:flex;
  width:100%;
  justify-content:flex-end;
  margin-top:-30px;
    @media (min-width: 0) and (max-width: 600px) {
      padding:0 20px;
  }
`;

export const BodyModalAccess = styled.div`
  max-width:400px;
  margin:auto;
  @media (min-width: 0) and (max-width: 600px) {
    width:100%;
    max-width:100%;
  }
`;

export const TitleModalAccess = styled.h1`
    color:${ModalSettings.modal_access_main_title_color};
    text-align:center;
    padding-bottom:40px;
    font-family:${ModalSettings.modal_access_main_title_fontfamily};
      @media (min-width: 0) and (max-width: 600px) {
          font-size:1.8em;
      }
`;
export const PanelDataModalAccess = styled.div`


`;
export const WrapButtonsModalAccess = styled.div`
    display:flex;
    justify-content:center;
`;


export const WrapperChooseModel = styled.div`
    h1 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
`;
export const WrapperCreateModel = styled.div`
    h1 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
`;
export const ModalErrorContent = styled.div`
    h1 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
    p {
        text-align:center; 
        font-size:1.1em;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:20px;
        text-align:center;
    }
`;

export const WrapperCreateTermsAddInfoUser = styled.div`
    h1 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
`;



export const BoxDetailsTermsAddInfoUser = styled.div`
    padding:10px;
`;
export const WrapperAddDataInfoUser = styled.div`
    h1 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
`;
export const FormAddDataInfoUser = styled.form`
   
`;
export const ItemAddDataInfoUser = styled.p`
    padding-bottom:5px;
   label {
        display:block;
        margin-bottom:5px;
        font-family:"Roboto-Medium"
   }
   .itI4324ac {
         width:100%;
         border:solid 1px ${FormSettings.border_color_input};
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
         
   }
   .itI4324at {
        min-height:${FormSettings.height_input_textarea};
        padding:20px;
   }
`;

export const ItemButtonDataInfoUser = styled.p`
   padding-bottom:5px;
   display:flex;
   align-items:center;
   justify-content:center;
`;





export const BoxListChooseModel = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    label {
        padding-right:10px;
    }
   select {
        display:block;
        background:none;
        border:0;
        width:50%;
        min-height:48px;
        border:solid 1px ${Main_Settings.color06};
        border-radius:${FormSettings.border_radius_input};
        text-align:center;
        cursor:pointer;
        font-size:${FormSettings.font_size_option_select};
        margin-right:10px;
     }
    ${ButtonRoundIcon} {
        min-height:40px;
        width:40px;
        background:${Main_Settings.color03};
        
        :hover {
            background:${Main_Settings.color02};
        }
        
        svg {
            fill:${Main_Settings.color05}
        }
       :hover svg {
            fill:${Main_Settings.color04}
        }
    }
`;
export const ButtonCloseModal = styled.button`
     position:absolute;
     top:20px;
     right:30px;
     background:none;
     border:none;
     cursor:pointer;
     svg {
        height:25px;
        fill:${Main_Settings.color05}
     }   
       :hover svg {
        fill:${Main_Settings.color02}
     } 
`;




export const ChooseModelTarget = styled.div`
    background:${Main_Settings.color02};
    margin:20px 0;
    padding:20px 40px;
    border-radius:  ${ModalSettings.border_radius_panel_modal_access};
    position:relative;
    h2 {
        font-family:"Roboto-Light";
        color:${Main_Settings.color04};
        font-size:1.3em;
        text-align:center;
        padding-bottom:30px;
    }
    textarea {
        width:100%;
        border-radius:  ${ModalSettings.border_radius_panel_modal_access};
        min-height:100px;
        padding:20px;
    }
    

`;

export const ChooseModelPlatform = styled.div`
    background:${Main_Settings.color02};
    padding:20px 40px;
    margin:20px 0;
    border-radius:  ${ModalSettings.border_radius_panel_modal_access};
    position:relative;
    padding-bottom:20px;
   h2 {
        font-family:"Roboto-Light";
        color:${Main_Settings.color04};
        font-size:1.3em;
        text-align:center;
    }
   
`;



export const InfoModelPLatform = styled.div`
    margin-bottom:20px;
    p {
        text-align:left;
        color:#fff;
        padding:10px 0;
    }
   
`;
export const BoxElementModelPlatform = styled.div`

`;
export const BoxSlideModelPlatform = styled.div`
    h3 {
        color:#fff;
        text-align:center;
    }
`;


export const BoxItemTextPlatform = styled.div`
    display:flex;
    flex-flow:column;
        textarea {
        width:100%;
        border-radius:  ${ModalSettings.border_radius_panel_modal_access};
        min-height:100px;
        padding:20px;
    }
    label {
        color:#fff;
        padding-bottom:10px;
        padding-left:20px;
        width:100%;
        font-family:"Roboto-Bold"
    }
`;


export const BoxHeaderButton = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;
export const BoxHeaderSlide = styled(BoxHeaderButton)`
    margin-top:20px;
`;

export const BoxNumberButton = styled.div`
    border-radius:100%;
    background:${Main_Settings.color03};
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display:flex;
    align-items:center;
    justify-content:center;
`;
export const BoxNumberSlide = styled(BoxNumberButton)`
    background:${Main_Settings.color01};
    color:${Main_Settings.color04}
`;
export const BoxItemButtonsPlatform = styled.div`
    padding:20px 0;    
    h3 {
        color:#fff;
        text-align:center;
        margin-bottom:10px;
    }
`;
export const WrapperButtonPlatform = styled.div`
    display:flex;
    flex-flow:column;
    align-items:center;
    margin-bottom:20px;
    
`;
export const WrapperButtonSlide = styled(WrapperButtonPlatform)`
    margin-top:20px;
    
`;

export const BoxContentTypeButtonPl = styled.div`
    background:${Main_Settings.color03};
    border-radius:40px;
    padding:10px 30px 25px 30px;
    position:relative;
`;


export const BoxSelectTypeButtonPl = styled.div`
    background:${Main_Settings.color03};
    padding:10px 30px;
    border-radius:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:auto;
    width:100%;
    select {
        display:block;
        border:0;
        padding:5px 10px;
        min-height:35px;
        border:solid 1px ${Main_Settings.color06};
        border-radius:${FormSettings.border_radius_input};
        text-align:center;
        cursor:pointer;
        font-size:${FormSettings.font_size_option_select};
    }
`;
export const WrapperSlideModelPlatform = styled.div`
   background:#000;
   border-radius:40px;
   padding:20px;
   padding-bottom:0;
   position:relative;
`;

export const InfoTypeButton = styled.div`
    padding:10px 0;
`;
export const ItemTypeButton = styled.p`
    margin:10px 0;
    
    label {
        display:block;
        margin-bottom:5px;
        padding-left:20px;
         font-family:"Roboto-Medium"
    }
    input {
         border:0;
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
         width:100%;
    }
    
`;
export const BoxSizeWebView = styled.div`
        padding-top:10px;
        p {
            margin-bottom:5px;
            padding-left:20px;
            font-family:"Roboto-Medium"
        }
        select {
            display:block;
            width:100%;
            border:0;
            min-height:35px;
            border:solid 1px ${Main_Settings.color06};
            border-radius:${FormSettings.border_radius_input};
            text-align:center;
            cursor:pointer;
            font-size:${FormSettings.font_size_option_select};
        }
`;
export const ButtonDeleteButton = styled(ButtonRoundIcon)`
    position:absolute;
    top:0;
    right:10px;
    background:${Main_Settings.color03} !important;
    :hover svg {
        opacity:.8;
    }
`;
export const BoxModelTagMessage = styled.div`
    display:flex;
    flex-flow:column;
    position:relative;
    .react-tagsinput {
        margin:10px 0;
         border-radius:${FormSettings.border_radius_input};
    }
    .react-tagsinput-input {
         border-radius:${FormSettings.border_radius_input};
         min-height:35px;
         font-size:${FormSettings.font_size_input_text};
         width:auto;
    }
        .react-tagsinput--focused {
        border-color: #ccc !important;
    }
        .react-tagsinput > span{
         padding:0 20px;
         display:flex;
         flex-wrap:wrap;
    }
    .react-tagsinput-tag {
        display:flex;
        align-items:center;
        background:${Main_Settings.color02};
        padding:10px;
        padding-right:5px;
        border-radius:40px;
        border:0;
        font-size:1em;
        color:${Main_Settings.color04};
    }
    .react-tagsinput-remove {
        cursor: pointer;
        color: #fff;
        font-size: 1.3em;
        margin: 0 3px;
    }
`;
export const BoxModelInfoTagMsg = styled.div`
    display:flex;
    alig-items:center;
    p {
        font-size:.9em;
        margin-left:5px;
    }
`;
export const ButtonModelAddTag = styled.button`
    position:absolute;
    top:40px;
    right:20px;
    z-index:9;
    width:30px;
    height:30px;
    border-radius:100%;
    border:0;
    cursor:pointer;
    background: ${buttonSettings.bg};
    svg {
        height:50%;
    }
`;
export const PanelOverlayModal = styled.div`

        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        border-radius:40px;
        z-index:99;
        display:flex;
        flex-flow:column;
        background:rgba(255,255,255, 1);
        padding:40px;
        
        h2 {
            text-align:center;
            font-size:1.4em;
            padding-bottom:10px;
        }
        ${WrapLoadMessageBar} {
            padding-top:60px;
        }
        
    
`;





export const BoxErrorInlineOverPlatform = styled.div`
    padding-bottom:20px;
    text-align:center;
    p {
        color:${Main_Settings.color07};
        font-weight:bold;
    }
`;
export const BoxItemOverlayPlatform = styled.div`
    background:${Main_Settings.color02};
    padding:20px 40px;
    border-radius:  ${ModalSettings.border_radius_panel_modal_access};
    min-height:100px;
    margin-bottom:20px;
    
    input {
         border:0;
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
         width:100%;
    }
    p {
        color:${Main_Settings.color04};
        text-align:center;
    }
`;

export const BoxResponseOverPlatform = styled.div`
    padding:30px;
`;




export const ButtonDeleteModel = styled(ButtonRoundIcon)`
    position:absolute;
    top:0;
    right:10px;
    background:${Main_Settings.color02} !important;
    :hover svg {
        opacity:.8;
    }
`;
export const MainContentModel = styled.div`
    position:relative;
    
   
   ${ButtonDeleteModel} {
        z-index:200;
        top:10px;
   }
`;
export const CreateModelTarget = styled.div`
    background:${Main_Settings.color02};
    margin:20px 0;
    padding:20px 40px;
    border-radius:  ${ModalSettings.border_radius_panel_modal_access};   


`;
export const ItemDataNoLabel = styled.p`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-bottom:20px;
    input {
         width:75%;
         border:0;
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
    }

`;


export const BoxButtonFormModel = styled.div`
    display:flex;
    padding:20px 0;
    align-items:center;
    justify-content:center;
    

`;

export const ItemResponseModal = styled.p`
    color:#fff;
    font-size:1.2em;
    padding-bottom:30px;
    text-align:center;
`;
export const WrapButtonsModal = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    
    button:nth-child(2) {
        margin-left:20px;
    }
    
`;
export const WrapLinkBackModal = styled.div`
    display:flex;
    justify-content:flex-end;
    
`;

export const LinkBackModal = styled.span`
    color:${LinkSettings.color_main};
    text-decoration:${LinkSettings.text_decoration_main};
    cursor:pointer;
    
    :hover {
        text-decoration:${LinkSettings.text_decoration_hover_main};
    }
`;

export const EditorCreateModel = styled.div`
background:#000;
border-radius:${FormSettings.border_radius_input};
padding:20px;
height:240px;
overflow:auto;
> div {
    min-height:200px;
    background:#fff;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #90a4ae;
}
.token.punctuation {
  color: #9e9e9e;
}
.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #e91e63;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #4caf50;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #795548;
}
.token.atrule,
.token.attr-value,
.token.keyword {
  color: #3f51b5;
}
.token.function {
  color: #f44336;
}
.token.regex,
.token.important,
.token.variable {
  color: #ff9800;
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
`;


export const WrapperExportConversation = styled.div`
    h2 {
        text-align:center;
        font-size:1.5em;
        padding-bottom:20px;
    }
`;
export const PanelFilterExportConversation = styled.div`
    display:flex;
    border:solid 1px #d0d0d0;
    padding:10px;
    border-radius:40px;
    align-items:center;
    justify-content:center;
    margin:auto;
    position:relative;
     ${Button01}, ${Button013} {
        min-width:180px;
        min-height:40px;
     }
          ${Button01}:nth-child(2), ${Button013}:nth-child(2) {
        margin:0 40px;
     }
`;
export const PanelResulExportConversation = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    border:solid 1px #d0d0d0;
    padding:10px;
    border-radius:40px;

    min-height:180px;

`;
export const PanelExportSearch = styled.div`
    position:absolute;
    z-index:2;
    top:45px;
    left:0;
    border-radius:40px;
    padding:30px;
    width:100%;
    min-height:200px;
    background:${Main_Settings.color02};

`;
export const FormExportSearch = styled.div`
    max-width:500px;
    margin:auto;
    
        ${BoxButtonFormModel} {
           button:first-child {
                margin-right:20px;
           }
        }
`;

export const ItemLabelFormExportSearch = styled.div`
    display:flex;
    align-items:center;
    flex-flow:column;
    label {
        display:block;
        color:#fff;
        padding-bottom:10px;
        font-size:1.2em;
       
    }
    .rmdp-input {
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text}; 
         width:100%;
         padding:0 20px;
    }
`;
export const InputForm01 = styled.input`
         width:100%;
         border:solid 1px ${FormSettings.border_color_input};
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
`;
export const BlockFormExportChat = styled.span`
         display:flex;
         .rmdp-container:first-child {
            margin-right:20px;
         }
`;
export const TableResultExpConversation = styled.table`
   border-collapse:collapse;
   min-width:100%;
   margin:0 30px;
    thead {
        background:${Main_Settings.color02};        
    }
    thead td {
        padding:10px;
        text-align:center;
        color:#fff;
        font-weight:bold;
        border-left:solid 1px ${Main_Settings.color04};
    }
    tbody td {
        border:solid 1px ${Main_Settings.color08};
        padding:8px 10px;
    }
    .colDateExport {
        width:230px;
    }
`;

export const PanelInfoResultExpConversation = styled.div`
    margin:20px 0;
    p {
        text-align:center;
    }
`;
export const InfoBubbleResultSearch = styled.span`
    background:#fff;
    margin-left:10px;
    padding:2px 10px;
    border-radius:40px;
    font-size:.9em;
`;
export const PanelButtonActionExpConversation = styled.div`
    margin-top:10px;
            ${BoxButtonFormModel} {
               button {
                padding-left:25px;
               }
               button:nth-child(2) {
                    margin:0 20px;
               }
               button svg {
                    width:20px;
                    margin-right:5px;
                    
               }
        }
`;
export const PreviewResultExpConversation = styled.div`
    svg {
        width:50px;
        fill:${Main_Settings.color08}
    }
`;
export const PanelMessageErrorBtnActionExpConv = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    p {
        font-family:Roboto-Bold;
        color:${Main_Settings.color07}
    }
`;

export const BoxModelButtonFormModel = styled.div`
    display:flex;
    align-items:center;
    flex-flow:column;
    input {
        margin-top:20px;
    }
`;


















