import React from 'react';

const File01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path id="bAreaRounded60" display="none" d="M342.4,512.5H169.6C76,512.5-0.5,436-0.5,342.4V169.6C-0.5,76,76-0.5,169.6-0.5h172.8
	C436-0.5,512.5,76,512.5,169.6v172.8C512.5,436,436,512.5,342.4,512.5z"/>
        <path id="bAreaRounded40" display="none" d="M399.1,512.5H112.9
	c-62.4,0-113.4-51-113.4-113.4V112.9c0-62.4,51-113.4,113.4-113.4h286.2c62.4,0,113.4,51,113.4,113.4v286.2
	C512.5,461.5,461.5,512.5,399.1,512.5z"/>
        <path id="bAreaRounded20" display="none" d="M455.8,512.5H56.2C25,512.5-0.5,487-0.5,455.8V56.2C-0.5,25,25-0.5,56.2-0.5h399.6
	c31.2,0,56.7,25.5,56.7,56.7v399.6C512.5,487,487,512.5,455.8,512.5z"/>
        <path id="bAreaRounded10" display="none" d="M484.7,513H28.3C12.8,513,0,500.2,0,484.7V28.3C0,12.8,12.8,0,28.3,0h456.3
	C500.2,0,513,12.8,513,28.3v456.3C513,500.2,500.2,513,484.7,513z"/>
        <rect id="bAreaSquare" display="none" width="513" height="513"/>
        <circle id="bAreaCircle" display="none" cx="255" cy="257" r="253"/>
        <path id="bArea01" d="M365.7,152.1c-27.5-27.5-72.2-27.5-99.7,0L186.1,232c-8.3,8.3-12.9,19.4-12.9,31.2c0,11.8,4.6,22.8,12.9,31.2
	c8.6,8.6,19.9,12.9,31.2,12.9c11.3,0,22.5-4.3,31.1-12.9l37.4-37.4c6.9-7,10.3-15.9,10.3-24.9c0-9-3.4-18-10.3-24.9l-62.3,62.3
	c-3.4,3.4-9,3.4-12.5,0c-2.2-2.2-2.6-4.8-2.6-6.2c0-1.4,0.3-4,2.6-6.2l79.9-79.9c13.7-13.7,36.1-13.7,49.9,0
	c13.7,13.7,13.7,36.1,0,49.8l-79.9,79.9l-44.7,44.7c-13.7,13.7-36.1,13.7-49.8,0c-13.7-13.7-13.7-36.1,0-49.8l1.7-1.7
	c-7.9-10.6-12.4-23.9-12.4-37.4l-14.2,14.2c-27.5,27.5-27.5,72.2,0,99.7c13.7,13.7,31.8,20.6,49.8,20.6s36.1-6.9,49.8-20.6
	l124.6-124.6C393.1,224.3,393.1,179.6,365.7,152.1z"/>
    </svg>
);

export default File01;