import React from 'react';

const Add01 = props => (
    <svg
        viewBox="0 0 448 452"
    >
        <path fill={props.color} d="M432,227c0,17.7-14.3,32-32,32H256v144c0,17.7-14.3,32-32,32s-32-14.3-32-32V259H48c-17.7,0-32-14.3-32-32s14.3-32,32-32
	h144V51c0-17.7,14.3-32,32-32s32,14.3,32,32v144h144C417.7,195,432,209.3,432,227z"/>
    </svg>
);

export default Add01;