import React, {useEffect, useState, useRef} from "react";
import {getListSavedRepliesMS, deleteSavedReplieMS, getListModels} from "../../hooks/AppProvider";
import {
    BoxErrorInlineOverPlatform,
    BoxListChooseModel,
    BoxModelInfoTagMsg,
    BoxModelTagMessage, BoxResponseOverPlatform, ButtonDeleteModel,
    ButtonModelAddTag, ChooseModelTarget, ItemResponseModal, MainContentModel, WrapButtonsModal,
    WrapperChooseModel
} from "../../styles/Modals";
import TextModel from "./models/messenger/TextModel";
import TagsInput from "react-tagsinput";
import {ButtonSendModel, WrapLoadMessageBar} from "../../styles/businessConversation";
import SvgAdd01 from "../../source/svg/Add01";
import {Button011, Button01} from "../../styles/Commons";
import Delete01 from "../../source/svg/Delete01";
import Loader from "../../utils/Loader";
import Main_settings from "../../styles/main_settings/Main_settings";
import TextButtonsModel from "./models/messenger/TextButtonsModel";
import SimpleBar from "simplebar-react";
import TextQuickReplies from "./models/messenger/TextQuickReplies";
import SliderModel from "./models/messenger/SliderModel";


function ModalSavedRepliesMS(props) {
    const properties = props
    const app = properties.dataModal.data.app
    const [savedReplies, setSavedReplies] = useState(false)
    const [repliesTarget, setRepliesTarget] = useState(false)
    const [responseMessage, setResponseMessage] = useState(false);
    const [tags, setTags] = useState( [])
    const taginput = useRef(null)
    const [showButtonSend, setShowButtonSend] = useState(true)
    const [changeObj, setChangeObj] = useState(false)
    const [showConfirmDeleteModel, setShowConfirmDeleteModel] = useState(false)
    const [showLoadingSavedReplie, setShowLoadingSavedReplie] = useState(false)
    const [outcomeMessage, setOutcomeMessage] = useState(false)
    const [showResponseDeleteModel, setShowResponseDeleteModel] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    let nameUser

    if (properties.dataModal.data.user) {
        if (properties.dataModal.data.user.name) nameUser = properties.dataModal.data.user.name
        else nameUser = properties.dataModal.data.user.sender
    }
    useEffect(() => {
        getSavedReplies()
    }, []);

    useEffect(() => {
        if (repliesTarget) {
             setTags(repliesTarget.tags)

        }
    }, [repliesTarget]);

    const handleChange = (tags) => {
        console.log(tags)
        setTags(tags)
    }

    useEffect(() => {
        if (responseMessage) {
            setShowButtonSend(true)
        }
        else {
            setShowButtonSend(false)
        }
    }, [responseMessage]);

    const getSavedReplies = async () =>  {
        let listsavedreplies = await getListSavedRepliesMS({app_id:app._id, platform:"Messenger"})
        if (!listsavedreplies.error) {
            if (listsavedreplies.savedreplies.length) {
                setSavedReplies(listsavedreplies.savedreplies)
                setRepliesTarget(listsavedreplies.savedreplies[0])
            }
            else {
                setSavedReplies([])
            }
        }
        else {
            setErrorMessage("Ops si è verificato un errore. Chiudi la finestra e riprova.")
        }
    }
    const switchDeleteModel = async (e) => {
        if (showConfirmDeleteModel) {
            setShowConfirmDeleteModel(false)
        }
        else {
            setShowConfirmDeleteModel(true)
        }
    }
    const onChangeChooseReplie = async (e) => {
        let findreplie = savedReplies.find(element => element.name === e.target.value)
        if (findreplie) {
            setRepliesTarget(findreplie)
        }
    }

    const actionReturnToReplies = () => {

        if (savedReplies.length) {
            setShowConfirmDeleteModel(false)
            setShowResponseDeleteModel(false)
            if (outcomeMessage) setOutcomeMessage(false)
        }
        else {
            properties.setModalSystem({show:false, action:null, source:null, data:null})
            properties.setResultModalSystem({target:"business-message-bar-MS", action:"update-savedreplies"})
        }

    };

    const actionSend = async (e) => {
        if (tags.length) {
            responseMessage.code.tags = tags
        }
        properties.setResultModalSystem({target:"business-message-bar-MS", model:{name:responseMessage.name, code:responseMessage.code}})
        properties.setModalSystem({show:false, action:null, source:null, data:null})

    }

    const confirmDeleteModel = async (e) => {
        let message
        let datasend = {
            id_app: app._id,
            name:repliesTarget.name
        }
        setShowLoadingSavedReplie(true)
        const calldeletemodel = await deleteSavedReplieMS(datasend)
        setShowLoadingSavedReplie(false)
        setShowResponseDeleteModel(true)
        if (calldeletemodel.error) {
            message = "Si è verificato un errore. Riprova più tardi"
            setOutcomeMessage(message)
        }
        else {
            message = "Il modello '" + repliesTarget.name + "' è stato eliminato"
            setOutcomeMessage(message)

        }
        getSavedReplies()
    }
    return (
        <>
            {(() => {
                if (errorMessage) {
                    <p>{errorMessage}</p>
                }
                else {
                    if (showResponseDeleteModel) {
                        return (
                            <>
                                <BoxResponseOverPlatform><p>{(outcomeMessage)}</p></BoxResponseOverPlatform>
                                <WrapButtonsModal>
                                    <Button01 title="Ok chiudi" onClick={actionReturnToReplies} >Ok</Button01>
                                </WrapButtonsModal>
                            </>
                        )
                    }
                    else {
                        if (repliesTarget && savedReplies.length) {
                            if (showConfirmDeleteModel) {
                                if (showLoadingSavedReplie) {
                                    return (
                                        <>
                                            <WrapLoadMessageBar>
                                                <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                            </WrapLoadMessageBar>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <div>
                                            <ChooseModelTarget>
                                                <ItemResponseModal>Sei sicuro di voler eliminare la risposta "{repliesTarget.name }" ?</ItemResponseModal>
                                                <WrapButtonsModal>
                                                    <Button01 onClick={switchDeleteModel}>Annulla</Button01>
                                                    <Button01 onClick={confirmDeleteModel}>Elimina</Button01>
                                                </WrapButtonsModal>
                                            </ChooseModelTarget>
                                        </div>
                                    )
                                }
                            }
                            else {
                                return (
                                    <>
                                        <WrapperChooseModel>
                                            <h1>Scegli la risposta da inviare a {nameUser} </h1>
                                            <BoxListChooseModel>
                                                <select defaultValue={repliesTarget} onChange={onChangeChooseReplie}>
                                                    {(() => {
                                                        return savedReplies.map((child, i) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={child.name} >
                                                                    {child.name}
                                                                </option>
                                                            )
                                                        })
                                                    })()}
                                                </select>
                                            </BoxListChooseModel>
                                        </WrapperChooseModel>
                                        {(() => {
                                            return (
                                                <>
                                                    <MainContentModel>
                                                        <ButtonDeleteModel title="Elimina questa risposta" onClick={switchDeleteModel}><Delete01 /></ButtonDeleteModel>
                                                        <SimpleBar autoHide={false} style={{maxHeight: "400px", width:"100%"}}>
                                                            {(() => {
                                                                if (repliesTarget) {
                                                                    if (repliesTarget.model.name === "textModel") {
                                                                        return (
                                                                            <TextModel
                                                                                setResponseMessage={setResponseMessage}
                                                                                replie={repliesTarget}
                                                                            />
                                                                        )
                                                                    }
                                                                    if (repliesTarget.model.name === "textButtonsModel") {
                                                                        return (
                                                                            <TextButtonsModel
                                                                                setResponseMessage={setResponseMessage}
                                                                                replie={repliesTarget}
                                                                            />
                                                                        )
                                                                    }
                                                                    if (repliesTarget.model.name === "textQuickReplies") {
                                                                        return (
                                                                            <TextQuickReplies
                                                                                setResponseMessage={setResponseMessage}
                                                                                replie={repliesTarget}
                                                                            />
                                                                        )
                                                                    }
                                                                    if (repliesTarget.model.name === "slider") {
                                                                        return (
                                                                            <SliderModel
                                                                                setResponseMessage={setResponseMessage}
                                                                                replie={repliesTarget}
                                                                            />
                                                                        )
                                                                    }
                                                                }
                                                            })()}
                                                        </SimpleBar>
                                                    </MainContentModel>
                                                    <BoxModelTagMessage>
                                                        <BoxModelInfoTagMsg>
                                                            <h3>Tags</h3>
                                                            <p>(I tags sono informazioni di tipo semantico molto utili in fase di ricerca ed invio di messaggi mirati)</p>
                                                        </BoxModelInfoTagMsg>
                                                        <TagsInput inputProps={{
                                                            placeholder: 'Aggiungi tag'
                                                        }} ref={taginput} value={tags} onChange={handleChange} />
                                                        <ButtonModelAddTag title="Aggiungi" onClick={() => taginput.current.accept()}><SvgAdd01 /></ButtonModelAddTag>
                                                    </BoxModelTagMessage>
                                                    <WrapButtonsModal>
                                                        {(showButtonSend) ?<Button01 title="invia" onClick={actionSend} >Invia </Button01> : <Button011>Invia</Button011> }
                                                    </WrapButtonsModal>


                                                </>
                                            )
                                        })()}



                                    </>
                                )
                            }
                        }
                    }

                }

            })()}
        </>
    )
}

export default ModalSavedRepliesMS