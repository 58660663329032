import styled from 'styled-components';
import {
    Button01, ButtonRoundIcon, ButtonIcon,ButtonRoundIconActive, RoundIcon, WrapLoader,
} from "./Commons"
import {FormSettings, LinkSettings, ModalSettings} from "./main_settings/Elements_settings";
import Main_settings from "./main_settings/Main_settings";

export const WrapperModalAccess = styled.div`
  width:100%;
  height:100vh;
  position:absolute;
  z-index:9999999;
  left:0;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${ModalSettings.bg_wrapper_modal_access};
`;

export const PanelModalAccess = styled.div`
  background:${ModalSettings.bg_panel_modal_access};
  padding:20px;
  min-width:600px;
  min-height:400px;
  border-radius:  ${ModalSettings.border_radius_panel_modal_access};
  box-shadow:${ModalSettings.box_shadow_panel_modal_access};
    ${ButtonRoundIcon} {
        width:30px;
        height:30px;
        background:none;
        margin-right:-20px;
        :hover {
            background:rgba(0,0,0, .2)
        }
        svg {
            fill:#fff;
        }
  }
  @media (min-width: 0) and (max-width: 600px) {
        width:450px;
        min-width:100%;
        min-height:100%;
        border-radius:0;
        display:flex;
        flex-flow:column;
        padding:40px 20px;
  }

`;
export const WrapCloseModalAccess = styled.div`
  display:flex;
  width:100%;
  justify-content:flex-end;
  position:absolute;
  top:30px; right:20px;
  margin-top:-30px;
    @media (min-width: 0) and (max-width: 600px) {
      padding:0 20px;
  }
`;

export const BodyModalAccess = styled.div`
  max-width:450px;
  margin:auto;
  @media (min-width: 0) and (max-width: 600px) {
    width:100%;
    max-width:100%;
  }
`;

export const TitleModalAccess = styled.h1`
    color:${ModalSettings.modal_access_main_title_color};
    text-align:center;
    padding-bottom:40px;
  font-size: 1.8em;
    font-family:${ModalSettings.modal_access_main_title_fontfamily};
      @media (min-width: 0) and (max-width: 600px) {
          font-size:1.8em;
      }
`;
export const PanelDataModalAccess = styled.div`


`;
export const WrapButtonsModalAccess = styled.div`
    display:flex;
    justify-content:center;
`;

export const ItemExtraLinkModalAccess = styled.p`
    color:${ModalSettings.modal_access_text_color}

`;


export const ItemExtraLinkModalAccessFPwd = styled(ItemExtraLinkModalAccess)`
    margin-top:20px;

`;
export const ItemExtraLinkModalAccessSign = styled(ItemExtraLinkModalAccess)`
    margin-top:20px;

`;
export const ItemExtraLinkModalAccessLogin = styled(ItemExtraLinkModalAccess)`
    margin-top:20px;
`;

export const ExtraLinkModalAccess  = styled.span`
    color: ${ModalSettings.modal_access_link_color};
    font-family: ${ModalSettings.modal_access_link_fontfamily};
    cursor:pointer;
    :hover {
        text-decoration: ${LinkSettings.text_decoration_hover_main};
    }
`;
export const ItemExtraLinkModalAccessInfoTerms = styled(ItemExtraLinkModalAccess)`
    margin-bottom:10px;
    a {
        font-family: ${ModalSettings.modal_access_link_fontfamily};
        color: ${ModalSettings.modal_access_link_color};
        text-decoration:${LinkSettings.text_decoration_main}
    }
    a:hover {
        text-decoration:${LinkSettings.text_decoration_hover_main}
    }
`;




export const ItemDataModalAccess = styled.p`
    display:flex;
    align-items:center;
    padding-bottom:20px;
   
    .itI6874ac {
         width:100%;
         border:0;
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
         
    }

`;
export const ItemDataSelectModalAccess = styled.div`
    padding-bottom:20px;
    
   
`;
export const TextInfoDataModalAccess = styled.div`
   
    p {
        text-align:center;
        padding-bottom:8px;
        color:${ModalSettings.modal_access_text_color}
    }
   
`;

export const TextInfoDataModalAccessSmall = styled(TextInfoDataModalAccess)`
   
    p {
        font-size:.75em
    }
   
`;

export const BodyModalAccessLogin = styled(BodyModalAccess)`   
`;
export const BodyModalAccessRegistration = styled(BodyModalAccess)`   
`;
export const BodyModalAccessSecureCode = styled(BodyModalAccess)`   
`;
export const BodyModalAccessForgotPwd = styled(BodyModalAccess)`   
`;

export const BodyWelcomeUser = styled.div` 
    max-width:600px;
`;
export const TextWelcomeUser = styled.p`
    font-size:1.6em;
    color:#fff;
    text-align:center;
    font-family:"Roboto-Bold";
    padding-bottom:10px;
`;
export const TextShowLastApp = styled.p`
    font-size:1.3em;
    color:#fff;
    text-align:center;
    padding-bottom:20px;
`;

export const CardWelcomeApp = styled.div` 
    background:#fff;
    border-radius:40px;
    padding:10px;
    display:flex;
    cursor:pointer;
    margin-top:20px;
    :hover {
        opacity:.8;
    }
`;
export const BoxImageCardWApp = styled.div` 
    width:100px;
    img {
        width:100px;
    }
`;
export const BoxContentCardWApp = styled.div` 
    flex:1; 
    padding:0 20px;
    h2 {
        padding-bottom:5px;
    }
`;

export const WrapValidatorDataModalAccess = styled.div `
    padding-bottom:5px;
    display:flex;
    background:${Main_settings.color04};
    padding:10px;
    margin-bottom:20px;
    border-radius:40px;

`;
export const ValidatorDataModalAccess = styled.p `
     color:${Main_settings.color07};
     font-family:"Roboto-Bold";
     text-align:center;
     
     
`;

export const PanelUserHeader = styled.div`
  

`;
export const PanelUserLogged = styled.div`
  display:flex;
  margin-right:5px; 
`;
export const PanelUserConversation = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  margin-right:10px;
  .ic5532 {
    width:38px;
    height:38px;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    border-radius:100%;
    position:relative;
  }
  .ic5532 svg {
    width:30px;
  }
  .ic5532Ac {
    background:#ff398a;
  }
    .ic5532Ac svg {
    width:20px;
  }
  .n02342ty {
    position:absolute;
    left:20px;
    top:0;
    border-radius:100%;
    background:#FF398A;
    min-width:13px;
    min-height:13px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:2px 6px;
    font-size:0.7em;
    color:#fff;
    font-family:Roboto-Medium;
  }
`;
export const PanelUserProfile = styled.div`
   display:flex;
  align-items:center;
  justify-content:center;
  .usI534e svg {
    width:32px;
  }
`;
export const UserHeadProfImage = styled.div`
  cursor:pointer;
  img {
    width:32px;
    border-radius:100%;
  }
  svg {
    width:32px;
  }
`;

export const PanelUserProfileOpt = styled.ul`
    position:absolute;
    top:50px;
    right:20px;
    z-index:999999;
    background:${Main_settings.color04};
    box-shadow:${Main_settings.boxshadow05};
    border-radius:${Main_settings.border_radius04};
`;
export const ItemPanelUserProfileOpt = styled.li`
    list-style:none;
    min-width:200px;
    padding:10px 15px;
    text-align:left;
    cursor:pointer;
    :hover {
        background:${Main_settings.color08};
    }
`;
export const PanelGuestLink = styled.ul`
    display:flex;
    padding:10px 10px 10px 0;
`;
export const ItemGuestLink = styled.li`
    list-style:none;
    margin-right:20px;
    :last-child {
        margin-right:0;
    }
    span {
        color:${LinkSettings.color_main};
        cursor:pointer;
        font-family: ${Main_settings.font02};
    }
    span:hover {
        text-decoration:${LinkSettings.text_decoration_hover_main}
    }
`;


export const PanelUserListConversation = styled.div`
    position:absolute;
    z-index:999999;
    right:0;
    width:400px;
    height:${props => props.heightpanel + 'px'};
    top: ${props => props.positiontop + 'px'};
    border-top-left-radius:40px;
    border-bottom-left-radius:40px;
    background:${Main_settings.color02};
    padding:10px 0 10px 10px;
    box-sizing: border-box;
    box-shadow:${Main_settings.boxshadow02};
     .tkS3232c {
         background:${Main_settings.color04};
         border-top-left-radius:40px;
         border-bottom-left-radius:40px;    
    }
    .tkS3232c h2 {
         color:  ${Main_settings.color01};
         font-size:1em;
         text-align:center;
         padding:10px 0;
         font-family:${Main_settings.font04}
    }
    @media (min-width: 0) and (max-width: 600px) {
        width:100%;  
        border-radius:0;
        padding:10px 0;
   }

`;
export const BoxLoaderConvPop = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height:${props => props.heightloader + 'px'};
`;
export const BoxNoConvPop = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height:${props => props.heightbox + 'px'};
    
    p {
        color:${Main_settings.color04};
        font-size:1.2em;
    }
`;

export const SingleUserConversation = styled.div`
    display:flex;    
    position:relative;
    align-items:center;

    .sZ545wcM {
        cursor:pointer;
        padding-right:20px;
    }
     .sZ545wcM:hover {
        background:#faecf2;
    }
    .sUC938tx {
        flex:1;
    }

    .o5445pC {
        border-radius:100%;
        width:22px;
        height:22px;
        background:#fff;
        font-size:0;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor:pointer;
        position:absolute;
        right:10px;
        top:50%;
        margin-top:-11px;
        z-index:1000;
    }
    .o5445pC:hover {
        background:#fddae8;
    }
    .o5445pC svg {
        width:12px;
    }
    .n0434wr  {
        min-width:40px;
        display:flex;
       align-items:center;
        justify-content:center;
    }
    .n0434wr span  {
        width:10px;
        height:10px;
        border-radius:100%;
        background:#FF398A;
    }
    .sZ545wcN .sUC222na {
        font-family:"Roboto-Medium";
    }
   .sZ545wcN .sUC222na{
        color:#FF398A;
    }

    .CLAC128tx {
        flex:1;
        margin-right:15px;
        
    }
    .CLAC128tx .sLAC938tx:nth-child(2) {
        border-top:solid 1px ${Main_settings.color09};
    }

    .ll1851 {
        font-size:0.9em;
    }

    

`;

export const BoxLeftSingleConv = styled.div`
        flex:1;
`;
export const BoxRightSingleConv = styled.div`
        width:40px;
        padding-top:2px;
`;
export const NameAppSingleConv = styled.div`
        margin-right:5px;
        color:${props => props.fontcolor};
        font-family:${props => props.fontfamily}
`;
export const BoxUpLeftSingleConv = styled.div`
        display:flex;
        align-items:center;
        justify-content:flex-start;
        padding-bottom:5px;
`;
export const LangAppSingleConv = styled.div`
        color:${props => props.fontcolor};
        font-family:${props => props.fontfamily}
`;

export const BoxDownLeftSingleConv = styled.div`
        display:flex;
        align-items:center;
        padding-right:25px;
        flex:1;
        @media (min-width: 0) and (max-width: 600px) {

                flex:0.9;
        }
    
`;
export const BoxContentSingleConversation = styled.div`
        display:flex;
        width:100%;
        align-items:center;
        justify-content:center;
        padding:8px 30px 8px 10px;
        border-bottom:solid 1px ${Main_settings.color11};
        background:#fff;
       :hover {
       background:${Main_settings.color11}
       }
           @media (min-width: 0) and (max-width: 600px) {
                display:flex;
                width:100%;
                align-items:center;
                padding:5px 40px 10px 11px;
                border-radius:0;
                border-bottom:solid 1px ${Main_settings.color08};
                background:none;
            }
`;



export const BoxImgSingleConversation = styled.div`
        position:relative;
        margin-right:8px;
`;
export const BoxImgAppSConversation = styled.div`      
        overflow:hidden;
        background:${Main_settings.color04};
        border-radius:${Main_settings.border_radius02};
        display:flex;
        align-items:center;
        justify-content:center;
        
`;
export const BoxImgActorSConversation = styled.div`
        position:absolute;
        overflow:hidden;
        background:${Main_settings.color04};
        border-radius:${Main_settings.border_radius02};
        display:flex;
        align-items:center;
        justify-content:center;
        bottom:0;
        right:-10px;
        
        
`;
export const BoxDetailSingleConversation = styled.div`
        display:flex;
        align-items:stretch;
        padding:8px;
        width:95%;
        flex-wrap:wrap;
        cursor:pointer;
        border-radius:${Main_settings.border_radius01};
       
       
   }
`;

export const BoxIntroMessageSingleConv = styled.div`
        color:${props => props.fontcolor};
        display:flex;
        font-family:${props => props.fontfamily};
        font-size:0.9em;  
                flex:1;
 
`;
export const ContentIntroMessageSingleConv = styled.span`
        display:flex;
        flex:1;
        svg {
            width:20px;
            fill:${Main_settings.color06}
        }
`;

export const BoxDateSingleConv = styled.div`
      color:#6a6a6a;
      font-size:0.8em;
      display:flex;
      justify-content:flex-end;
      margin-bottom:5px;
`;
export const BoxMsgUnreadSingleConv = styled.div`
      display:flex;
      justify-content:flex-end;
      
      
      span {
        background:${Main_settings.color02};
        color:${Main_settings.color09};
        border-radius:100%;
        font-size:.8em;
        min-width:13px;
        min-height:13px;
        display:flex;
        align-items:center;
        justify-content:center;
        padding:2px 6px;
      }
`;

export const OptionSingleConversationPop = styled.div`
    position:absolute;
    top:48px; 
    right:0;
    padding:5px;
    z-index:2000;
    border-radius:10px;
    background:#fff;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);

`;

export const ItemOptionConvPop = styled.div`
    font-size:0.9em;
    padding:10px 20px;
    border-radius:10px;
    cursor:pointer;
    :hover {
        background:#faecf2;
    }
`;

export const ContainerUserArea = styled.div`

`;



export const HeaderUserProfile = styled.div`
    height:300px;
    background:#000;
    @media (min-width: 0) and (max-width: 768px) {
       height:auto;
       background:none;
    }
`;

export const ContainerUserProfile = styled.div`
    max-width:1100px;
    margin:auto; 
`;

export const UserProfileMainInfo = styled.div`
    min-height:330px;
    display:flex;
    
   @media (min-width: 0) and (max-width: 768px) {
       flex-wrap:wrap;
       min-height:auto;
    }
`;
export const WrapUserAreaProfImage = styled.div`
    align-self: flex-end;
    
    @media (min-width: 0) and (max-width: 768px) {
       background:${Main_settings.color01};
       width:100%;
       padding:20px;
       margin-bottom:30px;
       align-self: flex-start;
    }
`;
export const UserAreaProfImage = styled.div`
     display:flex;
     border-radius:100%;
     width:250px;
     height:250px;
     background:${Main_settings.color02};
     margin:auto;
    svg {
        width:220px;
    }
    img {
         width:220px;
         border-radius:100%;
    }
    @media (min-width: 0) and (max-width: 768px) {
        margin-bottom:-40px;
        width:200px;
        height:200px;
       svg {
            width:170px;
        }
        img {
             width:170px;
        }
    }
`;

export const UserProfName = styled.div`
    align-self: center;
    color:${Main_settings.color04};
    font-family:"Roboto-Bold";
    font-size:3em;
    padding-left:50px;
        @media (min-width: 0) and (max-width: 768px) {
           width:100%;
           color:${Main_settings.color01};
           font-size:2em;
           padding-left:0;
           text-align:center;
           margin-bottom:30px;
        }
`;

export const UserProfileNav = styled.div`
    display:flex;
    margin-top:-110px;
    justify-content:flex-end;
    @media (min-width: 0) and (max-width: 600px) {
         margin-top:0;
         padding:0 20px;
         justify-content:center;
    }
`;

export const UserProfileMenu = styled.ul`
    display:flex;
   
`;

export const UserProfileMenuItem = styled.li`
    list-style:none;
    text-align:center;
    margin-right:10px;
    :last-child {
        margin-right:0;
    }
    ${ButtonRoundIcon} {
        width:100px;
        height:100px;
        + span {
            color:#cdb0bc;
        }
    }
    ${ButtonRoundIconActive} {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        
        + span {
            font-family:"Roboto-Medium";
            color:#000 ;
        }
    }
`;

export const BodyUserProfile = styled.ul`
    padding-bottom:60px;
    @media (min-width: 0) and (max-width: 600px) {
         padding-left:10px;
         padding-right:10px;
    }
   
`;
export const TitleViewUserProfile = styled.h1`
    text-align:center;
    margin:20px 0;
      
`;
export const WrapperUserAreaAccount= styled.div`
   box-shadow: ${Main_settings.boxshadow01};
   max-width:800px;
   margin:auto;
   border-radius:40px;
   padding:60px;
   margin-bottom:50px;
   
   @media (min-width: 0) and (max-width: 600px) {
      padding:30px 15px;
    }
`;

export const ListDataUserAreaAccount= styled.ul`
`;

export const ItemDataUserAreaAccount= styled.li`
   display:flex;
   position:relative;
   width:100%;
   border-bottom:solid 1px #eee;
   margin-bottom:20px;
   flex-wrap:wrap;
   
      @media (min-width: 0) and (max-width: 600px) {
        flex-flow:column;
    }
 
`;
export const PanelEditDataAreaAccount= styled.div`
    width:100%;
    background:#ff398a;
    border-radius:40px;
    padding:20px;
     ${WrapLoader} {
       svg {width:100px;}
    }
`;

export const KeyDataUserAreaAccount = styled.span `
   flex:1;
   padding-bottom:8px;
   font-size:1.1em;
   color:#9d9d9d;
`;
export const ValueDataUserAreaAccount = styled.span `
    flex:1;
    padding-bottom:8px;
    font-size:1.1em;
`;




export const ItemDataEditAreaAccount = styled.p `
    display:flex;
    align-items:center;
    padding-bottom:20px;
    
    label {
        text-align:right;
        padding-right:10px;
        color:#fff;
        width:30%;
    }
    .itI4324ac {
         width:55%;
         border:0;
         border-radius:${FormSettings.border_radius_input};
         min-height:${FormSettings.height_input_text};
         padding:${FormSettings.padding_input_text};
         font-size:${FormSettings.font_size_input_text};
         
    }
    .itI4324bc {
         width:100%;
         
    }
       
      @media (min-width: 0) and (max-width: 600px) {
        flex-flow:column;
       
       label {
            text-align:center;
            padding-right:0;
            padding-bottom:10px;
            color:#fff;
            width:1000%;
        }
        .itI4324ac {
             width:100%;
         
        }
    }

`;

export const WrapButtonsEditAreaAccount = styled.div `
    display:flex;
    justify-content:center;
    ${Button01} {
       :first-child {
        margin-right:10px;
       }
    }
`;

export const OptionDataUserAreaAccount = styled.div `
    ${ButtonRoundIcon} {
        position:absolute;
        right:0;
        top:0;
        width:25px;
        height:25px;
    }
`;

export const ResponseDataEditAreaAccount = styled.p `
    color:#fff;
    text-align:center;
    padding:60px 0;
    font-family: "Roboto-Medium";
    font-size:1.2em;
`;

export const TextInfoDataEditAreaAccount = styled.div `
    margin-bottom:20px;
    p {
        color:#fff;
        text-align:center;
    }
`;
export const WrapValidatorDataAreaAccount = styled.div `
    padding-bottom:5px;
    display:flex;
    align-items:center;
    justify-content:center;
    ${RoundIcon}    {
        width:20px;
        height:20px;
        margin-right:10px;
        background:#fff;
    }
`;
export const ValidatorDataAreaAccount = styled.p `
     color:#fff;
     font-family:"Roboto-Bold";
     text-align:center;
     
`;
export const WrapDateofbirthday = styled.div `
     background:#fff;
     border-radius:${FormSettings.border_radius_input};
     padding:${FormSettings.padding_input_select};
     label {
        display:block;
        text-align:center;
        font-size:${FormSettings.font_size_label_select};
        color:${FormSettings.color_label_select};
     }
     select {
        display:block;
        margin:auto;
        background:none;
        border:0;
        width:90%;
        border-radius:${FormSettings.border_radius_input};
        text-align:center;
        cursor:pointer;
        font-size:${FormSettings.font_size_option_select};
     }

     
`;
export const MainWrapDateofbirhtday = styled.div `
      display:flex;     
      justify-content:space-between;
      
      ${WrapDateofbirthday}    {
        :nth-child(1) {
            width:45%;
        }
        :nth-child(2) {
            width:20%;
        }
        :nth-child(3) {
            width:30%;
        }
    }
`;
export const BodyDataEditAreaAccount = styled.div `
    max-width:450px;
    margin:auto;
    ${MainWrapDateofbirhtday} {
        max-width:400px;
        margin-bottom:20px;
    }
    
`;
export const WrapperUserAreaAccountDeactivate= styled(WrapperUserAreaAccount)`
    padding:10px;
`;


export const LinkDeactivateAccount= styled.span`
   color:${LinkSettings.color_danger};
   display:block;
   text-align:center;
   cursor:pointer;
   :hover {
        text-decoration:underline;
   }
`;
export const ForgotpwdAreaAccount = styled.p`
   margin:10px 0;
   margin-top:-10px;
   span {
        cursor:pointer;
       color:#fff;
       text-decoration:${LinkSettings.text_decoration_main};
   }
   span:hover {
       text-decoration:${LinkSettings.text_decoration_hover_main};
   }

`;
export const BodyDeactivateAreaAccount= styled.div`
   padding:30px;
   h2 {
        text-align:center;
        padding-bottom:20px;
   }
   p, li {
        margin-bottom:10px;
   }
   li {
        margin-left:20px;
   }
   ${WrapButtonsEditAreaAccount} {
        padding:20px 0;
   }
`;

export const WrapperUserAreaAccountSettings= styled(WrapperUserAreaAccount)`
    padding:30px;
`;
export const MenuUserAreaSettings= styled.ul`
   max-width:800px;
   margin:auto;
   display:flex;
   justify-content:flex-end;
   list-style:none;
   padding-right:60px;
   position:relative;
   z-index:1;
   
`;
export const ItemMenuUserAreaSettings= styled.li`
   background:${Main_settings.color03};
   padding:10px 40px;
   border-top-left-radius: 40px;
   border-top-right-radius: 40px;
   box-shadow: ${Main_settings.boxshadow03};
   cursor:pointer;
   color:${Main_settings.color06};
   :hover {
      background:${Main_settings.color02};
      color:${Main_settings.color04};
   }
`;
export const ItemMenuUserAreaSettingsActive= styled(ItemMenuUserAreaSettings)`
   background:${Main_settings.color04};
   color:${Main_settings.color01};
   cursor:default;
   font-family:${Main_settings.font02};
   :hover {
      background:${Main_settings.color04};
      color:${Main_settings.color01};
   }
   
`;

export const SubTitleAreaAccount= styled.h2`
   text-align:center;
   padding-bottom:20px;
`;





