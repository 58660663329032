import React, {useState, useEffect, useRef} from "react";
import { UseInterval } from "../../../../../hooks/UseInterval";
import {getListConversationWA} from "../../../../../hooks/ConversationProvider";
import SimpleBar from "simplebar-react";
import "simplebar/src/simplebar.css";
import ImageUserChat from "../../../../../utils/ImageUserChat";
import moment from "moment";
import ParserTemplComponentsUserListWA from "../../../../../utils/ParserTemplComponentsUserListWA";
import ParserTemplComponentsAppList from "../../../../../utils/ParserTemplComponentsAppList";
import {
    BoxLoader,

    BusinessListConversations, WrapLoadMoreMessage


} from "../../../../../styles/businessConversation";
import Loader from "../../../../../utils/Loader";
import Main_settings from "../../../../../styles/main_settings/Main_settings";
import {ButtonRoundIcon} from "../../../../../styles/Commons";
import SvgAdd01 from "../../../../../source/svg/Add01";


function BusListConversationsWA(props) {
    const properties = props
    const [conversations, setConversations] = useState(false)
    const [totalConversations, setTotalConversations] = useState(false)
    const [thisCurrentConversation, setThisCurrentConversation] = useState(false)
    const [loadMoreConversations, setLoadMoreConversations] = useState(false)
    const [changeObj, setChangeObj] = useState("")
    const heightbar = properties.heightList


    const scrollbar = useRef(null)

    useEffect(() => {
        //callConversations()
        setConversations(properties.listConversations)
        setTotalConversations(properties.totalConversations)
        setThisCurrentConversation(properties.currentConversation)
    }, []);

    useEffect(() => {
        if (props.updateConversation) {
            callConversations()
        }


    }, [props.updateConversation]);

    useEffect(() => {
        if (props.updatelistConversations) {
            callConversations()
        }


    }, [props.updatelistConversations]);


    /*UseInterval(async() => {
        callConversations()

    }, 500 )*/

    async function callConversations(add) {

        if (add) {
            setLoadMoreConversations(true)
        }
        let currentnumberconv = conversations.length

        let calllistconversations = await getListConversationWA({app_id:props.app._id, current_number: currentnumberconv, add:add});
        if (add) {
            setLoadMoreConversations(false)
        }
        if (!calllistconversations.error) {
            setConversations(calllistconversations.conversations)
            setTotalConversations(calllistconversations.total_conversations)
            if (!thisCurrentConversation) {
                properties.setCurrentConversation(calllistconversations.conversations[0])
                setThisCurrentConversation(calllistconversations.conversations[0])
            }
            setChangeObj(new Date())
        }
    }

    async function updateCurrentConversation(e, tConversation) {
        e.preventDefault()
        if (thisCurrentConversation && thisCurrentConversation.conversations._id != tConversation.conversations._id) {
            properties.setCurrentConversation(tConversation)
            setThisCurrentConversation(tConversation)
        }

    }

  return (
        <>
          {(() => {
              if (conversations) {
                  if (conversations.length && thisCurrentConversation) {
                      return (
                          <>
                          <BusinessListConversations>

                                  {(() => {
                                      return conversations.map((child, i) => {
                                          let nameuser
                                          let targetuser
                                          if (child.user) {
                                              nameuser = child.user.name
                                              targetuser = child.user
                                          }
                                          let unreadmessage = 1

                                          if (child.last_message.updates && child.last_message.updates.console && child.last_message.updates.console.read === "true") unreadmessage = 0
                                          if (child.last_message) {
                                              let ddate = moment(child.last_message.date.date).format('ll');
                                              ddate = ddate.substring(0, ddate.length - 5);
                                              return (
                                                  <div className={`listitem_conversation ${unreadmessage ? "listitem_conversation_unread" : ""} ${child.conversations._id === thisCurrentConversation.conversations._id ? "listitem_conversation_active" : ""}`}  onClick={e => updateCurrentConversation(e, child)} key={i}>
                                                      <div className="listitem_pic_user_conv">
                                                          <ImageUserChat user={targetuser}/>
                                                      </div>
                                                      <div className="listitem_txt_user_conv">
                                                          <p className="subtxt_user_name">{nameuser}</p>
                                                          {(() => {
                                                              if (child.last_message.from === "user") {
                                                                  return (
                                                                      <>
                                                                          <p className="subtxt_user_conv">
                                                                              <ParserTemplComponentsUserListWA message={child.last_message} code={child.last_message.message_data}/>
                                                                          </p>
                                                                      </>
                                                                  )
                                                              } else {
                                                                  let targetcontent
                                                                  let length = 15;
                                                                  if (child.last_message.message_data.type === "file" && child.last_message.message_data.name) {
                                                                      targetcontent = child.last_message.message_data.name
                                                                  }
                                                                  else {
                                                                      let htmltext = ""
                                                                      if (child.last_message.message_data && child.last_message.message_data.body) htmltext= child.last_message.message_data.body.replace(/<[^>]+>/g, '').trim()
                                                                      targetcontent = htmltext
                                                                  }
                                                                  let trimmedText = targetcontent.substring(0, length) + "...";
                                                                  return (
                                                                      <>
                                                                          <p className="subtxt_user_conv">
                                                                              <span>{properties.app.name}: </span>{trimmedText}
                                                                          </p>
                                                                      </>
                                                                  )
                                                              }

                                                          })()}
                                                      </div>
                                                      <div className="listitem_date_user_conv">
                                                          <p>{ddate}</p>
                                                      </div>
                                                  </div>
                                              )
                                          } else {
                                              return (
                                                  <div className={`listitem_conversation ${child.conversations._id === thisCurrentConversation.conversations._id ? "listitem_conversation_active" : ""}`}
                                                      onClick={e => updateCurrentConversation(e, child)} key={i}>
                                                      <div className="listitem_pic_user_conv">
                                                          <ImageUserChat user={targetuser}/>
                                                      </div>
                                                      <div className="listitem_txt_user_conv">
                                                          <p className="subtxt_user_name">{nameuser}</p>
                                                          <p className="subtxt_user_conv">No messages</p>
                                                      </div>
                                                  </div>
                                              )
                                          }

                                      })
                                  })()}
                                  {(() => {
                                      if (conversations.length >= 1 && conversations.length != totalConversations) {
                                          if (loadMoreConversations) {
                                              return (
                                                  <WrapLoadMoreMessage>
                                                      <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                                  </WrapLoadMoreMessage>
                                              )

                                          }
                                          else {
                                              return (
                                                  <WrapLoadMoreMessage onClick={e => callConversations("true") }>
                                                      <ButtonRoundIcon title="Visualizza conversazioni precedenti"><SvgAdd01 /></ButtonRoundIcon>
                                                  </WrapLoadMoreMessage>
                                              )
                                          }
                                      }

                                  })()}
                          </BusinessListConversations>

                        </>
                      )
                  } else {
                      return (
                          <div>
                              <p>Non ci sono ancora conversazioni</p>
                          </div>
                      )
                  }
              }
              else {
                  return (
                      <BoxLoader heightloader={props.heightList}>
                          <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                      </BoxLoader>
                  )
              }

          })()}

        </>
  );
}

export default BusListConversationsWA;
