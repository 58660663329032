import React from 'react';

const Send01 = props => (
    <svg
        viewBox="0 0 639 514"
    >
        <path d="M590.6,273.9L225.1,480.8c-8.6,4.9-18.9,5.5-28,1.7c-5.1-2.1-9.6-5.5-12.9-9.8c-2.6-3.3-4.5-7-5.7-11.2l-35.8-127.9
	L51.2,341c-7.7,1.1-14.4-1.9-18.8-7.4c-7.1-9-5.6-22.1,3.4-29.2l75.5-59.6c5.6-4.4,12.5-6.8,19.6-6.9l353.9-2.5L95.5,168.7
	l-30-107.1c-3.3-11.7,0.3-24.3,10.1-33s22-10.4,33.3-5.8l477.8,193.6c11.4,4.6,19.1,15.3,20,27.6
	C607.5,256.2,601.3,267.9,590.6,273.9z"/>
    </svg>
);

export default Send01;