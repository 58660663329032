import React from 'react';

const Template01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path id="bAreaRounded60" display="none" d="M342.4,512.5H169.6C76,512.5-0.5,436-0.5,342.4V169.6C-0.5,76,76-0.5,169.6-0.5h172.8
	C436-0.5,512.5,76,512.5,169.6v172.8C512.5,436,436,512.5,342.4,512.5z"/>
        <path id="bAreaRounded40" display="none" d="M399.1,512.5H112.9
	c-62.4,0-113.4-51-113.4-113.4V112.9c0-62.4,51-113.4,113.4-113.4h286.2c62.4,0,113.4,51,113.4,113.4v286.2
	C512.5,461.5,461.5,512.5,399.1,512.5z"/>
        <path id="bAreaRounded20" display="none" d="M455.8,512.5H56.2C25,512.5-0.5,487-0.5,455.8V56.2C-0.5,25,25-0.5,56.2-0.5h399.6
	c31.2,0,56.7,25.5,56.7,56.7v399.6C512.5,487,487,512.5,455.8,512.5z"/>
        <path id="bAreaRounded10" display="none" d="M484.7,513H28.3C12.8,513,0,500.2,0,484.7V28.3C0,12.8,12.8,0,28.3,0h456.3
	C500.2,0,513,12.8,513,28.3v456.3C513,500.2,500.2,513,484.7,513z"/>
        <rect id="bAreaSquare" display="none" width="513" height="513"/>
        <circle id="bAreaCircle" display="none" cx="255" cy="257" r="253"/>
        <path id="bArea01" d="M360.1,107H137.9c-15.9,0-28.9,13.1-28.9,28.9v222.1c0,15.9,13.1,28.9,28.9,28.9h222.1
	c15.9,0,28.9-13.1,28.9-28.9V135.9C389,120.1,375.9,107,360.1,107z M213.1,336.1c0,3.7-3.7,6.5-7.5,6.5h-47.1h-0.9
	c-3.7,0-6.5-3.7-6.5-7.5v-93.8c0-3.7,3.7-6.5,7.5-6.5H207c3.7,0,6.5,3.7,6.5,7.5v93.8H213.1z M345.6,337.1c0,3.7-3.7,6.5-7.5,6.5
	h-89.6c-3.7,0-6.5-3.7-6.5-7.5v-94.7c0-3.7,3.7-6.5,7.5-6.5h89.1h0.9c3.7,0,6.5,3.7,6.5,7.5v94.7H345.6z M346.5,199.9
	c0,3.7-3.7,6.5-7.5,6.5H158.5h-0.9c-3.7,0-6.5-3.7-6.5-7.5v-42c0-3.7,3.7-6.5,7.5-6.5H340c3.7,0,6.5,3.7,6.5,7.5V199.9z"/>
    </svg>
);

export default Template01;