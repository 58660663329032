import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {

    SlideMessengerConv,
    ContentSlideMsConv,
    ImageSlideMsConv,
    TitleSlideMsConv,
    BodySlideMsConv,
    SubTitleSlideMsConv,
    TextSlideMsConv,
    BtnSlideMsConvNext,
    BtnSlideMsConvPrev

} from "../../../styles/TempComponent";
import {Button012} from "../../../styles/Commons";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnSlideMsConvNext
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnSlideMsConvPrev
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

export default class MultipleItemsMS extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            arrow:true,
            speed: 500,
            adaptiveHeight: true,
            slidesToShow: this.props.slidetoshow,
            slidesToScroll: this.props.slidetoshow,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <div>
                <Slider {...settings}>
                    {(() => {
                        const listEl = this.props.elements
                        return listEl.map((child, i) => {
                            return (
                                <SlideMessengerConv key={i} >
                                    <ContentSlideMsConv>
                                        {(() => {
                                            if (child.image_url) {
                                                return (
                                                    <ImageSlideMsConv>
                                                        <img alt="" src={child.image_url} />
                                                    </ImageSlideMsConv>
                                                )
                                            }

                                        })()}
                                        <BodySlideMsConv>
                                            <TextSlideMsConv>
                                                {(() => {
                                                    if (child.title) {
                                                        return (
                                                            <TitleSlideMsConv>{child.title}</TitleSlideMsConv>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (child.subtitle) {
                                                        return (
                                                            <SubTitleSlideMsConv>{child.subtitle}</SubTitleSlideMsConv>
                                                        )
                                                    }
                                                })()}
                                            </TextSlideMsConv>
                                            {(() => {
                                                if (child.buttons.length) {
                                                    let buttonsslide = child.buttons
                                                    return buttonsslide.map((button, a) => {
                                                        return (
                                                            <Button012 key={a}>{button.title}</Button012>
                                                        )
                                                    })
                                                }
                                            })()}
                                        </BodySlideMsConv>
                                    </ContentSlideMsConv>
                                </SlideMessengerConv>
                            )
                        })
                    })()}
                </Slider>
            </div>
        );
    }
}