import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


function ParserTemplComponentsUserListWA(props) {
    const properties = props
    const focusmessage = properties.code
    const  message = props.message

    return (
        <>
             {(() => {
                    if (focusmessage) {
                        if (focusmessage.type === "text") {
                            return (focusmessage.body)
                        }
                        else if (focusmessage.type === "document") {
                            return (<>Ha inviato un allegato</>)
                        }
                        else if (focusmessage.type === "image") {
                            return (<>Ha inviato un'immagine</>)
                        }
                        else if (focusmessage.type === "video") {
                            return (<>Ha inviato un video</>)
                        }
                        else if (focusmessage.type === "audio" || focusmessage.type === "ptt") {
                            return (<>Ha inviato un audio</>)
                        }
                        else if (focusmessage.type === "location") {
                            return (<>Ha inviato una posizione</>)
                        }
                        else if (focusmessage.type === "contacts") {
                            return (<>Ha inviato un contatto</>)
                        }
                        else {
                            return (<>formato non disponibile</>)
                        }
                    }
                    else {
                        if (message.platforms.chatapi && message.platforms.chatapi.message_data) {
                            let focusmessage =  JSON.parse(message.platforms.chatapi.message_data)
                            if (focusmessage.type === "text") {
                                return (focusmessage.body)
                            }
                            else if (focusmessage.type === "document") {
                                return (<>Ha inviato un allegato</>)
                            }
                            else if (focusmessage.type === "image") {
                                return (<>Ha inviato un'immagine</>)
                            }
                            else if (focusmessage.type === "video") {
                                return (<>Ha inviato un video</>)
                            }
                            else if (focusmessage.type === "audio" || focusmessage.type === "ptt") {
                                return (<>Ha inviato un audio</>)
                            }
                            else if (focusmessage.type === "location") {
                                return (<>Ha inviato una posizione</>)
                            }
                            else if (focusmessage.type === "contacts") {
                                return (<>Ha inviato un contatto</>)
                            }
                            else {
                                return (<>formato non disponibile</>)
                            }
                        }
                        return (<>formato non disponibile</>)
                    }
            })()}
       </>
    )
}

export default ParserTemplComponentsUserListWA