import React, {useState, useEffect, useRef} from "react";
import {sendMessageConsole} from "../../../../../hooks/ConversationProvider";

import {
    BusinessListLanguages,
    BusinessMessageBar,
    BusinessMessageBarContent,
    BusinessMenuActor,
    CurrentModelMessagePack,
    BoxCurrentActor,
    NameModelMessagePack,
    ButtonDeleteMessagePack,
    ButtonSendModel,
    WrapperCurrentModelMessagePack,
    ContentWrapperCurrentModelMsgPack,
    WrapperListMenuActor,
    CurrentActorItem,
    ListMenuActor,
    TitleMenuActor
} from "../../../../../styles/businessConversation"

import Send01 from "../../../../../source/svg/Send01";

import {ButtonRoundIcon} from "../../../../../styles/Commons";

import SvgCLose01 from "../../../../../source/svg/SvgClose01";

function BusMessageBar(props) {
    const properties = props
    const app = properties.app
    const messagebar= useRef(null)
    const [currentActor, setCurrentActor] = useState(false)
    const [actorList, setActorList] = useState([])
    const [menuActor, setMenuActor] = useState(false)
    const [currentModel, setCurrentModel] = useState(false)

    useEffect(() => {
        let arractorlist = properties.actors
        let actormainapp
        let actortarget
        if (app.conversations && app.conversations.main_actor_id) {
            actormainapp= app.conversations.main_actor_id
        }
        if (actormainapp) {
            actortarget = actormainapp
        }
        else {
            actortarget = {
                name: app.public_name,
                image: app.image,
                _id: app._id,
                default:true,
            }
        }
        if (!currentActor) {
            setCurrentActor(actortarget)
        }
        if (!actormainapp) {
            arractorlist.push(actortarget)
        }
        setActorList(arractorlist)

        setCurrentModel(null)
        props.setResultModalSystem(false)

    }, []);




    useEffect(() => {
        setCurrentModel(false)
        props.setResultModalSystem(false)
    }, [properties.optLangConversation]);


    /*useEffect(() => {
        let pos = actorList.map(function(e) { return e.actor_id; }).indexOf(api._id);
        if(pos === -1) {
            let actortarget = {
                name: api.public_name,
                image: api.image,
                id: api._id,
                default:true,
            }
            setActorList(prevArray => [...prevArray, actortarget])
        }
    }, [actorList]);*/


useEffect(() => {
    setCurrentModel(false)
    props.setResultModalSystem(false)
}, [props.currentConversation]);


useEffect(() => {
    if (props.resultModalSystem && props.resultModalSystem.target === "business-message-bar" && props.resultModalSystem.model && props.resultModalSystem.model.code) {
       setCurrentModel({name:props.resultModalSystem.model.name, code:props.resultModalSystem.model.code})
    }
}, [props.resultModalSystem]);




        const actionchoosemodel = () => {
            properties.setModalSystem({show:true, source:"business-message-bar", action:"choose-model-conversation", data:{app:app}})
        }

        const removeCurrentModel = () => {
            setCurrentModel(false)
            props.setResultModalSystem(false)
        }

        const editCurrentModel = async (e) => {
            properties.setModalSystem({show:true, source:"business-message-bar", action:"edit-model-conversation", data:{app:app, model:currentModel}})
        }

        const actionMenuActor = async (e) => {

                if (menuActor) setMenuActor(false)
                else setMenuActor(true)
        }

        const actionCurrentActor = async (e, actor) => {
            e.preventDefault()
            e.stopPropagation()

            let newactor = {
                name: actor.name,
                image: actor.image,
                _id: actor._id
            }

            setCurrentActor(newactor)
            setMenuActor(null)
        }

const actionSend = async (e) => {
    e.stopPropagation()
    e.preventDefault()
    let user_conversation

    if (properties.currentConversation.user) {
        user_conversation = properties.currentConversation.user._id
    }
    else {
        user_conversation = properties.currentConversation.guest._id
    }
    let objMessage = {
        app_id:properties.app._id,
        user_conversation:user_conversation,
        url_lang:properties.optLangConversation,
        body: {
            message:currentModel.code
        }
    }
    if (!currentActor.default) {
        objMessage.actor_id = currentActor._id
    }
    let runmessage = await sendMessageConsole(objMessage)
    if (runmessage.error) {
        properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
    }
    setCurrentModel(false)
    props.setResultModalSystem(false)
}
return (
<>
  {(() => {
        return (
            <BusinessMessageBar  >
                <>
                    {(() => {
                        if (currentActor) {
                            return (
                                <>
                                    {(() => {
                                        if (menuActor) {
                                            return(
                                                <BusinessMenuActor>
                                                    <TitleMenuActor>Scegli con chi rispondere:</TitleMenuActor>
                                                    <WrapperListMenuActor>
                                                        <CurrentActorItem>
                                                            <img style={{width:"40px"}} alt="" src={currentActor.image} />
                                                            <span>{currentActor.name}</span>
                                                        </CurrentActorItem>
                                                        <ListMenuActor>
                                                            {(() => {
                                                                return actorList.map((child, i) => {

                                                                    if (child._id.toString() != currentActor._id) {
                                                                        return (
                                                                            <li onClick={e => actionCurrentActor(e,child)} key={i}>
                                                                                <img style={{width:"40px"}} alt="" src={child.image} />
                                                                                {child.name}
                                                                            </li>
                                                                        )
                                                                    }
                                                                })
                                                            })()}
                                                        </ListMenuActor>
                                                    </WrapperListMenuActor>

                                                </BusinessMenuActor>
                                            )
                                        }
                                    })()}
                                <BusinessMessageBarContent >

                                    <BoxCurrentActor onClick={actionMenuActor}>
                                        <img style={{width:"40px"}} alt="" src={currentActor.image} />
                                    </BoxCurrentActor>
                                    <>
                                        {(() => {
                                            if (currentModel) {
                                                return (
                                                    <>
                                                    <ContentWrapperCurrentModelMsgPack>
                                                        <WrapperCurrentModelMessagePack>
                                                            <CurrentModelMessagePack>
                                                                <NameModelMessagePack>{currentModel.name}</NameModelMessagePack>
                                                                <ButtonDeleteMessagePack onClick={removeCurrentModel}><SvgCLose01></SvgCLose01></ButtonDeleteMessagePack>
                                                            </CurrentModelMessagePack>
                                                        </WrapperCurrentModelMessagePack>
                                                    </ContentWrapperCurrentModelMsgPack>
                                                    <ButtonSendModel title="invia" onClick={actionSend} ><Send01/> </ButtonSendModel>
                                                    </>
                                                )
                                            }
                                            else {
                                                return(
                                                        <div onClick={actionchoosemodel}><p>Rispondi come {currentActor.name} su Botmama....</p></div>
                                                    )
                                            }
                                        })()}

                                    </>

                                </BusinessMessageBarContent>
                                </>
                            )
                        }
                    })()}
                </>
            </BusinessMessageBar>
        )

  })()}

</>
);
}

export default BusMessageBar;
