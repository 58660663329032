import React from 'react';

const SvgShare01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path fill={props.fill} d="M366.3,341.2c-14.2,0-27.5,4.1-38.6,11.2L215,273.8c1.8-6.3,2.8-13,2.8-19.9c0-6.9-1-13.6-2.8-19.9l109.6-76.5
			c11.8,8.4,26.1,13.3,41.7,13.3c39.8,0,72-32.3,72-72c0-39.8-32.3-72-72-72s-72,32.3-72,72c0,5.3,0.6,10.4,1.6,15.3l-112.4,78.5
			c-11-6.8-23.9-10.7-37.7-10.7c-39.8,0-72,32.3-72,72s32.3,72,72,72c13.8,0,26.8-3.9,37.7-10.7l113.3,79.1
			c-1.6,6-2.5,12.3-2.5,18.9c0,39.8,32.3,72,72,72s72-32.3,72-72C438.3,373.5,406.1,341.2,366.3,341.2z"/>
    </svg>
);

export default SvgShare01;