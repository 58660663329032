import React from 'react';

const Language01 = props => (
    <svg
        viewBox="0 0 460 460"
    >
        <path fill={props.color} d="M304.8,226.7c0,17-0.9,33.4-2.5,49.1H160.2c-1.6-15.6-3.2-32-3.2-49.1s1.6-33.4,3.2-49.1h142.1
	C303.9,193.3,304.8,209.7,304.8,226.7z M421.2,177.7c4.1,15.7,6.2,32.1,6.2,49.1s-2.1,33.3-6.2,49.1h-94.4
	c1.6-15.8,2.5-32.9,2.5-49.1c0-16.9-0.8-33.3-2.5-49.1H421.2z M413.2,153.1h-89.5c-7.7-48.9-22.8-90-42.4-116.2
	C341.5,52.8,390.2,96.4,413.2,153.1z M298.9,153.1H163.5c4.7-27.9,11.9-52.6,20.7-72.6c8-18.1,17-31.2,25.7-39.5
	c8.6-8.1,15.7-10.6,21.3-10.6c5.6,0,12.7,2.4,21.3,10.6c8.7,8.3,17.6,21.4,25.7,39.5C287,100.5,294.2,125.2,298.9,153.1L298.9,153.1
	z M49.3,153.1C72.2,96.4,121,52.8,181.1,37c-19.5,26.2-34.7,67.2-42.4,116.2H49.3z M135.6,177.7c-1.6,15.8-3.1,32.2-3.1,49.1
	c0,16.2,1.5,33.3,3.1,49.1H41.2c-4-15.7-6.2-32.1-6.2-49.1s2.1-33.3,6.2-49.1H135.6z M184.2,372.8c-8.8-19.9-16-44.6-20.7-72.5
	h135.4c-4.7,27.9-11.9,52.6-20.7,72.5c-8,18.2-17,31.3-25.7,39.6c-8.6,8.1-15.7,10.6-22,10.6c-4.9,0-12-2.5-20.6-10.6
	C201.3,404.1,192.3,391,184.2,372.8L184.2,372.8z M181.1,416.5C121,400.6,72.2,357.1,49.3,300.3h89.4
	C146.4,349.3,161.6,390.3,181.1,416.5L181.1,416.5z M281.4,416.5c19.5-26.2,34.7-67.2,42.4-116.2h89.5
	C390.2,357.1,341.5,400.6,281.4,416.5L281.4,416.5z"/>
    </svg>
);

export default Language01;