export const validateEmail =(email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const generateCode=(length)=> {
    var result           = '';
    var characters       = '0123456789abcdefghilmnopqrstuvz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;

}
export const validatePhone =(phoneNumber) =>
{
    var regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if(phoneNumber.match(regEx)) return true;
    else return false;
}

export const unique = (a) => {
    a.sort();
    for (var i = 1; i < a.length;) {
        if (a[i - 1] == a[i]) {
            a.splice(i, 1);
        } else {
            i++;
        }
    }
    return a;
}


export const years=(month, day)=> {
    var max = new Date().getFullYear()
    var min = max - 120
    var years = []

    console.log(month)
    console.log(day)

    if (month === "2" && day === "29") {
        var min = max - 118
        for (var i = max; i >= min; i--) {
            if (!(i % 4)) {
                years.push(i)
            }

        }
    }
    else {
        for (var i = max; i >= min; i--) {
            years.push(i)
        }
    }


    return years

}

export const daysMonth=(month)=> {

    let arrReturn = []
    let lenght
    switch(month) {
        case "1": case "3": case "5": case "7": case "8": case "10": case "12":
            lenght = 32;
            break
        case "4": case "6": case "9": case "11":
            console.log("QUI")
            lenght = 31;
            break
        case "2":
            lenght = 30;
            break
        default:
            lenght = 32;
    }
    for (let i = 1; i < lenght; i++) {
        arrReturn.push(i.toString())
    }

    return arrReturn
}

export const validatePassword =(password) => {
    const minLength = 12;
    const hasNumber = /\d/;
    const hasUpper = /[A-Z]/;
    const hasLower = /[a-z]/;
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < minLength) {
        return false;
    }

    if (!hasNumber.test(password)) {
        return false;
    }

    if (!hasUpper.test(password)) {
        return false;
    }

    if (!hasLower.test(password)) {
        return false;
    }

    if (!hasSpecial.test(password)) {
        return false;
    }

    return true;
}