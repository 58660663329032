import React, {useState, useEffect, useRef} from "react";

import {getLegalConditions} from "../../../../hooks/AppProvider";
import {getInfoCrmPreviewUserChat} from "../../../../hooks/UserchatProvider";

import SvgCard01 from "../../../../source/svg/SvgCard01";
import {
    BoxMainInfoUser,
    PanelInfoUserCrmPreview,
    ListInfoUserCrmPreview,
    ItemInfoUserCrmPreview,
    PanelAddInfoUserCrmPreview,
    BusinessUserConvDescriptionService
} from "../../../../styles/businessConversation"
import SvgMail01 from "../../../../source/svg/SvgMail01";
import SvgCard02 from "../../../../source/svg/SvgCard02";
import SvgTelephone01 from "../../../../source/svg/SvgTelephone01";
import SvgCellphone01 from "../../../../source/svg/SvgCellphone01";
import SvgNote01 from "../../../../source/svg/SvgNote01";
import SvgNote02 from "../../../../source/svg/SvgNote02";
import {Button01} from "../../../../styles/Commons";
import Main_settings from "../../../../styles/main_settings/Main_settings";
import Loader from "../../../../utils/Loader";


function BusInfoUser(props) {
    const [infoCrmUser, setInfoCrmUser] = useState(false)
    const [loadCrmData, setLoadCrmData] = useState(false)


    useEffect(() => {
        checkInfoCrmUser()
    }, []);


    useEffect(() => {
        if (infoCrmUser) setInfoCrmUser(false)
        checkInfoCrmUser()
    }, [props.userChat]);

    useEffect(() => {
        if (props.resultModalSystem && props.resultModalSystem.target === "business-info-user") {
            checkInfoCrmUser()
        }

    }, [props.resultModalSystem]);

    const checkInfoCrmUser = async (e) => {
        setLoadCrmData(true)
        let callcheckinfocrmuser = await getInfoCrmPreviewUserChat({sender:props.userChat.sender, app_id:props.app._id})
        setLoadCrmData(false)
        if (!callcheckinfocrmuser.error) {
            if (callcheckinfocrmuser.data) setInfoCrmUser(callcheckinfocrmuser.data)
        }

    }

    const showDataUser = async (e) => {
        props.setModalSystem({show:true, source:"businfouser", action:"data-info-add-user", data:{userChat:props.userChat, app:props.app, userDataDb:infoCrmUser}})
    }

    const checkTerms = async (e) => {
        let callcheckterm = await getLegalConditions({app_id:props.app._id, conditions:"add_info_users"})
        if (!callcheckterm.error) {

            if (callcheckterm.success) {
                props.setModalSystem({show:true, source:"businfouser", action:"data-info-add-user", data:{userChat:props.userChat, app:props.app, userDataDb:infoCrmUser}})
            }
            else {
                props.setModalSystem({show:true, source:"businfouser", action:"show-terms-info-user", data:{app:props.app, userChat:props.userChat}})
            }
        }
        else {
            props.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }
    }
    return (
        <>
            <BoxMainInfoUser>
                {(() => {
                    if (loadCrmData) {
                        return (
                            <Loader width="25" height="25" color={Main_settings.color01} secondaryColor={Main_settings.color01} type="03" />
                        )
                    }
                    else {
                        if (infoCrmUser && (infoCrmUser.crm_data.nominative || infoCrmUser.crm_data.email || infoCrmUser.crm_data.telephone || infoCrmUser.crm_data.cellphone || infoCrmUser.crm_data.note)) {
                            let nominativetext = "+Nominativo"
                            let emailtext = "+Email"
                            let teltext = "+Telefono"
                            let celltext = "+Cellulare"
                            let notetext = "+Note"
                            if (infoCrmUser.crm_data.nominative) nominativetext = infoCrmUser.crm_data.nominative
                            if (infoCrmUser.crm_data.email) emailtext = <a href={`mailto:${infoCrmUser.crm_data.email}`}>{infoCrmUser.crm_data.email}</a>
                            if (infoCrmUser.crm_data.telephone) teltext = <a href={`tel:${infoCrmUser.crm_data.telephone}`}>{infoCrmUser.crm_data.telephone}</a>
                            if (infoCrmUser.crm_data.cellphone) celltext = <a href={`tel:${infoCrmUser.crm_data.cellphone}`}>{infoCrmUser.crm_data.cellphone}</a>
                            if (infoCrmUser.crm_data.note) notetext = infoCrmUser.crm_data.note
                            return (
                                <>
                                    <PanelInfoUserCrmPreview>
                                        <ListInfoUserCrmPreview>
                                            <ItemInfoUserCrmPreview><SvgCard02 /> {nominativetext}</ItemInfoUserCrmPreview>
                                            <ItemInfoUserCrmPreview><SvgMail01 />{emailtext}</ItemInfoUserCrmPreview>
                                            <ItemInfoUserCrmPreview><SvgTelephone01 />{teltext}</ItemInfoUserCrmPreview>
                                            <ItemInfoUserCrmPreview><SvgCellphone01 />{celltext}</ItemInfoUserCrmPreview>
                                            <ItemInfoUserCrmPreview><SvgNote02 /> {notetext}</ItemInfoUserCrmPreview>

                                        </ListInfoUserCrmPreview>
                                        <Button01 onClick={showDataUser}>Modifica</Button01>
                                    </PanelInfoUserCrmPreview>
                                </>
                            )
                        }
                        else {
                            return(
                                <>
                                    <PanelAddInfoUserCrmPreview>
                                        <BusinessUserConvDescriptionService>Aggiungi dettagli sul questo utente, come le informazioni di contatto.</BusinessUserConvDescriptionService>
                                        <Button01 onClick={checkTerms}>Aggiungi</Button01>
                                    </PanelAddInfoUserCrmPreview>
                                </>
                            )
                        }
                    }


                })()}
            </BoxMainInfoUser>
        </>
    )

}

export default BusInfoUser