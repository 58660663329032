import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ImageUserChat from "./ImageUserChat";
import moment from "moment";


function ParserTemplComponentsUser(props) {
    const properties = props
    const focusmessage = JSON.parse(properties.code)
    const message=  properties.message


    let nameuser


    if (properties.userchat.guest) {
        nameuser = "User " + properties.userchat.sender
    }
    else {
        nameuser = properties.userchat.name
    }
    let hourdate = moment(message.date.date).format('HH: mm');


    return (
        <div className="viewdefault_row_chat">
            <div title={nameuser} className="viewdefault_opt_msg_picture">
                <ImageUserChat
                    user={properties.userchat}
                />
            </div>
            <div title={hourdate} className="viewdefault_message_wrapper viewdefault_message_wrapper_user">
                {(() => {
                if (focusmessage.type != "form") {
                    //console.log(focusmessage.type)
                    if (focusmessage.type === "file") {
                        return (
                            <div className="container_message">
                                {(() => {
                                    const listFiles = focusmessage.body
                                    return listFiles.map((child, i) => {
                                        return (
                                            <div key={i} className="user_message_file user_message">
                                                <div className="user_message_file_info">
                                                    <div className="user_message_file_icon">
                                                        <FontAwesomeIcon icon={["fas", "file-download"]}/>
                                                    </div>
                                                    <p className="user_message_type">{child.type} {child.format}</p>
                                                </div>

                                                {(() => {
                                                    if (child.caption) {
                                                        return (
                                                            <p className="user_message_file_message">{child.caption}</p>
                                                        )
                                                    }

                                                })()}
                                            </div>
                                        )
                                    })
                                })()}
                            </div>
                        )
                    }
                    if (focusmessage.type === "image") {
                        return (
                            <div className="container_message">
                                {(() => {
                                    const listImages = focusmessage.body
                                    return listImages.map((child, i) => {
                                        return (
                                            <div key={i} className="user_message_image user_message">
                                                <img width="100px" alt="" src={child.url}/>
                                                {(() => {
                                                    if (child.caption) {
                                                        return (
                                                            <p className="user_message_image_message">{child.caption}</p>
                                                        )
                                                    }

                                                })()}
                                            </div>
                                        )
                                    })
                                })()}
                            </div>
                        )
                    }
                    if (focusmessage.type === "video") {
                        return (
                            <div className="container_message">
                                {(() => {
                                    const listVideos = focusmessage.body
                                    return listVideos.map((child, i) => {
                                        return (
                                            <div key={i} className="user_message_video user_message">
                                                <video controls >
                                                    <source type={child.format} src={child.url} />
                                                </video>
                                                {(() => {
                                                    if (child.caption) {
                                                        return (
                                                            <p className="user_message_video_message">{child.caption}</p>
                                                        )
                                                    }

                                                })()}
                                            </div>
                                        )
                                    })
                                })()}
                            </div>
                        )
                    }
                    if (focusmessage.type === "position") {
                        let lat = focusmessage.body.lat
                        let lng = focusmessage.body.lng
                        let linkframe = "https://maps.google.com/maps?q=" + lat + "," + lng + "&hl=en&z=14&output=embed"
                        let linkref= "https://www.google.com/maps/dir//" + lat + "," + lng
                        return (
                            <div className="container_message">
                                <div className="user_message_position user_message">
                                    <div className="user_message_position_map">
                                        <div className="user_message_position_icon">
                                            <FontAwesomeIcon icon={["fas", "map-marker-alt"]}/>
                                            <p className="user_message_type">{focusmessage.type}</p>
                                        </div>
                                    </div>
                                    {(() => {
                                        if (focusmessage.body.caption) {
                                            return (
                                                <p className="user_message_position_message">{focusmessage.body.caption}</p>
                                            )
                                        }

                                    })()}
                                </div>
                            </div>
                        )
                    }
                    if (focusmessage.type === "camera") {
                        return (
                            <div className="container_message">
                                {(() => {
                                    const listItem = focusmessage.body
                                    return listItem.map((child, i) => {
                                        if (child.type === "camera/image") {
                                            return (
                                                <div key={i} className="user_message_image user_message">
                                                    <img width="100px" alt="" src={child.url}/>
                                                    {(() => {
                                                        if (child.caption) {
                                                            return (
                                                                <p className="user_message_image_message">{child.caption}</p>
                                                            )
                                                        }

                                                    })()}
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div key={i} className="user_message_video user_message">
                                                    <video controls >
                                                        <source type={child.format} src={child.url} />
                                                    </video>
                                                    {(() => {
                                                        if (child.caption) {
                                                            return (
                                                                <p className="user_message_video_message">{child.caption}</p>
                                                            )
                                                        }

                                                    })()}
                                                </div>
                                            )
                                        }
                                    })
                                })()}
                            </div>
                        )

                    }
                    if (focusmessage.type === "audiorecording") {

                        return (
                            <audio controls>
                                <source src={focusmessage.body} />
                            </audio>
                        )
                    }
                    else {
                        return (
                            <div className="container_message">
                                <div className="user_message_text user_message">
                                    <p> {(focusmessage.type === "button-callback") ? focusmessage.body.text_button : focusmessage.body  }</p>
                                </div>
                            </div>
                        )
                    }
                }
                else {

                    return null
                }
                })()}
            </div>
         </div>
    )
}

export default ParserTemplComponentsUser