import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import cio from 'cheerio-without-node-native';
import {Button01} from "../styles/Commons";
import {BoxButtonFormModel, BoxModelButtonFormModel, InputForm01} from "../styles/Modals";

function ParserTemplComponentsModels(props) {
    const properties = props
    const html = properties.code
    const [objForm, setObjForm] = useState([])
    const [objFormButtonUrl, setObjFormButtonUrl] = useState([])
    const [urlbuttonURL, setUrlButtonURL] = useState("")
    const [urlbuttonTEXT, setUrlButtonTEXT] = useState("")
    const [changeObj, setChangeObj] = useState("")







    const onSubmit = (e) => {
        e.preventDefault();
        if (objForm.length) {
            const $ = cio.load(html);
            let namemodel = properties.nameModel
            for (let a = 0; a < objForm.length; a++) {
                if (objForm[a].type === "text") {
                    let element = $(`[name=${objForm[a].name}]`)
                    if (element.length) {
                        for (let i = 0; i < element.length; i++) {
                            let thisEl= element[i]
                            let content = objForm[a].value
                            let classname = objForm[a].class
                            $(thisEl).replaceWith(`<${objForm[a].element}>${content}</${objForm[a].element}>`)
                        }
                    }
                }
                else if (objForm[a].type === "buttonUrl") {
                    let element = $(`[name=${objForm[a].name}]`)
                    if (element.length) {
                        for (let i = 0; i < element.length; i++) {
                            let thisEl= element[i]
                            let content = objForm[a].valueText
                            let url =  objForm[a].valueUrl
                            let classname = objForm[a].class
                            $(thisEl).replaceWith(`<div data-mama='button' class="${classname}" type='url' changewindow='true' url='${url}'><span type="fontawesome" icon="fas,link" data-mama="icon"></span> ${content}</div>`)
                        }
                    }
                }

            }
            let htmlreplaced= $('body').html()
            properties.setResultModalSystem({target:"business-message-bar", model:{name:namemodel, code:htmlreplaced}})
            properties.setModalSystem({show:false, action:null, source:null, data:null})
        }

    };


    const onChangeElementText = (e) => {
        try {
            e.stopPropagation()
            let copyObjForm= objForm

            if (e.target.value === "") {
                if (copyObjForm.length) {
                    let indexreq = copyObjForm.map(function(e) { return e.name; }).indexOf(e.target.name);
                    if (indexreq !== -1) {
                        copyObjForm.splice(indexreq, 1);
                        setObjForm(prevArray => copyObjForm)
                    }
                }
            }
            else {
                let inputData = {
                    name: e.target.name,
                    type:"text",
                    value:  e.target.value,
                    element: e.target.getAttribute("transform")
                }
                if (e.target.classList.value) inputData.class = e.target.classList.value
                if (copyObjForm.length) {
                    let index = copyObjForm.findIndex(obj => obj.name === e.target.name);
                    if (index !== -1) {
                        copyObjForm.splice(index, 1, inputData);
                    }
                }
                else {
                    copyObjForm.push(inputData)
                }
                setObjForm(copyObjForm)
            }
            setChangeObj(new Date())
        }
        catch (e) {

        }
        //e.preventDefault()

    }


    const onChangeElementButtonUrl = (e, type) => {
        try {
            e.stopPropagation()


            if (e.target.value === "") {
                if (objForm.length) {
                    let indexreq = objForm.map(function(e) { return e.name; }).indexOf(e.target.name);
                    if (indexreq !== -1) {
                        let deletecomplete = 1
                        let currentobj = objForm.find(obj => obj.name === e.target.name);
                        if (currentobj.valueText && currentobj.valueUrl) deletecomplete = 0
                        else {
                            if (currentobj.valueUrl && type === "text")  deletecomplete = 0
                            if (currentobj.valueText && type === "url")  deletecomplete = 0
                        }
                        if (deletecomplete) {
                            objForm.splice(indexreq, 1);
                        }
                        else {
                            if (type === "text") delete currentobj.valueText
                            if (type === "url") delete currentobj.valueUrl
                            objForm.splice(indexreq, 1, currentobj);

                        }
                        setObjForm(prevArray => objForm)
                    }
                }
            }
            else {
                let inputdata = {
                    name: e.target.name,
                    type: "buttonUrl"
                }
                if (e.target.classList.value) {
                    inputdata.class = e.target.classList.value
                }
                if (type === "text") {
                    inputdata.valueText = e.target.value
                }
                else {
                    inputdata.valueUrl = e.target.value
                }
                if (objForm.length) {
                    let index = objForm.findIndex(obj => obj.name === e.target.name);
                    if (index !== -1) {
                        let currentobj = objForm.find(obj => obj.name === e.target.name);
                        if (type === "text" && currentobj.valueUrl) inputdata.valueUrl = currentobj.valueUrl
                        if (type === "url" && currentobj.valueText) inputdata.valueText = currentobj.valueText
                        objForm.splice(index, 1, inputdata);
                    }
                    else {
                        objForm.push(inputdata)
                    }
                    setObjForm(objForm)

                }
                else {
                    let newObj= [inputdata]
                    setObjForm(newObj)
                }

            }

            setChangeObj(new Date())
        }
        catch (error) {

        }
        //e.preventDefault()

    }



    return (
       <>
           <form onSubmit={onSubmit}>
               {
                   ReactHtmlParser(html, {
                       transform(node, index) {
                           let attribs = node.attribs
                           if (attribs && attribs["data-mama"]) {
                               if (attribs["data-mama"] === "model-insert") {
                                    if (attribs["type"] === "modelText") {
                                        if (attribs["required"] === "true") {
                                            return (
                                                <textarea className={attribs["class"]} key={index} required type="text" transform={attribs["transform"]} onChange={onChangeElementText} name={attribs["name"]} placeholder={attribs["placeholder"]} />
                                            )
                                        }
                                        else {
                                            return (
                                                <textarea className={attribs["class"]} key={index} type="text" transform={attribs["transform"]}  onChange={onChangeElementText} name={attribs["name"]} placeholder={attribs["placeholder"]} />
                                             )
                                        }
                                    }
                                   if (attribs["type"] === "modelButton") {
                                       if (attribs["variant"] === "url") {
                                           if (attribs["required"] === "true") {
                                               return (
                                                   <>
                                                       <BoxModelButtonFormModel key={index}>
                                                           <InputForm01 className={attribs["class"]} required type="text"  onChange={e => onChangeElementButtonUrl(e, "text")} name={attribs["name"]} placeholder="Inserisci il testo del bottone" />
                                                           <InputForm01 className={attribs["class"]} required type="text" onChange={e => onChangeElementButtonUrl(e, "url")} name={attribs["name"]} placeholder="Inserisci l'url del bottone" />
                                                       </BoxModelButtonFormModel>
                                                   </>
                                               )
                                           }
                                       }

                                   }


                               }
                           }
                       }
                   })
               }
               <BoxButtonFormModel>
                <Button01 type="submit" >Conferma</Button01>
               </BoxButtonFormModel>
           </form>
       </>
    )
}

export default ParserTemplComponentsModels