import React, {useState, useEffect} from "react";
import Switch from "react-switch";
import {

    BusinessUserConversationWrapper,
    BusinessUserConversationImage,
    BusinessUserConversationInfo,
    BusinessUserConversationSection,
    BusinessUserConversationAutoReply,
    BusinessUserConversationCrmPreview,
    BusinessUserConversationOtherActions,
    BusinessUserConvDescriptionService
} from "../../../../../styles/businessConversation";

import {Button01} from "../../../../../styles/Commons";
import {getApp, setStandbyUserMS} from "../../../../../hooks/AppProvider";
import ImageUserChat from "../../../../../utils/ImageUserChat";
import Main_Settings from "../../../../../styles/main_settings/Main_settings";

import BusInfoUser from "../BusInfoUser"
import BusMessageBar from "../Messagebar/BusMessageBar";

function BusTargetConversationUserMS(props) {
    const properties = props
    const [userChat, setUserChat] = useState(false)
    const [switchControlUser, setSwitchControlUser] = useState(false)

    const standbyapp = props.optStandbyApp
    const heightbar= props.heightBar

    useEffect(() => {
        if (props.optStandbyApp && switchControlUser) {
            setSwitchControlUser(false)
        }

    }, []);



    useEffect(() => {

    }, [properties.conversation]);


    useEffect(() => {
        if (props.optStandbyApp) {
            setSwitchControlUser(false)
        }
        else {
            setSwitchControlUser(true)
        }
    }, [standbyapp]);

    const changestandby = async  () => {
        if (!standbyapp) {
            actionSwitch()
        }
    }



    const actionSwitch = async() => {
        let standby
        if (switchControlUser) standby="true"
        else standby= "false"
        let actionstandbydb= await setStandbyUserMS({
            app_name:properties.app.name,
            standby_app:standbyapp,
            standby_user:standby,
            user_conversation: userChat._id
        })
        if (!actionstandbydb.error) {
            if (switchControlUser) {
                setSwitchControlUser(false)
            }
            else {
                setSwitchControlUser(true)
            }
            properties.setApp(actionstandbydb.app)
        }
        else {
            properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }
    }



    useEffect(() => {
        if (properties.conversation) {
            if (properties.conversation.user) {
                setUserChat(properties.conversation.user)
            }
            else {
                setUserChat(properties.conversation.guest)
            }
        }

    }, [properties.conversation]);


    useEffect(() => {
        if (userChat && !standbyapp) getstateuser(properties.app)

    }, [userChat]);

    useEffect(() => {
        if (props.updateUserWhatsApp) {

            goApp()
        }


    }, [props.updateUserWhatsApp]);


    async function goApp() {
        let callapp = await getApp(props.app.name);
        if (callapp && !callapp.error) {
            props.setApp(callapp.app)
            getstateuser(callapp.app)
        }
    }

    const exportConversation = () => {
        let currentcontosend =
        props.setModalSystem({show:true, source:"business-option-filter", action:"export-conversation", data:{app:props.app, platform:"Messenger", conversation_target:props.conversation.conversations, user:props.conversation.user}})
    }


    const getstateuser = async  (app) => {
        const usersdbstandby = app.external.platforms.messenger.state.standby.users
        let userindex = usersdbstandby.indexOf(userChat._id)
        if (userindex != -1) {
            if (switchControlUser) setSwitchControlUser(false)
        }
        else {
            if (!switchControlUser) setSwitchControlUser(true)
        }
    }


  return (
      <>
          {(() => {
              if (userChat) {
                  let info
                  if (userChat.name) {
                      info = userChat.name
                  }
                  else {
                      info = "User " + userChat.sender
                  }
                  //console.log(switchControlUser)
                  return (
                      <>
                      <BusinessUserConversationImage>
                        <ImageUserChat user={userChat} />
                      </BusinessUserConversationImage>
                      <BusinessUserConversationInfo>
                            <p> {info}</p>
                      </BusinessUserConversationInfo>
                      <BusinessUserConversationSection>
                          <h3>Risposta bot</h3>
                          <BusinessUserConvDescriptionService> {(switchControlUser) ? "Disattiva " : "Attiva "} la risposta del bot su questo utente.</BusinessUserConvDescriptionService>
                          <BusinessUserConversationAutoReply>
                              <p>
                                  {(switchControlUser) ? " Disattiva " : " Attiva "}
                              </p>
                              <Switch
                                  checked={switchControlUser}
                                  onChange={changestandby}
                                  onColor={Main_Settings.color03}
                                  onHandleColor={Main_Settings.color02}
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                  height={20}
                                  width={48}
                                  id="material-switch"
                              />
                          </BusinessUserConversationAutoReply>
                      </BusinessUserConversationSection>
                      <BusinessUserConversationCrmPreview>
                        <h3>Dettagli aggiunti</h3>
                        <BusInfoUser
                            app={props.app}
                            userApp={props.user}
                            userChat={userChat}
                            platform={"WhatsApp"}
                            setModalSystem={props.setModalSystem}
                            setResultModalSystem={props.setResultModalSystem}
                            resultModalSystem={props.resultModalSystem}
                        />

                      </BusinessUserConversationCrmPreview>
                      <BusinessUserConversationOtherActions>
                        <h3>Conversazione</h3>
                          <h4>Esportazione</h4>
                          <BusinessUserConvDescriptionService>Esporta la conversazione in formato file o condividila con un servizio esterno.</BusinessUserConvDescriptionService>
                          <Button01 onClick={exportConversation}>Esporta</Button01>
                      </BusinessUserConversationOtherActions>
                  </>
                  )
              }
              else {
                  return(

                      <p>Non ci sono informazioni per questo utente</p>
                   )

              }
          })()}

      </>
  );
}

export default BusTargetConversationUserMS;
