import React, {useState} from "react";
import axios from "axios";
import Header from "../Header/Header";
import {callCreateApp} from "../../hooks/AppProvider"
import branch from "../../settings/branch"
import setting from "../../settings/setting"
function CreateApp(props) {
  const [InputName, setInputName] = useState("")
  const [InputImage, setInputImage] = useState("")
  const [InputCategory, setInputCategory] = useState("")
  const [InputTitle, setInputTitle] = useState("")
  const [InputDescription, setInputDescription] = useState("")
  const [InputCallbackUrl, setInputCallbackUrl] = useState("")


    const sendDataForm = async (e) => {
        e.preventDefault();
        let dataApp = {
            name: InputName,
            image: InputImage,
            category: InputCategory,
            title: InputTitle,
            description: InputDescription,
            callback_url: InputCallbackUrl,

        }

        let appcreate = await callCreateApp(dataApp)
        if (!appcreate.error) {
            let url = setting[branch.name].app_url + "/" + appcreate.app.name + setting[branch.name].development_area_url + setting[branch.name].development_settings_url
            window.location.href = url
        }
    };

  return (
      <>
          <Header showApp={{show:false}} setListApp={props.setListApp} listApp={props.listApp} user={props.user} setClearToken={props.setClearToken} setToken={props.setToken} />
          <form onSubmit={sendDataForm}>
            <p><input placeholder="nome app" type="text" name="name" value={InputName} onChange={e => setInputName(e.target.value)}/></p>
            <p><input placeholder="immagine app" type="text" name="image" value={InputImage} onChange={e => setInputImage(e.target.value)}/></p>
            <p><input placeholder="categoria app" type="text" name="category" value={InputCategory} onChange={e => setInputCategory(e.target.value)}/></p>
            <p><input placeholder="titolo app" type="text" name="title" value={InputTitle} onChange={e => setInputTitle(e.target.value)}/></p>
            <p><input placeholder="descrizione app" type="text" name="description" value={InputDescription} onChange={e => setInputDescription(e.target.value)}/></p>
            <p><input placeholder="url di callback" type="text" name="callbackurl" value={InputCallbackUrl} onChange={e => setInputCallbackUrl(e.target.value)}/></p>
            <input type="submit" value="invia" />
          </form>
       </>
  );
}

export default CreateApp;
