import React, {useEffect, useState} from "react";
import {callCreateApp} from "../../hooks/AppProvider";
import setting from "../../settings/setting";
import branch from "../../settings/branch";


function ModalCreateApp(props) {
    const [InputName, setInputName] = useState("")
    const [InputPublicName, setInputPublicName] = useState("")
    const [InputImage, setInputImage] = useState("")
    const [InputCategory, setInputCategory] = useState("")
    const [InputTitle, setInputTitle] = useState("")
    const [selectLangApp, setSelectLangApp] = useState("")
    const [InputDescription, setInputDescription] = useState("")
    const [InputCallbackUrl, setInputCallbackUrl] = useState("")
    const [showButtonSend, setShowButtonSend] = useState(false)

    const sendDataForm = async (e) => {
        e.preventDefault();
        let dataApp = {
            name: InputName,
            public_name:InputPublicName,
            image: InputImage,
            title: InputTitle,
            description: InputDescription,
            default_language:selectLangApp,
            callback_url: InputCallbackUrl

        }

        let appcreate = await callCreateApp(dataApp)
        if (!appcreate.error) {
            let url = setting[branch.name].app_url + "/" + appcreate.app.name + setting[branch.name].development_area_url + setting[branch.name].development_settings_url
            window.location.href = url
        }
    };

    useEffect(() => {
        console.log(InputName)
        if (InputName != ""
            && InputPublicName != ""
            && InputImage != ""
            && InputTitle != ""
            && InputDescription != ""
            && selectLangApp != ""
            && InputCallbackUrl != "") {
            console.log("qui")
            setShowButtonSend(true)
        }
        else {
            setShowButtonSend(false)
        }
    }, [InputName, InputPublicName, InputImage, InputTitle, InputDescription, selectLangApp, InputCallbackUrl]);

    return (
        <>
            <form onSubmit={sendDataForm}>
                <p><input placeholder="nome dominio app" type="text" name="name" value={InputName} onChange={e => setInputName(e.target.value)}/></p>
                <p><input placeholder="nome pubblico app" type="text" name="name" value={InputPublicName} onChange={e => setInputPublicName(e.target.value)}/></p>
                <p><input placeholder="url immagine app" type="text" name="image" value={InputImage} onChange={e => setInputImage(e.target.value)}/></p>
                <p><input placeholder="titolo app" type="text" name="title" value={InputTitle} onChange={e => setInputTitle(e.target.value)}/></p>
                <p><input placeholder="descrizione app" type="text" name="description" value={InputDescription} onChange={e => setInputDescription(e.target.value)}/></p>
                <p>
                    <label>Lingua principale</label>
                    <select defaultValue="first"  onChange={e => setSelectLangApp(e.target.value)}>
                        <option disabled value="first"></option>
                        <option value="it">it</option>
                        <option value="en">en</option>
                        <option value="fr">fr</option>
                        <option value="es">es</option>
                        <option value="de">de</option>
                    </select>
                </p>
                <p><input placeholder="url di callback" type="text" name="callbackurl" value={InputCallbackUrl} onChange={e => setInputCallbackUrl(e.target.value)}/></p>
                {(() => {
                    if (showButtonSend) {
                        return (
                                <input type="submit" value="invia" />
                            )

                    }

                })()}
            </form>
        </>
    )

}

export default ModalCreateApp