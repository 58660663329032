import React, {useState, useEffect, useRef} from "react"
import {setInfoCrmPreviewUserChat} from "../../hooks/UserchatProvider";
import setting from "../../settings/setting";
import branch from "../../settings/branch";
import {validateEmail, validatePhone} from "../../utils/utils";
import {
    WrapperAddDataInfoUser,
    FormAddDataInfoUser,
    ItemAddDataInfoUser,
    ItemButtonDataInfoUser

} from "../../styles/Modals";
import {Button01, Button012, Button02} from "../../styles/Commons";

function ModalAddDataInfoUser(props) {
    const [InputNominative, setInputNominative] = useState("")
    const [InputEmail, setInputEmail] = useState("")
    const [InputTelephone, setInputTelephone] = useState("")
    const [InputCellphone, setInputCellphone] = useState("")
    const [InputNote, setInputNote] = useState("")
    const [showButtonSend, setShowButtonSend] = useState(false)


    useEffect(() => {
        if (props.dataModal.data.userDataDb) {
            let datadb= props.dataModal.data.userDataDb
            if (datadb.crm_data.nominative) setInputNominative(datadb.crm_data.nominative)
            if (datadb.crm_data.email) setInputEmail(datadb.crm_data.email)
            if (datadb.crm_data.telephone) setInputTelephone(datadb.crm_data.telephone)
            if (datadb.crm_data.cellphone) setInputCellphone(datadb.crm_data.cellphone)
            if (datadb.crm_data.note) setInputNote(datadb.crm_data.note)
        }

    }, []);

    const closeModal = () => {
        props.setModalSystem({show:false, action:null, source:null, data:null})
    }

    const sendDataForm = async (e) => {
        e.preventDefault();
        let dataApp = {
            sender:props.dataModal.data.userChat.sender,
            app_id:props.dataModal.data.app._id
        }
        if (InputNominative != "") dataApp.nominative = InputNominative
        if (InputEmail != "") dataApp.email = InputEmail
        if (InputTelephone != "") dataApp.telephone = InputTelephone
        if (InputCellphone != "") dataApp.cellphone = InputCellphone
        if (InputNote != "") dataApp.note = InputNote

        let appcreate = await setInfoCrmPreviewUserChat(dataApp)
        if (!appcreate.error) {
            props.setModalSystem({show:false, action:null, source:null, data:null})
            props.setResultModalSystem({target:"business-info-user", action:"update"})
        }
    };

    useEffect(() => {
            let greenlight = true
            if (InputEmail) {
                let cheemail = validateEmail(InputEmail)
                if (!cheemail) greenlight = false
            }
            if (InputTelephone) {
                let checktelephone = validatePhone(InputTelephone)
                if (!checktelephone) greenlight = false
            }
            if (InputCellphone) {
                let checkcellphone = validatePhone(InputCellphone)
                if (!checkcellphone) greenlight = false
            }
            if (greenlight) setShowButtonSend(true)
            else  setShowButtonSend(false)


    }, [InputNominative, InputEmail, InputTelephone, InputCellphone, InputNote]);

    return (
        <>
            <WrapperAddDataInfoUser>
                <h1>Modifica dati di {props.dataModal.data.userChat.name}</h1>
                <FormAddDataInfoUser onSubmit={sendDataForm}>
                    <ItemAddDataInfoUser>
                        <label htmlFor="nominative">Nominativo</label>
                        <input className="itI4324ac" type="text" name="nominative" value={InputNominative} onChange={e => setInputNominative(e.target.value)}/>
                    </ItemAddDataInfoUser>
                    <ItemAddDataInfoUser>
                        <label htmlFor="email">Email</label>
                        <input className="itI4324ac"  type="text" name="email" value={InputEmail} onChange={e => setInputEmail(e.target.value)}/>
                    </ItemAddDataInfoUser>
                    <ItemAddDataInfoUser>
                        <label htmlFor="telephone">Telefono</label>
                        <input className="itI4324ac"  type="text" name="telephone" value={InputTelephone} onChange={e => setInputTelephone(e.target.value)}/>
                    </ItemAddDataInfoUser>
                    <ItemAddDataInfoUser>
                        <label htmlFor="cellphone">Cellulare</label>
                        <input className="itI4324ac" type="text" name="cellphone" value={InputCellphone} onChange={e => setInputCellphone(e.target.value)}/>
                    </ItemAddDataInfoUser>
                    <ItemAddDataInfoUser>
                        <label htmlFor="note">Note (massimo 130 caratteri)</label>
                        <textarea maxLength="130" className="itI4324ac itI4324at" type="text" name="note" value={InputNote} onChange={e => setInputNote(e.target.value)}/>
                    </ItemAddDataInfoUser>
                    <ItemButtonDataInfoUser><Button02 onClick={closeModal}>Annulla</Button02>
                    {(() => {
                        if (showButtonSend) {
                            return (
                                <Button01 type="submit" >Salva</Button01>
                            )
                        }
                        else {
                            return (
                               <Button012>Salva</Button012>
                            )
                        }
                    })()}
                    </ItemButtonDataInfoUser>
                </FormAddDataInfoUser>
            </WrapperAddDataInfoUser>
        </>
    )
}

export default ModalAddDataInfoUser

