import React from 'react';

const SvgDownload01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path fill={props.fill} d="M425,393.2c0,40.3-32.8,73.1-73.1,73.1H153.8c-40.3,0-73.1-32.8-73.1-73.1c0-16.6,13.5-30.1,30.1-30.1
	s30.1,13.5,30.1,30.1c0,7.1,5.8,12.9,12.9,12.9h198.1c7.1,0,12.9-5.8,12.9-12.9c0-16.6,13.5-30.1,30.1-30.1
	C411.5,363.1,425,376.6,425,393.2z M231.6,332.8c5.6,5.6,13.3,8.8,21.3,8.8c8,0,15.6-3.2,21.3-8.8L363,244
	c11.7-11.7,11.7-30.8,0-42.5c-11.7-11.7-30.8-11.7-42.5,0L283,238.9V68.1c0-16.6-13.5-30.1-30.1-30.1s-30.1,13.5-30.1,30.1v170.8
	l-37.5-37.5c-11.7-11.7-30.8-11.7-42.5,0c-11.7,11.7-11.7,30.8,0,42.5L231.6,332.8z"/>
    </svg>
);

export default SvgDownload01;