import React, {useEffect, useState} from "react";
import setting from "../../settings/setting";
import branch from "../../settings/branch";



function ForgotpwdPage(props) {
    const [showModal, setShowModal] = useState(true)


    useEffect(() => {
        console.log("CI VA")
        if (props.user.registration.active) {
            let path = setting[branch.name].console_url + "/"
            window.open(path, "_self");

        }
        else {
            console.log("QUI ZIO")
            props.setModalAccess({component:"forgotpassword", show:true, source:"forgotpasswordpage",  condition: "step1"})
        }

    }, []);


    return (
        <></>
    )
}

export default ForgotpwdPage