import React, {useState, useEffect} from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"
import Home from "./Home/Home"
import branch from '../settings/branch';
import {getUserFromToken} from "../hooks/UserProvider"
import CreateApp from "./App/CreateApp";
import AppConsole from "./App/AppConsole";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import LoginPage from "./User/LoginPage";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';
import ModalSystem from "../components/ModalSystem/ModalSystem";
import ForgotpwdPage from "./User/ForgotpwdPage";
import ModalAccess from "./User/ModalAccess";

library.add(fab, fas, far)
function App() {

  const [user, setUser] = useState(false);
  const [token, setToken] = useState()
  const [clearToken, setClearToken] = useState(false)
  const [countUser, setCountUser] = useState(0);
  const [listApp, setListApp] = useState([])
  const [modalSystem, setModalSystem] = useState({show:false})
  const [resultModalSystem, setResultModalSystem] = useState(false)
  const [elDisableScroll, setElDisableScroll] = useState([])
  const [eventScroll, setEventScroll] = useState("")
  const [browserLanguage, setBrowserLanguage] = useState(false)
  const [modalAccess, setModalAccess] = useState({show:false})
  const [modalAccessContent, setModalAccessContent] = useState("")
  const[userExist, setUserExist] = useState("")

  useEffect(() => {
    getUser()

  }, []);

  useEffect(() => {
    getUser()
  }, [token]);

  useEffect(() => {
    setUser(null)
  }, [clearToken]);

  useEffect(() => {
    if (elDisableScroll.length) {
      disableBodyScroll(document.body)
    }
    else {
      enableBodyScroll(document.body);
    }

  }, [eventScroll]);

  async function getUser() {
    try {
      let usertoken = await getUserFromToken()
       setUser(usertoken)

    }catch (e) {
      console.log(e)
    }
  }



  return (
      <>
      {(() => {
      if (modalSystem.show === true) {
        return (
            <ModalSystem
                user={user}
                browserLanguage={browserLanguage}
                setModalSystem={setModalSystem}
                dataModal={modalSystem}
                setToken={setToken}
                elDisableScroll={elDisableScroll}
                setElDisableScroll ={setElDisableScroll}
                setEventScroll={setEventScroll}
                setResultModalSystem={setResultModalSystem}
            />
        )
      }
        if (modalAccess.show === true) {
          return (
              <ModalAccess
                  user={user}
                  browserLanguage={browserLanguage}
                  setShowModal={setModalAccess}
                  content={modalAccess}
                  setToken={setToken}
                  elDisableScroll={elDisableScroll}
                  setElDisableScroll ={setElDisableScroll}
                  setEventScroll={setEventScroll}
              />
          )
        }
      })()}
      <Router>
        <Switch>
          <Route
              path='/'
              exact
              render={(props) => {
                if (user) {
                  return <Home setListApp={setListApp} setModalSystem={setModalSystem} listApp={listApp} {...props}
                               user={user} setClearToken={setClearToken} setToken={setToken}/>
                }
              }}
          />
          <Route
              path='/login'
              exact
              render={(props) => {
                  if (user) {
                      return <LoginPage  elDisableScroll={elDisableScroll} user={user} setUser={setUser}  setEventScroll={setEventScroll} setElDisableScroll={setElDisableScroll}  {...props}  setClearToken={setClearToken}  setToken={setToken} setModalAccess={setModalAccess} setModalAccessContent={setModalAccessContent}  />
                  }

              }}
          />
          <Route
              path='/forgotpassword'
              exact
              render={(props) => {
                  if (user) {
                      return <ForgotpwdPage elDisableScroll={elDisableScroll} user={user} setUser={setUser}
                                            setEventScroll={setEventScroll}
                                            setElDisableScroll={setElDisableScroll}  {...props}
                                            setModalAccess={setModalAccess} setModalAccessContent={setModalAccessContent}
                                            setClearToken={setClearToken} setToken={setToken}/>
                  }
              }}
          />
          <Route
              path='/app/create'
              exact
              render={(props) => {
                  if (user) {
                      return <CreateApp setListApp={setListApp} listApp={listApp} {...props} user={user}
                                        setClearToken={setClearToken} setToken={setToken}/>
                  }
              }}
          />
          <Route
              path='/app/:nameapp/:area/:page'
              exact
              render={(props) => {
                  if (user) {
                      return <AppConsole setModalSystem={setModalSystem} setResultModalSystem={setResultModalSystem}
                                         resultModalSystem={resultModalSystem} setElDisableScroll={setElDisableScroll}
                                         elDisableScroll={elDisableScroll} setListApp={setListApp}
                                         listApp={listApp} {...props} user={user} setClearToken={setClearToken}
                                         setToken={setToken}/>
                  }

              }}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
