import React, {useState, useEffect, useRef} from "react";
import {sendMessageConsoleMS} from "../../../../../../hooks/ConversationProvider";
import {setMessengerTemplate} from "../../../../../../utils/platformTemplates";
import {

    BusinessMessageBar,
    BusinessMessageBarWrapper,
    BusinessMessageBarContent,
    MessageBarWA,
    MessageBarTemplate,
    BoxCurrentActor,
    ButtonSendModel,
    ButtonOptionMessage,
    BusinessMessageBarMenuMessage,
    WrapperCurrentModelMessagePack,
    CurrentModelMessagePack,
    NameModelMessagePack,
    ButtonDeleteMessagePack, WrapLoadMessageBar,
    ItemOptMessageBarMenuMessage,
    PanelPickerEmo
} from "../../../../../../styles/businessConversation"

import Send01 from "../../../../../../source/svg/Send01";



import SvgCLose01 from "../../../../../../source/svg/SvgClose01";
import SvgMoreOptions02 from "../../../../../../source/svg/SvgMoreOptions02";
import Loader from "../../../../../../utils/Loader";
import Main_settings from "../../../../../../styles/main_settings/Main_settings";
import Messagebaremoji from "./Messagebaremoji";
import EmojiPicker, {Theme} from "emoji-picker-react";
import {getListSavedRepliesMS} from "../../../../../../hooks/AppProvider";
import File01 from "../../../../../../source/svg/File01";
import Emojii01 from "../../../../../../source/svg/Emoji01";
import Template01 from "../../../../../../source/svg/Template01";
import Reply01 from "../../../../../../source/svg/Reply01";

function BusMessageBarMS(props) {
    const properties = props
    const app = properties.app
    const messagebar= useRef(null)

    const [currentModels, setCurrentModels] = useState([])
    const [showLoadingMessage, setShowLoadingMessage] = useState(false)
    const [contentBarMessage, setContentBarMessage] = useState(false)
    const [ableToSendMessage, setAbleToSendMessage] = useState("true")
    const [showMenuOptMessage, setShowMenuOptMessage] = useState(false)
    const [changeObj, setChangeObj] = useState(false)
    const [templateMessage, setTemplateMessage] = useState(true)
    const [templateFile, setTemplateFile] = useState(false)
    const [templateCustom, setTemplateCustom] = useState(false)
    const [showButtonSend, setShowButtonSend] = useState(false)
    const [savedReplies, setSavedReplies] = useState(false)

    const [showPickerEmo, setShowPickerEmo] = useState(false)

    const refmessagetext = useRef(null)
    const inputFileFile = useRef(null);


    useEffect(() => {
        if (messagebar.current && messagebar.current.offsetHeight && messagebar.current.clientHeight) {
            setTimeout(() => {properties.setHeightMessagebar(messagebar.current.clientHeight)}, 2000);
        }
        getSavedReplies()
    }, []);


    useEffect(() => {
        if (props.resultModalSystem && props.resultModalSystem.target === "business-message-bar-MS") {
            if (props.resultModalSystem.model && props.resultModalSystem.model.code) {
                const codemodal = props.resultModalSystem.model.code
                if (!templateCustom) setTemplateCustom(true)
                setCurrentModels(prevArray => [...prevArray, codemodal])
            }
            if (props.resultModalSystem.action === "update-savedreplies") {
                getSavedReplies()
            }

        }
    }, [props.resultModalSystem]);




    useEffect(() => {
        setContentBarMessage(false)
        refmessagetext.current.textContent = ""
    }, [props.currentConversation]);

    useEffect(() => {
        if (currentModels.length) {
            setShowButtonSend(true)
        }
        else {
            setShowButtonSend(false)
        }
        if (showMenuOptMessage) setShowMenuOptMessage(false)
    }, [currentModels]);


    useEffect(() => {
        if (templateFile) {
            if (templateMessage) {
                setTemplateMessage(false)
            }
            if (templateCustom) {
                setTemplateCustom(false)
            }
        }

    }, [templateFile]);

    useEffect(() => {
        if (templateMessage) {
            if (templateFile) {
                setTemplateFile(false)
            }
            if (templateCustom) {
                setTemplateCustom(false)
            }
        }

    }, [templateMessage]);

    useEffect(() => {
        if (templateCustom) {
            if (templateFile) {
                setTemplateFile(false)
            }
            if (templateMessage) {
                setTemplateMessage(false)
            }
        }

    }, [templateCustom]);

    useEffect(() => {
        if (contentBarMessage && contentBarMessage != "") {
            let user_conversation
            if (properties.conversation) {
                user_conversation = properties.conversation._id
            }
            let template = setMessengerTemplate("text", contentBarMessage)
            setCurrentModels([template])
        }
        else {
            if (currentModels.length) {
                setCurrentModels([])
            }
        }
    }, [contentBarMessage]);


    const getSavedReplies = async () =>  {
        setShowLoadingMessage(true)
        let listsavedreplies = await getListSavedRepliesMS({app_id:app._id, platform:"Messenger"})
        setShowLoadingMessage(false)
        if (!listsavedreplies.error) {
            setSavedReplies(listsavedreplies.savedreplies)
        }
        else {
            setSavedReplies([])
        }
    }

    const onChangeContentBarMessage = (e) =>  {

        setContentBarMessage(e.currentTarget.textContent)
    }
    const onClickMediaFile = () => {
        inputFileFile.current.click();
    };

    const onClickMediaEmo = () => {
        if (showPickerEmo) setShowPickerEmo(false)
        else setShowPickerEmo(true)
    };

    const addFile = async (e) => {
        e.stopPropagation()
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            const base64= await convertBase64(file);
            const objModel= {
                name: e.target.files[0].name,
                body: base64,
                format:e.target.files[0].type
            }
            let template = setMessengerTemplate("file", objModel)
            if (!templateFile) setTemplateFile(true)
            setCurrentModels(prevArray => [...prevArray, template])
        }
        e.target.value = null;
        //console.log(base64)
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result)
            };
            fileReader.onerror = (error) => {
                reject(error)
            };
        })
    }
    const actionShowMessageOptions = (e) =>  {

        if (showMenuOptMessage) {
            setShowMenuOptMessage(false)
            if (showPickerEmo) setShowPickerEmo(false)
        }
        else {
            setShowMenuOptMessage(true)

        }

    }
    const onClickMediaModel = () => {
        if (showMenuOptMessage) setShowMenuOptMessage(false)
        properties.setModalSystem({show:true, source:"business-message-bar-MS", action:"choose-model-conversation-MS", data:{app:app, user:props.userchat}})
    }
    const onClickMediaSavedReplies = () => {
        if (showMenuOptMessage) setShowMenuOptMessage(false)
        properties.setModalSystem({show:true, source:"business-message-bar-MS", action:"choose-saved-replies-MS", data:{app:app, user:props.userchat}})
    }

    const removeCurrentModel = (index) => {
        let currentArr = currentModels
        currentArr = currentArr.splice(0, index)

        setCurrentModels(currentArr)
        if (!currentArr.length) {
            setTemplateMessage(true)
            if (setTemplateCustom) setTemplateCustom(false)
            if (setTemplateFile) setTemplateFile(false)
        }
        setChangeObj(new Date())
    }
    function onEmojiClick(emojiData) {
        if (templateMessage) {
            let messagebarcopy= contentBarMessage
            if (messagebarcopy) messagebarcopy = messagebarcopy + emojiData.emoji
            else messagebarcopy= emojiData.emoji

            setContentBarMessage(messagebarcopy)
            refmessagetext.current.innerText = messagebarcopy
        }
        else {
            let template = setMessengerTemplate("text", emojiData.emoji)
            setCurrentModels(prevArray => [...prevArray, template])
        }
        setShowPickerEmo(false)
    }

    const actionSend = async (e) => {

        let user_conversation
        if (properties.conversation) {
            user_conversation = properties.conversation._id
        }
        /*let objMessage = {
            app_id:properties.api._id,
            message: {
                sender:user_conversation,
                body: {
                    text:contentBarMessage
                }
            }
        }*/
        /*let objMessage = {
            app_id:properties.api._id,
            user_id:user_conversation,
            body: currentModels
        }*/
        /*const arrProva = [
            {
                text:"1"
            },
            {
                text:"2"
            }
        ]*/
        if (templateMessage) {
            setContentBarMessage('')
            refmessagetext.current.innerText = ""
        }
        else {
            setTemplateMessage(true)
        }
        if (showMenuOptMessage) setShowMenuOptMessage(false)
        setCurrentModels([])
        setShowLoadingMessage(true)
        for (const item of currentModels) {
            let runmessage = await sendMessageConsoleMS({
                app_id:properties.app._id,
                user_id:user_conversation,
                body: item
            })
            if (runmessage.error) {
                properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
            }
        }
        setShowLoadingMessage(false)
        //setAbleToSendMessage(false)
        //let runmessage = await sendMessageConsoleMS(objMessage)
        //setAbleToSendMessage("true")
        /*if (runmessage.error) {
            console.log(runmessage)
            properties.setModalSystem({show:true, source:"business-message-bar", action:"error", data:{message_code:1}})
        }*/
    }
  return (
      <>
          {(() => {
                return (
                   <BusinessMessageBar  >
                            <BusinessMessageBarContent >
                                {(() => {
                                    if (showLoadingMessage) {
                                        return (
                                            <WrapLoadMessageBar>
                                                <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                            </WrapLoadMessageBar>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <BoxCurrentActor>
                                                    <img style={{width:"40px"}} alt="" src={app.image} />
                                                </BoxCurrentActor>
                                                {(() => {
                                                    if (templateMessage) {
                                                        return (
                                                            <MessageBarWA
                                                                contentEditable="true"
                                                                suppressContentEditableWarning={true}
                                                                placeholder="Rispondi su Messenger..."
                                                                onInput={onChangeContentBarMessage}
                                                                ref={refmessagetext}
                                                            >
                                                            </MessageBarWA>
                                                        )
                                                    }
                                                    else if (templateFile || templateCustom) {
                                                    return (
                                                        <MessageBarTemplate>
                                                            {(() => {
                                                                if (currentModels.length) {
                                                                    return currentModels.map((child, i) => {

                                                                        return (
                                                                            <WrapperCurrentModelMessagePack key={i}>
                                                                                <CurrentModelMessagePack>
                                                                                    <NameModelMessagePack>{child.name}</NameModelMessagePack>
                                                                                    <ButtonDeleteMessagePack onClick={() => removeCurrentModel(i)}><SvgCLose01></SvgCLose01></ButtonDeleteMessagePack>
                                                                                </CurrentModelMessagePack>
                                                                            </WrapperCurrentModelMessagePack>
                                                                        )

                                                                    })
                                                                }
                                                            })()}
                                                        </MessageBarTemplate>
                                                    )
                                                }
                                                })()}
                                                <ButtonOptionMessage title="Scegli il tipo di messaggio" onClick={actionShowMessageOptions} ><SvgMoreOptions02 /> </ButtonOptionMessage>
                                                {(() => {
                                                    if (showMenuOptMessage) {

                                                        return (
                                                            <BusinessMessageBarMenuMessage>
                                                                <ItemOptMessageBarMenuMessage title="Emojii" onClick={onClickMediaEmo}><Emojii01 /></ItemOptMessageBarMenuMessage>
                                                                <ItemOptMessageBarMenuMessage title="File" onClick={onClickMediaFile} >
                                                                    <File01 />
                                                                    <input ref={inputFileFile} style={{ display: "none" }} accept="*" type="file" multiple  onChange={addFile} />
                                                                </ItemOptMessageBarMenuMessage>
                                                                {(() => {
                                                                    if (showPickerEmo) {
                                                                        return (
                                                                            <PanelPickerEmo>
                                                                                    <EmojiPicker
                                                                                        onEmojiClick={onEmojiClick}
                                                                                        groupNames={{ smileys_people: "PEOPLE" }}
                                                                                        disableSkinTonePicker={true}
                                                                                        disableAutoFocus={true}
                                                                                        theme={Theme.AUTO}
                                                                                        native={false}
                                                                                    />

                                                                            </PanelPickerEmo>
                                                                        )
                                                                    }

                                                                })()}


                                                                <ItemOptMessageBarMenuMessage title="Template"  onClick={onClickMediaModel}><Template01 /></ItemOptMessageBarMenuMessage>
                                                                {(savedReplies && savedReplies.length) ? <ItemOptMessageBarMenuMessage title="Risposte salvate" onClick={onClickMediaSavedReplies}><Reply01 /></ItemOptMessageBarMenuMessage> : null}
                                                            </BusinessMessageBarMenuMessage>
                                                        )
                                                    }
                                                })()}
                                                {(() => {
                                                    if (showButtonSend && ableToSendMessage) {
                                                        return (
                                                            <ButtonSendModel title="invia" onClick={actionSend} ><Send01/> </ButtonSendModel>
                                                        )
                                                    }
                                                })()}
                                            </>
                                      )

                                    }

                                })()}

                            </BusinessMessageBarContent>
                        </BusinessMessageBar>
                )

          })()}

       </>
  );
}

export default BusMessageBarMS;
