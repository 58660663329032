import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ImageUserChat from "./ImageUserChat";
import moment from "moment";
import detect from "detect-file-type"

function ParserTemplComponentsUserMS(props) {
    const properties = props
    const focusmessage = properties.code
    const message=  properties.message


    let nameuser

    if (properties.userchat.guest) {
        nameuser = "User " + properties.userchat.sender
    }
    else {
        nameuser = properties.userchat.name
    }
    let hourdate = moment(message.date.date).format('HH: mm');

    return (
        <div className="viewdefault_row_chat">
            <div title={nameuser} className="viewdefault_opt_msg_picture">
                <ImageUserChat
                    user={properties.userchat}
                />
            </div>
            <div title={hourdate} className="viewdefault_message_wrapper viewdefault_message_wrapper_user">
                {(() => {

                if (focusmessage) {
                    if (focusmessage.type === "attachments") {
                        let attachments = focusmessage.body.attachment
                        if (attachments.length) {
                            return attachments.map((child, i) => {
                                if (child.type === "image") {
                                    return (
                                        <div className="container_message">
                                            <div className="user_message_image user_message">
                                                <img width="100px" alt="" src={child.payload.url}/>
                                            </div>
                                        </div>
                                    )
                                }
                                if (child.type === "audio") {
                                    return (
                                        <audio controls>
                                            <source src={child.payload.url} />
                                        </audio>
                                    )
                                }
                                if (child.type === "file") {
                                    let namefile
                                    let url = child.payload.url
                                    namefile = url.split(':').pop().split(';')[0];
                                    return (
                                        <div title={child.payload.url} className="user_message_file user_message">
                                            <div className="user_message_file_info">
                                                <div className="user_message_file_icon">
                                                    <a href={child.payload.url} ><FontAwesomeIcon icon={["fas", "file-download"]}/></a>
                                                </div>
                                                <p className="user_message_type">File</p>
                                            </div>
                                        </div>

                                    )
                                }
                            })
                        }
                    }
                    if (focusmessage.type === "text") {
                        return (
                            <div className="container_message">
                                <div className="user_message_text user_message">
                                    <p> {focusmessage.body.text}</p>
                                </div>
                            </div>
                        )
                    }
                    if (focusmessage.type === "postback") {
                       let textreturn
                        if (focusmessage.body.title) textreturn = focusmessage.body.title
                        else textreturn = focusmessage.body.payload + "(payload)"
                        return (
                            <div className="container_message">
                                <div className="user_message_text user_message">

                                    <p> {textreturn}</p>
                                </div>
                            </div>
                        )
                    }
                    if (focusmessage.type === "quick_reply") {
                        return (
                            <div className="container_message">
                                <div className="user_message_text user_message">
                                    <p> {focusmessage.body.text}</p>
                                </div>
                            </div>
                        )
                    }
                }
                else {
                    return null
                }

                })()}
            </div>
         </div>
    )
}

export default ParserTemplComponentsUserMS