import React, { useState, useEffect } from 'react';
import ReactHtmlParser, {transform, processNodes, convertNodeToElement} from 'react-html-parser';
import ImageUserChat from "./ImageUserChat";
import ImageApp from "./ImageApp";
import moment from "moment";
import Slider from "../tempcomponents/sliders/Slider";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    ContMessageAppFile
} from "../styles/businessConversation"
function ParserTemplComponentsAppWA(props) {
    const properties = props
    const message=  properties.message

    const focusmessage = properties.code
    let hourdate = moment(message.date.date).format('HH: mm');

    const downloadFile = (obj) => {
        const byteString = atob(obj.body.split(',')[1]);

        // Creare un array buffer
        const buffer = new ArrayBuffer(byteString.length);

        // Creare una vista array di byte
        const byteArray = new Uint8Array(buffer);

        // Riempire l'array con i byte della stringa Base64
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        // Creare un oggetto Blob
        const blob = new Blob([buffer], { type: 'application/octet-stream' });

        // Creare un oggetto File
        const file = new File([blob], obj.name, { type: 'application/octet-stream' });

        // Creare un URL oggetto temporaneo e creare un link per scaricare il file
        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = obj.name;

        // Simulare il clic sul link per avviare il download
        link.click();

        // Rilasciare l'URL oggetto temporaneo
        URL.revokeObjectURL(url);
    };

    return (
        <div className="viewdefault_row_chat viewdefault_row_chat_app">
            <div title={properties.app.name}  className="viewdefault_opt_msg_picture">
                {(() => {
                    if (message.actor) {
                        return (
                            <ImageApp
                                app={properties.app}
                                actor={message.actor}
                            />
                        )
                    }
                    else {
                        return (
                            <ImageApp
                                app={properties.app}
                            />
                        )
                    }

                })()}
            </div>
            <div title={hourdate} className="viewdefault_message_wrapper viewdefault_message_wrapper_app viewdefault_message_wrapper_app_WA">
                {(() => {

                    if (focusmessage.type === "text") {
                        return (<p>{focusmessage.body}</p>)
                    }
                    else if (focusmessage.type === "file") {
                        return (
                            <ContMessageAppFile onClick={(e => downloadFile(focusmessage))}><FontAwesomeIcon icon={["fas", "file-download"]} />{focusmessage.name}</ContMessageAppFile>
                        )

                    }
                    else {

                    }
                })()}
            </div>
        </div>
    )
}

export default ParserTemplComponentsAppWA