const urllocalip = "http://localhost"
let urllocalsocketip = "ws://127.0.0.1"
module.exports = {
    locale : {
        client_url: urllocalip + ":3000",
        console_url: urllocalip + ":3006",
        server_url: urllocalip + ":22000",
        consolesocket_url: urllocalsocketip + ":9000",
        clientsocket_url: urllocalsocketip + ":8000",
        app_url:"/app",
        development_area_url: "/development",
        development_settings_url: "/settings",
        development_roles_url: "/roles",
        business_area_url: "/business",
        business_conversations_url: "/conversations",
        business_stats_url: "/stats",
        business_roles_url: "/roles",
        business_billing_url: "/billing"
    },
    dev : {
        client_url: "https://bmamadev.ive.ai",
        console_url: "https://console.bmamadev.ive.ai",
        server_url: "https://server.bmamadev.ive.ai",
        consolesocket_url: "ws://consolesocket.bmamadev.ive.ai",
        clientsocket_url: "ws://clientsocket.bmamadev.ive.ai",
        app_url:"/app",
        development_area_url: "/development",
        development_settings_url: "/settings",
        development_roles_url: "/roles",
        business_area_url: "/business",
        business_conversations_url: "/conversations",
        business_stats_url: "/stats",
        business_roles_url: "/roles",
        business_billing_url: "/billing"
    },
    main : {
        client_url: "https://bmamamain.ive.ai",
        console_url: "https://console.bmamamain.ive.ai",
        server_url: "https://server.bmamamain.ive.ai",
        consolesocket_url: "ws://consolesocket.bmamamain.ive.ai",
        clientsocket_url: "ws://clientsocket.bmamamain.ive.ai",
        app_url:"/app",
        development_area_url: "/development",
        development_settings_url: "/settings",
        development_roles_url: "/roles",
        business_area_url: "/business",
        business_conversations_url: "/conversations",
        business_stats_url: "/stats",
        business_roles_url: "/roles",
        business_billing_url: "/billing"
    },
    prod : {
        client_url: "https://www.botmama.world",
        console_url: "https://console.botmama.world",
        server_url: "https://server.botmama.world",
        consolesocket_url: "wss://clientsocket.botmama.world",
        clientsocket_url: "wss://clientsocket.botmama.world",
        app_url:"/app",
        development_area_url: "/development",
        development_settings_url: "/settings",
        development_roles_url: "/roles",
        business_area_url: "/business",
        business_conversations_url: "/conversations",
        business_stats_url: "/stats",
        business_roles_url: "/roles",
        business_billing_url: "/billing"
    }
};


