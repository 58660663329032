import React from 'react';

const SvgCellphone01= props => (
    <svg
        viewBox="0 0 545.856 545.856"
    >
        <path fill={props.color} d="M188.946,131.054V399.34c0,5.278,4.284,9.562,9.562,9.562h148.83c5.279,0,9.562-4.284,9.562-9.562V131.054
		c0-5.279-4.283-9.562-9.562-9.562h-148.83C193.229,121.492,188.946,125.766,188.946,131.054z"/>
        <path fill={props.color} d="M203.557,0c-38.375,0-69.596,31.222-69.596,69.596v406.665c0,38.374,31.221,69.596,69.596,69.596h138.742
		c38.375,0,69.596-31.222,69.596-69.596V69.605C411.895,31.231,380.674,0,342.299,0H203.557z M278.614,47.535
		c9.945,0,18.369,0,18.369,0s14.955,2.18,17.557,11.705h-35.926V47.535z M251.465,47.535c4.533,0,11.876,0,19.345,0V59.24h-39.502
		C231.308,59.24,239.11,47.535,251.465,47.535z M375.883,113.058v318.001c0,20.215-16.4,36.605-36.615,36.605h-132.68
		c-20.215,0-36.605-16.391-36.605-36.605V113.058c0-20.215,16.38-36.605,36.605-36.605h132.68
		C359.483,76.452,375.883,92.852,375.883,113.058z"/>
    </svg>
);

export default SvgCellphone01;