import React from 'react';

const SvgMoreOptions02 = props => (
    <svg
        viewBox="0 0 512 512"
    >
        <rect fill={props.fill} id="icoArea1" width="513" height="513"/>
        <circle fill={props.fill} id="icoArea2" cx="255" cy="257" r="253"/>
        <path id="icoArea3" d="M149.2,228.5c-17.8,0-32.2,14.5-32.2,32.2s14.5,32.2,32.2,32.2s32.2-14.5,32.2-32.2S167,228.5,149.2,228.5z"
        />
        <path id="icoArea4" d="M252.4,228.5c-17.8,0-32.2,14.5-32.2,32.2s14.5,32.2,32.2,32.2s32.3-14.5,32.3-32.2S270.2,228.5,252.4,228.5z
	"/>
        <path id="icoArea5" d="M355.6,228.5c-17.8,0-32.2,14.5-32.2,32.2s14.5,32.2,32.2,32.2s32.2-14.5,32.2-32.2S373.4,228.5,355.6,228.5z
	"/>
    </svg>
);

export default SvgMoreOptions02;