import React, {useState, useEffect} from "react";


function DevSettingsPage(props) {
    const properties = props
    useEffect(() => {

    }, []);

  return (
      <>
          {(() => {
              if (properties.permissions) {
                  return (
                      <p>Dev settings</p>
                  )
              }
              else {
                  return (
                      <p>Non hai i permessi per visualizzare questa pagina</p>
                  )
              }

          })()}

       </>
  );
}

export default DevSettingsPage;
