import React, {useState, useEffect} from "react";
import branch from "../../settings/branch"
import setting from "../../settings/setting"

import {
    SectDevMenuBar,
    SectBusMenuBar,
    ListSectionMenuBar
} from "../../styles/AppConsole"

function Menubar(props) {
    const properties = props
    const [changeObj, setChangeObj] = useState("")
    const nameapp = properties.app.name
    const urlapp = setting[branch.name].app_url + "/" + nameapp
    const urldevsettings= urlapp + setting[branch.name].development_area_url + setting[branch.name].development_settings_url
    const urldevroles= urlapp + setting[branch.name].development_area_url + setting[branch.name].development_roles_url
    const urlbusconversations= urlapp + setting[branch.name].business_area_url + setting[branch.name].business_conversations_url
    const urlbusroles= urlapp + setting[branch.name].business_area_url + setting[branch.name].business_roles_url
    const urlbusstats= urlapp + setting[branch.name].business_area_url + setting[branch.name].business_stats_url
    const urlbusbilling= urlapp + setting[branch.name].business_area_url + setting[branch.name].business_billing_url

    useEffect(() => {

    }, []);

  return (
      <>
          {(() => {
              return (
                  <>
                      {(() => {
                          if (props.devpermission.settings || props.devpermission.roles) {
                              return (
                                  <SectDevMenuBar>
                                      <p>Development</p>
                                      <ListSectionMenuBar>
                                          <ul className="list_section_app">
                                              {(() => {
                                                  if (props.devpermission.settings) {
                                                      return (
                                                          <li><a href={urldevsettings}>Impostazioni</a></li>
                                                      )
                                                  }
                                              })()}
                                              {(() => {
                                                  if (props.devpermission.roles) {
                                                      return (
                                                          <li><a href={urldevroles}>Ruoli</a></li>
                                                      )
                                                  }
                                              })()}
                                          </ul>
                                      </ListSectionMenuBar>
                                  </SectDevMenuBar>
                              )
                          }

                      })()}
                      {(() => {
                          if (props.buspermission.conversations) {
                              return (
                                  <SectBusMenuBar>
                                      <p>Business</p>
                                      <ul className="list_section_app">
                                          <li><a href={urlbusconversations}>Conversazioni</a></li>
                                      </ul>
                                  </SectBusMenuBar>
                              )
                          }
                      })()}

                  </>
               )
          })()}
       </>
  );
}

export default Menubar;
