module.exports = {
    color01:"#000",
    color02:"#ff398a",
    color03: "#FFE1EF",
    color04: "#fff",
    color05: "#cdb0bc",
    color06: "#9d9d9d",
    color07: "#ff002a",
    color08: "#E5E5E5",
    color09: "#e3e2e2",
    color10: "#f6f6f6",
    color11:"#f9ebf2",
    color12:"#686868",
    colorRgba01: "rgba(255, 255, 255, .8)",
    border_radius01: "40px",
    border_radius02: "100%",
    border_radius03: "20px",
    border_radius04: "10px",
    font01: "Roboto-Regular",
    font02: "Roboto-Medium",
    font03: "Roboto-Bold",
    font04: "Roboto-Light",
    font_size01: ".9em",
    font_size02: "1em",
    height01: "48px",
    height02: "100px",
    boxshadow01: "0px 0px 13px -1px rgba(0,0,0,0.08)",
    boxshadow02: "0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    boxshadow03: "0px -6px 8px -1px rgba(0,0,0,0.04)",
    boxshadow05: "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)"
};