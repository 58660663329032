import React from 'react';

const SvgTelephone01= props => (
    <svg
        viewBox="0 0 51.071 51.071"
    >
        <circle fill={props.color} cx="25.54" cy="31.917" r="4.255"/>
        <path fill={props.color} d="M34.049,18.09v-2.663c0-1.464-1.197-2.66-2.662-2.66c-1.464,0-2.659,1.195-2.659,2.66v2.663h-6.382v-2.663
			c0-1.464-1.19-2.66-2.66-2.66c-1.469,0-2.661,1.195-2.661,2.66v2.663L2.67,33.418c-0.99,0.968-1.607,2.317-1.607,3.805v4.266
			c0,2.354,1.906,4.255,4.256,4.255h1.062v2.662c0,1.469,1.192,2.664,2.656,2.664c1.474,0,2.667-1.195,2.667-2.664v-2.662h27.662
			v2.662c0,1.469,1.197,2.664,2.662,2.664c1.469,0,2.662-1.195,2.662-2.664v-2.662h1.061c2.356,0,4.256-1.9,4.256-4.255v-4.266
			c0-1.487-0.618-2.837-1.604-3.805L34.049,18.09z M25.54,40.426c-4.705,0-8.514-3.81-8.514-8.511s3.809-8.512,8.514-8.512
			c4.7,0,8.509,3.811,8.509,8.512S30.24,40.426,25.54,40.426z"/>
        <path fill={props.color} d="M51.028,15.331c-0.311-3.016-1.226-7.766-3.97-10.46c-3.308-3.246-12.425-4.87-21.518-4.87
			c-9.101,0-18.212,1.626-21.524,4.87c-2.748,2.694-3.662,7.444-3.969,10.46c0,0-0.046,0.296-0.046,0.425
			c0,1.182,0.962,2.126,2.13,2.126c0.138,0,5.255-0.412,8.336-0.668c0.078-0.011,0.149-0.024,0.232-0.04
			c1.145-0.037,2.077-0.972,2.077-2.133l0.005-4.193c0-1.295,1.054-2.34,2.354-2.34H35.94c1.303,0,2.35,1.045,2.35,2.34l0.014,4.193
			c0,1.161,0.927,2.096,2.074,2.133c0.077,0.016,0.152,0.026,0.226,0.04c3.086,0.257,8.202,0.668,8.342,0.668
			c1.173,0,2.126-0.944,2.126-2.126C51.071,15.63,51.028,15.331,51.028,15.331z"/>
    </svg>
);

export default SvgTelephone01;