import branch from "../settings/branch"
import settings from "../settings/setting"
import axios from 'axios';
axios.defaults.withCredentials = true;

const serverurl = settings[branch.name].server_url
const userregistration_endpoint = serverurl + "/client/action/userregistration"
const userconfregistration_endpoint = serverurl + "/client/action/userconfirmregistration"
const userlogin_endpoint = serverurl + "/client/action/userlogin"
const senduserregsecurecode_endpoint = serverurl + "/client/action/senduserregistrationsecurecode"
const userbeginpwdreset_endpoint = serverurl + "/client/action/userbeginpwdreset"
const userendpwdreset_endpoint = serverurl + "/client/action/userendpwdreset"
const userconfirmforgotpwd_endpoint = serverurl + "/client/action/userconfirmforgotpwd"
const senduserforpwdsecurecode_endpoint = serverurl + "/client/action/senduserforgotpwdsecurecode"
const setuserlogout_endpoint = serverurl + "/client/action/setuserlogout"
const getuserfromtoken_endpoint = serverurl + "/client/action/getuserfromtoken"


export const userRegistration= (dataUser) => {
    const objJsonStr = JSON.stringify(dataUser);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(userregistration_endpoint + '?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

export const userConfirmRegistration= (dataUser) => {
    const objJsonStr = JSON.stringify(dataUser);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(userconfregistration_endpoint + '?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

export const userLogin= (dataUser) => {
    const objJsonStr = JSON.stringify(dataUser);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(userlogin_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}


export const sendUserRegistrationSecureCode= () => {
    return axios.get(senduserregsecurecode_endpoint,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

export const userForgotPassword= (dataUser, condition) => {
    const objJsonStr = JSON.stringify(dataUser);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    let endpoint

    switch (condition) {
        case "step1":
            endpoint = userbeginpwdreset_endpoint + "?param="
            break;
        case "step3":
            endpoint = userendpwdreset_endpoint +"?param="
            break;
    }
    return axios.get(endpoint + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

export const userConfirmForgotPwd= (dataUser) => {
    const objJsonStr = JSON.stringify(dataUser);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(userconfirmforgotpwd_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

export const sendUserForgotPwdSecureCode= () => {
    return axios.get(senduserforpwdsecurecode_endpoint,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}
export const setUserLogOut= () => {
    return axios.get(setuserlogout_endpoint,{withCredentials:true})
        .then(response => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
        })

}

export const getUserFromToken= () => {
    return axios.get(getuserfromtoken_endpoint,{withCredentials:true})
        .then(response => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
        })
}


