import React, {useEffect, useState} from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import 'prismjs/components/prism-markup';
import "prismjs/themes/prism-okaidia.css";
import {createModel, editModel} from "../../hooks/AppProvider";
import {
    WrapperCreateModel,
    CreateModelTarget,
    ItemDataNoLabel,
    EditorCreateModel,
    BoxButtonFormModel,
    ItemResponseModal,
    WrapButtonsModal,
    LinkBackModal,
    WrapLinkBackModal

} from "../../styles/Modals";
import {Button01, Button011} from "../../styles/Commons";

function ModalCreateModel(props) {
    const properties = props
    const app = properties.dataModal.data.app
    const [InputNameModel, setInputNameModel] = useState("")
    const [InputImageModel, setInputImageModel] = useState("")
    const [code, setCode] = useState("");
    const [showConfirmButton, setShowConfirmButton] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [modelReplaceOptions, setModelReplaceOptions] = useState(false)
    const [modelSuccessOptions, setModelSuccessOptions] = useState(false)
    const [responseMessage, setResponseMessage] = useState(false);
    const [model, setModel] = useState(false)



    const closeModal = async (e) => {

        properties.setModalSystem({show:false, action:null, source:null, data:null})
    }

    useEffect(() => {
        if (InputNameModel && code) {
            setShowConfirmButton(true)
        }
    }, [InputNameModel, code]);

    const confirmModel = async (e) => {
        let message
        let replace = false
        let success = false
        let datasend = {
            id_app: app._id,
            name:InputNameModel,
            image:InputImageModel,
            code:code,
            user:properties.user
        }
        let actcreatemodel = await createModel(datasend)

        if (!actcreatemodel.error) {
            message = "Il modello " + InputNameModel + " è stato creato"
            success = true
        }
        else {
            if (actcreatemodel.error_subcode && actcreatemodel.error_subcode === 600.2) {
                replace = true
                message = "Il modello " + InputNameModel + " esiste già"
            }
            else {
                message = "c'è stato un errore con la creazione del modello"
            }
        }
        if (replace) setModelReplaceOptions(true)
        if (success) {
            setModelSuccessOptions(true)
            setModel(actcreatemodel.model.name)
        }
        setResponseMessage(message)
        setShowResponse(true)
    }

    const replaceModel = async (e) => {
        let message
        let success = false
        let datasend = {
            id_app: app._id,
            name:InputNameModel,
            image:InputImageModel,
            code:code,
            user:properties.user
        }
        let acteditmodel = await editModel(datasend)
        if (!acteditmodel.error) {
            message = "Il modello " + InputNameModel + " è stato modificato"
            success = true
        }
        else {
             message = "c'è stato un errore con la modifica del modello"
        }
        if (success) {
            setModelSuccessOptions(true)
            setModel(acteditmodel.model.name)
        }
        setResponseMessage(message)
        setShowResponse(true)

        if (modelReplaceOptions) setModelReplaceOptions(false)
    }

    const useModelCreate = async (e) => {
        properties.setModalSystem({show:true, source:"modal-create-modal", action:"choose-model-conversation", data:{app:app, model:model}})
    }

    const goToChooseModel = async (e) => {
        properties.setModalSystem({show:true, source:"modal-create-modal", action:"choose-model-conversation", data:{app:app}})
    }

    const hideResponse = async (e) => {
        if (modelReplaceOptions) setModelReplaceOptions(false)
        setShowResponse(false)
    }


    return (
       <WrapperCreateModel>
           <h1>Crea un modello</h1>
           <CreateModelTarget>
            {(() => {

               if (showResponse) {
                   return (
                       <>
                           <ItemResponseModal>{responseMessage}</ItemResponseModal>
                           {(() => {
                                if (modelReplaceOptions) {
                                    return (
                                        <>
                                            <WrapButtonsModal>
                                                <Button01 onClick={hideResponse} >Torna indietro</Button01>
                                                <Button01 onClick={replaceModel} >Sovrascrivi il modello</Button01>
                                            </WrapButtonsModal>
                                        </>
                                    )
                                }
                               if (modelSuccessOptions) {
                                   return (
                                       <>
                                           <WrapButtonsModal>
                                            <Button01 onClick={useModelCreate}>Usa questo modello</Button01>
                                           </WrapButtonsModal>
                                       </>
                                   )
                               }
                           })()}

                        </>
                   )
               }
               else {
                   return (
                       <>

                           <div>
                               <ItemDataNoLabel>
                                   <input placeholder="* Nome modello" type="text" name="nameModel"value={InputNameModel} onChange={e => setInputNameModel(e.target.value)} />
                               </ItemDataNoLabel>
                               <ItemDataNoLabel>
                                   <input type="text" placeholder="Immagine modello" name="imageModel"value={InputImageModel} onChange={e => setInputImageModel(e.target.value)} />
                               </ItemDataNoLabel>
                               <EditorCreateModel>
                                   <Editor
                                       value={code}
                                       onValueChange={(code) => setCode(code)}
                                       highlight={(code) => highlight(code, languages.markup)}
                                       placeholder="* </> Codice modello"
                                       padding={10}
                                       style={{
                                           fontFamily: '"Fira code", "Fira Mono", monospace',
                                           fontSize: 16,
                                           background:'#FFF'
                                       }}
                                   />
                               </EditorCreateModel>

                           </div>
                           {(() => {
                               if (showConfirmButton) {
                                   return(
                                       <BoxButtonFormModel>
                                            <Button01 onClick={confirmModel}>Conferma</Button01>
                                       </BoxButtonFormModel>
                                   )
                               }
                               else {
                                   return (
                                       <BoxButtonFormModel>
                                            <Button011>Conferma</Button011>
                                       </BoxButtonFormModel>
                                   )
                               }


                           })()}
                       </>
                   )
               }

           })()}
           </CreateModelTarget>
           <WrapLinkBackModal>
            <LinkBackModal onClick={goToChooseModel}>Vai alla scelta modelli</LinkBackModal>
           </WrapLinkBackModal>
       </WrapperCreateModel>

    )
}

export default ModalCreateModel
