import {generateCode} from "./utils";


export const setMessengerTemplate =(type, data) => {
    let template
    let id = generateCode(5)
    let name
    switch (type) {
        case "text":
            name = data.substring(0, 8) + "..."
            template = {
               id:id,
               name:name,
               type:"text",
               message: {
                   text:data
               }
        }
        break;
        case "textButtons":
            name = data.text.substring(0, 8) + "..."
            template = {
                id:id,
                name:name + "🔘",
                type:"textButtons",
                message: {
                    "attachment": {
                        "type": "template",
                        "payload": {
                            "template_type": "button",
                            "text": data.text,
                            "buttons": data.buttons
                        }
                    }
                }
            }
            break;
        case "textQuickReplies":
            name = data.text.substring(0, 8) + "..."
            template = {
                id:id,
                name:name + "⚡️",
                type:"textQuickReplies",
                message: {
                    "text": data.text,
                    "quick_replies": data.buttons
                }
            }
            break;
        case "slider":
            name = "slider [" + data.name.substring(0, 8) + "...]"
            template = {
                id:id,
                name:name,
                type:"slider",
                message: {
                    "attachment": {
                        "type": "template",
                        "payload": {
                            "template_type": "generic",
                            "elements": data.elements
                        }
                    }
                }
            }
            break;
        case "file":
            template = {
                id:id,
                type:"file",
                name:data.name,
                format:data.format,
                filedata:data.body,
                message: {
                    "attachment":{
                        "type":"file",
                        "payload":{
                            "is_reusable": true
                        }
                    }
                }

            }
            break;
    }

    return template
}


