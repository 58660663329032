import React from 'react';

const SvgPreview01= props => (
    <svg
        viewBox="0 0 512 512"
    >
        <path fill={props.fill} d="M481.7,437.2l-85.7-85.7c32.7-52.3,16.8-121.3-35.6-154S239,180.7,206.4,233s-16.8,121.3,35.6,154
	c36.3,22.7,82.3,22.6,118.5-0.1l85.7,85.7c9.8,9.8,25.7,9.8,35.5,0C491.5,462.8,491.4,447,481.7,437.2L481.7,437.2z M239,291.9
	c0-34.2,27.8-62,62-62s62,27.8,62,62s-27.8,62-62,62l0,0C266.8,353.9,239,326.2,239,291.9L239,291.9z"/>
        <path fill={props.fill} d="M289,30H64c-13.8,0-25,11.2-25,25v350c0,13.8,11.2,25,25,25h152.5c-28.8-17.7-51.4-43.9-64.7-75H114c-13.8,0-25-11.2-25-25
	s11.2-25,25-25h25.8c-0.3-4.4-0.8-8.6-0.8-13c0-12.4,1.5-24.8,4.4-36.9H114c-13.8,0-25-11.2-25-25c0-13.8,11.2-25,25-25h50.5
	c12.9-20.2,30.1-37.2,50.3-50H114c-13.8,0-25-11.2-25-25c0-13.8,11.2-25,25-25h125c13.8,0,25,11.2,25,25c-0.2,1.6-0.5,3.2-0.9,4.7
	c12.4-3.1,25.1-4.6,37.9-4.7c4.4,0,8.7,0.3,13,0.6V55C313.9,41.2,302.8,30,289,30z"/>
    </svg>
);

export default SvgPreview01;