import React, { Component } from "react";
import AromaSlider from "./aromaslider/AromaSlider";

export default function Slider(props) {
    const properties = props
    const node = properties.node

    return (

        <React.Fragment>
            {(() => {
                    if (node.attribs.name === "aromaSlider") {
                        return(
                            <AromaSlider
                                node={node}
                                code={properties.code}
                            />
                        )
                    }
            })()}
        </React.Fragment>

    )

    /*return (
        <SlickSlider
            user={properties.user}
            source={properties.source}
            setLayout={properties.setLayout}
            setGoConversation={properties.setGoConversation}
            page={properties.page}
            lang={properties.lang}
            queryurl={properties.query}
            setApp={properties.setApp}
            template={properties.template}
            setModalComponent={properties.setModalComponent}
            setPageComponent={properties.setPageComponent}
            onapp={properties.onapp}
            setToken={properties.setToken}
            elDisableScroll={properties.elDisableScroll}
            setElDisableScroll ={properties.setElDisableScroll}
            setEventScroll={properties.setEventScroll}
            node={properties.node}
            code={properties.code}
        />
    );*/

}