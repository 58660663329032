import React, {useState, useEffect, useRef} from "react"
import TextButtonsModel from "./TextButtonsModel";
import {
    BoxContentTypeButtonPl,
    BoxElementModelPlatform, BoxHeaderButton, BoxItemButtonsPlatform,
    BoxItemTextPlatform, BoxNumberButton, BoxSelectTypeButtonPl, ButtonDeleteButton,
    ChooseModelPlatform,
    InfoModelPLatform, InfoTypeButton, ItemTypeButton, WrapperButtonPlatform
} from "../../../../styles/Modals";
import {setMessengerTemplate} from "../../../../utils/platformTemplates";
import Delete01 from "../../../../source/svg/Delete01";
import {Button01} from "../../../../styles/Commons";


function TextQuickReplies(props) {
    const [textValue, setTextValue] = useState(false)
    const textareatext= useRef(null)
    const [changeObjButtons, setChangeObjButtons] = useState(false)
    const [activeButtons, setActiveButtons] = useState([])
    const [buttons, setButtons] = useState([
        {
            id:"button01",
            title:"",
            payload:"",
            image_url:"",
            active:"true"
        },
        {
            id:"button02",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button03",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button04",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button05",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button06",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button07",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button08",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button09",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button10",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
        {
            id:"button11",
            title:"",
            payload:"",
            image_url:"",
            active:"false"
        },
    ])
    useEffect(() => {
        if (props.replie) changeContentModel()
        else setActiveButtons([buttons[0]])

    }, []);
    useEffect(() => {
        checkElementToSend()
    }, [changeObjButtons]);

    useEffect(() => {
        checkElementToSend()

    }, [textValue]);



    const onChangeElementTextarea = async (e) => {
        e.stopPropagation()
        let value = e.target.value
        if (value != "") {
            setTextValue(e.target.value)
        }
        else {
            setTextValue(false)
        }
    }

    const changeContentModel = () => {
        let objdata = JSON.parse(props.replie.message_data)
        let savedmessage = objdata.text
        let savedbuttons = objdata.quick_replies
        let newbuttons = buttons


        for (let i = 0; i < savedbuttons.length; i++) {
            if (i < newbuttons.length) {
                newbuttons[i].title = savedbuttons[i].title
                newbuttons[i].payload = savedbuttons[i].payload
                if (savedbuttons[i].image_url) newbuttons[i].image_url = savedbuttons[i].image_url
                newbuttons[i].active = "true"
            }

        }


        textareatext.current.value = savedmessage
        setTextValue(savedmessage)
        setButtons(newbuttons)
        setChangeObjButtons(new Date())
        //console.log(newbuttons)


    }

    const actionInsertValueBtns = async (e, id, target) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr[i][target] = e.target.value
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }


    const checkElementToSend = async (e) => {
        let isabletosend = false
        let buttonsarr= []
        let button = {}
        if (textValue && textValue != "" && buttons.length) {
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].active === "true") {
                    if (buttons[i].title != "" && buttons[i].payload != "") {
                        isabletosend= true
                        button = {
                            "content_type":"text",
                            "payload": buttons[i].payload,
                            "title": buttons[i].title
                        }
                        if (buttons[i].image_url != "") button.image_url = buttons[i].image_url
                        buttonsarr.push(button)
                    }
                    else {
                        isabletosend = false
                        buttonsarr = []
                    }
                }
            }
        }
        if (isabletosend) {
            let data = {
                text:textValue,
                buttons:buttonsarr
            }
            let template = setMessengerTemplate("textQuickReplies",  data)
            props.setResponseMessage({code:template})
        }
        else {
            props.setResponseMessage(false)
        }
    }
    const actionDeleteButtons = async (id) => {
        let newArrButtonsAct = activeButtons
        newArrButtonsAct = newArrButtonsAct.filter(function(item) {
            return item.id !== id
        })
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr[i].active = "false"
            }
        }

        setButtons(newArr)
        setActiveButtons(newArrButtonsAct)
        setChangeObjButtons(new Date())
    }

    const actionAddButtons = async () => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].active === "false") {
                newArr[i].active = "true"
                setActiveButtons(prevArray => [...prevArray, newArr[i]])
                break;
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }
    return (
        <>
            <ChooseModelPlatform>
                <h2>Modello testo e bottoni rapidi</h2>
                <InfoModelPLatform>
                    <p>Con questo template è possibile inviare un messaggio di testo seguito da uno o più bottoni rapidi. Con bottone rapido si intende un bottone che una volta premuto dall'utente scompare dalla chat.</p>
                </InfoModelPLatform>
                <BoxElementModelPlatform>
                    <BoxItemTextPlatform>
                        <label>Testo del messaggio (obbligatorio)</label>
                        <textarea
                            onChange={onChangeElementTextarea}
                            ref={textareatext}
                            placeholder="Scrivi il testo del messaggio..."
                        />
                    </BoxItemTextPlatform>
                    <BoxItemButtonsPlatform>
                        <h3>Bottoni (massimo 11 elementi)</h3>
                        {(() => {
                            if (buttons.length) {
                                return buttons.map((child, i) => {
                                    if (child.active === "true") {
                                        return (
                                            <>
                                                <WrapperButtonPlatform key={i}>
                                                    <BoxHeaderButton>
                                                        <BoxNumberButton>
                                                            {i + 1}
                                                        </BoxNumberButton>
                                                    </BoxHeaderButton>
                                                    <BoxContentTypeButtonPl>
                                                        <InfoTypeButton><p>Il bottone rapido suggerisce all'utente un testo da digitare in chat. Una volta premuto, il testo del bottone scompare (da qui il termine rapido) mentre il testo del postback viene inviato a Beta che fornisce la risposta. Al bottone può essere anche associata un'icona.</p></InfoTypeButton>
                                                        <ItemTypeButton><label>Testo del bottone (obbligatorio)</label><input type="text"  placeholder="Scrivi il testo del bottone..." onChange={e => actionInsertValueBtns(e, child.id, "title")} value={child.title} /></ItemTypeButton>
                                                        <ItemTypeButton><label>Testo del postback (obbligatorio)</label><input type="text" placeholder="Scrivi il testo del postback..." onChange={e => actionInsertValueBtns(e, child.id, "payload")} value={child.payload} /></ItemTypeButton>
                                                        <ItemTypeButton><label>Url dell'immagine</label><input type="text" placeholder="Scrivi l'url dell'immagine associata al pulsante..." onChange={e => actionInsertValueBtns(e, child.id, "image_url")} value={child.image_url} /></ItemTypeButton>
                                                        {(() => {
                                                            if (i != 0) {
                                                                return (
                                                                    <>
                                                                        <ButtonDeleteButton onClick={e => actionDeleteButtons(child.id)}><Delete01 color="#000" /></ButtonDeleteButton>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </BoxContentTypeButtonPl>
                                                </WrapperButtonPlatform>
                                            </>
                                        )
                                    }

                                })
                            }
                        })()}
                        {(() => {
                            if (activeButtons.length  != buttons.length) {
                                return (
                                    <>
                                        <WrapperButtonPlatform>
                                            <Button01 onClick={actionAddButtons}>Aggiungi bottone</Button01>
                                        </WrapperButtonPlatform>
                                    </>
                                )
                            }
                        })()}
                    </BoxItemButtonsPlatform>
                </BoxElementModelPlatform>
            </ChooseModelPlatform>
        </>
    )

}


export default TextQuickReplies