import React from 'react';

const SvgNote02= props => (
    <svg
        viewBox="0 0 36 36"
    >
        <path fill={props.color}
            d="M33,6.4,29.3,2.7a1.71,1.71,0,0,0-2.36,0L23.65,6H6A2,2,0,0,0,4,8V30a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V11.76l3-3A1.67,1.67,0,0,0,33,6.4ZM18.83,20.13l-4.19.93,1-4.15,9.55-9.57,3.23,3.23ZM29.5,9.43,26.27,6.2l1.85-1.85,3.23,3.23Z"
            className="clr-i-solid clr-i-solid-path-1"></path>
        <rect fill={props.color} x="0" y="0" width="36" height="36" fill-opacity="0"/>
    </svg>
);

export default SvgNote02;