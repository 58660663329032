import React, {useState, useEffect, useRef} from "react"
import TextModel from "./TextModel";
import {
    BoxContentTypeButtonPl,
    BoxElementModelPlatform, BoxHeaderSlide, BoxNumberSlide, BoxSlideModelPlatform, BoxHeaderButton, BoxItemButtonsPlatform,
    BoxItemTextPlatform, BoxNumberButton, BoxSelectTypeButtonPl, BoxSizeWebView, ButtonDeleteButton,
    ChooseModelPlatform, WrapperButtonSlide,
    InfoModelPLatform, InfoTypeButton, ItemTypeButton, WrapperButtonPlatform, WrapperSlideModelPlatform
} from "../../../../styles/Modals";
import Delete01 from "../../../../source/svg/Delete01";
import {setMessengerTemplate} from "../../../../utils/platformTemplates";
import {Button01} from "../../../../styles/Commons";



function SliderModel(props) {
    const [activesSlides, setActivesSlides] = useState([])

    const [activesButtons, setActivesButtons] = useState([])


    const [changeObjButtons, setChangeObjButtons] = useState(false)
    const [changeObjSlides, setChangeObjSlides] = useState(false)
    const [slides, setSlides] = useState([
        {
            id:"slide01",
            active:"true",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide02",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide03",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide04",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide05",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide06",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide07",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide08",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        },
        {
            id:"slide09",
            active:"false",
            image_url:"",
            title:"",
            subtitle:""
        }
    ])
    const [buttons, setButtons] = useState([
        {
            id:"button01Slide01",
            main_select_value:"slide01btn01Link",
            slide_id:"slide01",
            link_button: {
                value:"slide01btn01Link",
                active:"true",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide01btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide01",
            main_select_value:"slide01btn02Link",
            slide_id:"slide01",
            link_button: {
                value:"slide01btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide01btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button03Slide01",
            main_select_value:"slide01btn03Link",
            slide_id:"slide01",
            link_button: {
                value:"slide01btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide01btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide02",
            main_select_value:"slide02btn01Link",
            slide_id:"slide02",
            link_button: {
                value:"slide02btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide02btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide02",
            main_select_value:"slide02btn02Link",
            slide_id:"slide02",
            link_button: {
                value:"slide02btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide02btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button03Slide02",
            main_select_value:"slide02btn03Link",
            slide_id:"slide02",
            link_button: {
                value:"slide02btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide02btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide03",
            main_select_value:"slide03btn01Link",
            slide_id:"slide03",
            link_button: {
                value:"slide03btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide03btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide03",
            main_select_value:"slide03btn02Link",
            slide_id:"slide03",
            link_button: {
                value:"slide03btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide03btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button03Slide03",
            main_select_value:"slide03btn03Link",
            slide_id:"slide03",
            link_button: {
                value:"slide03btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide03btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide04",
            main_select_value:"slide04btn01Link",
            slide_id:"slide04",
            link_button: {
                value:"slide04btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide04btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide04",
            main_select_value:"slide04btn02Link",
            slide_id:"slide04",
            link_button: {
                value:"slide04btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide04btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide04",
            main_select_value:"slide04btn03Link",
            slide_id:"slide04",
            link_button: {
                value:"slide04btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide04btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide05",
            main_select_value:"slide05btn01Link",
            slide_id:"slide05",
            link_button: {
                value:"slide05btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide05btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide05",
            main_select_value:"slide05btn02Link",
            slide_id:"slide05",
            link_button: {
                value:"slide05btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide05btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide05",
            main_select_value:"slide05btn03Link",
            slide_id:"slide05",
            link_button: {
                value:"slide05btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide05btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide06",
            main_select_value:"slide06btn01Link",
            slide_id:"slide06",
            link_button: {
                value:"slide06btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide06btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide06",
            main_select_value:"slide06btn02Link",
            slide_id:"slide06",
            link_button: {
                value:"slide06btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide06btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide06",
            main_select_value:"slide06btn03Link",
            slide_id:"slide06",
            link_button: {
                value:"slide06btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide06btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide07",
            main_select_value:"slide07btn01Link",
            slide_id:"slide07",
            link_button: {
                value:"slide07btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide07btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide07",
            main_select_value:"slide07btn02Link",
            slide_id:"slide07",
            link_button: {
                value:"slide07btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide07btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide07",
            main_select_value:"slide07btn03Link",
            slide_id:"slide07",
            link_button: {
                value:"slide07btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide07btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide08",
            main_select_value:"slide08btn01Link",
            slide_id:"slide08",
            link_button: {
                value:"slide08btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide08btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide08",
            main_select_value:"slide08btn02Link",
            slide_id:"slide08",
            link_button: {
                value:"slide08btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide08btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide08",
            main_select_value:"slide08btn03Link",
            slide_id:"slide08",
            link_button: {
                value:"slide08btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide08btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },

        {
            id:"button01Slide09",
            main_select_value:"slide09btn01Link",
            slide_id:"slide09",
            link_button: {
                value:"slide09btn01Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide09btn01Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button02Slide09",
            main_select_value:"slide09btn02Link",
            slide_id:"slide09",
            link_button: {
                value:"slide09btn02Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide09btn02Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        },
        {
            id:"button04Slide09",
            main_select_value:"slide09btn03Link",
            slide_id:"slide09",
            link_button: {
                value:"slide09btn03Link",
                active:"false",
                title:"",
                url:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"web_url",
            },
            tip_button: {
                value:"slide09btn03Tip",
                active:"false",
                title:"",
                payload:"",
                type:"postback"
            }
        }
    ])

    const changeContentModel = () => {
        let objdata = JSON.parse(props.replie.message_data)
        let savedslides = objdata.attachment.payload.elements
        let newslides = slides
        let newbuttons = buttons
        let activeslides = []
        let activeButtons = []
        let savedbuttons = []

        for (let i = 0; i < savedslides.length; i++) {
            if (i < newslides.length) {
                newslides[i].title = savedslides[i].title
                if (savedslides[i].subtitle)  newslides[i].subtitle = savedslides[i].subtitle
                if (savedslides[i].image_url) newslides[i].image_url = savedslides[i].image_url
                newslides[i].active = "true"
                if (savedslides[i].buttons.length) {
                    let targetbuttons = savedslides[i].buttons
                    let filterbuttons = newbuttons.filter(button => button.slide_id ===  newslides[i].id)
                    for (let a = 0; a < targetbuttons.length; a++) {
                       if (filterbuttons[a].slide_id === newslides[i].id) {

                            if (targetbuttons[a].type === "web_url") {
                                filterbuttons[a].link_button.active = "true"
                                filterbuttons[a].tip_button.active = "false"
                                filterbuttons[a].link_button.title = targetbuttons[a].title
                                filterbuttons[a].link_button.url= targetbuttons[a].url
                                if (targetbuttons[a].webview_height_ratio) {
                                    filterbuttons[a].link_button.webview = targetbuttons[a].webview_height_ratio
                                    filterbuttons[a].link_button.webview_main_select_value = targetbuttons[a].webview_height_ratio
                                }
                                activeButtons.push(filterbuttons[a])
                            }
                            else if (targetbuttons[a].type === "postback") {
                                filterbuttons[a].main_select_value = newbuttons[i].tip_button.value
                                filterbuttons[a].link_button.active = "false"
                                filterbuttons[a].tip_button.active = "true"
                                filterbuttons[a].tip_button.title = targetbuttons[a].title
                                filterbuttons[a].tip_button.payload= targetbuttons[a].payload
                                activeButtons.push(filterbuttons[a])
                            }
                        }

                    }
                    for (let a = 0; a < newbuttons.length; a++) {
                        for (let z = 0; z < filterbuttons.length; z++) {
                            if (newbuttons[a].id === filterbuttons[z].id) {
                                newbuttons[a] = filterbuttons[z]
                            }
                        }
                    }

                }
                activeslides.push(newslides[i])

            }
        }
        setSlides(newslides)
        setActivesSlides(activeslides)
        setButtons(newbuttons)
        setActivesButtons(activeButtons)
        setChangeObjSlides(new Date())
        setChangeObjButtons(new Date)

    }
    useEffect(() => {
        if (props.replie)changeContentModel()
        else {
            setActivesSlides([slides[0]])
            setActivesButtons([buttons[0]])
        }




    }, []);
    useEffect(() => {
        if (changeObjSlides) checkElementToSend()

    }, [changeObjSlides]);

    useEffect(() => {
        if (changeObjButtons) checkElementToSend()


    }, [changeObjButtons]);

    const actionInsertValueSlides = async (e, id,  target) => {
        let newSlides = slides
        let newActiveSlides = activesSlides
        for (let i = 0; i < newSlides.length; i++) {
            if (newSlides[i].id === id) {
                newSlides[i][target] = e.target.value
            }
        }
        for (let i = 0; i < newActiveSlides.length; i++) {
            if (newActiveSlides[i].id === id) {
                newActiveSlides[i][target] = e.target.value
            }
        }
        setActivesSlides(newActiveSlides)
        setSlides(newSlides)
        setChangeObjSlides(new Date())
    }

    const onChangeTypeButton = async (e) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].link_button.value === e.target.value) {
                if (newArr[i].link_button.active === "true") {
                    newArr[i].link_button.active = "false"
                    newArr[i].tip_button.active = "true"
                }
                else {
                    newArr[i].link_button.active = "true"
                    newArr[i].tip_button.active = "false"
                }
            }
            if (newArr[i].tip_button.value === e.target.value) {
                if (newArr[i].tip_button.active === "true") {
                    newArr[i].tip_button.active = "false"
                    newArr[i].link_button.active = "true"
                }
                else {
                    newArr[i].tip_button.active = "true"
                    newArr[i].link_button.active = "false"
                }
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())

    }
    const actionInsertValueBtns = async (e, id, type, target) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr[i][type][target] = e.target.value
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }

    const checkElementToSend = async (e) => {
        let isabletosend = false
        let newslides = activesSlides
        let objbutton
        let elementsarr = []
        let nameelements
        for (let i = 0; i < newslides.length; i++) {
            if (newslides[i].title != "") {
                if (i === 0) nameelements = newslides[i].title
                let newelement = {
                    title:newslides[i].title,
                    buttons:[]
                }
                if (newslides[i].image_url != "") newelement.image_url = newslides[i].image_url
                if (newslides[i].subtitle != "") newelement.subtitle = newslides[i].subtitle
                let buttonstarget = buttons.filter(element => element.slide_id === newslides[i].id)
                if (buttonstarget.length) {

                    for (let a = 0; a < buttonstarget.length; a++) {
                        //console.log(buttonstarget[a])
                        if (buttonstarget[a].link_button.active === "true") {
                            if (buttonstarget[a].link_button.title != "" && buttonstarget[a].link_button.url != "") {
                                isabletosend= true
                                objbutton = {
                                    "webview_height_ratio": buttonstarget[a].link_button.webview,
                                    "type": "web_url",
                                    "url": buttonstarget[a].link_button.url,
                                    "title": buttonstarget[a].link_button.title
                                }
                                newelement.buttons.push(objbutton)
                            }
                            else {
                                isabletosend = false
                                newelement.buttons = []
                            }
                        }
                        else if (buttonstarget[a].tip_button.active === "true") {
                            if (buttonstarget[a].tip_button.title != "" && buttonstarget[a].tip_button.payload != "") {
                                isabletosend= true
                                objbutton = {
                                    "type": "postback",
                                    "payload": buttonstarget[a].tip_button.payload,
                                    "title": buttonstarget[a].tip_button.title
                                }
                                newelement.buttons.push(objbutton)
                            }
                            else {
                                isabletosend = false
                                newelement.buttons = []
                            }

                        }
                    }
                }
                elementsarr.push(newelement)
            }
            else {
                isabletosend = false
                break
            }

        }
        if (isabletosend) {
            //console.log(elementsarr)
            let data = {
                name:nameelements,
                elements:elementsarr
            }
            let template = setMessengerTemplate("slider",  data)
            props.setResponseMessage({code:template})
        }
        else {
            props.setResponseMessage(false)
        }
    }
    const actionAddButtons = async (slide_id) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].slide_id === slide_id && (newArr[i].link_button.active === "false" && newArr[i].tip_button.active === "false")) {
                newArr[i].link_button.active = "true"
                setActivesButtons(prevArray => [...prevArray, newArr[i]])
                break;
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }
    const actionAddSlide = async () => {
        let newArrSlide = slides
        let newArrButtons = buttons
        let slidetarget
        for (let i = 0; i < newArrSlide.length; i++) {
            if (newArrSlide[i].active === "false") {
                newArrSlide[i].active = "true"
                slidetarget = newArrSlide[i].id
                setActivesSlides(prevArray => [...prevArray, newArrSlide[i]])
                break;
            }
        }
        for (let i = 0; i < newArrButtons.length; i++) {
            if (newArrButtons[i].slide_id === slidetarget) {
                newArrButtons[i].link_button.active = "true"
                setActivesButtons(prevArray => [...prevArray, newArrButtons[i]])
                break;
            }
        }
        setSlides(newArrSlide)
        setButtons(newArrButtons)
        setChangeObjSlides(new Date())
        setChangeObjButtons(new Date())
    }

    const actionDeleteSlide = async (id) => {
        let newArrButtonsAct = activesButtons
        newArrButtonsAct = newArrButtonsAct.filter(function(item) {
            return item.slide_id !== id
        })

        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].slide_id === id) {
                newArr[i].link_button.active = "false"
                newArr[i].tip_button.active = "false"
            }
        }

        let newArrSlideAct = activesSlides
        newArrSlideAct = newArrSlideAct.filter(function(item) {
            return item.id !== id
        })

        let newSlides = slides
        for (let i = 0; i < newSlides.length; i++) {
            if (newSlides[i].id === id) {
                newSlides[i].active = "false"
            }
        }

        setButtons(newArr)
        setActivesButtons(newArrButtonsAct)
        setSlides(newSlides)
        setActivesSlides(newArrSlideAct)
        setChangeObjButtons(new Date())
        setChangeObjSlides(new Date())
    }

    const actionDeleteButtons = async (id) => {
        let newArrButtonsAct = activesButtons
        newArrButtonsAct = newArrButtonsAct.filter(function(item) {
            return item.id !== id
        })

        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr[i].link_button.active = "false"
                newArr[i].tip_button.active = "false"
            }
        }

        setButtons(newArr)
        setActivesButtons(newArrButtonsAct)
        setChangeObjButtons(new Date())
    }
    return (
        <>
            <ChooseModelPlatform>
                <h2>Modello slider</h2>
                <InfoModelPLatform>
                    <p>Con questo template è possibile inviare un messaggio composto da una o più slide. Ogni slide comprende diversi elementi: immagine, titolo, sottotitolo e bottoni permanenti.</p>
                </InfoModelPLatform>
                <BoxSlideModelPlatform>
                    <h3>Slide (massimo 9 elementi)</h3>
                    {(() => {
                        return activesSlides.map((slide, i) => {
                            if (slide.active === "true") {

                                return (
                                    <div key={i}>
                                        <BoxHeaderSlide>
                                            <BoxNumberSlide>
                                                {i +1}
                                            </BoxNumberSlide>
                                        </BoxHeaderSlide>
                                        <WrapperSlideModelPlatform>

                                            <BoxItemTextPlatform>
                                                <ItemTypeButton><label>Titolo (obbligatorio) </label><input type="text"  placeholder="Scrivi il titolo..." onChange={e => actionInsertValueSlides(e, slide.id, "title")} value={slide.title} /></ItemTypeButton>
                                                <ItemTypeButton><label>Sottotitolo </label><input type="text"  placeholder="Scrivi il sottotitolo..." onChange={e => actionInsertValueSlides(e, slide.id, "subtitle")} value={slide.subtitle} /></ItemTypeButton>
                                                <ItemTypeButton><label>Url Immagine </label><input type="text"  placeholder="Scrivi l'url dell'immagine..." onChange={e => actionInsertValueSlides(e, slide.id, "image_url")} value={slide.image_url} /></ItemTypeButton>
                                            </BoxItemTextPlatform>
                                            <BoxItemButtonsPlatform>
                                                <h3>Bottoni (massimo 3 elementi)</h3>
                                                {(() => {
                                                    if (buttons.length) {
                                                        return buttons.map((child, a) => {
                                                            let numberbutton = a + 1
                                                            if (numberbutton > 3) {
                                                                switch (numberbutton) {
                                                                    case 4: case 7: case 10: case 13: case 16: case 19: case 22: case 25:
                                                                        numberbutton = 1
                                                                        break;
                                                                    case 5: case 8: case 11: case 14: case 17: case 20: case 23: case 26:
                                                                        numberbutton = 2
                                                                        break;
                                                                    case 6: case 9: case 12: case 15: case 18: case 21: case 22: case 27:
                                                                        numberbutton = 3
                                                                        break;
                                                                }
                                                            }
                                                            if (slide.id === child.slide_id && (child.link_button.active === "true" || child.tip_button.active === "true")) {
                                                                return (
                                                                    <WrapperButtonPlatform key={a}>
                                                                        <BoxHeaderButton>
                                                                            <BoxNumberButton>
                                                                                {numberbutton}
                                                                            </BoxNumberButton>
                                                                            <BoxSelectTypeButtonPl>
                                                                                <select defaultValue={child.main_select_value} onChange={onChangeTypeButton}>
                                                                                    {(() => {
                                                                                        if (child.main_select_value === child.link_button.value) {
                                                                                            return (
                                                                                                <>
                                                                                                    <option value={child.link_button.value}>Link</option>
                                                                                                    <option value={child.tip_button.value}>Suggerimento</option>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                        else {
                                                                                            return (
                                                                                                <>
                                                                                                    <option value={child.tip_button.value}>Suggerimento</option>
                                                                                                    <option value={child.link_button.value}>Link</option>
                                                                                                </>
                                                                                            )

                                                                                        }
                                                                                    })()}
                                                                                </select>
                                                                            </BoxSelectTypeButtonPl>
                                                                        </BoxHeaderButton>
                                                                        <BoxContentTypeButtonPl>
                                                                            {(() => {
                                                                                if (child.link_button.active === "true") {
                                                                                    return (
                                                                                        <>
                                                                                            <InfoTypeButton><p>Questa tipologia di bottone apre una una pagina web all'interno della chat.</p></InfoTypeButton>

                                                                                            <ItemTypeButton><label>Testo del bottone (obbligatorio)</label><input placeholder="Scrivi il testo del bottone..." type="text" onChange={e => actionInsertValueBtns(e, child.id, "link_button", "title")} value={child.link_button.title} /></ItemTypeButton>

                                                                                            <ItemTypeButton><label>Url del bottone (obbligatorio)</label><input placeholder="Scrivi l'url del bottone..."  type="text" onChange={e => actionInsertValueBtns(e, child.id, "link_button", "url")} value={child.link_button.url} /></ItemTypeButton>
                                                                                            <BoxSizeWebView>
                                                                                                <p>Dimensioni della Web view</p>
                                                                                                <select onChange={e => actionInsertValueBtns(e, child.id, "link_button", "webview")} defaultValue={child.link_button.webview_main_select_value}>
                                                                                                    <option value="full">Full</option>
                                                                                                    <option value="tall">Tall</option>
                                                                                                    <option value="compact">Compact</option>
                                                                                                </select>
                                                                                            </BoxSizeWebView>

                                                                                        </>
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <InfoTypeButton><p>Questa tipologia di bottone suggerisce all'utente un testo da digitare in chat. Una volta premuto, il testo del bottone viene mostrato all'utente, mentre il testo del postback viene inviato al bot che fornisce la risposta.</p></InfoTypeButton>
                                                                                            <ItemTypeButton><label>Testo del bottone (obbligatorio)</label><input type="text"  placeholder="Scrivi il testo del bottone..." onChange={e => actionInsertValueBtns(e, child.id, "tip_button", "title")} value={child.tip_button.title} /></ItemTypeButton>
                                                                                            <ItemTypeButton><label>Testo del postback (obbligatorio)</label><input type="text" placeholder="Scrivi il testo del postback..." onChange={e => actionInsertValueBtns(e, child.id, "tip_button", "payload")} value={child.tip_button.payload} /></ItemTypeButton>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                            {(() => {
                                                                                if (a === 0 || numberbutton === 1 ) {

                                                                                    return (
                                                                                        null
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <>
                                                                                            <ButtonDeleteButton onClick={e => actionDeleteButtons(child.id)}><Delete01 color="#000" /></ButtonDeleteButton>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })()}
                                                                        </BoxContentTypeButtonPl>
                                                                    </WrapperButtonPlatform>

                                                                )
                                                            }

                                                        })
                                                    }
                                                })()}
                                                {(() => {
                                                    let activebuttonsslide = activesButtons.filter(element => element.slide_id === slide.id)
                                                    if (activebuttonsslide.length === 3) {
                                                        return (
                                                            null
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <>
                                                                <WrapperButtonPlatform>
                                                                    <Button01 onClick={e => actionAddButtons(slide.id)}>Aggiungi bottone</Button01>
                                                                </WrapperButtonPlatform>
                                                            </>
                                                        )
                                                    }
                                                })()}
                                            </BoxItemButtonsPlatform>
                                            {(() => {
                                                if (i != 0) {
                                                    return (
                                                        <>
                                                            <ButtonDeleteButton onClick={e => actionDeleteSlide(slide.id)}><Delete01 color="#000" /></ButtonDeleteButton>
                                                        </>
                                                    )
                                                }

                                            })()}
                                        </WrapperSlideModelPlatform>
                                        {(() => {

                                            if (activesSlides.length != slides.length) {
                                                return (
                                                    <>
                                                        <WrapperButtonSlide>
                                                            <Button01 onClick={actionAddSlide}>Aggiungi slide</Button01>
                                                        </WrapperButtonSlide>
                                                    </>
                                                )
                                            }
                                        })()}
                                    </div>
                                )
                            }

                        })
                    })()}
                </BoxSlideModelPlatform>
            </ChooseModelPlatform>
         </>
    )

}


export default SliderModel