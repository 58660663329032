import React, {useEffect, useState} from "react";
import setting from "../../settings/setting";
import branch from "../../settings/branch";



function LoginPage(props) {
    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        if (!props.user.registration || !props.user.registration.active) {
            props.setModalAccess({component:"login", show:true, source:"loginpage", console:props.match.params.console})
        }
        else {
            let path = setting[branch.name].console_url + "/"
            window.open(path, "_self");
        }

    }, []);


    return (
        <></>
    )
}

export default LoginPage