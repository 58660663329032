import React, {useState, useEffect} from "react";
import BusStatsMessages from "./BusStatsMessages";

function BusStatsPage(props) {


    const properties = props

  return (
      <>
          {(() => {
              if (properties.permissions) {
                      return (
                          <div>
                              <p>Statistiche</p>
                                <BusStatsMessages />
                          </div>
                      )


              }
              else {
                  return (
                      <p>Non hai i permessi per visualizzare questa pagina</p>
                  )
              }

          })()}

       </>
  );
}

export default BusStatsPage;
