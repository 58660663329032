import branch from "../settings/branch"
import settings from "../settings/setting"
import axios from 'axios';
axios.defaults.withCredentials = true;

const serverurl = settings[branch.name].server_url
const getinfocrmpreviewuserchat_endpoint = serverurl + "/console/action/getinfoprevuserchat"
const setinfocrmpreviewuserchat_endpoint = serverurl + "/console/action/setinfoprevuserchat"

export const getInfoCrmPreviewUserChat= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getinfocrmpreviewuserchat_endpoint + '?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}
export const setInfoCrmPreviewUserChat= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setinfocrmpreviewuserchat_endpoint + '?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            console.log(error);
        })

}

