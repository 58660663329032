import { componentscss} from "./components";

export const maincss = `
    * {
        margin:0;
        padding:0;
    }
    code,
kbd,
pre,
samp {
    font-size: 1em;
}
.box-body-result span,
dfn {
    font-style: italic;
}
button,
hr,
input {
    overflow: visible;
}
audio,
canvas,
progress,
video {
    display: inline-block;
}
progress,
sub,
sup {
    vertical-align: baseline;
}
*,
legend {
    box-sizing: border-box;
}
body,
html,
sub,
sup {
    position: relative;
}
#menu li a,
a,
a:hover {
    text-decoration: none;
}
a:hover,
a:hover img,
button:hover {
    opacity: 0.7;
}
html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
    display: block;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
}
a:active,
a:hover {
    outline-width: 0;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp {
    font-family: monospace, monospace;
}
mark {
    background-color: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button,
select {
    text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
    -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
    outline: ButtonText dotted 1px;
}
fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
legend {
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
summary {
    display: list-item;
}
[hidden],
template {
    display: none;
}
html,
html > body {
    font: 100% Roboto, Trebuchet MS, Arial, Trebuchet MS Tahoma, Verdana, sans-serif;
    text-align: left;
    font-weight: 400;
}
html > body {
    font-size: 16px;
}
a,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
table,
ul {
    font-size: 1em;
    color: #000;
}
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    font-weight: 600;
}
div a,
div div,
div fieldset,
div h1,
div h2,
div h3,
div h4,
div h5,
div h6,
div ol,
div p,
div table,
div ul,
ol li a,
ol li h2,
ol li h3,
ol li h4,
ol li h5,
ol li h6,
ol li p,
ol li ul,
p a,
table a,
table h2,
table h3,
table h4,
table h5,
table h6,
table ol,
table p,
table ul,
ul li a,
ul li h2,
ul li h3,
ul li h4,
ul li h5,
ul li h6,
ul li ol,
ul li p,
ul li ul {
    font-size: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}
code,
pre {
    font-family: "Source Code Pro", monospace !important;
}
.reader {
    position: absolute;
    top: -100000px !important;
    left: -100000px !important;
}
.remove {
    display: none !important;
}
.block {
    display: block !important;
}
#blogo  {
    width:auto;
    display:block;
}
#blogo img  {
    width:200px;
    display:block;
}

#appimage_listapp_header {
    width:35px;
    height:35px;
    margin-right:0;
}
#appimage_listapp_header img {
    width:30px !important;
    height:30px !important;
}
#sectdev_menubar_app_console {
border-bottom:solid 1px #d4d4d4;
}

.list_section_app {
    list-style:none;
}
.list_section_app li{
    margin-bottom:15px;
}
.list_section_app li a{
    font-weight:normal;
    font-size:1.1em;
}


#box_list_conversations {
}
.listitem_conversation  {
    display:flex;
    padding:15px 25px;
    cursor:pointer;
}
.listitem_conversation:hover, .listitem_conversation_active  {
background:#f5f6f7;
}
.listitem_pic_user_conv {
    width:40px;
    
}
.listitem_pic_user_conv img {
    display:block !important;
    width:40px;
    height:40px;
    border-radius:100%;
}
.listitem_txt_user_conv  {
    flex:1;
    padding:0 10px;
   
}
.listitem_conversation_unread    {
   position:relative;
}
.listitem_conversation_unread p.subtxt_user_name   {
    font-weight:bold !important;   
}
.listitem_conversation_unread:after   {
    position:absolute;
    right:10px;
    top:50%;
    margin-top:-5px;
    width:10px;
    height:10px;
    border-radius:100%;
    background:#FF398A;
    content:"";
}
.listitem_txt_user_conv .subtxt_user_conv  {
    font-size:.9em;
    color:#919191;
    margin-top:5px;
}
.listitem_date_user_conv {
    max-width:100px;;
}
.listitem_date_user_conv p {
    font-size:.8em;
    color:#919191;
}

#bus_wrapper_chat {
    overflow:auto;
    padding:10px;
}

.date-chat {
    padding:10px;
    text-align:center;
    color:#bfbfbf;
    font-size:0.8em
}
.viewdefault_row_chat {
    display:flex;
    align-items:center;
    margin-top:20px;
}
.viewdefault_opt_msg_picture  {
    width:35px;
    margin-right:20px;
    align-self:flex-end;
    
}
.viewdefault_opt_msg_picture img {
    width:35px;
    height:35px;
    display:block !important;
    
}
.viewdefault_message_wrapper {
    display:flex; 
    flex-flow:column; 
    padding:10px; 
    border-radius:10px;

}
.viewdefault_message_wrapper_user {
    background:#f7eff3;
    max-width:400px;
}
.viewdefault_message_wrapper_app {
    background:#fafafa;
    padding:20px;
    max-width:100%;
}
.viewdefault_message_wrapper_app img {
    max-width:400px;
} 
.viewdefault_message_wrapper_app_WA {
    max-width:60%;
} 
.viewdefault_message_wrapper_user .container_message{
    align-self:flex-start;
    padding:10px;
}
.viewdefault_row_chat_app {
    justify-content:flex-end;
}
.viewdefault_row_chat_app .viewdefault_opt_msg_picture {
    order:2;
    margin-left:20px;
}
.viewdefault_row_chat  .user_message_file_icon svg, .viewdefault_row_chat .user_message_position_icon svg {
    width:50px;
    height:50px;
    margin-bottom:10px;
    color:#FF398A;
}
.viewdefault_row_chat .user_message_video video, .viewdefault_row_chat .user_message_image img {
    width:300px;
    margin-bottom:10px;
}
.viewdefault_row_chat .user_message_type  {
    color:#686868;
    font-style:italic;
    font-size:0.9em;
    margin-top:0;
    margin-bottom:10px;
}
.viewdefault_row_chat .user_file {cursor:pointer}
${componentscss}
`;
