import React, {useState} from "react";
import {userRegistration} from "../../hooks/UserProvider";

function Registration(props) {
    const [InputFirstname, setInputFirstname] = useState("")
    const [InputLastname, setInputLastname] = useState("")
    const [InputEmail, setInputEmail] = useState("")
    const [InputPassword, setInputPassword] = useState("")
    const [showError, setShowError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    async function sendDataForm(e) {
        e.preventDefault();
        const dataUser = {
            firstname: InputFirstname,
            lastname: InputLastname,
            email: InputEmail,
            password: InputPassword
        }
        try {
            let responsereg = await userRegistration(dataUser);
            if (responsereg.error) {
                setShowError(true)
                if (responsereg.error_code === 401) {
                    if (responsereg.error_subcode && responsereg.error_subcode === 401.2) props.setModalContent({component:"securCode", source:"registration", show:true, message: responsereg.errorreply.message.toString()})
                    else setErrorMessage(responsereg.errorreply.message.toString())
                }
                else {
                    setErrorMessage("Something was wrong")
                }
            }
            else {
                (responsereg.token) && props.setToken(responsereg.token)
                props.setModalContent({component:"securCode", source:"registration", show:true})
            }


        }catch (e) {

        }
    }
    return (
        <div>
            <h1>Registration</h1>
            {showError && (
                <p>{errorMessage}</p>
            )
            }
            <form onSubmit={sendDataForm}>
                <p><input placeholder="nome" type="text" name="name" value={InputFirstname} onChange={e => setInputFirstname(e.target.value)}/></p>
                <p><input placeholder="cognome" type="text" name="surname" value={InputLastname} onChange={e => setInputLastname(e.target.value)}/></p>
                <p><input placeholder="email" type="text" name="email" value={InputEmail} onChange={e => setInputEmail(e.target.value)}/></p>
                <p><input placeholder="password" type="password" name="password" value={InputPassword} onChange={e => setInputPassword(e.target.value)}/></p>
                <input type="submit" value="invia" />
            </form>
        </div>
    )
}
export default Registration