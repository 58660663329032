import React, {useState, useEffect, useRef} from "react"
import TextModel from "./TextModel";
import {
    BoxElementModelPlatform,
    BoxItemTextPlatform,
    ChooseModelPlatform,
    InfoModelPLatform,
    BoxItemButtonsPlatform,
    WrapperButtonPlatform,
    BoxSelectTypeButtonPl,
    BoxContentTypeButtonPl,
    InfoTypeButton,
    ItemTypeButton,
    BoxSizeWebView,
    ButtonDeleteButton,
    BoxNumberButton,
    BoxHeaderButton
} from "../../../../styles/Modals";
import {setMessengerTemplate} from "../../../../utils/platformTemplates";
import index from "react-html-parser/lib/elementTypes";
import {Button01} from "../../../../styles/Commons";
import Delete01 from "../../../../source/svg/Delete01";



function TextButtonsModel(props) {

    const [textValue, setTextValue] = useState(false)
    const [changeObjButtons, setChangeObjButtons] = useState(false)

    const [buttons, setButtons] = useState([
        {
            id:"button01",
            main_select_value:"button01Link",
            link_button: {
                value:"button01Link",
                active:"true",
                text_button:"",
                url_button:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"url",
            },
            tip_button: {
                value:"button01Tip",
                active:"false",
                text_button:"",
                postback_button:"",
                type:"postback"
            }
        },
        {
            id:"button02",
            main_select_value:"button02Link",
            link_button: {
                value:"button02Link",
                active:"false",
                text_button:"",
                url_button:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"url"
            },
            tip_button: {
                value:"button02Tip",
                active:"false",
                text_button:"",
                postback_button:"",
                type:"postback"
            }
        },
        {
            id:"button03",
            main_select_value:"button03Link",
            link_button: {
                value:"button03Link",
                active:"false",
                text_button:"",
                url_button:"",
                webview:"full",
                webview_main_select_value:"full",
                type:"url"
            },
            tip_button: {
                value:"button03Tip",
                active:"false",
                text_button:"",
                postback_button:"",
                type:"postback"
            }
        }
    ])
    const [sendButtons, setSendButtons] = useState([])
    const [activeButtons, setActiveButtons] = useState([])

    const textareatext= useRef(null)

    useEffect(() => {
        if (props.replie) changeContentModel()
        else setActiveButtons([buttons[0]])

    }, []);


    useEffect(() => {
        checkElementToSend()

    }, [changeObjButtons]);

    useEffect(() => {
        checkElementToSend()

    }, [textValue]);

    const changeContentModel = () => {
        let objdata = JSON.parse(props.replie.message_data)
        let savedmessage = objdata.attachment.payload.text
        let savedbuttons = objdata.attachment.payload.buttons
        let newActiveButtons = []
        let newbuttons = buttons

        for (let a = 0; a < savedbuttons.length; a++) {
            if (a < newbuttons.length) {
                if (savedbuttons[a].type === "web_url") {
                    newbuttons[a].link_button.active = "true"
                    newbuttons[a].tip_button.active = "false"
                    newbuttons[a].link_button.text_button = savedbuttons[a].title
                    newbuttons[a].link_button.url_button= savedbuttons[a].url
                    if (savedbuttons[a].webview_height_ratio) {
                        newbuttons[a].link_button.webview = savedbuttons[a].webview_height_ratio
                        newbuttons[a].link_button.webview_main_select_value = savedbuttons[a].webview_height_ratio
                    }
                    activeButtons.push(newbuttons[a])
                }
                else if (savedbuttons[a].type === "postback") {
                    newbuttons[a].main_select_value = newbuttons[a].tip_button.value
                    newbuttons[a].link_button.active = "false"
                    newbuttons[a].tip_button.active = "true"
                    newbuttons[a].tip_button.text_button = savedbuttons[a].title
                    newbuttons[a].tip_button.postback_button= savedbuttons[a].payload
                    activeButtons.push(newbuttons[a])
                }
            }

        }
        textareatext.current.value = savedmessage
        setTextValue(savedmessage)
        setButtons(newbuttons)
        setActiveButtons(activeButtons)
        setChangeObjButtons(new Date())
    }


    const onChangeElementTextarea = async (e) => {
        e.stopPropagation()
        let value = e.target.value
        if (value != "") {
            setTextValue(e.target.value)
        }
        else {
            setTextValue(false)
        }
    }

    const checkElementToSend = async (e) => {
        let isabletosend = false
        let buttonsarr= []
        let button = {}
        if (textValue && textValue != "" && buttons.length) {
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].link_button.active === "true") {
                    if (buttons[i].link_button.text_button != "" && buttons[i].link_button.url_button != "") {
                        isabletosend= true
                        button = {
                            "webview_height_ratio": buttons[i].link_button.webview,
                            "type": "web_url",
                            "url": buttons[i].link_button.url_button,
                            "title": buttons[i].link_button.text_button
                        }
                        buttonsarr.push(button)
                    }
                    else {
                        isabletosend = false
                        buttonsarr = []
                    }
                }
                else if (buttons[i].tip_button.active === "true") {
                    if (buttons[i].tip_button.text_button != "" && buttons[i].tip_button.postback_button != "") {
                        isabletosend= true
                        button = {
                            "type": "postback",
                            "payload": buttons[i].tip_button.postback_button,
                            "title": buttons[i].tip_button.text_button
                        }
                        buttonsarr.push(button)
                    }
                    else {
                        isabletosend = false
                        buttonsarr = []
                    }

                }
            }
        }
        if (isabletosend) {

            let data = {
                text:textValue,
                buttons:buttonsarr
            }
            let template = setMessengerTemplate("textButtons",  data)
            props.setResponseMessage({code:template})
        }
        else {
            props.setResponseMessage(false)
        }
    }

    const actionAddButtons = async () => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].link_button.active === "false" && newArr[i].tip_button.active === "false") {
                newArr[i].link_button.active = "true"
                setActiveButtons(prevArray => [...prevArray, newArr[i]])
                break;
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }

    const actionDeleteButtons = async (id) => {
        let newArrButtonsAct = activeButtons
        newArrButtonsAct = newArrButtonsAct.filter(function(item) {
            return item.id !== id
        })
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
                newArr[i].link_button.active = "false"
                newArr[i].tip_button.active = "false"
            }
        }

        setButtons(newArr)
        setActiveButtons(newArrButtonsAct)
        setChangeObjButtons(new Date())
    }

    const onChangeTypeButton = async (e) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].link_button.value === e.target.value) {
                if (newArr[i].link_button.active === "true") {
                    newArr[i].link_button.active = "false"
                    newArr[i].tip_button.active = "true"
                }
                else {
                    newArr[i].link_button.active = "true"
                    newArr[i].tip_button.active = "false"
                }
            }
            if (newArr[i].tip_button.value === e.target.value) {
                if (newArr[i].tip_button.active === "true") {
                    newArr[i].tip_button.active = "false"
                    newArr[i].link_button.active = "true"
                }
                else {
                    newArr[i].tip_button.active = "true"
                    newArr[i].link_button.active = "false"
                }
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }


    const actionInsertValueBtns = async (e, id, type, target) => {
        let newArr = buttons
        for (let i = 0; i < newArr.length; i++) {
            if (newArr[i].id === id) {
               newArr[i][type][target] = e.target.value
            }
        }
        setButtons(newArr)
        setChangeObjButtons(new Date())
    }
    return (
        <>
            <ChooseModelPlatform>
                <h2>Modello testo e bottoni permanenti</h2>
                <InfoModelPLatform>
                    <p>Con questo template è possibile inviare un messaggio di testo seguito da uno o più bottoni permanenti. Con bottone permanente si intende un bottone che una volta premuto dall'utente rimane visibile e cliccabile in chat.</p>
                </InfoModelPLatform>
                <BoxElementModelPlatform>

                    <BoxItemTextPlatform>
                        <label>Testo del messaggio (obbligatorio)</label>
                        <textarea
                            onChange={onChangeElementTextarea}
                            ref={textareatext}
                            placeholder="Scrivi il testo del messaggio..."
                        />
                    </BoxItemTextPlatform>
                    <BoxItemButtonsPlatform>
                        <h3>Bottoni (massimo 3 elementi)</h3>
                        {(() => {
                            if (buttons.length) {
                               return activeButtons.map((child, i) => {
                                        if (child.link_button.active === "true" || child.tip_button.active === "true" ) {
                                            return (
                                                <WrapperButtonPlatform key={i}>
                                                    <BoxHeaderButton>
                                                        <BoxNumberButton>
                                                            {i + 1}
                                                        </BoxNumberButton>
                                                        <BoxSelectTypeButtonPl>
                                                        <select defaultValue={child.main_select_value} onChange={onChangeTypeButton}>
                                                            {(() => {
                                                                if (child.main_select_value === child.link_button.value) {
                                                                    return (
                                                                        <>
                                                                        <option value={child.link_button.value}>Link</option>
                                                                        <option value={child.tip_button.value}>Suggerimento</option>
                                                                        </>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                            <>
                                                                                <option value={child.tip_button.value}>Suggerimento</option>
                                                                                <option value={child.link_button.value}>Link</option>
                                                                            </>
                                                                        )

                                                                }
                                                            })()}
                                                        </select>
                                                    </BoxSelectTypeButtonPl>
                                                    </BoxHeaderButton>
                                                    <BoxContentTypeButtonPl>
                                                        {(() => {
                                                            if (child.link_button.active === "true") {
                                                                return (
                                                                    <>
                                                                        <InfoTypeButton><p>Questa tipologia di bottone apre una una pagina web all'interno della chat.</p></InfoTypeButton>

                                                                        <ItemTypeButton><label>Testo del bottone (obbligatorio)</label><input placeholder="Scrivi il testo del bottone..." type="text" onChange={e => actionInsertValueBtns(e, child.id, "link_button", "text_button")} value={child.link_button.text_button} /></ItemTypeButton>

                                                                        <ItemTypeButton><label>Url del bottone (obbligatorio)</label><input placeholder="Scrivi l'url del bottone..."  type="text" onChange={e => actionInsertValueBtns(e, child.id, "link_button", "url_button")} value={child.link_button.url_button} /></ItemTypeButton>
                                                                        <BoxSizeWebView>
                                                                            <p>Dimensioni della Web view</p>
                                                                            <select onChange={e => actionInsertValueBtns(e, child.id, "link_button", "webview")} defaultValue={child.link_button.webview_main_select_value}>
                                                                                <option value="full">Full</option>
                                                                                <option value="tall">Tall</option>
                                                                                <option value="compact">Compact</option>
                                                                            </select>
                                                                        </BoxSizeWebView>

                                                                    </>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <>
                                                                        <InfoTypeButton><p>Questa tipologia di bottone suggerisce all'utente un testo da digitare in chat. Una volta premuto, il testo del bottone viene mostrato all'utente, mentre il testo del postback viene inviato al bot che fornisce la risposta.</p></InfoTypeButton>
                                                                        <ItemTypeButton><label>Testo del bottone (obbligatorio)</label><input type="text"  placeholder="Scrivi il testo del bottone..." onChange={e => actionInsertValueBtns(e, child.id, "tip_button", "text_button")} value={child.tip_button.text_button} /></ItemTypeButton>
                                                                        <ItemTypeButton><label>Testo del postback (obbligatorio)</label><input type="text" placeholder="Scrivi il testo del postback..." onChange={e => actionInsertValueBtns(e, child.id, "tip_button", "postback_button")} value={child.tip_button.postback_button} /></ItemTypeButton>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (i != 0) {
                                                                return (
                                                                    <>
                                                                        <ButtonDeleteButton onClick={e => actionDeleteButtons(child.id)}><Delete01 color="#000" /></ButtonDeleteButton>
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </BoxContentTypeButtonPl>


                                                </WrapperButtonPlatform>
                                            )
                                        }

                                    })
                            }
                        })()}

                        {(() => {
                            if (activeButtons.length != buttons.length) {
                                return (
                                    <>
                                        <WrapperButtonPlatform>
                                            <Button01 onClick={actionAddButtons}>Aggiungi bottone</Button01>
                                        </WrapperButtonPlatform>
                                    </>
                                )
                            }
                        })()}

                    </BoxItemButtonsPlatform>
                </BoxElementModelPlatform>
            </ChooseModelPlatform>
        </>
    )
}

export default TextButtonsModel