import styled from 'styled-components';
import Main_Settings from "../styles/main_settings/Main_settings"
import Main_settings from "./main_settings/Main_settings";
import {buttonSettings, ButtonRoundIconSettings, RoundIconSettings} from "./main_settings/Elements_settings";
import {Button012} from "./Commons";

export const SlideMessengerConv = styled.div`       
    padding:10px;
    align-items:stretch; 
     ${Button012} {
        margin-bottom:10px;
    }
    
`;
export const ContentSlideMsConv = styled.div`
   border:solid 5px #fff;
   flex:1;
   border-radius:20px;
`;
export const ImageSlideMsConv = styled.div`
  height:120px;
  overflow:hidden;
   img {
        width:100%;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
   }
`;
export const TitleSlideMsConv = styled.h2`
    padding-bottom:2px;
`;
export const BodySlideMsConv = styled.div`
    padding:20px;
`;
export const SubTitleSlideMsConv = styled.p`
    font-size:.9em;
`;
export const TextSlideMsConv = styled.div`
    min-height:90px;
`;
export const BtnSlideMsConv = styled.div`
   width:40px;
   height:40px; 
   :before {
       color:${buttonSettings.bg};
       font-size:40px;
       font-color:#000;
   }
`;
export const BtnSlideMsConvPrev = styled(BtnSlideMsConv)`
   
`;
export const BtnSlideMsConvNext = styled(BtnSlideMsConv)`
   
`;









