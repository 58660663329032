import React, {useState, useEffect, useRef} from "react";
import {getConversationMS, getMoreMessagesMS, setMessagesAsReadMS} from "../../../../../hooks/ConversationProvider";
import moment from "moment"
import SimpleBar from "simplebar-react";
import 'moment/locale/it';
import ParserTemplComponentsUserWA from "../../../../../utils/ParserTemplComponentsUserWA";
import ParserTemplComponentsAppWA from "../../../../../utils/ParserTemplComponentsAppWA";

import {
    BoxMainConversation,
    WrapLoadMoreMessage
} from "../../../../../styles/businessConversation"

import {
    ButtonRoundIcon
} from "../../../../../styles/Commons";
import Main_settings from "../../../../../styles/main_settings/Main_settings";
import Loader from "../../../../../utils/Loader";
import SvgAdd01 from "../../../../../source/svg/Add01";
import ParserTemplComponentsUserMS from "../../../../../utils/ParserTemplComponentsUserMS";
import ParserTemplComponentsAppMS from "../../../../../utils/ParserTemplComponentsAppMS";


function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function BusTargetConversationChatMS(props) {
    const properties = props
    const [listMessages, setListMessages] = useState([])
    const [widthMainConv, setWidthMainConv ]  = useState(false)
    const [heightWrapChat, setHeightWrapChat ]  = useState(false)
    const [heightMessagebar, setHeightMessagebar ]  = useState(false)
    const [userchat, setUserChat] = useState(false)
    const [loadMoreMessages, setLoadMoreMessages] = useState(false)


    const oldListMessageCount = usePrevious(listMessages.length)

    const mainconversation = useRef(null)

    const scrollbar= useRef()
    const scrollfocus= useRef()



    useEffect(() => {
       if (oldListMessageCount != listMessages.length) {
           //nuovo messaggio arrivato!!
           //scrollfocus.current.scrollIntoView({block: "end", inline: "nearest"})
       }

    }, [listMessages]);




    useEffect(() => {
        if (properties.conversation && properties.conversation.conversations) {
            callDettConversation()
            if (properties.conversation.user) {
                setUserChat(properties.conversation.user)
                properties.setCurrentUserConvDetails(properties.conversation.user)

            }
        }
    }, [properties.conversation]);


    //console.log(scrollbar.current)



    useEffect(() => {
        if (properties.updateConversation) {
            if (properties.conversation && properties.conversation.conversations) {
                callDettConversation()
            }
        }
    }, [properties.updateConversation]);


    /*UseInterval(async() => {
        callDettConversation()

    }, 600 )*/

    async function callDettConversation() {
        const idconv= properties.conversation.conversations._id
        let callconversation = await getConversationMS({conversation_id:idconv});
        if (callconversation && !callconversation.error && callconversation.conversation.length) {
            setListMessages(callconversation.conversation)
            properties.setCurrentConversationDetails(callconversation.conversation)
            callMessagesAsRead(callconversation.conversation)
            if (scrollfocus.current) scrollfocus.current.scrollIntoView()
        }
        else {
            setListMessages([])
        }
    }
    async function callMessagesAsRead(conversation) {

        const found = conversation.find(element => element.updates &&  element.updates.console &&  element.updates.console.read && element.updates.console.read === "false");

        if (found) {
            const idconv= properties.conversation.conversations._id
            const callmessageread = await setMessagesAsReadMS({conversation_id:idconv})
            if (!callmessageread.error) {
                props.setUpdateListConversations(new Date())
            }
        }
    }

    async function callMoreMessages() {
        setLoadMoreMessages(true)
        let currentnumbermsg = listMessages.length
        const idconv= properties.conversation.conversations._id
        let callconversationMore = await getMoreMessagesMS({conversation_id:idconv, current_number: currentnumbermsg});
        setLoadMoreMessages(false)
        if (!callconversationMore.error) {
            setListMessages(callconversationMore.conversation)
            properties.setCurrentConversationDetails(callconversationMore.conversation)
        }
    }

  return (
      <>
          <BoxMainConversation>
                  {(() => {
                      if (listMessages.length) {
                          return (
                              <>
                                  {(() => {
                                      if (loadMoreMessages) {
                                          return (
                                                  <WrapLoadMoreMessage>
                                                      <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                                  </WrapLoadMoreMessage>
                                           )

                                      }
                                      else {
                                          return (
                                                  <WrapLoadMoreMessage onClick={callMoreMessages}>
                                                      <ButtonRoundIcon title="Visualizza messaggi precedenti"><SvgAdd01 /></ButtonRoundIcon>
                                                  </WrapLoadMoreMessage>
                                           )

                                      }

                                  })()}
                                  {(() => {
                                      moment.locale('IT');
                                      let ddate = moment(listMessages[0].date.date).format('dddd, ll');
                                      let hourdate = moment(listMessages[0].date.date).format('HH: mm');


                                      return listMessages.map((child, i) => {
                                           let newdate = moment(child.date.date).format('dddd, ll');
                                           let newhour = moment(child.date.date).format('HH: mm');
                                            if (i === 0 ) {
                                                return (
                                                    <div key={i}>
                                                        <div className="date-chat">{ddate + ", " + hourdate}</div>
                                                        {(() => {
                                                            if (child.from === "user") {
                                                                return (
                                                                    <ParserTemplComponentsUserMS
                                                                        code={child.message_data}
                                                                        app={properties.app}
                                                                        userchat={userchat}
                                                                        message={child}
                                                                    />
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <ParserTemplComponentsAppMS
                                                                        code={child.message_data}
                                                                        app={properties.app}
                                                                        message={child}
                                                                    />
                                                                )
                                                            }
                                                        })()}
                                                    </div>

                                                )
                                            }
                                              else {
                                                  if (ddate != newdate) {
                                                      ddate = newdate
                                                      hourdate = newhour
                                                      return (
                                                          <div key={i}>
                                                              <div className="date-chat">{ddate + ", " + hourdate}</div>
                                                              {(() => {
                                                                  if (child.from === "user") {
                                                                      return (
                                                                          <ParserTemplComponentsUserMS
                                                                              code={child.message_data}
                                                                              app={properties.app}
                                                                              userchat={userchat}
                                                                              message={child}
                                                                          />
                                                                      )
                                                                  }
                                                                  else {
                                                                      return (
                                                                          <ParserTemplComponentsAppMS
                                                                              code={child.message_data}
                                                                              app={properties.app}
                                                                              message={child}
                                                                          />
                                                                      )
                                                                  }
                                                              })()}
                                                          </div>
                                                      )
                                                  }
                                                  else {
                                                      return (
                                                          <div key={i}>
                                                              {(() => {
                                                                  if (child.from === "user") {
                                                                      return (
                                                                          <ParserTemplComponentsUserMS
                                                                              code={child.message_data}
                                                                              app={properties.app}
                                                                              userchat={userchat}
                                                                              message={child}
                                                                          />
                                                                      )

                                                                  }
                                                                  else {
                                                                      return (
                                                                          <ParserTemplComponentsAppMS
                                                                              code={child.message_data}
                                                                              app={properties.app}
                                                                              message={child}
                                                                          />
                                                                      )
                                                                  }

                                                              })()}
                                                          </div>
                                                      )
                                                  }
                                              }

                                      })
                                  })()}
                              </>
                          )
                      }
                      else {
                          return (
                              <p>Non ci sono messaggi</p>
                          )
                      }
                  })()}
                  <div ref={scrollfocus}></div>
          </BoxMainConversation>
       </>
  );
}

export default BusTargetConversationChatMS;
