import React, {useState, useEffect, useRef} from "react";
import { UseInterval } from "../../../../hooks/UseInterval";
import {getListConversation, getMoreConversations} from "../../../../hooks/ConversationProvider";
import SimpleBar from "simplebar-react";
import "simplebar/src/simplebar.css";
import ImageUserChat from "../../../../utils/ImageUserChat";
import moment from "moment";
import ParserTemplComponentsUserList from "../../../../utils/ParserTemplComponentsUserList";
import ReactPaginate from 'react-paginate';

import {
    BoxLoader,
    BusinessListConversations, WrapLoadMoreMessage,
    PaginationListConv

} from "../../../../styles/businessConversation";
import Loader from "../../../../utils/Loader";
import Main_settings from "../../../../styles/main_settings/Main_settings";
import {ButtonRoundIcon} from "../../../../styles/Commons";
import SvgAdd01 from "../../../../source/svg/Add01";


function BusListConversations(props) {
    const properties = props
    const [conversations, setConversations] = useState(false)
    const [thisCurrentConversation, setThisCurrentConversation] = useState(false)
    const [totalConversations, setTotalConversations] = useState(false)
    const [changeObj, setChangeObj] = useState(false)
    const [loadMoreConversations, setLoadMoreConversations] = useState(false)
    const scrollbar = useRef(null)


    const [pagination, setPagination] = useState({

       data: properties.listConversations.map((value, index) => (({
            id: index,
            value:value
        }))),
        offset: 0,
        numberPerPage: 20,
        pageCount: 0,
        currentData: []
    })

    const [updatePagination, setUpdatePagination] = useState(false);



    useEffect(() => {
        //callConversations("load")
        setConversations(properties.listConversations)
        setTotalConversations(properties.totalConversations)
        setThisCurrentConversation(properties.currentConversation)
    }, []);




    useEffect(() => {
        if (pagination) {
            setPagination((prevState) => ({
                ...prevState,
                pageCount: prevState.data.length / prevState.numberPerPage,
                currentData: prevState.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
            }))
        }

    }, [pagination.numberPerPage, pagination.offset])

    useEffect(() => {
        if (pagination) {
            setPagination((prevState) => ({
                ...prevState,
                currentData: pagination.data.slice(pagination.offset, pagination.offset + pagination.numberPerPage)
            }))
            setChangeObj(new Date())
        }

    }, [pagination.data])



    useEffect(() => {
        if (properties.updateConversation) {
            callConversations()
        }

    }, [properties.updateConversation]);

    useEffect(() => {
        if (properties.updatelistConversations) {
            callConversations()
        }

    }, [properties.updatelistConversations]);



    async function callConversations(lang, add) {
        if (add) {
            setLoadMoreConversations(true)
        }
        let currentLang= props.optLangConversation
        let currentnumberconv = conversations.length
        let calllistconversations = await getListConversation({app_id:props.app._id, lang:currentLang, current_number: currentnumberconv, add:add});
        if (add) {
            setLoadMoreConversations(false)
        }
        if (!calllistconversations.error) {

            setConversations(calllistconversations.conversations)

            setTotalConversations(calllistconversations.total_conversations)
            if (lang) {
                properties.setCurrentConversation(calllistconversations.conversations[0])
                setThisCurrentConversation(calllistconversations.conversations[0])
            }
            properties.setListLang(calllistconversations.languages)
            setChangeObj(new Date())
            updateMadonna(calllistconversations.conversations)
        }
    }

    async function updateCurrentConversation(e, tConversation) {
        e.preventDefault()
        if (thisCurrentConversation && thisCurrentConversation.conversations._id != tConversation.conversations._id) {
            properties.setCurrentConversation(tConversation)
            setThisCurrentConversation(tConversation)
        }

    }

    function updateMadonna(conversation) {
        setPagination((prevState) => ({
            ...prevState,
            data: conversation.map((value, index) => (({
                id: index,
                value:value
            }))),
        }))
        setChangeObj(new Date())
    }

    async function callMoreConversations() {
        setLoadMoreConversations(true)
        let currentnumberconv = conversations.length

        let currentLang= properties.optLangConversation
        const appid= properties.app._id
        let callconversationMore = await getMoreConversations({app_id:appid, lang:currentLang, current_number: currentnumberconv});
        setLoadMoreConversations(false)
        if (!callconversationMore.error) {
            setConversations(callconversationMore.conversations)
            setChangeObj(new Date())
        }
    }
    const handlePageClick = event => {
        const selected = event.selected;
        const offset = selected * pagination.numberPerPage
        setPagination({ ...pagination, offset })
    }
  return (
        <>
          {(() => {
              if (conversations) {
                  if (pagination && pagination.currentData.length && thisCurrentConversation) {

                      return (
                          <>
                              <BusinessListConversations>
                                  {(() => {
                                      return pagination.currentData.map((child, i) => {
                                          let currentconversation = child.value
                                          let nameuser
                                          let targetuser
                                          let unreadmessage = 1

                                          if (currentconversation.last_message.updates && currentconversation.last_message.updates.console && currentconversation.last_message.updates.console.read === "true") unreadmessage = 0

                                          if (currentconversation.user || currentconversation.guest) {
                                              if (currentconversation.user) {
                                                  nameuser = currentconversation.user.name
                                                  targetuser = currentconversation.user
                                              } else {
                                                  nameuser = "User " + currentconversation.guest.sender
                                                  targetuser = currentconversation.guest
                                              }
                                          }
                                          if (currentconversation.user || currentconversation.guest)  {
                                              if (currentconversation.last_message) {
                                                  let ddate = moment(currentconversation.last_message.date.date).format('ll');
                                                  ddate = ddate.substring(0, ddate.length - 5);
                                                  return (
                                                      <div className={`listitem_conversation ${unreadmessage ? "listitem_conversation_unread" : ""} ${currentconversation.conversations._id === thisCurrentConversation.conversations._id ? "listitem_conversation_active" : ""}`}  onClick={e => updateCurrentConversation(e, currentconversation)} key={i}>
                                                          <div className="listitem_pic_user_conv">
                                                              <ImageUserChat user={targetuser}/>
                                                          </div>
                                                          <div className="listitem_txt_user_conv">

                                                              <p className="subtxt_user_name ">{nameuser}</p>
                                                              {(() => {
                                                                  if (currentconversation.last_message.from === "user") {
                                                                      return (
                                                                          <>
                                                                              <p className="subtxt_user_conv">
                                                                                  <ParserTemplComponentsUserList
                                                                                      code={currentconversation.last_message.message_data}/>
                                                                              </p>
                                                                          </>
                                                                      )
                                                                  } else {
                                                                      let htmltext = currentconversation.last_message.message_data.replace(/<[^>]+>/g, '').trim()
                                                                      let length = 10;
                                                                      let trimmedText = htmltext.substring(0, length) + "...";
                                                                      let authorconv = properties.app.public_name
                                                                      if (properties.app.conversations && properties.app.conversations.main_actor_id) authorconv = properties.app.conversations.main_actor_id.name
                                                                      if (currentconversation.last_message.actor_id) {
                                                                          authorconv = currentconversation.last_message.actor_id.name
                                                                      }
                                                                      if (authorconv.length > 15) authorconv = authorconv.substring(0, 15) + "...";
                                                                      return (
                                                                          <>
                                                                              <p className="subtxt_user_conv">
                                                                                  <span>{authorconv}: </span>{trimmedText}
                                                                              </p>
                                                                          </>
                                                                      )
                                                                  }

                                                              })()}
                                                          </div>
                                                          <div className="listitem_date_user_conv">
                                                              <p>{ddate}</p>
                                                          </div>
                                                      </div>
                                                  )
                                              } else {
                                                  return (
                                                      <div className={`listitem_conversation ${currentconversation.conversations._id === thisCurrentConversation.conversations._id ? "listitem_conversation_active" : ""}`}
                                                           onClick={e => updateCurrentConversation(e, currentconversation)} key={i}>
                                                          <div className="listitem_pic_user_conv">
                                                              <ImageUserChat user={targetuser}/>
                                                          </div>
                                                          <div className="listitem_txt_user_conv">
                                                              <p className="subtxt_user_name">{nameuser}</p>
                                                              <p className="subtxt_user_conv">No messages</p>
                                                          </div>
                                                      </div>
                                                  )
                                              }
                                          }



                                      })
                                  })()}
                                  <PaginationListConv>
                                      <ReactPaginate
                                          previousLabel={'<<'}
                                          nextLabel={'>>'}
                                          breakLabel={'...'}
                                          pageCount={pagination.pageCount}
                                          marginPagesDisplayed={1}
                                          pageRangeDisplayed={1}
                                          onPageChange={handlePageClick}
                                          containerClassName={'pagination'}
                                          activeClassName={'active'}
                                      />
                                  </PaginationListConv>
                                  {(() => {
                                      //console.log(conversations.length)
                                      //console.log(totalConversations)
                                      if (conversations.length >= 1 && conversations.length != totalConversations) {
                                          if (loadMoreConversations) {
                                              return (
                                                  <WrapLoadMoreMessage>
                                                      <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                                                  </WrapLoadMoreMessage>
                                              )

                                          }
                                          else {
                                              return (
                                                  <WrapLoadMoreMessage></WrapLoadMoreMessage>
                                              )
                                          }
                                      }

                                  })()}
                              </BusinessListConversations>

                          </>
                      )
                  } else {
                      return (
                          <div>
                              <p>Non ci sono ancora conversazioni</p>
                          </div>
                      )
                  }
              }
              else {

                  return (
                      <BoxLoader heightloader={props.heightList}>
                          <Loader width="40" height="40" color={Main_settings.color02} secondaryColor={Main_settings.color02} type="03" />
                      </BoxLoader>
                  )
              }

          })()}

        </>
  );
}

export default BusListConversations;
