import axios from 'axios';
import punycode from 'punycode/'
import branch from "../settings/branch"
import settings from "../settings/setting"
import moment from "moment";
const serverurl = settings[branch.name].server_url
const getlistconversation_endpoint = serverurl + "/console/action/getlistconversations"
const getlistconversationWA_endpoint = serverurl + "/console/action/getlistconversationsWA"
const getlistconversationMS_endpoint = serverurl + "/console/action/getlistconversationsMS"
const getconversation_endpoint = serverurl + "/console/action/getconversation"
const getmoremessages_endpoint = serverurl + "/console/action/getmoremessages"
const getmoreconversations_endpoint = serverurl + "/console/action/getmoreconversations"
const getexportconversation_endpoint = serverurl + "/console/action/getexportconversation"
const getconversationfile_endpoint = serverurl + "/console/action/getconversationfile"




const setmessagesasread_endpoint = serverurl + "/console/action/setmessagesasread"
const getexportconversationWA_endpoint = serverurl + "/console/action/getexportconversationWA"

const getexportconversationMS_endpoint = serverurl + "/console/action/getexportconversationMS"



const getconversationWA_endpoint = serverurl + "/console/action/getconversationWA"
const getmoremessagesWA_endpoint = serverurl + "/console/action/getmoremessagesWA"
const sendmessageconsole_endpoint = serverurl + "/api/messageconsole"
const sendmessageconsoleWA_endpoint = serverurl + "/whatsapp/messageconsole/chatapi"
const sendmessageconsoleMS_endpoint = serverurl + "/messenger/messageconsole"
const setmessagesasread_endpointWA = serverurl + "/console/action/setmessagesasreadWA"


const getconversationMS_endpoint = serverurl + "/console/action/getconversationMS"
const setmessagesasread_endpointMS = serverurl + "/console/action/setmessagesasreadMS"
const getmoremessagesMS_endpoint = serverurl + "/console/action/getmoremessagesMS"
//import {setSender} from "./getLocalStorage";
axios.defaults.withCredentials = true;

export const getMoreMessagesWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getmoremessagesWA_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}
export const getMoreMessages= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getmoremessages_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}
export const getMoreMessagesMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getmoremessagesMS_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getExportConversationWA= (data) => {
    return axios.post(getexportconversationWA_endpoint, data, {withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })
}


export const getExportConversationMS= (data) => {
    return axios.post(getexportconversationMS_endpoint, data, {withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })
}

export const getExportConversation= (data) => {
    return axios.post(getexportconversation_endpoint, data, {withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })
}


export const getMoreConversations= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getmoreconversations_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}
export const getListConversation= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistconversation_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListConversationWA = (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistconversationWA_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListConversationMS = (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistconversationMS_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}




export const getConversation= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getconversation_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getConversationWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getconversationWA_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}


export const getConversationMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getconversationMS_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setMessagesAsRead= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setmessagesasread_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setMessagesAsReadWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setmessagesasread_endpointWA + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setMessagesAsReadMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setmessagesasread_endpointMS + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const sendMessageConsole = (data) => {
    return axios.post(sendmessageconsole_endpoint, data)
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const sendMessageConsoleWA = async(type, data) =>  {
    try {
        console.log("DATA!!!")
        console.log(data)
        let senddata = await axios.post(sendmessageconsoleWA_endpoint, data)

        console.log("SEND DATA!")
        console.log(senddata)

        return senddata
    }
    catch (e) {
        console.log("errore")
        console.log(e)
    }



}

export const sendMessageConsoleMS = (data) => {
    return axios.post(sendmessageconsoleMS_endpoint, data)
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getConversationFile = (data) => {
    return axios.post(getconversationfile_endpoint, data, {withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

