import branch from "../settings/branch"
import settings from "../settings/setting"
import axios from 'axios';
axios.defaults.withCredentials = true;

const serverurl = settings[branch.name].server_url
const appcreate_endpoint = serverurl + "/console/action/createApp"
const appstandby_endpoint = serverurl + "/console/action/appstandby"
const appstandbyWA_endpoint = serverurl + "/console/action/appstandbyWA"
const appstandbyMS_endpoint = serverurl + "/console/action/appstandbyMS"
const setuserstandby_endpoint = serverurl + "/console/action/setuserstandby"
const getuserstandby_endpoint = serverurl + "/console/action/getuserstandby"

const setuserstandbyWA_endpoint = serverurl + "/console/action/setuserstandbyWA"
const getuserstandbyWA_endpoint = serverurl + "/console/action/getuserstandbyWA"

const setuserstandbyMS_endpoint = serverurl + "/console/action/setuserstandbyMS"
const getuserstandbyMS_endpoint = serverurl + "/console/action/getuserstandbyMS"

const getapp_endpoint = serverurl + "/console/action/getapp"
const getlistapp_endpoint = serverurl + "/console/action/getlistapp"
const getlistmodels_endpoint = serverurl + "/console/action/getlistmodels"

const getlistmodelsMS_endpoint = serverurl + "/console/action/getlistmodelsMS"
const getlistsavedrepliesMS_endpoint = serverurl + "/console/action/getlistsavedrepliesMS"

const deletesavedrepliesMS_endpoint = serverurl + "/console/action/deletesavedrepliesMS"

const savereplyconsoleMS_endpoint = serverurl + "/console/action/savedrepliesMS"

const createmodel_endpoint = serverurl + "/console/action/createmodel"
const deletemodel_endpoint = serverurl + "/console/action/deletemodel"
const editmodel_endpoint = serverurl + "/console/action/editmodel"



const getlegalconditions_endpoint = serverurl + "/console/action/getlegalconditions"

const setlegalconditions_endpoint = serverurl + "/console/action/setlegalconditions"

export const getApp= (name) => {
    const objJsonStr = JSON.stringify(name);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getapp_endpoint + '?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListApp = (app) => {
    return axios.get(getlistapp_endpoint,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListModels = (app) => {
    const objJsonStr = JSON.stringify(app);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistmodels_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListModelsMS = (app) => {
    const objJsonStr = JSON.stringify(app);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistmodelsMS_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const deleteModel = (data) => {
    return axios.post(deletemodel_endpoint, {data})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const createModel = (data) => {
    return axios.post(createmodel_endpoint, {data})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const editModel = (data) => {
    return axios.post(editmodel_endpoint, {data})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const saveReplyConsoleMS = (data) => {
    return axios.post(savereplyconsoleMS_endpoint, data)
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getListSavedRepliesMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlistsavedrepliesMS_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}

export const deleteSavedReplieMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(deletesavedrepliesMS_endpoint + "?param=" + objJsonB64,{withCredentials:true})
        .then(response => {
            if (response.data.error) {
                return response.data
            }
            else {
                return response.data
            }
        })
        .catch((error) => {
            return {error:error};
        })

}


export const callCreateApp= (dataForm) => {
    const objJsonStr = JSON.stringify(dataForm);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(appcreate_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}
export const callStandbyApp= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(appstandby_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const callStandbyAppWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(appstandbyWA_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const callStandbyAppMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(appstandbyMS_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setStandbyUser= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setuserstandby_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getStandbyUser= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getuserstandby_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}


export const setStandbyUserWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setuserstandbyWA_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getStandbyUserWA= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getuserstandbyWA_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}
export const getStandbyUserMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getuserstandbyMS_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setStandbyUserMS= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setuserstandbyMS_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const getLegalConditions= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(getlegalconditions_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}

export const setLegalConditions= (data) => {
    const objJsonStr = JSON.stringify(data);
    const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
    return axios.get(setlegalconditions_endpoint +'?param=' + objJsonB64,{withCredentials:true})
        .then(response => {
            return (response.data)
        })
        .catch((error) => {
            return {error:error};
        })

}



