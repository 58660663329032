import RobotoBlack from "./Roboto-Black.ttf";
import RobotoBlackItalic from "./Roboto-BlackItalic.ttf";
import RobotoBold from "./Roboto-Bold.ttf";
import RobotoBoldItalic from "./Roboto-BoldItalic.ttf";
import RobotoItalic from "./Roboto-Italic.ttf";
import RobotoLight from "./Roboto-Light.ttf"
import RobotoLightItalic from "./Roboto-LightItalic.ttf"
import RobotoMedium from "./Roboto-Medium.ttf"
import RobotoMediumItalic from "./Roboto-MediumItalic.ttf"
import RobotoRegular from "./Roboto-Regular.ttf"
import RobotoThin from "./Roboto-Thin.ttf";
import RobotoThinItalic from "./Roboto-ThinItalic.ttf"


export default RobotoFonts => `
    @font-face {font-family: "Roboto-Black";src: local("Roboto-Black"),url(${RobotoBlack}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Black-Italic";src: local("Roboto-Black-Italic"),url(${RobotoBlackItalic}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Bold";src: local("Roboto-Bold"),url(${RobotoBold}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Bold-Italic";src: local("Roboto-Bold-Italic"),url(${RobotoBoldItalic}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Italic";src: local("Roboto-Italic"),url(${RobotoItalic}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Light-Italic";src: local("Roboto-Light-Italic"),url(${RobotoLightItalic}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Light";src: local("Roboto-Light"),url(${RobotoLight}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Medium";src: local("Roboto-Medium"),url(${RobotoMedium}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Medium-Italic";src: local("Roboto-Medium-Italic"),url(${RobotoMediumItalic}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Regular";src: local("Roboto-Regular"),url(${RobotoRegular}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Thin";src: local("Roboto-Thin"),url(${RobotoThin}) format("truetype");font-display: fallback;}
    @font-face {font-family: "Roboto-Thin-Italic";src: local("Roboto-Thin-Italic"),url(${RobotoThinItalic}) format("truetype");font-display: fallback;}
`





