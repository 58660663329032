import styled from 'styled-components';

import {buttonSettings, ButtonRoundIconSettings, RoundIconSettings} from "./main_settings/Elements_settings";
import Main_settings from "../styles/main_settings/Main_settings";

export const Buttons = styled.input`
  border:0;
  border-radius: 10px;
  cursor:pointer;
`;

export const Button01 = styled.button`
  border:0;
  border-radius: ${buttonSettings.border_radius};
  cursor:pointer;
  display:flex;
  align-items:center;
  font-size:100%;
  justify-content:center;
  background:${buttonSettings.bg};
  padding:8px 35px;
  font-family:${buttonSettings.font_normal};
  color: ${buttonSettings.color_active};
  opacity:0.9;
  :hover {
    opacity:1;
  }
`;

export const Button011 = styled(Button01)`

  opacity:.5;
  cursor:default;
  font-family:${buttonSettings.font_inactive};
  :hover {
    opacity:.5;
  }
`;
export const Button02 = styled(Button01)`
    background:${buttonSettings.bglow};

`;

export const Button012 = styled.div`
  border:0;
  border-radius: ${buttonSettings.border_radius};
  cursor:pointer;
  display:flex;
  align-items:center;
  font-size:100%;
  justify-content:center;
  background:${buttonSettings.bg};
  padding:8px 35px;
  color: ${buttonSettings.color_active};
  :hover {
    opacity:1;
  }
  opacity:.5;
  cursor:default;
  font-family:${buttonSettings.font_inactive};
  :hover {
    opacity:.5;
  }
`;

export const Button013 = styled(Button012)`

  background:${buttonSettings.bg_selected};
  padding:8px 35px;
  color: ${buttonSettings.color_selected};

  opacity:1;
    :hover {
    opacity:1;
  }
  cursor:default;
`;

export const ButtonRoundIcon = styled.div`
    border-radius:${ButtonRoundIconSettings.border_radius};
    background:${ButtonRoundIconSettings.bg_inactive};
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    svg {
        width:50%;
        fill:${ButtonRoundIconSettings.fill_inactive};
    }
    :hover  {
         background:${ButtonRoundIconSettings.bg_active};
    }
    :hover svg {
        fill:${ButtonRoundIconSettings.fill_active};
    }
`;

export const ButtonIcon = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    svg {
        width:50%;
    }
`;

export const ButtonRoundIconActive = styled(ButtonRoundIcon)`
    background:${ButtonRoundIconSettings.bg_active};
    cursor:default;
    svg {
        width:50%;
        fill:${ButtonRoundIconSettings.fill_active};
    }
    :hover  {
         background:${ButtonRoundIconSettings.bg_active};
    }
    :hover svg {
        fill:${ButtonRoundIconSettings.fill_active};
    }
`;

export const RoundIcon = styled.div`
    border-radius:${RoundIconSettings.border_radius};
    display:flex;
    align-items:center;
    justify-content:center;
    svg {
        width:20%;
    }
`;


export const WrapLoader = styled.div`
   display:flex;
   align-items:center;
   justify-content:center; 
   
`;
export const IconImageApp = styled.img`
   width:${props => props.widthimg};
   height:${props => props.heightimg};
   border-radius:100%;
`;


export const WrapSvgNoUserImage = styled.div`
    
   svg {
        width:${props => props.widthimg};
   }
`;

export const IconImageUser = styled.img`
   width:${props => props.widthimg};
   height:${props => props.heightimg};
   border-radius:50%;
   aspect-ratio:1/1;
   
`;

export const Headerbar = styled.div`
    background:#fff;
    padding:10px;
    display:flex;
    justify-content:space-between;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

export const PanelUserLogged = styled.div`
  display:flex;
  margin-right:5px; 
`;

export const PanelUserProfile = styled.div`
   display:flex;
  align-items:center;
  justify-content:center;
  .usI534e svg {
    width:32px;
  }
`;

export const BoxLogo = styled.div`
  width:200px;
  cursor:pointer;
  display:flex;
  align-items:center;
  img {
    width:150px;
  }
`;
export const WrapperListAppHome = styled.div`
  border:solid 1px #000;
  margin-top:20px;
  
`;


export const BoxUserHeader = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;
export const BoxListAppHeader = styled.div`
    flex:1;
    display:flex;
    align-items:center;
    padding-left:40px;
    select {
        border:0;
        border-bottom:solid 1px #c4c4c4;
        padding:2px;
        cursor:pointer;
    }
`;
export const PanelUserProfileOpt = styled.ul`
    position:absolute;
    top:50px;
    right:20px;
    z-index:999999;
    background:${Main_settings.color04};
    box-shadow:${Main_settings.boxshadow05};
    border-radius:${Main_settings.border_radius04};
`;

export const ItemPanelUserProfileOpt = styled.li`
    list-style:none;
    min-width:200px;
    padding:10px 15px;
    text-align:left;
    cursor:pointer;
    :hover {
        background:${Main_settings.color08};
    }
`;

export const UserHeadProfImage = styled.div`
  cursor:pointer;
  display:flex;
  align-items:center;
  img {
    width:32px;
    border-radius:100%;
  }
  span {
    margin-left:10px;
  }
  svg {
    width:32px;
  }
`;








