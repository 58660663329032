import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { createGlobalStyle } from 'styled-components'
import {maincss} from "./styles/main";
import RobotoFonts from "../src/source/font/googlewebfonts/Roboto/Roboto"
const GlobalStyle = createGlobalStyle`
  ${RobotoFonts}
  ${maincss}
  
`
ReactDOM.render(
  <React.StrictMode>
      <GlobalStyle/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your api, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
