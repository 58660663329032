import React, { useState, useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


function ParserTemplComponentsUserList(props) {
    const properties = props
    const focusmessage = JSON.parse(properties.code)

    return (
        <>
             {(() => {
                if (focusmessage.type != "form") {
                    if (focusmessage.type === "file") {
                        return (
                            <>
                                {(() => {
                                    const lastFile = focusmessage.body[focusmessage.body.length -1]
                                    return (
                                        <>
                                            <span>File</span>
                                            {(() => {
                                                if (lastFile.caption) {
                                                    return (
                                                        <span>{lastFile.caption}</span>
                                                    )
                                                }

                                            })()}
                                        </>
                                        )

                                })()}
                            </>
                        )
                    }
                    if (focusmessage.type === "image") {
                        return (
                            <>
                                {(() => {
                                    const lastImage = focusmessage.body[focusmessage.body.length -1]
                                        return (
                                            <>
                                                <FontAwesomeIcon icon={["far,fa-file-image"]}/>
                                                {(() => {
                                                    if (lastImage.caption) {
                                                        return (
                                                            <span>{lastImage.caption}</span>
                                                        )
                                                    }

                                                })()}
                                            </>
                                        )
                                })()}
                           </>
                        )
                    }
                    if (focusmessage.type === "video") {
                        return (
                            <>
                                {(() => {
                                    const lastVideo = focusmessage.body[focusmessage.body.length -1]
                                    return (
                                        <>
                                            <FontAwesomeIcon icon={["far,fa-file-video"]}/>
                                            {(() => {
                                                if (lastVideo.caption) {
                                                    return (
                                                        <span>{lastVideo.caption}</span>
                                                    )
                                                }

                                            })()}
                                        </>
                                    )
                                })()}
                            </>
                        )
                    }
                    if (focusmessage.type === "position") {
                        return (
                                <>
                                    <span><FontAwesomeIcon icon={["fas, map-marker-alt"]}/>Position</span>
                                    {(() => {
                                        if (focusmessage.body.caption) {
                                            return (
                                                <span>{focusmessage.body.caption}</span>
                                            )
                                        }

                                    })()}
                                </>
                        )
                    }
                    if (focusmessage.type === "camera") {
                        return (
                            <div className="container_message">
                                {(() => {
                                    const lastItem = focusmessage.body[focusmessage.body.length -1]
                                    if (lastItem.type === "camera/image") {
                                        return (
                                            <>
                                                <FontAwesomeIcon icon={["far,fa-file-image"]}/>
                                                {(() => {
                                                    if (lastItem.caption) {
                                                        return (
                                                            <span>{lastItem.caption}</span>
                                                        )
                                                    }

                                                })()}
                                            </>
                                        )
                                    }
                                    else {
                                        return (
                                            <>
                                                <FontAwesomeIcon icon={["far,fa-file-video"]}/>
                                                {(() => {
                                                    if (lastItem.caption) {
                                                        return (
                                                            <span>{lastItem.caption}</span>
                                                        )
                                                    }

                                                })()}
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        )

                    }
                    else {
                        return (
                                <>
                                    <span> {(focusmessage.type === "button-callback") ? focusmessage.body.text_button : focusmessage.body  }</span>
                                </>

                        )
                    }

                }
                else {
                    return null
                }
            })()}
       </>
    )
}

export default ParserTemplComponentsUserList